/* Options:
Date: 2024-10-07 14:01:34
Version: 6.21
Tip: To override a DTO option, remove "//" prefix before updating
BaseUrl: http://localhost:5000

//GlobalNamespace: 
//MakePropertiesOptional: False
//AddServiceStackTypes: True
//AddResponseStatus: False
//AddImplicitVersion: 
//AddDescriptionAsComments: True
//IncludeTypes: 
//ExcludeTypes: 
//DefaultImports: 
*/


export interface IReturn<T>
{
    createResponse(): T;
}

export interface IReturnVoid
{
    createResponse(): void;
}

export interface IHasSessionId
{
    sessionId: string;
}

export interface IHasBearerToken
{
    bearerToken: string;
}

export interface IPost
{
}

// @DataContract
export class QueryBase
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    // @DataMember(Order=3)
    public orderBy: string;

    // @DataMember(Order=4)
    public orderByDesc: string;

    // @DataMember(Order=5)
    public include: string;

    // @DataMember(Order=6)
    public fields: string;

    // @DataMember(Order=7)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<QueryBase>) { (Object as any).assign(this, init); }
}

export interface IStoreGroupRequest
{
    siteId?: number;
    countryCode: string;
    stateCode: string;
}

export interface IPrimaryEntity
{
    id: number;
}

export interface ISiteReferenceEntity
{
    siteId: number;
}

export interface IDeletedEntity
{
    isDeleted: boolean;
}

export enum ProductInventorySkuType
{
    Default = 1,
    Custom = 2,
}

export interface IPrimaryEntityRequest
{
    id?: number;
}

export interface IPrimaryListEntityRequest
{
    ids: number[];
}

export interface ISiteReferenceRequest extends ISiteReferenceEntity
{
}

export interface ISearchEntityRequest
{
    search: string;
}

export enum OperationActionType
{
    None = 1,
    SoftDelete = 2,
    Duplicate = 3,
    NoValidate = 4,
}

export enum WidgetImageType
{
    Image = 1,
    Video = 2,
    External = 3,
}

export class ColumnLocalizeValue
{
    public recordId?: number;
    public type: string;
    public origin: string;
    public column: string;
    public isUserChange?: boolean;
    public isNeedSave?: boolean;

    public constructor(init?: Partial<ColumnLocalizeValue>) { (Object as any).assign(this, init); }
}

export class LocalizeValue extends ColumnLocalizeValue
{
    public lczKey: string;
    public lczValue: string;

    public constructor(init?: Partial<LocalizeValue>) { super(init); (Object as any).assign(this, init); }
}

export class BaseWidgetText
{
    public lczText: LocalizeValue;
    public isEnabled: boolean;
    public maxChars?: number;

    public constructor(init?: Partial<BaseWidgetText>) { (Object as any).assign(this, init); }
}

export enum WidgetButtonType
{
    Button = 1,
    Link = 2,
}

export enum WidgetButtonLabelType
{
    Text = 1,
    Icon = 2,
}

export enum WidgetButtonUrlType
{
    Page = 1,
    ExternalLink = 2,
    Email = 3,
    Phone = 4,
    File = 5,
    Popup = 6,
}

export enum WidgetButtonElementType
{
    Default = 1,
    Primary = 2,
    Secondary = 3,
    Link = 4,
    StorePrimary = 5,
    StoreSecondary = 6,
    BlogPrimary = 7,
    BlogSecondary = 8,
}

export enum WidgetButtonFormRefenceType
{
    MailingList = 1,
    TestimonialForm = 2,
}

export enum PageTemplateType
{
    Page = 1,
    Collection = 2,
    Product = 3,
    Blog = 4,
    Booking = 5,
    Events = 6,
    BlogPageList = 7,
    StoreCheckout = 8,
    StoreThankyouPage = 9,
    StoreShopingCart = 10,
}

export class BaseWidgetButtonPage
{
    public id: number;
    public slug: string;
    public linkType: PageTemplateType;

    public constructor(init?: Partial<BaseWidgetButtonPage>) { (Object as any).assign(this, init); }
}

export enum PageType
{
    Home = 1,
    Default = 2,
    AboutUs = 3,
    OurTeam = 4,
    Services = 5,
    Gallery = 6,
    Faq = 7,
    Testimonials = 8,
    ContactUs = 9,
    Subscribe = 10,
    Product = 11,
    Collection = 12,
    Booking = 13,
    Events = 14,
    Policy = 15,
    Landing = 16,
    Blog = 17,
    BlogPageList = 18,
    Career = 19,
    Resources = 20,
    Courses = 21,
    CourseDetails = 22,
    ProjectDetails = 23,
    Portfolio = 24,
    ServiceDetails = 25,
    Menu = 26,
    Press = 27,
    CookiePolicy = 28,
}

export class BaseWidgetButtonStyle
{
    public color: string;
    public backgroundColor: string;
    public borderColor: string;
    public hoverColor: string;
    public hoverBackgroundColor: string;
    public hoverBorderColor: string;

    public constructor(init?: Partial<BaseWidgetButtonStyle>) { (Object as any).assign(this, init); }
}

export class BaseWidgetButton
{
    public label: string;
    public lczText: LocalizeValue;
    public backgroundColor: string;
    public type: WidgetButtonType;
    public labelType: WidgetButtonLabelType;
    public urlType: WidgetButtonUrlType;
    public contactType: WidgetButtonUrlType;
    public elementType: WidgetButtonElementType;
    public formRefenceType?: WidgetButtonFormRefenceType;
    public page: BaseWidgetButtonPage;
    public defaultLinkingPageType?: PageType;
    public externalLink: string;
    public email: string;
    public phone: string;
    public file: string;
    public popUp: number;
    public isTargetBlank: boolean;
    public isClosePopup: boolean;
    public isEnabled: boolean;
    public isEnabledIcon: boolean;
    public style: BaseWidgetButtonStyle;

    public constructor(init?: Partial<BaseWidgetButton>) { (Object as any).assign(this, init); }
}

export class BaseWidgetImage
{
    public key?: number;
    public type: WidgetImageType;
    public name: string;
    public alt: string;
    public description: BaseWidgetText;
    public isEnabled?: boolean;
    public imageUrl: string;
    public videoUrl: string;
    public externalUrl: string;
    public imageLink: BaseWidgetButton;

    public constructor(init?: Partial<BaseWidgetImage>) { (Object as any).assign(this, init); }
}

export class ProductGroupOptionRecord
{
    public key: string;
    public groupKey: string;
    public optionKey: string;

    public constructor(init?: Partial<ProductGroupOptionRecord>) { (Object as any).assign(this, init); }
}

export enum InventoryStockType
{
    InStock = 1,
    OutOfStock = 2,
}

export interface IActionRequest
{
    action: OperationActionType;
}

export enum ShippingGroupCostType
{
    Free = 1,
    Cost = 2,
}

export interface IOwnerEntityRequest
{
    userAuthId?: number;
}

export interface IAuditEntity extends ICreatedAtEntity, IUpdatedAtEntity
{
}

export interface ICreatedAtEntity
{
    createdAt: string;
}

export interface IUpdatedAtEntity
{
    updatedAt: string;
}

export enum ProductWidgetType
{
    General = 1,
    Promotion = 2,
    Variations = 3,
    Actions = 4,
    ShippingAndReturn = 5,
    GarantedSafeCheckout = 6,
    ImageGallery = 7,
    AlreadyViewed = 8,
    SimilarProducts = 9,
    AdditionalInformation = 10,
}

export class ProductWidget
{
    public key?: number;
    public name: string;
    public type: ProductWidgetType;
    public settings: Object;
    public isEnabled: boolean;
    public isHiddenOnMobile: boolean;

    public constructor(init?: Partial<ProductWidget>) { (Object as any).assign(this, init); }
}

export interface ICreateDefaultProductRequest
{
    siteId: number;
    generalProductWidget: ProductWidget;
    actionsProductWidget: ProductWidget;
}

export interface IEntityLocalizeRequest
{
    languageCode: string;
}

export interface IEntitySetLczRequest extends IPrimaryEntity, IEntityLocalizeRequest
{
    columnName: string;
    key: string;
    value: string;
}

export class BaseGetEntityLczConfig extends QueryBase implements IGetEntityLczConfig
{
    public id?: number;
    public ids: number[];
    public languageCodes: string[];
    public isSelectEmpty: boolean;

    public constructor(init?: Partial<BaseGetEntityLczConfig>) { super(init); (Object as any).assign(this, init); }
}

export interface IGetEntityLczConfig extends IPrimaryEntityRequest, IPrimaryListEntityRequest
{
    languageCodes: string[];
    isSelectEmpty: boolean;
}

export interface IDisplayEntityRequest
{
    name: string;
}

export class BaseTranslateRecordRequest implements ITranslateRecordRequest
{
    public id?: number;
    public ids: number[];
    public languageCodes: string[];
    public useAI: boolean;
    public useHistory: boolean;
    public columns: string[];

    public constructor(init?: Partial<BaseTranslateRecordRequest>) { (Object as any).assign(this, init); }
}

export interface ITranslateRecordRequest extends IPrimaryEntityRequest, IPrimaryListEntityRequest
{
    languageCodes: string[];
    useAI: boolean;
    useHistory: boolean;
    columns: string[];
}

export class BaseBuildRecordLocalizeConfigRequest implements IBuildRecordLocalizeConfigRequest
{
    public id?: number;
    public ids: number[];
    public languageCode: string;

    public constructor(init?: Partial<BaseBuildRecordLocalizeConfigRequest>) { (Object as any).assign(this, init); }
}

export interface IBuildRecordLocalizeConfigRequest extends IPrimaryEntityRequest, IPrimaryListEntityRequest, IEntityLocalizeRequest
{
}

export interface ILocalizeRecordRequest extends IEntityLocalizeRequest
{
    recordId: number;
    columnKeys: string[];
    valueKeys: string[];
}

export interface IExportLczReport extends IPrimaryEntityRequest, IPrimaryListEntityRequest
{
}

export enum ProductOptionGroupType
{
    Size = 1,
    ColorAndImage = 2,
    Text = 3,
}

export class ProductOption
{
    public key: string;
    public label: LocalizeValue;
    public color: string;
    public imageUrl: string;
    public isEnabled?: boolean;

    public constructor(init?: Partial<ProductOption>) { (Object as any).assign(this, init); }
}

export class ProductOptionsGroup
{
    public key: string;
    public type: ProductOptionGroupType;
    public label: LocalizeValue;
    public isImageOptions: boolean;
    public options: ProductOption[];

    public constructor(init?: Partial<ProductOptionsGroup>) { (Object as any).assign(this, init); }
}

export enum PublishStatusType
{
    Active = 1,
    Inactive = 2,
}

export enum GetProductsSortType
{
    None = 0,
    Column = 1,
    Default = 2,
    DefaultDescending = 3,
    Price = 4,
    PriceDescending = 5,
}

export interface IProductFilterOptions
{
    tagLabels: string[];
    productGroupsDic: { [index: string]: string[]; };
    collectionId?: number;
    siteId: number;
}

export interface IOnlyActiveEntityRequest
{
    includeDeleted: boolean;
}

export interface ISiteLocalizeRequest extends IEntityLocalizeRequest, ISiteReferenceRequest
{
    siteLanguageCode: string;
}

export interface IPrimaryGreaterEntityRequest
{
    idGreaterThan?: number;
}

export enum UpdateItemOrderActionType
{
    Up = 1,
    Down = 2,
}

export enum ProductType
{
    Anything = 1,
    Physical = 2,
    Digital = 3,
}

export enum WidgetType
{
    AboutUs = 1,
    Banner = 2,
    Blog = 3,
    Booking = 4,
    ContactUs = 5,
    Collection = 6,
    Events = 7,
    Faq = 8,
    FeaturedItems = 9,
    Gallery = 10,
    Logos = 11,
    OurTeam = 12,
    PriceList = 13,
    Product = 14,
    Services = 15,
    Slider = 16,
    SmartBanner = 17,
    Subscribe = 18,
    Testimonials = 19,
    Text = 20,
    Video = 21,
    BlogTitle = 22,
    BlogText = 23,
    BlogImage = 24,
    BlogButton = 25,
    BlogDivider = 26,
    BlogGallery = 27,
    BlogVideo = 28,
    BlogImageWithText = 29,
    BlogQuote = 30,
    BlogSubscribe = 31,
    BlogActions = 32,
    BlogAboutAuthor = 33,
    BlogTopPosts = 34,
    BlogRecipe = 35,
    BlogFeaturedItems = 36,
    BlogSummary = 37,
    BlogTags = 38,
    ProductBundle = 39,
    ProductPromoBanner = 40,
    ProductAdditionalInfoProductDescription = 41,
    ProductAdditionalInfoFeatures = 42,
    ProductAdditionalInfoShippingAndDelivery = 43,
    ProductAdditionalInfoSizeAndFit = 44,
    ProductAdditionalInfoReviews = 45,
    ProductAdditionalInfo = 46,
    ProductFeaturedItems = 47,
    BlogDescriptionWithTitle = 48,
    Social = 49,
    Music = 50,
    Career = 51,
    Resources = 52,
    Menu = 53,
    Table = 54,
}

export class BaseWidget
{
    public key?: number;
    public name: string;
    public type: WidgetType;
    public settings: Object;
    public isEnabled: boolean;
    public isHiddenOnMobile: boolean;
    public isNew?: boolean;
    public isGeneric: boolean;

    public constructor(init?: Partial<BaseWidget>) { (Object as any).assign(this, init); }
}

export enum ProductPromotionWidgetType
{
    ShareOnSocialMedia = 1,
    GetDiscount = 2,
    ProductMiniBanner = 3,
}

export class EnabledSetting
{
    public isEnabled: boolean;

    public constructor(init?: Partial<EnabledSetting>) { (Object as any).assign(this, init); }
}

export enum ShareActionsType
{
    Link = 1,
    Facebook = 2,
    Messenger = 3,
    WhatsApp = 4,
    Telegram = 5,
    Twitter = 6,
    Linkedin = 7,
    Vimeo = 8,
}

export class PageSeo
{
    public isIndex: boolean;

    public constructor(init?: Partial<PageSeo>) { (Object as any).assign(this, init); }
}

export class WebhookEvent
{
    public id: string;
    public createTime: string;
    public eventVersion: string;
    public resourceType: string;
    public eventType: string;
    public summary: string;
    public resource: Object;
    public status: string;
    public transmissions: Object[];

    public constructor(init?: Partial<WebhookEvent>) { (Object as any).assign(this, init); }
}

export class PayPalWebHookData extends WebhookEvent
{

    public constructor(init?: Partial<PayPalWebHookData>) { super(init); (Object as any).assign(this, init); }
}

export class BasePayPalWebHook
{
    public body: string;
    public data: PayPalWebHookData;

    public constructor(init?: Partial<BasePayPalWebHook>) { (Object as any).assign(this, init); }
}

export enum OrderRefundType
{
    None = 1,
    Full = 2,
    SpecificOrderItem = 3,
    ShippingCosts = 4,
}

export enum ReportType
{
    Aggregate = 1,
    Details = 2,
}

export enum ReportColumn
{
    OrderCreatedYear = 1,
    OrderCreatedMonth = 2,
    OrderCreatedDay = 3,
    OrderCountryCodeByIp = 4,
    OrderPaymentStatus = 5,
    OrderTrafficSourceDomain = 6,
    OrderBillingCurrencyCode = 7,
    OrderCustomerId = 8,
    OrderTrafficSourceType = 9,
    OrderIsTestMode = 10,
    OrderBillingTotalPrice = 11,
    OrderBillingFirstName = 12,
    OrderBillingLastName = 13,
    OrderBillingEmail = 14,
    OrderBillingCity = 15,
    OrderCreatedAt = 16,
    OrderSiteId = 17,
    OrderId = 18,
    OrderCustomerReturningType = 19,
    OiProductId = 20,
    OiProductName = 21,
    OiSkuId = 22,
    OiSkuName = 23,
    OiCollectionId = 24,
    OiCollectionName = 25,
}

export enum ReporLongInFilterType
{
    OiSkuIds = 1,
    OiProductIds = 2,
    OrderPaymentStatusIds = 3,
    OrderShipmentStatusIds = 4,
    OrderPaymentMethodIds = 5,
    OrderCreatedYear = 6,
    OrderCreatedMonth = 7,
    OrderCreatedDay = 8,
    OrderSiteIds = 9,
    OiCollectionIds = 10,
}

export enum ReporStrInFilterType
{
    OrderBilingCountryCodes = 1,
    OrderCountryByIpCodes = 2,
    OrderTrafficSourceDomain = 3,
}

export enum ReporDecimalRangeFilterType
{
    OrderBillingTotalPrice = 1,
    OiPrice = 2,
}

export class ReporDecimalRangeFilter
{
    public from?: number;
    public to?: number;

    public constructor(init?: Partial<ReporDecimalRangeFilter>) { (Object as any).assign(this, init); }
}

export enum ReporDateRangeFilterType
{
    OrderCreatedAt = 1,
}

export class ReporDateRangeFilter
{
    public from?: string;
    public to?: string;

    public constructor(init?: Partial<ReporDateRangeFilter>) { (Object as any).assign(this, init); }
}

export enum ReporLikeFilterType
{
    OrderLast4Digits = 1,
    OrderPaypalEmail = 2,
}

export enum PaymentStatusType
{
    None = 1,
    Processing = 2,
    Pending = 3,
    Processed = 4,
    Canceled = 5,
    Declined = 6,
    Refund = 7,
    Chargeback = 8,
    PartialRefund = 9,
    PendingManual = 10,
    ProcessedManually = 11,
    CanceledManually = 12,
    RefundedManually = 13,
    RefundPending = 14,
    RefundFailed = 15,
    RefundCanceled = 16,
}

export enum ShipmentStatusType
{
    None = 1,
    Pending = 2,
    InTransit = 3,
    Delivered = 4,
    Returned = 5,
}

export enum PaymentMethodType
{
    CreditCard = 1,
    PayPal = 2,
    Manual = 3,
}

export enum SaveOrderActionType
{
    None = 1,
    ByCustomer = 2,
    BySeller = 3,
    ByAdmin = 4,
    SoftDelete = 5,
    Duplicate = 6,
    NoValidate = 7,
}

export enum StoreCheckoutOrderReceivingMethodType
{
    Delivery = 1,
    StorePickup = 2,
}

export enum OrderItemType
{
    Sku = 0,
    Bundle = 1,
}

export class UnitOrderItem implements IUnitOrderItem
{
    public id: string;
    public isRefunded: boolean;
    public productId: number;
    public skuId?: number;
    public basePrice: number;
    public salePrice?: number;
    public unitPrice: number;
    public couponDiscount: number;
    public saleDiscount: number;
    public totalDiscount: number;
    public finalPrice: number;
    public refundTotal: number;
    public quantity: number;

    public constructor(init?: Partial<UnitOrderItem>) { (Object as any).assign(this, init); }
}

export class OrderItem
{
    public id: string;
    public type: OrderItemType;
    public isRefunded: boolean;
    public productId: number;
    public productName: string;
    public skuId?: number;
    public skuName: string;
    public skuImage: BaseWidgetImage;
    public collectionId?: number;
    public collectionName: string;
    public skuConfig: ProductGroupOptionRecord[];
    public optionGroups: ProductOptionsGroup[];
    public units: UnitOrderItem[];
    public bundleItems: OrderItem[];
    public price: number;
    public salePrice?: number;
    public unitCouponDiscount: number;
    public unitSaleDiscount: number;
    public unitTotalDiscount: number;
    public unitFinalPrice: number;
    public cartItemBasePrice: number;
    public cartItemTotalDiscount: number;
    public refundTotal: number;
    public subTotal: number;
    public cartItemFinalPrice: number;
    public quantity: number;
    public productOptionLabels: string;
    public optionLabels: string[];

    public constructor(init?: Partial<OrderItem>) { (Object as any).assign(this, init); }
}

export class CartItemInput
{
    public skuId?: number;
    public bundleId?: number;
    public quantity: number;

    public constructor(init?: Partial<CartItemInput>) { (Object as any).assign(this, init); }
}

export class OrderNote
{
    public createdAt?: string;
    public userName: string;
    public message: string;

    public constructor(init?: Partial<OrderNote>) { (Object as any).assign(this, init); }
}

export enum FormFieldType
{
    Input = 1,
    TextArea = 2,
    DropDown = 3,
    Checkbox = 4,
    RadioButton = 5,
    Date = 6,
    Time = 7,
}

export enum FormFieldValidationRuleType
{
    LettersOnly = 1,
    NumbersOnly = 2,
    Text = 3,
    Email = 4,
    Phone = 5,
}

export class FieldOption
{
    public id?: number;
    public label: string;
    public lczText: LocalizeValue;
    public isEnabled?: boolean;

    public constructor(init?: Partial<FieldOption>) { (Object as any).assign(this, init); }
}

export class FormField
{
    public key?: number;
    public label: string;
    public lczText: LocalizeValue;
    public type: FormFieldType;
    public value: string;
    public validationRule?: FormFieldValidationRuleType;
    public isEnabled?: boolean;
    public isMandatory: boolean;
    public options: FieldOption[];

    public constructor(init?: Partial<FormField>) { (Object as any).assign(this, init); }
}

export class CustomField extends FormField
{

    public constructor(init?: Partial<CustomField>) { super(init); (Object as any).assign(this, init); }
}

export enum OrderReturnReasonType
{
    ChangedHisMind = 1,
    DefectiveDamagedItem = 2,
    IncomplereSetOfItems = 3,
    WrongItemPurchased = 4,
    ItemDoesNotMatchTheDescription = 5,
    DamagedPackaging = 6,
    DeliveryDelay = 7,
    CustomerIsFraud = 8,
    CustomReason = 9,
}

export interface ISiteClientEntity extends ISiteReferenceEntity
{
    clientId: number;
}

export enum DiscountStatus
{
    Active = 1,
    Inactive = 2,
}

export enum DiscountType
{
    Percentage = 1,
    AbsoluteValue = 2,
}

export enum DiscountAssignType
{
    Products = 1,
    Collections = 2,
    All = 3,
    Skus = 4,
}

export enum DiscountProductApplyType
{
    All = 1,
    MaxPriced = 2,
    MinPriced = 3,
}

export enum UpdateCustomerActionType
{
    None = 1,
    SoftDelete = 2,
    Duplicate = 3,
}

export class CustomerNote
{
    public createdAt: string;
    public userName: string;
    public message: string;

    public constructor(init?: Partial<CustomerNote>) { (Object as any).assign(this, init); }
}

export interface CustomerDbSavableFields
{
    billingFirstName: string;
    billingLastName: string;
    billingEmail: string;
    billingAddress: string;
    billingCity: string;
    billingZip: string;
    billingCountryCode: string;
    billingStateCode: string;
    billingStateId?: number;
    isEqualBillingToShipping?: boolean;
    shippingAddress: string;
    shippingCity: string;
    shippingZip: string;
    shippingCountryCode: string;
    shippingStateCode: string;
    shippingStateId?: number;
    phone: string;
    isCorporatePurchase?: boolean;
    vatId: string;
    loginEmail: string;
    notes: CustomerNote[];
    createdAt: string;
    updatedAt: string;
    siteId: number;
    stripeId: string;
    cardToken: string;
}

export interface IDeletedEntityRequest
{
    isDeleted?: boolean;
}

export enum SortType
{
    Manual = 1,
    Last = 2,
    Popular = 3,
}

export enum CollectionType
{
    Manual = 1,
    Automatic = 2,
}

export enum CollectionStructureType
{
    Simple = 1,
    WithSidebar = 2,
    WithFilters = 3,
}

export enum CollectionTopFilterType
{
    Filter = 1,
    Sort = 2,
    ShowItems = 3,
}

export enum CollectionProductElementType
{
    Rating = 1,
    ProductVariation = 2,
    AddToWishlist = 3,
    ShowMoreBtn = 4,
}

export enum CollectionSidebarWidgetType
{
    Collections = 1,
    Tags = 2,
    Price = 3,
    Filters = 4,
    Rating = 5,
    Subscribe = 7,
}

export class SidebarWidgetContentListItem
{
    public id: number;
    public order: number;
    public isEnabled: boolean;

    public constructor(init?: Partial<SidebarWidgetContentListItem>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ResponseError
{
    // @DataMember(Order=1)
    public errorCode: string;

    // @DataMember(Order=2)
    public fieldName: string;

    // @DataMember(Order=3)
    public message: string;

    // @DataMember(Order=4)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<ResponseError>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ResponseStatus
{
    // @DataMember(Order=1)
    public errorCode: string;

    // @DataMember(Order=2)
    public message: string;

    // @DataMember(Order=3)
    public stackTrace: string;

    // @DataMember(Order=4)
    public errors: ResponseError[];

    // @DataMember(Order=5)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<ResponseStatus>) { (Object as any).assign(this, init); }
}

export class BaseResponse implements IBaseResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<BaseResponse>) { (Object as any).assign(this, init); }
}

export class Tag
{
    public key: number;
    public label: string;
    public siteId?: number;

    public constructor(init?: Partial<Tag>) { (Object as any).assign(this, init); }
}

export enum CollectionFilterWidgetType
{
    Tags = 1,
    Price = 2,
    Rating = 3,
    Filters = 4,
}

export class CollectionFilterWidget
{
    public key: number;
    public type: CollectionFilterWidgetType;
    public isEnabled: boolean;
    public labelOverride: LocalizeValue;
    public contentListItems: SidebarWidgetContentListItem[];
    public tags: Tag[];

    public constructor(init?: Partial<CollectionFilterWidget>) { (Object as any).assign(this, init); }
}

export class PageStyleSettings
{
    public backgroundColor: string;
    public textColor: string;
    public fieldColor: string;
    public fieldBorderColor: string;
    public fieldBackgroundColor: string;
    public summaryColor: string;
    public summaryBackground: string;

    public constructor(init?: Partial<PageStyleSettings>) { (Object as any).assign(this, init); }
}

export class CollectionWidgetSettings
{
    public structureType: CollectionStructureType;
    public topFilterEnabledSettings: { [index: string]: EnabledSetting; };
    public productElementShowSettings: { [index: string]: EnabledSetting; };
    public sidebarWidgets: CollectionSidebarWidget[];
    public filterWidgets: CollectionFilterWidget[];
    public productItemButtonLabel: string;
    public styleSettings: PageStyleSettings;

    public constructor(init?: Partial<CollectionWidgetSettings>) { (Object as any).assign(this, init); }
}

export class FilterProductOption
{
    public groupLabel: string;
    public label: string;
    public color: string;
    public imageUrl: string;
    public count?: number;

    public constructor(init?: Partial<FilterProductOption>) { (Object as any).assign(this, init); }
}

export class FilterProductOptionGroup
{
    public key: string;
    public type: ProductOptionGroupType;
    public label: string;
    public options: FilterProductOption[];

    public constructor(init?: Partial<FilterProductOptionGroup>) { (Object as any).assign(this, init); }
}

export class GetProductGroupOptionsResponse extends BaseResponse
{
    public minPrice?: number;
    public maxPrice?: number;
    public productGroups: FilterProductOptionGroup[];

    public constructor(init?: Partial<GetProductGroupOptionsResponse>) { super(init); (Object as any).assign(this, init); }
}

export class GetProductInventorySkuResponse extends BaseResponse
{
    public id: number;
    public name: string;
    public key: string;
    public image: BaseWidgetImage;
    public price: number;
    public salePrice?: number;
    public isUseSalePrice?: boolean;
    public unitPrice: number;
    public sku: string;
    public skuConfig: ProductGroupOptionRecord[];
    public type: ProductInventorySkuType;
    public availableQuantity?: number;
    public inventoryStockType: InventoryStockType;
    public isDeleted: boolean;
    public productId: number;
    public productName: string;
    public productOptionGroups: ProductOptionsGroup[];

    public constructor(init?: Partial<GetProductInventorySkuResponse>) { super(init); (Object as any).assign(this, init); }
}

export enum ProductWidgetImageGalleryGroupType
{
    Slider = 1,
    FixedImages = 2,
    GalleryOnScroll = 3,
}

export enum ProductWidgetImageGalleryLayoutType
{
    Vertical = 1,
    Square = 2,
    Horizontal = 3,
    Combo = 4,
}

export enum ProductWidgetImageGalleryVariationType
{
    V1 = 1,
    V2 = 2,
    V3 = 3,
    V4 = 4,
}

export class ProductWidgetImageGallerySettings
{
    public isEnabledImageZoom: boolean;

    public constructor(init?: Partial<ProductWidgetImageGallerySettings>) { (Object as any).assign(this, init); }
}

export class ProductWidgetImageGallery
{
    public type: ProductWidgetType;
    public groupType: ProductWidgetImageGalleryGroupType;
    public layoutType: ProductWidgetImageGalleryLayoutType;
    public variationType: ProductWidgetImageGalleryVariationType;
    public settings: ProductWidgetImageGallerySettings;

    public constructor(init?: Partial<ProductWidgetImageGallery>) { (Object as any).assign(this, init); }
}

export class ProductWidgetSettings
{
    public widgetCategory: string;
    public widgetType: string;
    public imageGallery: ProductWidgetImageGallery;
    public widgets: ProductWidget[];
    public enabledSettings: { [index: string]: EnabledSetting; };

    public constructor(init?: Partial<ProductWidgetSettings>) { (Object as any).assign(this, init); }
}

export class GetProductsResponse extends BaseResponse implements IPrimaryEntity, ISiteReferenceEntity, IDeletedEntity, IAuditEntity, IOwnerEntity
{
    public id: number;
    public name: string;
    public nameLcz: ColumnLocalizeValue;
    public nameHtml: string;
    public nameHtmlLcz: ColumnLocalizeValue;
    public keywords: Tag[];
    public metaTitle: string;
    public metaDescription: string;
    public slug: string;
    public isUsePrice?: boolean;
    public isUseSalePrice?: boolean;
    public isCustomSlug?: boolean;
    public description: string;
    public descriptionLcz: ColumnLocalizeValue;
    public mainImage: BaseWidgetImage;
    public isEnabledRollOverPicture: boolean;
    public rollOverPictureImage: BaseWidgetImage;
    public isEnabledOptionGroups?: boolean;
    public type?: ProductType;
    public images: BaseWidgetImage[];
    public videoLinks: string[];
    public label: string;
    public isEnabledDimensions?: boolean;
    public height?: number;
    public width?: number;
    public weight?: number;
    public volume: string;
    public isAutomaticallyTrackInventory?: boolean;
    public defaultInventorySkuId?: number;
    public isEnabledSku?: boolean;
    public sku: string;
    public tags: Tag[];
    public optionGroups: ProductOptionsGroup[];
    public viewedCount: number;
    public publishStatus: PublishStatusType;
    public publishDate?: string;
    public createdAt: string;
    public updatedAt: string;
    public isDeleted: boolean;
    public pageType: PageType;
    public pageTemplateType: PageTemplateType;
    public siteId: number;
    public userAuthId?: number;
    public promotionId?: number;
    public widgets: BaseWidget[];
    public promotionWidgetSettings: { [index: string]: EnabledSetting; };
    public shareActions: { [index: string]: EnabledSetting; };
    public rating?: number;
    public isDefault?: boolean;
    public inventoryStockType?: InventoryStockType;
    public availableQuantity?: number;
    public price?: number;
    public salePrice?: number;
    public skus: GetProductInventorySkuResponse[];
    public widgetSettings: ProductWidgetSettings;
    public defaultInventorySku: GetProductInventorySkuResponse;
    public activeInventorySku: GetProductInventorySkuResponse;
    public defaultProductId?: number;
    public skuList: number[];
    public seoSettings: PageSeo;

    public constructor(init?: Partial<GetProductsResponse>) { super(init); (Object as any).assign(this, init); }
}

export class GetCollectionsResponse extends BaseResponse implements IPrimaryEntity, ISiteReferenceEntity, IDeletedEntity
{
    public id: number;
    public name: string;
    public nameLcz: ColumnLocalizeValue;
    public keywords: Tag[];
    public metaTitle: string;
    public metaDescription: string;
    public slug: string;
    public isCustomSlug?: boolean;
    public sortType: SortType;
    public image: BaseWidgetImage;
    public usePresetsOfCollections?: boolean;
    public publishStatus: PublishStatusType;
    public publishDate?: string;
    public type: CollectionType;
    public includeAllProducts: boolean;
    public productIds: number[];
    public byOptionsOrTagsProductIds: number[];
    public createdAt: string;
    public updatedAt: string;
    public isDeleted: boolean;
    public siteId: number;
    public productCount: number;
    public pageType: PageType;
    public pageTemplateType: PageTemplateType;
    public widgetSettings: CollectionWidgetSettings;
    public widgets: BaseWidget[];
    public productTagLabels: string[];
    public productGroupsDic: { [index: string]: string[]; };
    public productGroupOptions: GetProductGroupOptionsResponse;
    public products: QueryResponse<GetProductsResponse>;
    public seoSettings: PageSeo;

    public constructor(init?: Partial<GetCollectionsResponse>) { super(init); (Object as any).assign(this, init); }
}

export class BaseWidgetDescription
{
    public text: string;
    public lczText: LocalizeValue;
    public isEnabled: boolean;
    public maxChars: number;
    public isTextAnimationEnabled: boolean;

    public constructor(init?: Partial<BaseWidgetDescription>) { (Object as any).assign(this, init); }
}

export class BaseWidgetInputPlaceholder
{
    public lczText: LocalizeValue;
    public isEnabled: boolean;
    public maxChars: number;

    public constructor(init?: Partial<BaseWidgetInputPlaceholder>) { (Object as any).assign(this, init); }
}

export class BaseWidgetTitle
{
    public text: string;
    public lczText: LocalizeValue;
    public isEnabled: boolean;
    public tag: string;
    public maxChars: number;
    public isTextAnimationEnabled: boolean;

    public constructor(init?: Partial<BaseWidgetTitle>) { (Object as any).assign(this, init); }
}

export class BaseWidgetContactFormStyle
{
    public labelColor: string;
    public fieldColor: string;
    public fieldBorderColor: string;
    public fieldBackgroundColor: string;

    public constructor(init?: Partial<BaseWidgetContactFormStyle>) { (Object as any).assign(this, init); }
}

export class BaseWidgetSubscribeForm
{
    public mailingListId: number;
    public inputPlaceholder: BaseWidgetInputPlaceholder;
    public button: BaseWidgetButton;
    public confirmationTitle: BaseWidgetTitle;
    public confirmationDescription: BaseWidgetDescription;
    public confirmationImage: BaseWidgetImage;
    public style: BaseWidgetContactFormStyle;

    public constructor(init?: Partial<BaseWidgetSubscribeForm>) { (Object as any).assign(this, init); }
}

export class SidebarSubscribeWidget
{
    public description: BaseWidgetDescription;
    public formSettings: BaseWidgetSubscribeForm;

    public constructor(init?: Partial<SidebarSubscribeWidget>) { (Object as any).assign(this, init); }
}

export class CollectionSidebarWidget
{
    public key: number;
    public type: CollectionSidebarWidgetType;
    public isEnabled: boolean;
    public labelOverride: LocalizeValue;
    public contentListItems: SidebarWidgetContentListItem[];
    public collections: GetCollectionsResponse[];
    public tags: Tag[];
    public featuresPromoProducts: GetProductsResponse[];
    public subscribe: SidebarSubscribeWidget;

    public constructor(init?: Partial<CollectionSidebarWidget>) { (Object as any).assign(this, init); }
}

export class GetCartSkuItem
{
    public skuId: number;
    public quantity: number;

    public constructor(init?: Partial<GetCartSkuItem>) { (Object as any).assign(this, init); }
}

export class GetCartBundleItem
{
    public bundleId: number;
    public quantity: number;

    public constructor(init?: Partial<GetCartBundleItem>) { (Object as any).assign(this, init); }
}

export enum BundlePriceType
{
    FinalPrice = 1,
    PercentageDiscount = 2,
}

export class BundleItem
{
    public skuId: number;
    public quantity: number;

    public constructor(init?: Partial<BundleItem>) { (Object as any).assign(this, init); }
}

export enum SignupWizardStepType
{
    Landing = 0,
    UserEmail = 1,
    BusinessCategory = 2,
    MagicIntro1 = 3,
    Services = 4,
    Sentences = 5,
    Location = 6,
    Schedule = 7,
    MagicIntro2 = 8,
    BussinessName = 9,
    BusinessUniqueness = 10,
    ContactInfo = 11,
    MagicIntro3 = 12,
    ThemeSelection = 13,
    PutAllTogather = 14,
    Completed = 15,
}

export enum WizardLocationType
{
    Marker = 1,
    Area = 2,
}

export enum ContactType
{
    Name = 1,
    Address = 2,
    Phone = 3,
    Email = 4,
    ThroughWebsite = 5,
}

export class WizardLandingData
{
    public email: string;

    public constructor(init?: Partial<WizardLandingData>) { (Object as any).assign(this, init); }
}

export class WizardBusinessCategoryData
{
    public id: number;
    public name: string;
    public isCustom: boolean;

    public constructor(init?: Partial<WizardBusinessCategoryData>) { (Object as any).assign(this, init); }
}

export class WizardSelectedService
{
    public id: string;
    public isCustom: boolean;
    public title: string;
    public description: string;

    public constructor(init?: Partial<WizardSelectedService>) { (Object as any).assign(this, init); }
}

export class WizardServicesData
{
    public services: WizardSelectedService[];

    public constructor(init?: Partial<WizardServicesData>) { (Object as any).assign(this, init); }
}

export class WizardSentencesItemData
{
    public appId: number;
    public appName: string;
    public name: string;

    public constructor(init?: Partial<WizardSentencesItemData>) { (Object as any).assign(this, init); }
}

export class WizardSentencesData
{
    public sentences: WizardSentencesItemData[];

    public constructor(init?: Partial<WizardSentencesData>) { (Object as any).assign(this, init); }
}

export class WidgetMapLocation
{
    public lat: number;
    public lng: number;

    public constructor(init?: Partial<WidgetMapLocation>) { (Object as any).assign(this, init); }
}

export class WizardLocationData
{
    public locationType: WizardLocationType;
    public locationTypeName: string;
    public locationAddress: string;
    public countryCode: string;
    public countryName: string;
    public regionName: string;
    public cityName: string;
    public routeName: string;
    public streetNumber: string;
    public postalCode: string;
    public latLng: WidgetMapLocation;

    public constructor(init?: Partial<WizardLocationData>) { (Object as any).assign(this, init); }
}

export class WizardBusinessNameData
{
    public businessName: string;

    public constructor(init?: Partial<WizardBusinessNameData>) { (Object as any).assign(this, init); }
}

export class WizardBusinessUniquenessItemData
{
    public id: number;
    public name: string;

    public constructor(init?: Partial<WizardBusinessUniquenessItemData>) { (Object as any).assign(this, init); }
}

export class WizardBusinessUniquenessData
{
    public uniqueness: WizardBusinessUniquenessItemData[];

    public constructor(init?: Partial<WizardBusinessUniquenessData>) { (Object as any).assign(this, init); }
}

export class WizardContactInfoItemData
{
    public contactType: ContactType;
    public value: string;

    public constructor(init?: Partial<WizardContactInfoItemData>) { (Object as any).assign(this, init); }
}

export class WizardContactInfoData
{
    public infos: WizardContactInfoItemData[];

    public constructor(init?: Partial<WizardContactInfoData>) { (Object as any).assign(this, init); }
}

export enum WizardThemeSelectionStepType
{
    Option = 1,
    Palettes = 2,
    Fonts = 3,
}

export class WizardThemeSelectionData
{
    public themeStepType: WizardThemeSelectionStepType;
    public optionId: number;
    public colorId: number;
    public fontId: number;

    public constructor(init?: Partial<WizardThemeSelectionData>) { (Object as any).assign(this, init); }
}

export interface IAnalyticsTracking
{
    utmSource: string;
    utmMedium: string;
    utmCampaign: string;
    utmTerm: string;
    utmContent: string;
}

export interface IOwnerEntity
{
    userAuthId?: number;
}

export enum SubscriptionPlanDiscountOfferType
{
    Default = 1,
    DiscountOffer = 2,
    BlackFriday = 3,
    CyberMonday = 4,
    LimitedOffer = 5,
    ThreeMonths = 6,
    ChristmasOffer = 7,
    NewYearOffer = 8,
    FebruaryOffer = 9,
    ValentiesOffer = 10,
    MarchOffer = 11,
    AprilOffer = 12,
    MoveToYearRenewDomainOffer = 13,
    JuneOffer = 14,
    YearlyOffer = 15,
    SeptemberOffer = 16,
    OctoberOffer = 17,
    MobileOffer = 18,
}

export enum HocoosSubscriptionPlanPeriod
{
    Month = 1,
    Year = 2,
    ThreeMonths = 200,
}

// @DataContract
export enum UpgradeModalTypes
{
    Default = 1,
    DomainPurchase = 2,
    ImageUploadLimitReach = 3,
    AiGeneration = 4,
    PaymentConnect = 5,
    CustomMailbox = 6,
    Integrations = 7,
    ShutterStockImages = 8,
    Campaign = 9,
    Copyright = 10,
    Dashboard = 11,
    FreeShutterStockImages = 12,
    VideoUploadLimitReach = 13,
    CustomFavicon = 14,
    AiImageGeneration = 15,
    AudioUploadLimitReach = 16,
    FileUploadLimitReach = 17,
    SubscriptionInfo = 18,
    EditorTopBanner = 19,
    LogoGeneration = 20,
    ConnectDomain = 21,
    OfferPopupAfterSiteCreation = 22,
    AiCreditsLimitReached = 23,
    AiToneOfVoiceClicked = 24,
    AiCreditsClicked = 25,
    SecondDayNoUpgradePromotion = 26,
    SearchSiteContent = 27,
    LogoGenerationLimitReached = 28,
    BookingFormEditingBtnClicked = 29,
    BookingFormEditingEditFields = 30,
    ContactUsFormEditingBtnClicked = 31,
    ContactUsFormEditingEditFields = 32,
    FeedBackFormEditingBtnClicked = 33,
    FeedBackFormEditingEditFields = 34,
    AccountSettingsSideBanner = 35,
    AiBlogGenerationLimitReached = 36,
    AiImageGenerationLimitReached = 37,
    MobileUploader = 38,
    CookieBanner = 39,
}

export class GeneralStepInfo
{
    public question: string;
    public answer: string;

    public constructor(init?: Partial<GeneralStepInfo>) { (Object as any).assign(this, init); }
}

export enum ReasonStepType
{
    TooExpensive = 1,
    TechnicalIssues = 2,
    BetterAlternative = 3,
    BusinessClosesDown = 4,
    LackOfNecessaryFeatures = 5,
    TooComplex = 6,
    Other = 7,
}

export class ReasonStep extends GeneralStepInfo
{
    public answerType: ReasonStepType;
    public answerTypeCode: string;
    public answerTypeIndex: number;
    public otherDetails: string;

    public constructor(init?: Partial<ReasonStep>) { super(init); (Object as any).assign(this, init); }
}

export enum BetterAlternativeFeatureType
{
    EditingFlexibility = 1,
    BookingFeatures = 2,
    StoreFeatures = 3,
    IntegrationsFlexibility = 4,
    FairPricing = 5,
    GoodReputation = 6,
    Other = 7,
}

export class BetterAlternativeInfo
{
    public answerType: BetterAlternativeFeatureType;
    public answerTypeCode: string;
    public answerTypeIndex: number;
    public otherDetails: string;

    public constructor(init?: Partial<BetterAlternativeInfo>) { (Object as any).assign(this, init); }
}

export class FeatureToStayInfo extends GeneralStepInfo
{
    public otherDetails: string;
    public isInformWhenFeaturesAvailable: boolean;

    public constructor(init?: Partial<FeatureToStayInfo>) { super(init); (Object as any).assign(this, init); }
}

export class TechnicalIssuesInfo extends GeneralStepInfo
{
    public otherDetails: string;

    public constructor(init?: Partial<TechnicalIssuesInfo>) { super(init); (Object as any).assign(this, init); }
}

export class TooComplexInfo extends GeneralStepInfo
{
    public otherDetails: string;

    public constructor(init?: Partial<TooComplexInfo>) { super(init); (Object as any).assign(this, init); }
}

export class CancelReasonDetailStep extends GeneralStepInfo
{
    public betterAlternative: BetterAlternativeInfo;
    public featuresToStay: FeatureToStayInfo;
    public technicalIssues: TechnicalIssuesInfo;
    public tooComplex: TooComplexInfo;

    public constructor(init?: Partial<CancelReasonDetailStep>) { super(init); (Object as any).assign(this, init); }
}

export class GeneralRatingStepInfo extends GeneralStepInfo
{
    public value: number;

    public constructor(init?: Partial<GeneralRatingStepInfo>) { super(init); (Object as any).assign(this, init); }
}

export class ExperienceInfo extends GeneralRatingStepInfo
{

    public constructor(init?: Partial<ExperienceInfo>) { super(init); (Object as any).assign(this, init); }
}

export class RecommendUsToFriendInfo extends GeneralRatingStepInfo
{

    public constructor(init?: Partial<RecommendUsToFriendInfo>) { super(init); (Object as any).assign(this, init); }
}

export class CancelRatingStep
{
    public experience: ExperienceInfo;
    public recommendUsToFriend: RecommendUsToFriendInfo;

    public constructor(init?: Partial<CancelRatingStep>) { (Object as any).assign(this, init); }
}

export enum OfferToStayType
{
    YearBillingCycleDiscount = 1,
    FreeCustomMailBox = 2,
    IncreaseStorageImageAndAI = 3,
    DomainPurchaseDiscount = 4,
    NoThanks = 5,
    Other = 6,
}

export class OfferToStayInfo
{
    public answerType: OfferToStayType;
    public answerTypeCode: string;
    public answerTypeIndex: number;
    public otherDetails: string;

    public constructor(init?: Partial<OfferToStayInfo>) { (Object as any).assign(this, init); }
}

export class CouponInfo
{
    public isSuspendSite: boolean;

    public constructor(init?: Partial<CouponInfo>) { (Object as any).assign(this, init); }
}

export class CancelFinishStep extends GeneralStepInfo
{
    public offerToStay: OfferToStayInfo;
    public coupon: CouponInfo;

    public constructor(init?: Partial<CancelFinishStep>) { super(init); (Object as any).assign(this, init); }
}

export enum HocoosSubscriptionPlan
{
    FreeTrial = 1,
    Paid = 2,
    Suspend = 3,
    FreePremium = 4,
}

export enum SubscriptionPlanSuspendType
{
    None = 0,
    PaymentFailed = 1,
    Refund = 2,
    Chargeback = 3,
    PayProSubscriptionCancel = 4,
    PayProSubscriptionFinished = 5,
    PayProSubscriptionChargeFailed = 6,
    HocoosSubscriptionCancel = 7,
}

export enum SiteSuspendType
{
    EndedFreePeriod = 1,
    EndedPaidPeriod = 2,
    SiteDeactivated = 3,
    EndedFreePremiumPeriod = 4,
}

export enum SiteInitializationStatus
{
    SiteCreated = 1,
    PagesCreated = 2,
}

export enum LocalizableLanguage
{
    Default = 0,
    English = 1,
    Ukrainian = 2,
    Spanish = 3,
    ChineseTraditional = 4,
    HindiIndia = 5,
    French = 6,
    ArabicEgypt = 7,
    BengaliBangladesh = 8,
    Russian = 9,
    Portuguese = 10,
    UrduPakistan = 11,
    Indonesian = 12,
    German = 13,
    JapaneseTraditional = 14,
    Hebrew = 15,
    Italian = 16,
    Turkish = 17,
    Polish = 18,
    Dutch = 19,
    Korean = 20,
    PortugueseBrazil = 21,
    ChineseSimplified = 22,
}

export enum UpdateSiteActionType
{
    None = 1,
    GeneralSettings = 2,
    SoftDelete = 3,
}

export class HomePageSetting
{
    public pageTemplateType: PageTemplateType;
    public pageId?: number;

    public constructor(init?: Partial<HomePageSetting>) { (Object as any).assign(this, init); }
}

export enum LayoutType
{
    Horizontal = 1,
    Sidebar = 2,
    Fullscreen = 3,
}

export enum MenuType
{
    Flat = 1,
    Tree = 2,
    Mega = 3,
}

export enum HeaderAlignType
{
    Left = 1,
    Right = 2,
}

export enum MenuLinkType
{
    ExsistingPages = 1,
    ExternalUrl = 2,
    InternalScroll = 3,
}

export class MenuItem
{
    public key?: number;
    public name: LocalizeValue;
    public pageId?: number;
    public pageSlug: string;
    public externalUrl: string;
    public linkType: MenuLinkType;
    public linkPageType: PageTemplateType;
    public pageType: PageType;
    public isEnabled: boolean;
    public isTargetBlank: boolean;
    public isMore: boolean;
    public anchorTarget: string;
    public image: BaseWidgetImage;
    public children: MenuItem[];
    public depth?: number;
    public description: string;

    public constructor(init?: Partial<MenuItem>) { (Object as any).assign(this, init); }
}

export enum HeaderSettingType
{
    Logo = 1,
    Emails = 2,
    Phones = 3,
    Search = 4,
    SocialNetworks = 5,
    Cart = 6,
    Wishlist = 7,
}

export class EnabledElementSetting extends EnabledSetting
{
    public displayLabel?: boolean;
    public labelOverride: LocalizeValue;

    public constructor(init?: Partial<EnabledElementSetting>) { super(init); (Object as any).assign(this, init); }
}

export enum ShoppingCartType
{
    DropDown = 1,
    Sidebar = 2,
}

export enum HeaderStructureType
{
    T1V1 = 1,
}

export enum HeaderMenuAlignType
{
    Left = 1,
    Center = 2,
    Right = 3,
}

export enum MenuAnimationType
{
    Simple = 1,
    Dynamic = 2,
    Modern = 3,
}

export class BaseCommunicationItem implements ICommunicationItem
{
    public communicationId?: number;
    public value: string;

    public constructor(init?: Partial<BaseCommunicationItem>) { (Object as any).assign(this, init); }
}

export class Phone extends BaseCommunicationItem
{
    public isLabelEnabled: boolean;
    public label: LocalizeValue;
    public description: LocalizeValue;
    public number: string;
    public value: string;

    public constructor(init?: Partial<Phone>) { super(init); (Object as any).assign(this, init); }
}

export class Email extends BaseCommunicationItem
{
    public isLabelEnabled: boolean;
    public label: LocalizeValue;
    public description: LocalizeValue;
    public address: string;
    public value: string;

    public constructor(init?: Partial<Email>) { super(init); (Object as any).assign(this, init); }
}

export enum SocialNetworkType
{
    Facebook = 1,
    Instagram = 2,
    Twitter = 3,
    Youtube = 4,
    Whatsapp = 5,
    Linkedin = 6,
    Telegram = 7,
    Dribbble = 8,
    Behance = 9,
    Pinterest = 10,
    Vk = 11,
    Vimeo = 12,
    SpotifyPodcasts = 13,
    ApplePodcasts = 14,
    TikTok = 15,
}

export class SocialNetwork extends BaseCommunicationItem
{
    public type: SocialNetworkType;
    public isLabelEnabled: boolean;
    public label: LocalizeValue;
    public url: string;
    public value: string;

    public constructor(init?: Partial<SocialNetwork>) { super(init); (Object as any).assign(this, init); }
}

export class Location extends BaseCommunicationItem
{
    public address: string;
    public isLabelEnabled: boolean;
    public label: LocalizeValue;
    public latLng: WidgetMapLocation;
    public value: string;

    public constructor(init?: Partial<Location>) { super(init); (Object as any).assign(this, init); }
}

export class Search
{

    public constructor(init?: Partial<Search>) { (Object as any).assign(this, init); }
}

export class SiteHeaderSettings implements ICommunicationEntity
{
    public enabledSettings: { [index: string]: EnabledElementSetting; };
    public shoppingCartType: ShoppingCartType;
    public structureType: HeaderStructureType;
    public menuAlignType: HeaderMenuAlignType;
    public menuAnimationType: MenuAnimationType;
    public phones: Phone[];
    public emails: Email[];
    public socialNetworks: SocialNetwork[];
    public locations: Location[];
    public search: Search;
    public contactsOrder: HeaderSettingType[];
    public button: BaseWidgetButton;
    public isSticky: boolean;

    public constructor(init?: Partial<SiteHeaderSettings>) { (Object as any).assign(this, init); }
}

export enum LogoType
{
    Image = 1,
    Text = 2,
    HideLogo = 3,
}

export class Logo
{
    public type: LogoType;
    public image: BaseWidgetImage;
    public name: string;

    public constructor(init?: Partial<Logo>) { (Object as any).assign(this, init); }
}

export enum FooterStructureType
{
    T1V1 = 1,
    T1V2 = 2,
    T1V3 = 3,
    T1V4 = 4,
    T2V1 = 5,
    T2V2 = 6,
    T2V3 = 7,
    T2V4 = 8,
    T3V1 = 9,
    T3V2 = 10,
    T3V3 = 11,
    T3V4 = 12,
    T3V5 = 13,
    T3V6 = 14,
    T3V7 = 15,
    T4V1 = 16,
    T4V2 = 17,
    T4V3 = 18,
    T4V4 = 19,
    T4V5 = 20,
    T5V1 = 21,
    T5V2 = 22,
    T5V3 = 23,
    T5V4 = 24,
    T5V5 = 25,
    T5V6 = 26,
}

export enum FooterSettingType
{
    Contacts = 1,
    SocialNetworks = 2,
    Newsletter = 3,
    Policies = 4,
    Links = 5,
    WorkingTime = 6,
    Logo = 7,
    Copyright = 8,
    BackgroundImage = 9,
}

export class FooterContact implements ICommunicationItem
{
    public key?: number;
    public type: ContactType;
    public communicationId?: number;
    public value: string;
    public isHiddenIcon: boolean;

    public constructor(init?: Partial<FooterContact>) { (Object as any).assign(this, init); }
}

export class NewsletterConfirmationStyle
{
    public color: string;
    public background: string;

    public constructor(init?: Partial<NewsletterConfirmationStyle>) { (Object as any).assign(this, init); }
}

export class NewsletterConfirmationVisibleElements
{
    public isEnabledTitle: boolean;
    public isEnabledDescription: boolean;
    public isEnabledImage: boolean;

    public constructor(init?: Partial<NewsletterConfirmationVisibleElements>) { (Object as any).assign(this, init); }
}

export class NewsletterConfirmation
{
    public title: LocalizeValue;
    public description: LocalizeValue;
    public image: BaseWidgetImage;
    public style: NewsletterConfirmationStyle;
    public visibleElements: NewsletterConfirmationVisibleElements;

    public constructor(init?: Partial<NewsletterConfirmation>) { (Object as any).assign(this, init); }
}

export class Newsletter
{
    public mailingListId?: number;
    public title: LocalizeValue;
    public button: BaseWidgetButton;
    public confirmation: NewsletterConfirmation;
    public buttonText: LocalizeValue;

    public constructor(init?: Partial<Newsletter>) { (Object as any).assign(this, init); }
}

export enum PolicyContentType
{
    File = 1,
    Url = 2,
    Content = 3,
}

export class Policy
{
    public key?: number;
    public pageId?: number;
    public pageSlug: string;
    public title: string;
    public type: PolicyContentType;
    public url: string;
    public filePath: string;
    public content: string;
    public isActive: boolean;

    public constructor(init?: Partial<Policy>) { (Object as any).assign(this, init); }
}

export class WorkingDay
{
    public key?: number;
    public startHour?: number;
    public startMinute?: number;
    public startTimeFormat: string;
    public endHour?: number;
    public endMinute?: number;
    public endTimeFormat: string;
    public isOpened: boolean;

    public constructor(init?: Partial<WorkingDay>) { (Object as any).assign(this, init); }
}

export class WorkingTime
{
    public isAlwaysActive: boolean;
    public isMergedSchedule: boolean;
    public mon: WorkingDay;
    public tue: WorkingDay;
    public wed: WorkingDay;
    public thu: WorkingDay;
    public fri: WorkingDay;
    public sat: WorkingDay;
    public sun: WorkingDay;

    public constructor(init?: Partial<WorkingTime>) { (Object as any).assign(this, init); }
}

export class SiteFooterSettings implements ICommunicationEntity
{
    public structureType?: FooterStructureType;
    public enabledSettings: { [index: string]: EnabledElementSetting; };
    public contacts: FooterContact[];
    public socialNetworks: { [index: string]: BaseCommunicationItem; };
    public showUsernameInFooterSocialNetworks: boolean;
    public newsletter: Newsletter;
    public policies: Policy[];
    public workingTime: WorkingTime;
    public backroundImage: BaseWidgetImage;

    public constructor(init?: Partial<SiteFooterSettings>) { (Object as any).assign(this, init); }
}

export class ThemeStyles
{
    public bodyBg: string;
    public bodyColor: string;
    public bodyFontFamily: string;
    public bodyFontSize: string;
    public bodyFontWeight: string;
    public bodyFontStyle: string;
    public bodyLineHeight: string;
    public bodyTextTransform: string;
    public bodyTextLetterSpacing: string;
    public primaryFont: string;
    public secondaryFont: string;
    public pageContainer: string;
    public primaryColor: string;
    public secondaryColor: string;
    public accentColor: string;
    public mutedColor: string;
    public fifthColor: string;
    public sixthColor: string;
    public seventhColor: string;
    public whiteColor: string;
    public blackColor: string;
    public transparentColor: string;
    public primaryBorder: string;
    public secondaryBorder: string;
    public accentBorder: string;
    public borderRadius: string;
    public headerBg: string;
    public headerColor: string;
    public headerHoverColor: string;
    public headerActiveColor: string;
    public headerFontFamily: string;
    public headerFontSize: string;
    public headerFontWeight: string;
    public headerFontStyle: string;
    public headerLineHeight: string;
    public headerTextTransform: string;
    public headerStickyBg: string;
    public headerStickyColor: string;
    public headerStickyOpacityPercentage: string;
    public headerStickyBlur: string;
    public backToTopColor: string;
    public backToTopBg: string;
    public backToTopBorderColor: string;
    public footerBg: string;
    public footerColor: string;
    public footerFontFamily: string;
    public footerFontSize: string;
    public footerFontWeight: string;
    public footerFontStyle: string;
    public footerLineHeight: string;
    public footerTextTransform: string;
    public headlineH1Color: string;
    public headlineH1FontFamily: string;
    public headlineH1FontSize: string;
    public headlineH1FontWeight: string;
    public headlineH1FontStyle: string;
    public headlineH1LineHeight: string;
    public headlineH1TextTransform: string;
    public headlineH1TextDecoration: string;
    public headlineH1LineVisible: string;
    public headlineH1LineBg: string;
    public headlineH2Color: string;
    public headlineH2FontFamily: string;
    public headlineH2FontSize: string;
    public headlineH2FontWeight: string;
    public headlineH2FontStyle: string;
    public headlineH2LineHeight: string;
    public headlineH2TextTransform: string;
    public headlineH2TextDecoration: string;
    public subTitleH3Color: string;
    public subTitleH3FontFamily: string;
    public subTitleH3FontSize: string;
    public subTitleH3FontWeight: string;
    public subTitleH3FontStyle: string;
    public subTitleH3LineHeight: string;
    public subTitleH3TextTransform: string;
    public subTitleH3TextDecoration: string;
    public subTitleH4Color: string;
    public subTitleH4FontFamily: string;
    public subTitleH4FontSize: string;
    public subTitleH4FontWeight: string;
    public subTitleH4FontStyle: string;
    public subTitleH4LineHeight: string;
    public subTitleH4TextTransform: string;
    public subTitleH4TextDecoration: string;
    public bodyText1Color: string;
    public bodyText1FontFamily: string;
    public bodyText1FontSize: string;
    public bodyText1FontWeight: string;
    public bodyText1FontStyle: string;
    public bodyText1LineHeight: string;
    public bodyText1TextTransform: string;
    public bodyText1TextDecoration: string;
    public bodyText2Color: string;
    public bodyText2FontFamily: string;
    public bodyText2FontSize: string;
    public bodyText2FontWeight: string;
    public bodyText2FontStyle: string;
    public bodyText2LineHeight: string;
    public bodyText2TextTransform: string;
    public bodyText2TextDecoration: string;
    public bodyText3Color: string;
    public bodyText3FontFamily: string;
    public bodyText3FontSize: string;
    public bodyText3FontWeight: string;
    public bodyText3FontStyle: string;
    public bodyText3LineHeight: string;
    public bodyText3TextTransform: string;
    public bodyText3TextDecoration: string;
    public overline1Color: string;
    public overline1FontFamily: string;
    public overline1FontSize: string;
    public overline1FontWeight: string;
    public overline1FontStyle: string;
    public overline1LineHeight: string;
    public overline1TextTransform: string;
    public overline1TextDecoration: string;
    public overline2Color: string;
    public overline2FontFamily: string;
    public overline2FontSize: string;
    public overline2FontWeight: string;
    public overline2FontStyle: string;
    public overline2LineHeight: string;
    public overline2TextTransform: string;
    public overline2TextDecoration: string;
    public caption1Color: string;
    public caption1FontFamily: string;
    public caption1FontSize: string;
    public caption1FontWeight: string;
    public caption1FontStyle: string;
    public caption1LineHeight: string;
    public caption1TextTransform: string;
    public caption1TextDecoration: string;
    public caption2Color: string;
    public caption2FontFamily: string;
    public caption2FontSize: string;
    public caption2FontWeight: string;
    public caption2FontStyle: string;
    public caption2LineHeight: string;
    public caption2TextTransform: string;
    public caption2TextDecoration: string;
    public caption3Color: string;
    public caption3FontFamily: string;
    public caption3FontSize: string;
    public caption3FontWeight: string;
    public caption3FontStyle: string;
    public caption3LineHeight: string;
    public caption3TextTransform: string;
    public caption3TextDecoration: string;
    public fieldLabelColor: string;
    public fieldLabelFontFamily: string;
    public fieldLabelFontSize: string;
    public fieldLabelFontWeight: string;
    public fieldLabelFontStyle: string;
    public fieldLabelLineHeight: string;
    public fieldLabelTextTransform: string;
    public fieldLabelTextDecoration: string;
    public inputFieldColor: string;
    public inputFieldBg: string;
    public inputFieldBorderColor: string;
    public inputFieldFocusBorderColor: string;
    public inputFieldFontFamily: string;
    public inputFieldFontSize: string;
    public inputFieldFontWeight: string;
    public inputFieldFontStyle: string;
    public inputFieldLineHeight: string;
    public inputFieldTextTransform: string;
    public inputFieldTextDecoration: string;
    public btnFontFamily: string;
    public btnFontSize: string;
    public btnFontWeight: string;
    public btnFontStyle: string;
    public btnTextTransform: string;
    public btnTextDecoration: string;
    public btnBg: string;
    public btnHoverBg: string;
    public btnColor: string;
    public btnHoverColor: string;
    public btnBorderColor: string;
    public btnHoverBorderColor: string;
    public btnBorderRadius: string;
    public btnBorderWidth: string;
    public btnBorderStyle: string;
    public btnPrimaryBg: string;
    public btnPrimaryHoverBg: string;
    public btnPrimaryColor: string;
    public btnPrimaryHoverColor: string;
    public btnPrimaryBorderColor: string;
    public btnPrimaryHoverBorderColor: string;
    public btnPrimaryDisabledBg: string;
    public btnPrimaryDisabledColor: string;
    public btnSecondaryBg: string;
    public btnSecondaryHoverBg: string;
    public btnSecondaryColor: string;
    public btnSecondaryHoverColor: string;
    public btnSecondaryBorderColor: string;
    public btnSecondaryHoverBorderColor: string;
    public btnLinkColor: string;
    public btnLinkHoverColor: string;
    public btnLinkTextDecoration: string;
    public btnLinkLabelIcon: string;
    public imageBorderRadius: string;
    public gridItemBorderRadius: string;
    public accordionBg: string;
    public accordionColor: string;
    public accordionBorderColor: string;
    public accordionHeadBg: string;
    public accordionHeadColor: string;
    public miniBannerBg: string;
    public miniBannerBorderColor: string;
    public cardsBg: string;
    public cardsBorderColor: string;
    public tabBg: string;
    public tabColor: string;
    public tabHoverBg: string;
    public tabHoverColor: string;
    public tabActiveBg: string;
    public tabActiveColor: string;
    public tabFontFamily: string;
    public tabFontSize: string;
    public tabFontWeight: string;
    public tabActiveFontWeight: string;
    public tabFontStyle: string;
    public tabTextTransform: string;
    public tabTextDecoration: string;
    public overlayBg: string;
    public overlayColor: string;
    public imageBackdropFilter: string;
    public sliderNavColor: string;
    public sliderNavBg: string;
    public sliderIndicatorsBg: string;
    public sliderIndicatorsColor: string;
    public sliderLeftNavIcon: string;
    public sliderRightNavIcon: string;
    public sliderBottomLeftNavIcon: string;
    public sliderBottomRightNavIcon: string;
    public widgetTintBackgroundColor: string;
    public storeBodyBg: string;
    public storeBodyColor: string;
    public storeBtnPrimaryBg: string;
    public storeBtnPrimaryHoverBg: string;
    public storeBtnPrimaryColor: string;
    public storeBtnPrimaryHoverColor: string;
    public storeBtnPrimaryBorderColor: string;
    public storeBtnPrimaryHoverBorderColor: string;
    public storeBtnPrimaryDisabledBg: string;
    public storeBtnPrimaryDisabledColor: string;
    public storeBtnSecondaryBg: string;
    public storeBtnSecondaryHoverBg: string;
    public storeBtnSecondaryColor: string;
    public storeBtnSecondaryHoverColor: string;
    public storeBtnSecondaryBorderColor: string;
    public storeBtnSecondaryHoverBorderColor: string;
    public storeBtnSecondaryDisabledBg: string;
    public storeBtnSecondaryDisabledColor: string;
    public blogBodyBg: string;
    public blogBodyColor: string;
    public blogBtnPrimaryBg: string;
    public blogBtnPrimaryHoverBg: string;
    public blogBtnPrimaryColor: string;
    public blogBtnPrimaryHoverColor: string;
    public blogBtnPrimaryBorderColor: string;
    public blogBtnPrimaryHoverBorderColor: string;
    public blogBtnPrimaryDisabledBg: string;
    public blogBtnPrimaryDisabledColor: string;
    public blogBtnSecondaryBg: string;
    public blogBtnSecondaryHoverBg: string;
    public blogBtnSecondaryColor: string;
    public blogBtnSecondaryHoverColor: string;
    public blogBtnSecondaryBorderColor: string;
    public blogBtnSecondaryHoverBorderColor: string;
    public blogBtnSecondaryDisabledBg: string;
    public blogBtnSecondaryDisabledColor: string;

    public constructor(init?: Partial<ThemeStyles>) { (Object as any).assign(this, init); }
}

export class ThemeFont
{
    public name: string;
    public family: string;
    public url: string;

    public constructor(init?: Partial<ThemeFont>) { (Object as any).assign(this, init); }
}

export enum ButtonShapeType
{
    Circle = 1,
    Square = 2,
}

export enum ButtonViewType
{
    V1 = 1,
    V2 = 2,
    V3 = 3,
    V4 = 4,
}

export class ScrollUpSettings
{
    public isEnabled: boolean;
    public shapeType: ButtonShapeType;
    public viewType: ButtonViewType;

    public constructor(init?: Partial<ScrollUpSettings>) { (Object as any).assign(this, init); }
}

export enum CookieBannerVariationType
{
    V1 = 1,
}

export enum TransitionAnimationType
{
    FadeIn = 1,
    FadeInDown = 2,
    FadeInLeft = 3,
    FadeInRight = 4,
    FadeInUp = 5,
    Bounce = 6,
    BounceIn = 7,
    BounceInDown = 8,
    BounceInLeft = 9,
    BounceInRight = 10,
    BounceInUp = 11,
    ZoomIn = 12,
    ZoomInDown = 13,
    ZoomInLeft = 14,
    ZoomInRight = 15,
    ZoomInUp = 16,
    Swing = 17,
    Wobble = 18,
}

export class CookieBannerSettings
{
    public isEnabled: boolean;
    public background: string;
    public color: string;
    public modalBackground: string;
    public modalColor: string;
    public acceptButton: BaseWidgetButton;
    public rejectButton: BaseWidgetButton;
    public customizeButton: BaseWidgetButton;
    public savePreferencesButton: BaseWidgetButton;
    public description: BaseWidgetDescription;
    public variationType: CookieBannerVariationType;
    public transitionAnimationType: TransitionAnimationType;
    public modalTransitionAnimationType: TransitionAnimationType;

    public constructor(init?: Partial<CookieBannerSettings>) { (Object as any).assign(this, init); }
}

export class LayoutSettings
{
    public scrollUpSettings: ScrollUpSettings;
    public cookieBannerSettings: CookieBannerSettings;

    public constructor(init?: Partial<LayoutSettings>) { (Object as any).assign(this, init); }
}

export enum WidgetContainerType
{
    Default = 1,
    Narrow = 2,
    Wide = 3,
}

export enum LinkButtonLabelType
{
    Icon = 1,
    Text = 2,
}

export class UiBoxMapStyleSetting
{
    public markerIcon: BaseWidgetImage;
    public styles: string;

    public constructor(init?: Partial<UiBoxMapStyleSetting>) { (Object as any).assign(this, init); }
}

export enum ImageFilterType
{
    Normal = 1,
    Aden = 2,
    Brooklyn = 3,
    Clarendon = 4,
    Gingham = 5,
    Hudson = 6,
    Lark = 7,
    Valencia = 8,
}

export class ImageFilterSetting
{
    public isEnabledLightTones?: boolean;
    public opacityPercentage?: number;
    public blur?: number;
    public isEnabledBlur?: boolean;
    public type: ImageFilterType;

    public constructor(init?: Partial<ImageFilterSetting>) { (Object as any).assign(this, init); }
}

export enum AppType
{
    Store = 1,
    Testimonials = 2,
    Comments = 3,
    Booking = 4,
    Blog = 5,
    VideoCourses = 6,
    Events = 7,
    PopUp = 8,
}

export enum StoreShoppingPageSettingType
{
    ShippingEstimates = 1,
    RelatedProducts = 2,
}

export class StoreShoppingCartGenerealSettings
{
    public elementShowSettings: { [index: string]: EnabledElementSetting; };
    public title: BaseWidgetTitle;

    public constructor(init?: Partial<StoreShoppingCartGenerealSettings>) { (Object as any).assign(this, init); }
}

export enum StoreShoppingPageCardTotalSettingType
{
    Coupon = 1,
    PromoBanner = 2,
    GuaranteedSafeCheckout = 3,
}

export class StoreShoppingCartTotalSettings
{
    public elementShowSettings: { [index: string]: EnabledElementSetting; };
    public couponLabel: BaseWidgetDescription;
    public proceedToCheckout: BaseWidgetButton;
    public continueShopping: BaseWidgetButton;
    public applyCouponButton: BaseWidgetButton;
    public garantedSaveCheckout: BaseWidgetImage[];

    public constructor(init?: Partial<StoreShoppingCartTotalSettings>) { (Object as any).assign(this, init); }
}

export class StoreShoppingCartShippingEstimatesSettings
{
    public title: BaseWidgetTitle;
    public button: BaseWidgetButton;

    public constructor(init?: Partial<StoreShoppingCartShippingEstimatesSettings>) { (Object as any).assign(this, init); }
}

export enum StoreShoppingPageRecommendedProductSettingType
{
    Title = 1,
}

export class StoreShoppingCartRecommendedProductSettings
{
    public elementShowSettings: { [index: string]: EnabledElementSetting; };
    public title: BaseWidgetTitle;
    public bundleId?: number;

    public constructor(init?: Partial<StoreShoppingCartRecommendedProductSettings>) { (Object as any).assign(this, init); }
}

export class StoreShoppingPageSetting
{
    public isFinishedCreate?: boolean;
    public generealSettings: StoreShoppingCartGenerealSettings;
    public cardTotalSettings: StoreShoppingCartTotalSettings;
    public shippingEstimatesSettings: StoreShoppingCartShippingEstimatesSettings;
    public recommendedProductSettings: StoreShoppingCartRecommendedProductSettings;
    public styleSettings: PageStyleSettings;

    public constructor(init?: Partial<StoreShoppingPageSetting>) { (Object as any).assign(this, init); }
}

export enum StoreCheckoutElementType
{
    EmailMeWithNewsAndOffers = 1,
    ShippingAddress = 2,
    BillingAddress = 3,
    GuaranteedSafeCheckout = 4,
    TermsAndConditions = 5,
    IHaveACouponCode = 6,
    Sku = 7,
    CustomFields = 8,
    Phone = 9,
}

export class EnabledFieldSetting extends EnabledSetting
{
    public isMandatory: boolean;
    public label: string;

    public constructor(init?: Partial<EnabledFieldSetting>) { super(init); (Object as any).assign(this, init); }
}

export enum StoreCheckoutShippingMethodElementType
{
    ShipToTheAddress = 1,
    PickUpFromTheStore = 2,
}

export class StoreCheckoutPageShippingMethodAddressItem
{
    public key?: number;
    public icon: BaseWidgetImage;
    public address: string;

    public constructor(init?: Partial<StoreCheckoutPageShippingMethodAddressItem>) { (Object as any).assign(this, init); }
}

export class StoreCheckoutPageShippingMethodSetting
{
    public elementsShowSettings: { [index: string]: EnabledElementSetting; };
    public addresses: StoreCheckoutPageShippingMethodAddressItem[];

    public constructor(init?: Partial<StoreCheckoutPageShippingMethodSetting>) { (Object as any).assign(this, init); }
}

export class StoreCheckoutPageSetting
{
    public isFinishedCreate?: boolean;
    public pageElementsShowSettings: { [index: string]: EnabledFieldSetting; };
    public shippingMethodSettings: StoreCheckoutPageShippingMethodSetting;
    public garantedSaveCheckout: BaseWidgetImage[];
    public checkoutPolicy: Policy;
    public customFields: CustomField[];
    public returnButton: BaseWidgetButton;
    public completeButton: BaseWidgetButton;
    public styleSettings: PageStyleSettings;

    public constructor(init?: Partial<StoreCheckoutPageSetting>) { (Object as any).assign(this, init); }
}

export enum StorePurchaseConfirmationStructureType
{
    V1 = 1,
    V2 = 2,
}

export enum StorePurchaseConfirmationSettingType
{
    FullOrderDetails = 1,
    ConfirmationEmailDetails = 2,
    BackToSiteButton = 3,
    DelayedAccountCreation = 4,
}

export class StorePurchaseConfirmationPageSetting
{
    public isFinishedCreate?: boolean;
    public structureType?: StorePurchaseConfirmationStructureType;
    public pageSettings: { [index: string]: EnabledElementSetting; };
    public contentTitle: BaseWidgetTitle;
    public contentDescription: BaseWidgetDescription;
    public styleSettings: PageStyleSettings;

    public constructor(init?: Partial<StorePurchaseConfirmationPageSetting>) { (Object as any).assign(this, init); }
}

export class InvoiceSetting
{
    public businessName: string;
    public businessLogo: BaseWidgetImage;
    public taxId: string;
    public zip: string;
    public countryCode: string;
    public stateCode: string;
    public city: string;
    public address: string;
    public phone: string;
    public email: string;

    public constructor(init?: Partial<InvoiceSetting>) { (Object as any).assign(this, init); }
}

export class MultiCurrency
{
    public key?: number;
    public code: string;

    public constructor(init?: Partial<MultiCurrency>) { (Object as any).assign(this, init); }
}

export class StoreCurrencySetting
{
    public defaultCurrencyCode: string;
    public multiCurrencies: MultiCurrency[];

    public constructor(init?: Partial<StoreCurrencySetting>) { (Object as any).assign(this, init); }
}

export class StoreAppSetting
{
    public shoppingPageSettings: StoreShoppingPageSetting;
    public checkoutPageSettings: StoreCheckoutPageSetting;
    public purchaseConfirmationPageSettings: StorePurchaseConfirmationPageSetting;
    public invoiceSettings: InvoiceSetting;
    public currencySettings: StoreCurrencySetting;
    public isFinishedWizard: boolean;
    public useCustomTaxRate: boolean;

    public constructor(init?: Partial<StoreAppSetting>) { (Object as any).assign(this, init); }
}

export class BookingAppSetting
{
    public isFinishedWizard: boolean;

    public constructor(init?: Partial<BookingAppSetting>) { (Object as any).assign(this, init); }
}

export class BlogAppSetting
{
    public isFinishedWizard: boolean;

    public constructor(init?: Partial<BlogAppSetting>) { (Object as any).assign(this, init); }
}

export enum CommentsRegistrationType
{
    Required = 1,
    NotRequired = 2,
}

export enum CommentsDisplayOnPageType
{
    Product = 1,
    Blog = 2,
}

export enum CommentsFeaturesType
{
    DisplayDateTime = 1,
    DisplayProfileImage = 2,
    UseEmojis = 3,
    Reactions = 4,
    Replies = 5,
    Shares = 6,
}

export enum CommentsModerationType
{
    Yes = 1,
    No = 2,
}

export enum CommentsSortingType
{
    NewestToOldest = 1,
    MostPopularOnTheTop = 2,
}

export enum CommentsToDisplayType
{
    All = 1,
    LastThree = 2,
    Custom = 3,
}

export class CommentsAppProperties
{
    public registrationType: CommentsRegistrationType;
    public displayOnPage: { [index: string]: EnabledElementSetting; };
    public features: { [index: string]: EnabledElementSetting; };
    public shareSocialNetworks: { [index: string]: string; };
    public moderationType: CommentsModerationType;
    public allowEditingWithinMinutes: number;
    public sortingType: CommentsSortingType;
    public toDisplayType: CommentsToDisplayType;
    public customQtyToDisplay: number;

    public constructor(init?: Partial<CommentsAppProperties>) { (Object as any).assign(this, init); }
}

export enum CommentsVisitorsRuleType
{
    React = 1,
    Reply = 2,
    Share = 3,
}

export class CommentsAppUserInfo
{
    public title: LocalizeValue;
    public profileImage: string;
    public visitorsRules: { [index: string]: EnabledElementSetting; };

    public constructor(init?: Partial<CommentsAppUserInfo>) { (Object as any).assign(this, init); }
}

export class CommentsAppSetting
{
    public properties: CommentsAppProperties;
    public userInfo: CommentsAppUserInfo;

    public constructor(init?: Partial<CommentsAppSetting>) { (Object as any).assign(this, init); }
}

export enum EventsCheckoutStructureType
{
    V1 = 1,
    V2 = 2,
    V3 = 3,
    V4 = 4,
}

export enum EventsCheckoutSettingType
{
    CorporatePurchase = 1,
    PhoneNumber = 2,
    BillingAddress = 3,
    ShippingAddress = 4,
    BillingIsEqualShipping = 5,
    TermsConditions = 6,
    ReceiveTheNewsletter = 7,
    NotesToYourOrder = 8,
}

export class EventsCheckoutPageSetting
{
    public structureType?: EventsCheckoutStructureType;
    public pageSettings: { [index: string]: EnabledFieldSetting; };
    public customFields: CustomField[];

    public constructor(init?: Partial<EventsCheckoutPageSetting>) { (Object as any).assign(this, init); }
}

export enum EventsPurchaseСonfirmationStructureType
{
    V1 = 1,
    V2 = 2,
}

export enum EventsPurchaseСonfirmationSettingType
{
    FullOrderDetails = 1,
    ConfirmationEmailDetails = 2,
    BackToSiteButton = 3,
    DelayedAccountCreation = 4,
}

export class EventsPurchaseСonfirmationPageSetting
{
    public structureType?: EventsPurchaseСonfirmationStructureType;
    public pageSettings: { [index: string]: EnabledElementSetting; };

    public constructor(init?: Partial<EventsPurchaseСonfirmationPageSetting>) { (Object as any).assign(this, init); }
}

export class EventsAppSetting
{
    public checkoutPageSettings: EventsCheckoutPageSetting;
    public purchaseConfirmationPageSettings: EventsPurchaseСonfirmationPageSetting;

    public constructor(init?: Partial<EventsAppSetting>) { (Object as any).assign(this, init); }
}

export enum MarketingIntegrationType
{
    FacebookPixels = 1,
    GoogleAnalytics = 2,
    GoogleTagManager = 3,
    Whatsapp = 4,
    GooglePlaces = 5,
    Messenger = 6,
    GoogleAds = 7,
    MicrosoftAds = 8,
    GoogleSearchConsole = 9,
    Custom = 10,
    GoogleAdSense = 11,
    TawkTo = 12,
}

export enum MarketingIntegrationAdSenseVerificationMethodType
{
    CodeSnippet = 1,
    AdsTxtSnippet = 2,
    MetaTag = 3,
}

export enum MarketingIntegrationCustomPositionType
{
    Head = 1,
    Body = 2,
}

export class MarketingIntegrationCustomSetting
{
    public name: string;
    public isEnabled: boolean;
    public positionType: MarketingIntegrationCustomPositionType;
    public trackingCode: string;
    public url: string;
    public script: string;

    public constructor(init?: Partial<MarketingIntegrationCustomSetting>) { (Object as any).assign(this, init); }
}

export class MarketingIntegrationSetting
{
    public isEnabled: boolean;
    public trackingCode: string;
    public url: string;
    public script: string;
    public adSenseVerificationMethodType?: MarketingIntegrationAdSenseVerificationMethodType;
    public customScripts: MarketingIntegrationCustomSetting[];

    public constructor(init?: Partial<MarketingIntegrationSetting>) { (Object as any).assign(this, init); }
}

export enum SliderNavType
{
    SquareArrow = 1,
    CircleArrow = 2,
    AngleArrow = 3,
}

export enum SliderControlType
{
    ArrowAndDots = 1,
    Arrow = 2,
    Dots = 3,
    None = 4,
    Number = 5,
    Text = 6,
}

export enum SliderIndicatorsType
{
    Dots = 1,
    Dashes = 2,
    Text = 3,
    Numbers = 4,
    Pagination = 5,
}

export enum SliderAnimationType
{
    Default = 1,
    BounceIn = 2,
    FlipInX = 3,
    FadeInRight = 4,
    ZoomIn = 5,
}

export class BaseWidgetSliderSettings
{
    public isAutoPlay: boolean;
    public isFilledArrowControl: boolean;
    public isInfiniteScroll: boolean;
    public autoPlaySpeed: number;
    public navColor: string;
    public navBackground: string;
    public indicatorsColor: string;
    public indicatorsBackground: string;
    public navType: SliderNavType;
    public controlType: SliderControlType;
    public indicatorsType: SliderIndicatorsType;
    public animationType: SliderAnimationType;

    public constructor(init?: Partial<BaseWidgetSliderSettings>) { (Object as any).assign(this, init); }
}

export enum PayProPaymentProductType
{
    None = 0,
    Upgrade = 1,
    Domain = 2,
    Polaris = 3,
    Storage = 4,
}

export enum PpgIpnTypeId
{
    OrderCharged = 1,
    OrderRefunded = 2,
    OrderChargedBack = 3,
    OrderDeclined = 4,
    OrderPartiallyRefunded = 5,
    SubscriptionChargeSucceed = 6,
    SubscriptionChargeFailed = 7,
    SubscriptionSuspended = 8,
    SubscriptionRenewed = 9,
    SubscriptionTerminated = 10,
    SubscriptionFinished = 11,
    LicenseRequested = 12,
    TrialCharge = 13,
    OrderChargebackIsWon = 14,
    OrderCustomerInformationChanged = 15,
    InstantLeadNotification = 16,
    OrderOnWaiting = 17,
}

export enum PaymentProductTariffType
{
    Regular = 1,
    Free = 2,
    Discount = 3,
    Coupon = 4,
}

export enum PpgOrderStatus
{
    Waiting = 1,
    Canceled = 2,
    Refunded = 3,
    Chargeback = 4,
    Processed = 5,
}

export enum PpgSubscriptionStatus
{
    Active = 1,
    Suspended = 2,
    Terminated = 3,
    Finished = 4,
}

export enum SiteFileType
{
    Image = 1,
    Document = 2,
    Audio = 3,
    Video = 4,
}

export interface IActionSourceRequest
{
    source: string;
}

export class BaseSavePage
{
    public widgets: BaseWidget[];
    public pageType?: PageType;
    public pageTemplateType?: PageTemplateType;
    public isEnabledInMenu?: boolean;

    public constructor(init?: Partial<BaseSavePage>) { (Object as any).assign(this, init); }
}

export class SaveDefaultPageConfig extends BaseSavePage
{

    public constructor(init?: Partial<SaveDefaultPageConfig>) { super(init); (Object as any).assign(this, init); }
}

export interface IPolarisMailRecordFilterRequest extends ISiteReferenceRequest
{
    polarisEmailId?: number;
    subscriptionId?: number;
}

export enum LogoStyle
{
    V1 = 1,
    V2 = 2,
    V3 = 3,
    V4 = 4,
    V5 = 5,
    V6 = 6,
}

export enum SiteErrorStatusCode
{
    Deleted = 527,
    NotFound = 528,
    Suspend = 529,
    SuspendDomain = 530,
    SanctionedCountry = 531,
}

export enum DomainRenewalOfferType
{
    ExpiredNext30Days = 1,
    ExpiredNext14Days = 2,
    ExpiredNext2Days = 3,
    ExpiredToday = 6,
    ChargeToday = 11,
    ChargePrevious7Days = 12,
}

export enum DomainRenewalType
{
    Year = 1,
    Month = 2,
    SwitchToYear = 3,
}

export enum DomainType
{
    Free = 1,
    Custom = 2,
    Paid = 3,
    FreeWithYearlySubscription = 4,
}

export enum DomainStatus
{
    Pending = 1,
    VerificationError = 2,
    Connected = 3,
    Disconnected = 4,
}

export class ContactFormDataItem
{
    public label: string;
    public value: Object;
    public valueStr: string;
    public type: FormFieldType;

    public constructor(init?: Partial<ContactFormDataItem>) { (Object as any).assign(this, init); }
}

export enum CommunicationType
{
    Email = 1,
    Phone = 2,
    Address = 3,
    Social = 4,
}

export class CommunicationPhone
{
    public number: string;

    public constructor(init?: Partial<CommunicationPhone>) { (Object as any).assign(this, init); }
}

export class CommunicationEmail
{
    public address: string;

    public constructor(init?: Partial<CommunicationEmail>) { (Object as any).assign(this, init); }
}

export enum CommunicationAddressType
{
    Text = 1,
    Detail = 2,
}

export class CommunicationAddressLocation
{
    public lat: number;
    public lng: number;

    public constructor(init?: Partial<CommunicationAddressLocation>) { (Object as any).assign(this, init); }
}

export class CommunicationAddress
{
    public type: CommunicationAddressType;
    public countryCode: string;
    public countryName: string;
    public regionName: string;
    public cityName: string;
    public routeName: string;
    public streetNumber: string;
    public postalCode: string;
    public fullAddress: string;
    public location: CommunicationAddressLocation;

    public constructor(init?: Partial<CommunicationAddress>) { (Object as any).assign(this, init); }
}

export class CommunicationSocial
{
    public type: SocialNetworkType;
    public url: string;

    public constructor(init?: Partial<CommunicationSocial>) { (Object as any).assign(this, init); }
}

export enum BillingInfoType
{
    Invoice = 1,
    NameCom = 2,
}

export interface IBaseResponse
{
}

export class OperationRequest
{
    public name: string;
    public body: Object;

    public constructor(init?: Partial<OperationRequest>) { (Object as any).assign(this, init); }
}

export class SiteEvent<T>
{
    public id: number;
    public name: string;
    public body: T;
    public siteId: number;

    public constructor(init?: Partial<SiteEvent<T>>) { (Object as any).assign(this, init); }
}

export class GlobalEvent<T>
{
    public id: number;
    public name: string;
    public body: T;

    public constructor(init?: Partial<GlobalEvent<T>>) { (Object as any).assign(this, init); }
}

export enum EmailTemplateTypeEnum
{
    UserSignup = 1,
    UserSignupSocial = 2,
    UserLogin = 3,
    UserPasswordRestoreNotification = 4,
    UserPasswordRestoreNotificationSuccess = 5,
    UserPremiumUpgradeSubscription = 101,
    UserPremiumCancelSubscription = 102,
    UserPurchaseDomainConfirmation = 103,
    GeneralContactFormRequest = 104,
    UserMobileWizardSiteCreated = 105,
    UserDomainVerification = 106,
    UserNeedPremiumPayment = 107,
    DomainRenewal = 108,
    DomainRenewalConfirmation = 109,
    UnfinishedSiteCreation = 110,
    DomainRenewalChargeFailed = 111,
    SiteSubscriptionCancelWarning = 112,
    DomainAutoRenewEnabledNotification = 113,
    SiteSubcriptionCancelSupportRequest = 114,
    StoreCustomerPurchaseConfirmation = 201,
    StoreCustomerManualPaymentPurchaseConfirmation = 202,
    StoreCustomerWaitingForPayment = 203,
    StoreCustomerDeclineNotification = 204,
    StoreCustomerPayPalCancelNotification = 205,
    StoreCustomerRefundNotification = 206,
    StoreCustomerChargebackNotification = 207,
    StoreCustomerLeadManagement = 208,
    StoreCustomerOrderShipmentInTransit = 209,
    StoreCustomerOrderShipmentDelivered = 210,
    StoreCustomerOrderShipmentReturned = 211,
    StoreCustomerXDaysAfterOrderCancelledOrNotFinished = 212,
    StoreCustomerXDaysAfterPurchase = 213,
    StoreCustomerXDaysAfterRefund = 214,
    StoreSellerPurchaseConfirmation = 215,
    StoreSellerManualPaymentPurchaseConfirmation = 216,
    StoreSellerWaitingForPayment = 217,
    StoreSellerDeclineNotification = 218,
    StoreSellerPayPalCancelNotification = 219,
    StoreSellerRefundNotification = 220,
    StoreSellerChargebackNotification = 221,
    StoreSellerOrderShipmentInTransit = 222,
    StoreSellerOrderShipmentDelivered = 223,
    StoreSellerOrderShipmentReturned = 224,
    StoreSellerProductAlmostOutOfStock = 225,
    StoreSellerProductOutOfStock = 226,
    StoreSellerProductXDaysBeforePublishDate = 227,
    StoreSellerCollectionXDaysBeforePublishDate = 228,
    BookingOwnerRequestReceived = 301,
    BookingOwnerRequestCanceled = 302,
    BookingOwnerRequestDetailsChanged = 303,
    BookingCustomerRequestAccepted = 304,
    BookingCustomerRequestCanceled = 305,
    BookingCustomerRequestDetailsChanged = 306,
    TestimonialCustomerRequestForFeedback = 401,
    TestimonialOwnerRequestForFeedback = 402,
    SequenceCollectMailingList = 501,
    SequenceConfigureApp = 502,
    SequenceConfigureBlogApp = 503,
    SequenceConfigureBookingApp = 504,
    SequenceConfigureCommerceApp = 505,
    SequenceConfigureMarketingApp = 506,
    SequenceConfigureStripe = 507,
    SequenceNoSiteFirstInteraction = 508,
    SequenceNoSiteSecondInteraction = 509,
    SequenceNoSiteThirdInteraction = 510,
    SequenceNoSiteFourthInteraction = 511,
    SequenceUpgradePremiumDomain = 512,
    SequenceWelcome = 513,
    SequenceWelcomeExtended = 514,
    SequenceUnsubscribeSuccess = 515,
    CampaignTest = 601,
    Campaign = 602,
    MarketingDiscuss = 603,
    MarketingOwnerFeedback = 604,
    ValentinesDay = 605,
}

export enum EmailTemplatePartialType
{
    Header = 1,
    Body = 2,
    Footer = 3,
}

export enum StopListType
{
    Email = 0,
    Domain = 1,
}

export interface IOnlyPublishedRequest
{
    isOnlyPublished: boolean;
}

export enum UpdateBlogTagActionType
{
    None = 1,
    SoftDelete = 2,
    Duplicate = 3,
    NoValidate = 4,
}

export enum BlogPageTemplateSidebarType
{
    None = 1,
    Left = 2,
    Right = 3,
}

export enum BlogPageTemplateSidebarItemType
{
    Categories = 1,
    TopPosts = 2,
    Authors = 3,
    Tags = 4,
    Archives = 5,
    Subscribe = 6,
    RecentPosts = 7,
    AboutUs = 8,
}

export class GetBlogCategoriesResponse extends BaseResponse implements IPrimaryEntity, IAuditEntity, IDeletedEntity, ISiteReferenceEntity, IOwnerEntity
{
    public id: number;
    public name: string;
    public keywords: Tag[];
    public metaTitle: string;
    public metaDescription: string;
    public slug: string;
    public postIds: number[];
    public createdAt: string;
    public updatedAt: string;
    public isDeleted: boolean;
    public siteId: number;
    public userAuthId?: number;

    public constructor(init?: Partial<GetBlogCategoriesResponse>) { super(init); (Object as any).assign(this, init); }
}

export class GetBlogTagsResponse extends BaseResponse implements IPrimaryEntity, ISiteReferenceEntity, IDeletedEntity, IAuditEntity, IOwnerEntity
{
    public id: number;
    public name: string;
    public keywords: Tag[];
    public metaTitle: string;
    public metaDescription: string;
    public slug: string;
    public postIds: number[];
    public createdAt: string;
    public updatedAt: string;
    public isDeleted: boolean;
    public siteId: number;
    public userAuthId?: number;

    public constructor(init?: Partial<GetBlogTagsResponse>) { super(init); (Object as any).assign(this, init); }
}

export class GetAuthorsResponse extends BaseResponse implements IPrimaryEntity, IAuditEntity, ISiteReferenceEntity, IOwnerEntity
{
    public id: number;
    public firstName: string;
    public lastName: string;
    public position: string;
    public profileAvatar: BaseWidgetImage;
    public description: string;
    public createdAt: string;
    public updatedAt: string;
    public socialLinks: { [index: string]: string; };
    public uploadedSocialIcons: BaseWidgetImage[];
    public isDeleted: boolean;
    public siteId: number;
    public userAuthId?: number;
    public postCount?: number;

    public constructor(init?: Partial<GetAuthorsResponse>) { super(init); (Object as any).assign(this, init); }
}

export class BlogPageTemplateSidebarAboutUsWidget
{
    public image: BaseWidgetImage;
    public title: BaseWidgetTitle;
    public description: BaseWidgetDescription;
    public socialNetworks: { [index: string]: string; };

    public constructor(init?: Partial<BlogPageTemplateSidebarAboutUsWidget>) { (Object as any).assign(this, init); }
}

export class BlogPageTemplateSidebarWidget
{
    public key?: number;
    public type: BlogPageTemplateSidebarItemType;
    public isEnabled: boolean;
    public labelOverride: LocalizeValue;
    public contentListItems: SidebarWidgetContentListItem[];
    public categories: GetBlogCategoriesResponse[];
    public tags: GetBlogTagsResponse[];
    public topPosts: GetPostsResponse[];
    public recentPosts: GetPostsResponse[];
    public authors: GetAuthorsResponse[];
    public archives: string[];
    public subscribe: SidebarSubscribeWidget;
    public aboutUs: BlogPageTemplateSidebarAboutUsWidget;

    public constructor(init?: Partial<BlogPageTemplateSidebarWidget>) { (Object as any).assign(this, init); }
}

export class GetPostsResponse extends BaseResponse implements IPrimaryEntity, IAuditEntity, ISiteReferenceEntity, IDeletedEntity, IOwnerEntity
{
    public id: number;
    public name: string;
    public nameLcz: ColumnLocalizeValue;
    public nameHtml: string;
    public nameHtmlLcz: ColumnLocalizeValue;
    public shortDescription: string;
    public shortDescriptionLcz: ColumnLocalizeValue;
    public order: number;
    public keywords: Tag[];
    public metaTitle: string;
    public metaDescription: string;
    public slug: string;
    public isCustomSlug?: boolean;
    public expectedReadMinutes?: number;
    public authorId?: number;
    public publishStatus: PublishStatusType;
    public coverImage: BaseWidgetImage;
    public widgets: BaseWidget[];
    public mainCategoryId?: number;
    public publishDate: string;
    public createdAt: string;
    public updatedAt: string;
    public viewedCount: number;
    public sharedCount: number;
    public likedCount: number;
    public button: BaseWidgetButton;
    public sidebarType: BlogPageTemplateSidebarType;
    public sidebarWidgets: BlogPageTemplateSidebarWidget[];
    public isDeleted: boolean;
    public pageType: PageType;
    public pageTemplateType: PageTemplateType;
    public siteId: number;
    public userAuthId?: number;
    public author: GetAuthorsResponse;
    public mainCategory: GetBlogCategoriesResponse;
    public tags: GetBlogTagsResponse[];
    public isGeneratedByAi?: boolean;
    public globalLocalizeCode: string;
    public seoSettings: PageSeo;

    public constructor(init?: Partial<GetPostsResponse>) { super(init); (Object as any).assign(this, init); }
}

export enum BlogCategoryTemplateStructureType
{
    SimpleGrid = 1,
    Masonry = 2,
    SliderWithListGrid = 3,
    Mix = 4,
}

export enum BlogCategoryTemplateStructureVariationType
{
    V1 = 1,
    V2 = 2,
    V3 = 3,
}

export enum BlogCategoryTemplatePostElementType
{
    Category = 1,
    Date = 2,
    AuthorName = 3,
    PostSummary = 4,
    LikesAndViews = 5,
    ReadingTime = 6,
}

export interface ISiteNullableReferenceRequest
{
    siteId?: number;
}

export enum UpdateBlogCategoryActionType
{
    None = 1,
    SoftDelete = 2,
    Duplicate = 3,
    NoValidate = 4,
}

export enum UpdateAuthorActionType
{
    None = 1,
    SoftDelete = 2,
    Duplicate = 3,
    NoValidate = 4,
}

export enum UpdateBookingWorkerActionType
{
    None = 1,
    SoftDelete = 2,
}

export class BookingWorkerVacation
{
    public startsAt: string;
    public endsAt: string;

    public constructor(init?: Partial<BookingWorkerVacation>) { (Object as any).assign(this, init); }
}

export class CustomDailySchedule
{
    public key?: number;
    public date: string;
    public schedule: WorkingDay[];

    public constructor(init?: Partial<CustomDailySchedule>) { (Object as any).assign(this, init); }
}

export enum BookingWorkerLocationScheduleType
{
    UseLocationWeeklySchedule = 1,
    CustomWeeklySchedule = 2,
    CustomDailySchedule = 3,
}

export class BookingWorkerAtLocationInput
{
    public locationId?: number;
    public workingTime: WorkingTime;
    public workingTimeDaily: CustomDailySchedule[];
    public scheduleType: BookingWorkerLocationScheduleType;

    public constructor(init?: Partial<BookingWorkerAtLocationInput>) { (Object as any).assign(this, init); }
}

export enum UpdateBookingServiceActionType
{
    None = 1,
    SoftDelete = 2,
}

export enum MaxUpfrontPeriodTypeEnum
{
    Days = 1,
    Weeks = 2,
    Months = 3,
}

export interface BookingServiceUpdatableFields
{
    title: string;
    maxUpfrontPeriodValue: number;
    maxUpfrontPeriodType: MaxUpfrontPeriodTypeEnum;
    price?: number;
    breakBeforeHours?: number;
    breakBeforeMinutes?: number;
    durationHours?: number;
    durationMinutes?: number;
    breakAfterHours?: number;
    breakAfterMinutes?: number;
    createdAt: string;
    updatedAt: string;
    isDeleted: boolean;
    siteId: number;
}

export interface BookingServiceExtendedWebFields
{
    atLocationIds: number[];
    byWorkerIds: number[];
}

export enum RequestStatus
{
    Pending = 1,
    Approved = 2,
    Declined = 3,
    Canceled = 4,
}

export enum UpdateBookingRequestActionType
{
    None = 1,
    SoftDelete = 2,
    DeclineByWebsiteOwner = 3,
    CenceledByWebsiteOwner = 4,
    ApproveByWebsiteOwner = 5,
    DetailsChangedByWebsiteOwner = 6,
    UpdateScheduleByWebsiteOwner = 7,
}

export class BookingCustomField
{
    public label: string;
    public value: string;
    public type?: FormFieldType;

    public constructor(init?: Partial<BookingCustomField>) { (Object as any).assign(this, init); }
}

export enum RequestSource
{
    Client = 1,
    System = 2,
}

export enum SiteFileStatus
{
    Uploading = 1,
    Uploaded = 2,
    Error = 3,
}

export interface ISiteFileScannerRequest extends IPrimaryEntityRequest, IPrimaryListEntityRequest, ICreatedAtRangeRequest, ISiteNullableReferenceRequest
{
    name: string;
    nameContains: string;
    status?: SiteFileStatus;
    scoreLessThan?: number;
    scoreGreaterThan?: number;
    mediaId: string;
}

export interface ICreatedAtRangeRequest
{
    createdAtBefore?: string;
    createdAtAfter?: string;
}

export class Media
{
    public id: string;
    public uri: string;

    public constructor(init?: Partial<Media>) { (Object as any).assign(this, init); }
}

export enum ModerationStatus
{
    Ongoing = 1,
    Finished = 2,
    Stopped = 3,
    Failure = 4,
}

export class ModerationData
{
    public status: ModerationStatus;
    public started: number;
    // @DataMember(Name="last_update")
    public lastUpdate: number;

    public progress: number;
    public operations: number;
    public frames: Frame[];

    public constructor(init?: Partial<ModerationData>) { (Object as any).assign(this, init); }
}

export interface ISiteFilesRequest extends IPrimaryEntityRequest, IPrimaryListEntityRequest, ISiteReferenceRequest
{
    nameContains: string;
    name: string;
}

export enum ImageOrientation
{
    Landscape = 1,
    Portrait = 2,
    Squarish = 3,
}

export interface ICodeEntityRequest
{
    code: string;
}

export enum RatingType
{
    Number = 1,
    Emodji = 2,
}

export enum PopupWidgetType
{
    Subscribe = 1,
    Promotion = 2,
    ContactUs = 3,
    SocialBar = 4,
    Feedback = 5,
    AgeVerification = 6,
    CookieBanner = 7,
}

export enum SubscribeFormType
{
    Popup = 1,
    FloatingBarVertical = 2,
    FloatingBarHorizontal = 3,
}

export enum SubscribeFormPosition
{
    Center = 1,
    Left = 2,
    Right = 3,
    Top = 4,
    Bottom = 5,
}

export enum SubscribeFormVariationType
{
    SubscribeV1 = 1,
    SubscribeV2 = 2,
    SubscribeV3 = 3,
    SubscribeV4 = 4,
    SubscribeV5 = 5,
    SubscribeV6 = 6,
    SubscribeV7 = 7,
    SubscribeV8 = 8,
    SubscribeV9 = 9,
    SubscribeV10 = 10,
    SubscribeV11 = 11,
    ContactUsV1 = 100,
    ContactUsV2 = 101,
    ContactUsV3 = 102,
    ContactUsV4 = 103,
    ContactUsV5 = 104,
    FeedbackV1 = 200,
    FeedbackV2 = 201,
    PromotionsV1 = 300,
    PromotionsV2 = 301,
    PromotionsV3 = 302,
    PromotionsV4 = 303,
    PromotionsV5 = 304,
    PromotionsV6 = 305,
    AgeVerificationV1 = 400,
    AgeVerificationV2 = 401,
    AgeVerificationV3 = 402,
    AgeVerificationV4 = 403,
    SocialBarV1 = 500,
    SocialBarV2 = 501,
}

export enum TimerType
{
    AllUsers = 1,
    Session = 2,
}

export class BaseWidgetCountdown
{
    public date: string;
    public hours: number;
    public minutes: number;
    public seconds: number;
    public startDate: string;

    public constructor(init?: Partial<BaseWidgetCountdown>) { (Object as any).assign(this, init); }
}

export enum MarketingPopupAgeVerificationSettingType
{
    Title = 1,
    Description = 2,
    AcceptButton = 3,
    CancelButton = 4,
    CloseButton = 5,
}

export class MarketingEnabledElementSetting
{
    public isEnabled: boolean;

    public constructor(init?: Partial<MarketingEnabledElementSetting>) { (Object as any).assign(this, init); }
}

export enum MarketingPopupDefaultConfirmationSettingType
{
    ConfirmationTitle = 1,
    ConfirmationDescription = 2,
}

export class MarketingPopupSubscribe
{
    public title: LocalizeValue;
    public description: LocalizeValue;
    public button: BaseWidgetButton;
    public image: BaseWidgetImage;
    public confirmationTitle: LocalizeValue;
    public confirmationDescription: LocalizeValue;
    public confirmationImage: BaseWidgetImage;
    public countdownType: TimerType;
    public finishDate: BaseWidgetCountdown;
    public elementsShowSettings: { [index: string]: MarketingEnabledElementSetting; };
    public confirmationElementsShowSettings: { [index: string]: MarketingEnabledElementSetting; };

    public constructor(init?: Partial<MarketingPopupSubscribe>) { (Object as any).assign(this, init); }
}

export enum MarketingPopupPromotionSettingType
{
    Title = 1,
    Description = 2,
    Button = 3,
}

export class MarketingPopupPromotion
{
    public title: LocalizeValue;
    public description: LocalizeValue;
    public button: BaseWidgetButton;
    public image: BaseWidgetImage;
    public elementsShowSettings: { [index: string]: MarketingEnabledElementSetting; };

    public constructor(init?: Partial<MarketingPopupPromotion>) { (Object as any).assign(this, init); }
}

export enum MarketingPopupDefaultSettingType
{
    Title = 1,
    Description = 2,
}

export class MarketingPopupContactUs
{
    public image: BaseWidgetImage;
    public title: LocalizeValue;
    public description: LocalizeValue;
    public fields: FormField[];
    public button: BaseWidgetButton;
    public confirmationTitle: LocalizeValue;
    public confirmationDescription: LocalizeValue;
    public elementsShowSettings: { [index: string]: MarketingEnabledElementSetting; };
    public confirmationElementsShowSettings: { [index: string]: MarketingEnabledElementSetting; };

    public constructor(init?: Partial<MarketingPopupContactUs>) { (Object as any).assign(this, init); }
}

export class MarketinFeedBackGradeSetting
{
    public value: number;
    public count: number;

    public constructor(init?: Partial<MarketinFeedBackGradeSetting>) { (Object as any).assign(this, init); }
}

export enum FeedBackIconRate
{
    Angry = 1,
    Dissatisfied = 2,
    Netural = 3,
    Satisfied = 4,
    VerySatisfied = 5,
}

export class MarketingPopupFeedback
{
    public title: LocalizeValue;
    public description: LocalizeValue;
    public fields: FormField[];
    public button: BaseWidgetButton;
    public confirmationTitle: LocalizeValue;
    public confirmationDescription: LocalizeValue;
    public confirmationImage: BaseWidgetImage;
    public grade: MarketinFeedBackGradeSetting;
    public iconRate: { [index: string]: MarketingEnabledElementSetting; };
    public gradeLabels: BaseWidgetDescription[];
    public elementsShowSettings: { [index: string]: MarketingEnabledElementSetting; };
    public confirmationElementsShowSettings: { [index: string]: MarketingEnabledElementSetting; };

    public constructor(init?: Partial<MarketingPopupFeedback>) { (Object as any).assign(this, init); }
}

export class MarketiongPopupAgeAgeVerification
{
    public title: LocalizeValue;
    public description: LocalizeValue;
    public acceptButton: BaseWidgetButton;
    public cancelButton: BaseWidgetButton;
    public image: BaseWidgetImage;
    public elementsShowSettings: { [index: string]: MarketingEnabledElementSetting; };

    public constructor(init?: Partial<MarketiongPopupAgeAgeVerification>) { (Object as any).assign(this, init); }
}

export class SocialLink
{
    public key?: number;
    public type: SocialNetworkType;
    public link: string;

    public constructor(init?: Partial<SocialLink>) { (Object as any).assign(this, init); }
}

export class MarketingPopupSocialBar
{
    public title: LocalizeValue;
    public socialLinks: SocialLink[];

    public constructor(init?: Partial<MarketingPopupSocialBar>) { (Object as any).assign(this, init); }
}

export enum SubscribeFormTriggersType
{
    ExitIntent = 1,
    Timer = 2,
    ScrollPercentOfPage = 3,
}

export class SubscribeFormRuleTriggers
{
    public type: SubscribeFormTriggersType;
    public timer?: number;
    public scrollPercent?: number;

    public constructor(init?: Partial<SubscribeFormRuleTriggers>) { (Object as any).assign(this, init); }
}

export enum SubscribeFormDisplayOnPagesType
{
    AllPages = 1,
    SpecificPages = 2,
}

export class SubscribeFormRuleDisplayOnPages
{
    public type: SubscribeFormDisplayOnPagesType;
    public pageIds: number[];

    public constructor(init?: Partial<SubscribeFormRuleDisplayOnPages>) { (Object as any).assign(this, init); }
}

export enum SubscribeFormFrequencyType
{
    EachPageVisit = 1,
    TimePerSession = 2,
    Times = 3,
}

export class SubscribeFormRuleFrequency
{
    public type: SubscribeFormFrequencyType;
    public timePerSession?: number;

    public constructor(init?: Partial<SubscribeFormRuleFrequency>) { (Object as any).assign(this, init); }
}

export enum SubscribeFormTargetingType
{
    AllVisitors = 1,
    ReturningVisitors = 2,
    NewVisitors = 3,
}

export class SubscribeFormRuleTargeting
{
    public type: SubscribeFormTargetingType;

    public constructor(init?: Partial<SubscribeFormRuleTargeting>) { (Object as any).assign(this, init); }
}

export enum SubscribeFormRuleMainTriggerType
{
    CertainTrigger = 1,
    ButtonClicking = 2,
}

export class SubscribeFormRule
{
    public triggers: SubscribeFormRuleTriggers;
    public displayOnPages: SubscribeFormRuleDisplayOnPages;
    public frequency: SubscribeFormRuleFrequency;
    public targeting: SubscribeFormRuleTargeting;
    public mainTrigger: SubscribeFormRuleMainTriggerType;

    public constructor(init?: Partial<SubscribeFormRule>) { (Object as any).assign(this, init); }
}

export enum PageSeoType
{
    Page = 0,
    Collection = 1,
    Product = 2,
    Blog = 3,
    Event = 4,
}

export interface IEmailAddressInfo
{
    firstName: string;
    lastName: string;
    email: string;
}

export enum CampaignContactStatus
{
    Draft = 1,
    Scheduled = 2,
    Sent = 3,
    Pending = 4,
    Error = 5,
}

export class EmailTemplateHeaderSettings
{
    public variation: string;
    public logo: Logo;
    public title: string;
    public subtitle: string;
    public hideTitle?: boolean;
    public hideSubtitle?: boolean;

    public constructor(init?: Partial<EmailTemplateHeaderSettings>) { (Object as any).assign(this, init); }
}

export class EmailTemplateFooterSettings
{
    public variation: string;
    public logo: Logo;
    public isEnabledLogo: boolean;
    public isEnabledContactInfo: boolean;
    public isEnabledSocialNetworks: boolean;
    public contacts: FooterContact[];
    public enabledSocialNetworks: SocialNetworkType[];
    public socialNetworks: { [index: string]: string; };
    public socialNetworksVariation?: number;
    public isEnabledUnsubscribe: boolean;

    public constructor(init?: Partial<EmailTemplateFooterSettings>) { (Object as any).assign(this, init); }
}

export enum EmailWidgetType
{
    AboutUs = 1,
    Attractions = 2,
    Author = 3,
    Banner = 4,
    BlogPost = 5,
    BlogList = 6,
    ContactUs = 7,
    Coupon = 8,
    Countdown = 9,
    Divider = 10,
    EventDetails = 11,
    EventList = 12,
    FeaturedItems = 13,
    Gallery = 14,
    Quote = 15,
    Image = 16,
    Services = 17,
    Share = 18,
    Shedule = 19,
    Subscribe = 20,
    Text = 21,
    Video = 22,
}

export class EmailTemplateWidgeHiddenElements
{
    public hideTitle?: boolean;
    public hideText?: boolean;
    public hideButton?: boolean;

    public constructor(init?: Partial<EmailTemplateWidgeHiddenElements>) { (Object as any).assign(this, init); }
}

export class EmailTemplateWidget
{
    public key?: number;
    public id: number;
    public name: string;
    public type: EmailWidgetType;
    public settings: Object;
    public hiddenElements: EmailTemplateWidgeHiddenElements;
    public isEnabled: boolean;

    public constructor(init?: Partial<EmailTemplateWidget>) { (Object as any).assign(this, init); }
}

export class EmailTemplateContentSettings
{
    public styles: ThemeStyles;
    public fonts: ThemeFont[];
    public colorPaletteId?: number;
    public fontGroupId?: number;
    public colorMappingId?: number;
    public fontMappingId?: number;
    public boxMappingId?: number;
    public header: EmailTemplateHeaderSettings;
    public footer: EmailTemplateFooterSettings;
    public widgets: EmailTemplateWidget[];

    public constructor(init?: Partial<EmailTemplateContentSettings>) { (Object as any).assign(this, init); }
}

export enum EmailWidgetCategoryType
{
    Store = 1,
    Blog = 2,
    General = 3,
}

export enum CampaignStatus
{
    Sent = 1,
    Scheduled = 2,
    Draft = 3,
    Pending = 4,
}

export enum TestimonialRecordStatusType
{
    New = 1,
    Published = 2,
    Hidden = 3,
}

export enum TestimonialRecordPositivenessType
{
    Positive = 1,
    Negative = 2,
}

export enum SaveTestimonialRecordActionType
{
    None = 1,
    New = 2,
    Publish = 3,
    Hide = 4,
    ChangePositiveness = 5,
    MarkNegative = 6,
    SoftDelete = 7,
}

export enum TestimonialFormFeedbackType
{
    Open = 1,
    Customized = 2,
}

export enum TestimonialFormAnswerType
{
    Short = 1,
    Paragraph = 2,
    Checkbox = 3,
    Radio = 4,
    Dropdown = 5,
}

export class TestimonialFormAnswer
{
    public key: number;
    public label: string;
    public lczText: LocalizeValue;
    public isEnabled?: boolean;

    public constructor(init?: Partial<TestimonialFormAnswer>) { (Object as any).assign(this, init); }
}

export class TestimonialFormCustomizedFeedback
{
    public key: number;
    public question: string;
    public label: string;
    public lczText: LocalizeValue;
    public value: string;
    public answerType: TestimonialFormAnswerType;
    public answers: TestimonialFormAnswer[];

    public constructor(init?: Partial<TestimonialFormCustomizedFeedback>) { (Object as any).assign(this, init); }
}

export interface TestimonialRecordDbSavableFields
{
    id: number;
    formId: number;
    status?: TestimonialRecordStatusType;
    userEmail: string;
    userName: string;
    userPosition: string;
    userCity: string;
    userAge: string;
    userOther: string;
    userReview: string;
    userRating?: number;
    maxRating?: number;
    feedbackType?: TestimonialFormFeedbackType;
    customizedFeedbacks: TestimonialFormCustomizedFeedback[];
    openFeedback: string;
    referralSource: string;
    siteId: number;
    isDeleted: boolean;
    positivenessType?: TestimonialRecordPositivenessType;
}

export enum TestimonialFormFeedbackRequestType
{
    ByEmail = 1,
    ByWidget = 2,
}

export enum TestimonialFormPublishStatusType
{
    Active = 1,
    Inactive = 2,
    Draft = 3,
}

export enum UpdateTestimonialFormActionType
{
    None = 1,
    SoftDelete = 2,
    Duplicate = 3,
    NoValidate = 4,
    Content = 5,
    Confirmation = 6,
    Triggers = 7,
}

export enum TestimonialFormUserFieldType
{
    Email = 1,
    Review = 2,
    Name = 3,
    Position = 4,
    City = 5,
    Age = 6,
    Other = 7,
}

export class TestimonialFormUserField
{
    public key: number;
    public label: string;
    public lczText: LocalizeValue;
    public isMandatory: boolean;
    public type: TestimonialFormUserFieldType;

    public constructor(init?: Partial<TestimonialFormUserField>) { (Object as any).assign(this, init); }
}

export enum RatingUiType
{
    Number = 1,
    Star = 2,
    Smile = 3,
}

export enum TestimonialFormEmailTriggerAfterPeriodType
{
    Minute = 1,
    Hour = 2,
    Day = 3,
    Week = 4,
    Month = 5,
    Year = 6,
}

export interface TestimonialFormDbSavableFields
{
    title: string;
    description: string;
    allowSocialMedia?: boolean;
    allowImageUpload?: boolean;
    userFields: TestimonialFormUserField[];
    feedbackType?: TestimonialFormFeedbackType;
    customizedFeedbacks: TestimonialFormCustomizedFeedback[];
    ratingType?: RatingUiType;
    ratingMaxValue?: number;
    positiveLabel: string;
    avarageLabel: string;
    negativeLabel: string;
    confirmationTitle: string;
    confirmationDescription: string;
    confirmationImage: BaseWidgetImage;
    feedbackRequestType?: TestimonialFormFeedbackRequestType;
    emailTriggerAfterPeriodValue?: number;
    emailTriggerAfterPeriodType?: TestimonialFormEmailTriggerAfterPeriodType;
    serviceIds: number[];
    publishStatus?: TestimonialFormPublishStatusType;
    isDeleted: boolean;
    siteId: number;
}

export enum StructurePackAppType
{
    Default = 1,
    Blog = 2,
    Comments = 3,
    Events = 4,
    Store = 5,
    Testimonials = 6,
    VideoCourses = 7,
}

export interface IDisplayEntity
{
    name: string;
}

export enum UiClassType
{
    Body = 0,
    Header = 1,
    Footer = 2,
    Overlay = 3,
    GridItem = 4,
    Divider = 5,
    Image = 6,
    Modal = 7,
    Button = 8,
    ButtonPrimary = 9,
    ButtonSecondary = 10,
    ButtonLink = 11,
    Tab = 12,
    InputField = 13,
    FieldLabel = 14,
    AccordionBody = 15,
    AccordionHead = 16,
    SliderNavigation = 17,
    SliderNavigationDefault = 18,
    SliderIndicators = 19,
    HeadlineH1 = 20,
    HeadlineH2 = 21,
    SubtitleH3 = 22,
    SubtitleH4 = 23,
    BodyText1 = 24,
    BodyText2 = 25,
    BodyText3 = 26,
    Overline1 = 27,
    Overline2 = 28,
    Caption1 = 29,
    Caption2 = 30,
    Caption3 = 31,
    MiniBanner = 32,
    Cards = 33,
    StoreBody = 34,
    StoreButtonPrimary = 35,
    StoreButtonSecondary = 36,
    BlogBody = 37,
    BlogButtonPrimary = 38,
    BlogButtonSecondary = 39,
}

export class UiFontSettings
{
    public family: string;
    public size: string;
    public weight: string;
    public activeWeight: string;
    public style: string;
    public lineHeight: string;
    public textTransform: string;
    public textDecoration: string;
    public lineVisible: string;

    public constructor(init?: Partial<UiFontSettings>) { (Object as any).assign(this, init); }
}

export enum DesignPackFontGroupType
{
    Site = 1,
    Email = 2,
}

export class BaseEnabledWidgetElement
{
    public isEnabled: boolean;
    public label: string;

    public constructor(init?: Partial<BaseEnabledWidgetElement>) { (Object as any).assign(this, init); }
}

export class WidgetVisibleElementSetting
{
    public elementGroups: { [index: string]: WidgetVisibleElementSetting; };
    public elements: { [index: string]: BaseEnabledWidgetElement; };
    public subElements: { [index: string]: BaseEnabledWidgetElement; };

    public constructor(init?: Partial<WidgetVisibleElementSetting>) { (Object as any).assign(this, init); }
}

export enum UiColorType
{
    Primary = 1,
    Secondary = 2,
    Accent = 3,
    Muted = 4,
    Fifth = 5,
    Sixth = 6,
    Seventh = 7,
    White = 8,
    Black = 9,
    Transparent = 10,
}

export class UiColorSettings
{
    public hexBg: string;
    public hexColor: string;
    public bg: UiColorType;
    public color: UiColorType;
    public hoverBg: UiColorType;
    public hoverColor: UiColorType;
    public activeBg: UiColorType;
    public activeColor: UiColorType;
    public borderColor: UiColorType;
    public hoverBorderColor: UiColorType;
    public focusBorderColor: UiColorType;
    public disabledBg: UiColorType;
    public disabledColor: UiColorType;
    public lineBg: UiColorType;

    public constructor(init?: Partial<UiColorSettings>) { (Object as any).assign(this, init); }
}

export class UiBoxSettings
{
    public maxWidth: string;
    public boxShadow: string;
    public borderWidth: string;
    public borderColor: string;
    public borderStyle: string;
    public borderRadius: string;

    public constructor(init?: Partial<UiBoxSettings>) { (Object as any).assign(this, init); }
}

export enum ToneOfVoice
{
    Normal = 1,
    Formal = 2,
    Friendly = 3,
    Professional = 4,
}

export interface IImportLczReport
{
}

export enum UpdateContentPackActionType
{
    None = 1,
    SoftDelete = 2,
    Duplicate = 3,
}

export class ContentPackSettingBase
{
    public id: string;
    public value: LocalizeValue;

    public constructor(init?: Partial<ContentPackSettingBase>) { (Object as any).assign(this, init); }
}

export class ContentPackSettingTitle extends ContentPackSettingBase
{

    public constructor(init?: Partial<ContentPackSettingTitle>) { super(init); (Object as any).assign(this, init); }
}

export class ContentPackSettingSubtitle extends ContentPackSettingBase
{

    public constructor(init?: Partial<ContentPackSettingSubtitle>) { super(init); (Object as any).assign(this, init); }
}

export class ContentPackSettingDescription extends ContentPackSettingBase
{

    public constructor(init?: Partial<ContentPackSettingDescription>) { super(init); (Object as any).assign(this, init); }
}

export class ContentPackSettingItemTitle extends ContentPackSettingBase
{

    public constructor(init?: Partial<ContentPackSettingItemTitle>) { super(init); (Object as any).assign(this, init); }
}

export class ContentPackSettingItemDescription extends ContentPackSettingBase
{

    public constructor(init?: Partial<ContentPackSettingItemDescription>) { super(init); (Object as any).assign(this, init); }
}

export class ContentPackSettingButton extends ContentPackSettingBase
{

    public constructor(init?: Partial<ContentPackSettingButton>) { super(init); (Object as any).assign(this, init); }
}

export class ContentPackWidgetCategoryAboutUsSetting implements IContentPackWidgetSetting
{
    public titles: ContentPackSettingTitle[];
    public subtitles: ContentPackSettingSubtitle[];
    public descriptions: ContentPackSettingDescription[];
    public shortDescriptions: ContentPackSettingDescription[];
    public iconTitles: ContentPackSettingItemTitle[];
    public iconDescriptions: ContentPackSettingItemDescription[];
    public primaryButtons: ContentPackSettingButton[];
    public secondaryButtons: ContentPackSettingButton[];

    public constructor(init?: Partial<ContentPackWidgetCategoryAboutUsSetting>) { (Object as any).assign(this, init); }
}

export class ContentPackWidgetCategoryBannerSetting
{
    public titles: ContentPackSettingTitle[];
    public subtitles: ContentPackSettingSubtitle[];
    public descriptions: ContentPackSettingDescription[];
    public promoTitles: ContentPackSettingTitle[];
    public primaryButtons: ContentPackSettingButton[];
    public secondaryButtons: ContentPackSettingButton[];
    public tertiaryButtons: ContentPackSettingButton[];

    public constructor(init?: Partial<ContentPackWidgetCategoryBannerSetting>) { (Object as any).assign(this, init); }
}

export class ContentPackWidgetCategoryBlogSetting
{
    public titles: ContentPackSettingTitle[];
    public subtitles: ContentPackSettingSubtitle[];
    public descriptions: ContentPackSettingDescription[];
    public authorName: ContentPackSettingTitle[];
    public buttons: ContentPackSettingButton[];

    public constructor(init?: Partial<ContentPackWidgetCategoryBlogSetting>) { (Object as any).assign(this, init); }
}

export class ContentPackWidgetCategoryBlogTitleSetting
{
    public titles: ContentPackSettingTitle[];

    public constructor(init?: Partial<ContentPackWidgetCategoryBlogTitleSetting>) { (Object as any).assign(this, init); }
}

export class ContentPackWidgetCategoryBlogTextSetting
{
    public descriptions: ContentPackSettingDescription[];

    public constructor(init?: Partial<ContentPackWidgetCategoryBlogTextSetting>) { (Object as any).assign(this, init); }
}

export class ContentPackWidgetCategoryBlogButtonSetting
{
    public buttons: ContentPackSettingButton[];

    public constructor(init?: Partial<ContentPackWidgetCategoryBlogButtonSetting>) { (Object as any).assign(this, init); }
}

export class ContentPackWidgetCategoryBlogGallerySetting
{
    public buttons: ContentPackSettingButton[];

    public constructor(init?: Partial<ContentPackWidgetCategoryBlogGallerySetting>) { (Object as any).assign(this, init); }
}

export class ContentPackWidgetCategoryBlogVideoSetting
{
    public onHoverTexts: ContentPackSettingItemDescription[];

    public constructor(init?: Partial<ContentPackWidgetCategoryBlogVideoSetting>) { (Object as any).assign(this, init); }
}

export class ContentPackWidgetCategoryBlogImageSetting
{
    public titles: ContentPackSettingTitle[];
    public imageDescriptions: ContentPackSettingDescription[];

    public constructor(init?: Partial<ContentPackWidgetCategoryBlogImageSetting>) { (Object as any).assign(this, init); }
}

export class ContentPackWidgetCategoryBlogImageWithTextSetting
{
    public titles: ContentPackSettingTitle[];
    public descriptions: ContentPackSettingDescription[];
    public imageDescriptions: ContentPackSettingDescription[];

    public constructor(init?: Partial<ContentPackWidgetCategoryBlogImageWithTextSetting>) { (Object as any).assign(this, init); }
}

export class ContentPackWidgetCategoryBlogQuoteSetting
{
    public descriptions: ContentPackSettingDescription[];

    public constructor(init?: Partial<ContentPackWidgetCategoryBlogQuoteSetting>) { (Object as any).assign(this, init); }
}

export class ContentPackSettingPlaceholder extends ContentPackSettingBase
{

    public constructor(init?: Partial<ContentPackSettingPlaceholder>) { super(init); (Object as any).assign(this, init); }
}

export class ContentPackWidgetCategorySubscribeSetting
{
    public titles: ContentPackSettingTitle[];
    public descriptions: ContentPackSettingDescription[];
    public buttons: ContentPackSettingButton[];
    public placeholders: ContentPackSettingPlaceholder[];
    public confirmationTitles: ContentPackSettingTitle[];
    public confirmationDescriptions: ContentPackSettingDescription[];

    public constructor(init?: Partial<ContentPackWidgetCategorySubscribeSetting>) { (Object as any).assign(this, init); }
}

export class ContentPackSettingItemLabel extends ContentPackSettingBase
{

    public constructor(init?: Partial<ContentPackSettingItemLabel>) { super(init); (Object as any).assign(this, init); }
}

export class ContentPackWidgetCategoryBlogActionsSetting
{
    public labels: ContentPackSettingItemLabel[];

    public constructor(init?: Partial<ContentPackWidgetCategoryBlogActionsSetting>) { (Object as any).assign(this, init); }
}

export class ContentPackWidgetCategoryBlogAboutAuthorSetting
{
    public titles: ContentPackSettingTitle[];
    public subtitles: ContentPackSettingSubtitle[];
    public descriptions: ContentPackSettingDescription[];

    public constructor(init?: Partial<ContentPackWidgetCategoryBlogAboutAuthorSetting>) { (Object as any).assign(this, init); }
}

export class ContentPackWidgetCategoryBlogTopPostsSetting
{
    public titles: ContentPackSettingTitle[];
    public descriptions: ContentPackSettingDescription[];
    public buttons: ContentPackSettingButton[];

    public constructor(init?: Partial<ContentPackWidgetCategoryBlogTopPostsSetting>) { (Object as any).assign(this, init); }
}

export class ContentPackWidgetCategoryBlogRecipeSetting
{
    public titles: ContentPackSettingTitle[];
    public longDescriptions: ContentPackSettingDescription[];
    public descriptions: ContentPackSettingDescription[];
    public recipeIngredientstTitles: ContentPackSettingTitle[];
    public recipeIngredientsDescriptions: ContentPackSettingDescription[];
    public recipePreparationTitles: ContentPackSettingTitle[];
    public recipePreparationDescriptions: ContentPackSettingDescription[];
    public imageDescriptions: ContentPackSettingDescription[];

    public constructor(init?: Partial<ContentPackWidgetCategoryBlogRecipeSetting>) { (Object as any).assign(this, init); }
}

export class ContentPackWidgetCategoryBlogSummarySetting
{
    public titles: ContentPackSettingTitle[];
    public descriptions: ContentPackSettingDescription[];

    public constructor(init?: Partial<ContentPackWidgetCategoryBlogSummarySetting>) { (Object as any).assign(this, init); }
}

export class ContentPackWidgetCategoryBlogFeaturedItemsSetting
{
    public featuredItemNames: ContentPackSettingItemTitle[];
    public buttons: ContentPackSettingButton[];

    public constructor(init?: Partial<ContentPackWidgetCategoryBlogFeaturedItemsSetting>) { (Object as any).assign(this, init); }
}

export class ContentPackWidgetCategoryProductBundleSetting
{
    public titles: ContentPackSettingTitle[];
    public featuredItemNames: ContentPackSettingItemTitle[];
    public buttons: ContentPackSettingButton[];

    public constructor(init?: Partial<ContentPackWidgetCategoryProductBundleSetting>) { (Object as any).assign(this, init); }
}

export class ContentPackWidgetCategoryProductAdditionalInfoProductDescription
{
    public titles: ContentPackSettingTitle[];
    public subtitles: ContentPackSettingSubtitle[];
    public descriptions: ContentPackSettingDescription[];
    public listDescriptions: ContentPackSettingDescription[];
    public listTitles: ContentPackSettingTitle[];

    public constructor(init?: Partial<ContentPackWidgetCategoryProductAdditionalInfoProductDescription>) { (Object as any).assign(this, init); }
}

export class ContentPackWidgetCategoryProductAdditionalInfoFeatures
{
    public titles: ContentPackSettingTitle[];
    public subtitles: ContentPackSettingSubtitle[];
    public descriptions: ContentPackSettingDescription[];
    public listDescriptions: ContentPackSettingDescription[];
    public listTitles: ContentPackSettingTitle[];

    public constructor(init?: Partial<ContentPackWidgetCategoryProductAdditionalInfoFeatures>) { (Object as any).assign(this, init); }
}

export class ContentPackWidgetCategoryProductAdditionalInfoSizeAndFit
{
    public titles: ContentPackSettingTitle[];
    public subtitles: ContentPackSettingSubtitle[];
    public descriptions: ContentPackSettingDescription[];

    public constructor(init?: Partial<ContentPackWidgetCategoryProductAdditionalInfoSizeAndFit>) { (Object as any).assign(this, init); }
}

export class ContentPackWidgetCategoryProductAdditionalInfoShippingAndDelivery
{
    public subtitles: ContentPackSettingSubtitle[];
    public descriptions: ContentPackSettingDescription[];
    public listDescriptions: ContentPackSettingDescription[];
    public listTitles: ContentPackSettingTitle[];

    public constructor(init?: Partial<ContentPackWidgetCategoryProductAdditionalInfoShippingAndDelivery>) { (Object as any).assign(this, init); }
}

export class ContentPackWidgetCategoryProductAdditionalInfoReviews
{
    public subtitles: ContentPackSettingSubtitle[];
    public descriptions: ContentPackSettingDescription[];
    public positions: ContentPackSettingDescription[];

    public constructor(init?: Partial<ContentPackWidgetCategoryProductAdditionalInfoReviews>) { (Object as any).assign(this, init); }
}

export class ContentPackBookingContactForm
{
    public location: ContentPackSettingBase;
    public service: ContentPackSettingBase;
    public staffMember: ContentPackSettingBase;
    public date: ContentPackSettingBase;
    public time: ContentPackSettingBase;
    public firstName: ContentPackSettingBase;
    public lastName: ContentPackSettingBase;
    public email: ContentPackSettingBase;
    public button: ContentPackSettingBase;
    public confirmationTitle: ContentPackSettingBase;
    public confirmationMessage: ContentPackSettingBase;

    public constructor(init?: Partial<ContentPackBookingContactForm>) { (Object as any).assign(this, init); }
}

export class ContentPackWidgetCategoryBookingSetting
{
    public titles: ContentPackSettingTitle[];
    public secondaryTitles: ContentPackSettingSubtitle[];
    public subtitles: ContentPackSettingSubtitle[];
    public descriptions: ContentPackSettingDescription[];
    public buttons: ContentPackSettingButton[];
    public contactForm: ContentPackBookingContactForm;

    public constructor(init?: Partial<ContentPackWidgetCategoryBookingSetting>) { (Object as any).assign(this, init); }
}

export class ContentPackWidgetCategoryCommentsSetting
{
    public titles: ContentPackSettingTitle[];
    public subtitles: ContentPackSettingSubtitle[];
    public descriptions: ContentPackSettingDescription[];

    public constructor(init?: Partial<ContentPackWidgetCategoryCommentsSetting>) { (Object as any).assign(this, init); }
}

export class ContentPackContactUsContactForm
{
    public firstName: ContentPackSettingBase;
    public lastName: ContentPackSettingBase;
    public email: ContentPackSettingBase;
    public phone: ContentPackSettingBase;
    public message: ContentPackSettingBase;
    public button: ContentPackSettingBase;
    public confirmationTitle: ContentPackSettingBase;
    public confirmationMessage: ContentPackSettingBase;

    public constructor(init?: Partial<ContentPackContactUsContactForm>) { (Object as any).assign(this, init); }
}

export class ContentPackWidgetCategoryContactUsSetting
{
    public titles: ContentPackSettingTitle[];
    public subtitles: ContentPackSettingSubtitle[];
    public descriptions: ContentPackSettingDescription[];
    public contactsInformationTitles: ContentPackSettingTitle[];
    public contactsInformationDescriptions: ContentPackSettingDescription[];
    public openingHoursTitles: ContentPackSettingTitle[];
    public openingHoursDescriptions: ContentPackSettingDescription[];
    public callBackTitles: ContentPackSettingTitle[];
    public callBackDescriptions: ContentPackSettingDescription[];
    public fieldPlaceholders: ContentPackSettingItemLabel[];
    public primaryButtons: ContentPackSettingButton[];
    public contactForm: ContentPackContactUsContactForm;

    public constructor(init?: Partial<ContentPackWidgetCategoryContactUsSetting>) { (Object as any).assign(this, init); }
}

export class ContentPackWidgetCategoryEventsSetting
{
    public titles: ContentPackSettingTitle[];
    public subtitles: ContentPackSettingSubtitle[];
    public descriptions: ContentPackSettingDescription[];

    public constructor(init?: Partial<ContentPackWidgetCategoryEventsSetting>) { (Object as any).assign(this, init); }
}

export class ContentPackSettingTab extends ContentPackSettingBase
{

    public constructor(init?: Partial<ContentPackSettingTab>) { super(init); (Object as any).assign(this, init); }
}

export class ContentPackSettingQuestion extends ContentPackSettingBase
{

    public constructor(init?: Partial<ContentPackSettingQuestion>) { super(init); (Object as any).assign(this, init); }
}

export class ContentPackSettingAnswers extends ContentPackSettingBase
{

    public constructor(init?: Partial<ContentPackSettingAnswers>) { super(init); (Object as any).assign(this, init); }
}

export class ContentPackWidgetCategoryFaqSetting
{
    public titles: ContentPackSettingTitle[];
    public subtitles: ContentPackSettingSubtitle[];
    public descriptions: ContentPackSettingDescription[];
    public tabs: ContentPackSettingTab[];
    public questions: ContentPackSettingQuestion[];
    public answers: ContentPackSettingAnswers[];

    public constructor(init?: Partial<ContentPackWidgetCategoryFaqSetting>) { (Object as any).assign(this, init); }
}

export class ContentPackWidgetCategoryFeaturedItemsSetting
{
    public titles: ContentPackSettingTitle[];
    public subtitles: ContentPackSettingSubtitle[];
    public tabs: ContentPackSettingTab[];
    public featuredItemNames: ContentPackSettingItemTitle[];
    public buttons: ContentPackSettingButton[];

    public constructor(init?: Partial<ContentPackWidgetCategoryFeaturedItemsSetting>) { (Object as any).assign(this, init); }
}

export class ContentPackWidgetCategoryGallerySetting
{
    public titles: ContentPackSettingTitle[];
    public subtitles: ContentPackSettingSubtitle[];
    public descriptions: ContentPackSettingDescription[];
    public tabs: ContentPackSettingTab[];
    public imageTitles: ContentPackSettingItemTitle[];
    public imageDescriptions: ContentPackSettingItemDescription[];
    public buttons: ContentPackSettingButton[];

    public constructor(init?: Partial<ContentPackWidgetCategoryGallerySetting>) { (Object as any).assign(this, init); }
}

export class ContentPackWidgetCategoryLogosSetting
{
    public titles: ContentPackSettingTitle[];
    public subtitles: ContentPackSettingSubtitle[];
    public authors: ContentPackSettingTitle[];
    public logoLabels: ContentPackSettingItemLabel[];
    public descriptions: ContentPackSettingDescription[];

    public constructor(init?: Partial<ContentPackWidgetCategoryLogosSetting>) { (Object as any).assign(this, init); }
}

export class ContentPackSettingItemSubtitle extends ContentPackSettingBase
{

    public constructor(init?: Partial<ContentPackSettingItemSubtitle>) { super(init); (Object as any).assign(this, init); }
}

export class ContentPackWidgetCategoryOurTeamSetting
{
    public titles: ContentPackSettingTitle[];
    public subtitles: ContentPackSettingSubtitle[];
    public personNames: ContentPackSettingItemTitle[];
    public personPositions: ContentPackSettingItemSubtitle[];
    public personEmails: ContentPackSettingItemSubtitle[];
    public personPhones: ContentPackSettingItemSubtitle[];
    public personDescriptions: ContentPackSettingItemDescription[];
    public descriptions: ContentPackSettingDescription[];
    public buttons: ContentPackSettingButton[];

    public constructor(init?: Partial<ContentPackWidgetCategoryOurTeamSetting>) { (Object as any).assign(this, init); }
}

export class ContentPackSettingService extends ContentPackSettingBase
{
    public descriptions: ContentPackSettingDescription[];

    public constructor(init?: Partial<ContentPackSettingService>) { super(init); (Object as any).assign(this, init); }
}

export class ContentPackWidgetCategoryPriceListSetting
{
    public titles: ContentPackSettingTitle[];
    public subtitles: ContentPackSettingSubtitle[];
    public tabs: ContentPackSettingTab[];
    public services: ContentPackSettingService[];
    public descriptions: ContentPackSettingDescription[];
    public buttons: ContentPackSettingButton[];

    public constructor(init?: Partial<ContentPackWidgetCategoryPriceListSetting>) { (Object as any).assign(this, init); }
}

export class ContentPackWidgetCategoryServiceSetting
{
    public titles: ContentPackSettingTitle[];
    public subtitles: ContentPackSettingSubtitle[];
    public descriptions: ContentPackSettingDescription[];
    public tabs: ContentPackSettingTab[];
    public services: ContentPackSettingService[];
    public buttons: ContentPackSettingButton[];

    public constructor(init?: Partial<ContentPackWidgetCategoryServiceSetting>) { (Object as any).assign(this, init); }
}

export class ContentPackWidgetCategorySliderSetting
{
    public titles: ContentPackSettingTitle[];
    public primaryButtons: ContentPackSettingButton[];
    public secondaryButtons: ContentPackSettingButton[];
    public authors: ContentPackSettingTitle[];
    public linkButtons: ContentPackSettingButton[];
    public descriptions: ContentPackSettingDescription[];
    public quotes: ContentPackSettingDescription[];

    public constructor(init?: Partial<ContentPackWidgetCategorySliderSetting>) { (Object as any).assign(this, init); }
}

export class ContentPackWidgetCategorySmartBannerSetting
{
    public titles: ContentPackSettingTitle[];
    public subtitles: ContentPackSettingSubtitle[];
    public descriptions: ContentPackSettingDescription[];
    public statsTitles: ContentPackSettingTitle[];
    public statsDescription: ContentPackSettingDescription[];
    public secondDescriptions: ContentPackSettingDescription[];
    public progressDescription: ContentPackSettingDescription[];
    public countdownTitles: ContentPackSettingTitle[];
    public thirdDescriptions: ContentPackSettingDescription[];
    public buttons: ContentPackSettingButton[];

    public constructor(init?: Partial<ContentPackWidgetCategorySmartBannerSetting>) { (Object as any).assign(this, init); }
}

export class ContentPackSettingReview extends ContentPackSettingBase
{

    public constructor(init?: Partial<ContentPackSettingReview>) { super(init); (Object as any).assign(this, init); }
}

export class ContentPackSettingRating extends ContentPackSettingBase
{

    public constructor(init?: Partial<ContentPackSettingRating>) { super(init); (Object as any).assign(this, init); }
}

export class ContentPackWidgetCategoryTestimonialstSetting
{
    public titles: ContentPackSettingTitle[];
    public subtitles: ContentPackSettingSubtitle[];
    public descriptions: ContentPackSettingDescription[];
    public personNames: ContentPackSettingItemTitle[];
    public positions: ContentPackSettingItemSubtitle[];
    public reviews: ContentPackSettingReview[];
    public reviewTitles: ContentPackSettingReview[];
    public ratings: ContentPackSettingRating[];
    public authors: ContentPackSettingTitle[];

    public constructor(init?: Partial<ContentPackWidgetCategoryTestimonialstSetting>) { (Object as any).assign(this, init); }
}

export class ContentPackWidgetCategoryTestiomonialFormSetting
{
    public titles: ContentPackSettingTitle[];
    public subtitles: ContentPackSettingSubtitle[];
    public descriptions: ContentPackSettingDescription[];

    public constructor(init?: Partial<ContentPackWidgetCategoryTestiomonialFormSetting>) { (Object as any).assign(this, init); }
}

export class ContentPackWidgetCategoryTextSetting
{
    public titles: ContentPackSettingTitle[];
    public subtitles: ContentPackSettingSubtitle[];
    public descriptions: ContentPackSettingDescription[];
    public tabs: ContentPackSettingTab[];
    public buttons: ContentPackSettingButton[];
    public itemTitles: ContentPackSettingTitle[];
    public itemDescriptions: ContentPackSettingDescription[];

    public constructor(init?: Partial<ContentPackWidgetCategoryTextSetting>) { (Object as any).assign(this, init); }
}

export class ContentPackSettingItemUrl extends ContentPackSettingBase
{

    public constructor(init?: Partial<ContentPackSettingItemUrl>) { super(init); (Object as any).assign(this, init); }
}

export class ContentPackWidgetCategoryVideoSetting
{
    public titles: ContentPackSettingTitle[];
    public subtitles: ContentPackSettingSubtitle[];
    public descriptions: ContentPackSettingDescription[];
    public longDescriptions: ContentPackSettingDescription[];
    public buttons: ContentPackSettingButton[];
    public onHoverTexts: ContentPackSettingItemDescription[];
    public videos: ContentPackSettingItemUrl[];
    public itemTitles: ContentPackSettingTitle[];
    public itemDescriptions: ContentPackSettingDescription[];

    public constructor(init?: Partial<ContentPackWidgetCategoryVideoSetting>) { (Object as any).assign(this, init); }
}

export class ContentPackWidgetCategoryCareerSetting
{
    public titles: ContentPackSettingTitle[];
    public descriptions: ContentPackSettingDescription[];
    public tabs: ContentPackSettingTab[];
    public itemShortDescriptions: ContentPackSettingDescription[];
    public itemTitles: ContentPackSettingItemTitle[];
    public itemLocations: ContentPackSettingItemTitle[];
    public itemDescriptions: ContentPackSettingItemDescription[];
    public buttons: ContentPackSettingButton[];

    public constructor(init?: Partial<ContentPackWidgetCategoryCareerSetting>) { (Object as any).assign(this, init); }
}

export class ContentPackWidgetCategoryMusicSetting
{
    public titles: ContentPackSettingTitle[];
    public subtitles: ContentPackSettingSubtitle[];
    public itemTitles: ContentPackSettingTitle[];
    public buttons: ContentPackSettingButton[];

    public constructor(init?: Partial<ContentPackWidgetCategoryMusicSetting>) { (Object as any).assign(this, init); }
}

export class ContentPackWidgetCategoryTableSetting
{
    public titles: ContentPackSettingTitle[];
    public subtitles: ContentPackSettingSubtitle[];
    public descriptions: ContentPackSettingDescription[];
    public tabs: ContentPackSettingTab[];
    public columns: ContentPackSettingSubtitle[];

    public constructor(init?: Partial<ContentPackWidgetCategoryTableSetting>) { (Object as any).assign(this, init); }
}

export class ContentPackWidgetCategoryResourcesSetting
{
    public titles: ContentPackSettingTitle[];
    public subtitles: ContentPackSettingSubtitle[];
    public descriptions: ContentPackSettingDescription[];
    public itemTitles: ContentPackSettingItemTitle[];
    public itemDescriptions: ContentPackSettingItemDescription[];
    public buttons: ContentPackSettingButton[];

    public constructor(init?: Partial<ContentPackWidgetCategoryResourcesSetting>) { (Object as any).assign(this, init); }
}

export class ContentPackWidgetCategoryMenuSetting
{
    public titles: ContentPackSettingTitle[];
    public subtitles: ContentPackSettingSubtitle[];
    public descriptions: ContentPackSettingDescription[];
    public tabs: ContentPackSettingTab[];
    public itemTitles: ContentPackSettingItemTitle[];
    public itemDescriptions: ContentPackSettingItemDescription[];

    public constructor(init?: Partial<ContentPackWidgetCategoryMenuSetting>) { (Object as any).assign(this, init); }
}

export enum NotificationLevel
{
    Info = 1,
    Success = 2,
    Warning = 3,
    Confirm = 4,
    Prompt = 5,
    Error = 6,
}

export enum TextContentType
{
    Text = 1,
    Lcz = 2,
}

export class TextContent
{
    public type: TextContentType;
    public lczCode: string;
    public text: string;

    public constructor(init?: Partial<TextContent>) { (Object as any).assign(this, init); }
}

export class SiteNotification
{
    public level: NotificationLevel;
    public header: TextContent;
    public body: TextContent;
    public footer: TextContent;

    public constructor(init?: Partial<SiteNotification>) { (Object as any).assign(this, init); }
}

export enum EventSendType
{
    Simple = 1,
    RequiredReceipt = 2,
}

export interface ICodeListEntityRequest
{
    codes: string[];
}

export enum SettingValueType
{
    String = 1,
    Number = 2,
    Boolean = 3,
    DateTime = 4,
    NumberList = 5,
}

export class SettingValue
{
    public stringValue: string;
    public numberValue?: number;
    public booleanValue?: boolean;
    public dateTimeValue?: string;
    public numberListValue: number[];

    public constructor(init?: Partial<SettingValue>) { (Object as any).assign(this, init); }
}

export enum ProcessedRecordStatus
{
    Pending = 1,
    Processed = 2,
    Skipped = 3,
    Failed = 4,
}

export class ProcessedRecordError
{
    public message: string;
    public stackTrace: string;
    public innerError: ProcessedRecordError;

    public constructor(init?: Partial<ProcessedRecordError>) { (Object as any).assign(this, init); }
}

export interface IData
{
    data: { [index: string]: Object; };
}

export enum UserOpenAiHistoryType
{
    None = 0,
    Rephrase = 1,
    NewText = 2,
    NewPost = 3,
    Search = 4,
    SiteInit = 5,
    Logo = 6,
    ExtendImage = 7,
}

export enum EventLogType
{
    Signup = 1,
    WidgetChangeOrCreate = 2,
    PageAdded = 3,
    ContentEdited = 4,
    WizardCategorySearch = 5,
    SubscriptionUpgrade = 6,
    SubscriptionCancel = 7,
    WizardStepSubmitted = 8,
    WizardOpened = 9,
    SiteCreated = 10,
    LandingPageInvalidEmail = 11,
    LoginOk = 12,
    LoginFailed = 13,
    SignupEmailSequenceSent = 14,
    DomainVerified = 15,
    ContinueOnDesktopEmailLink = 16,
    WizardFinished = 17,
    ClickUpgradeAccountButton = 18,
    WizardBackStepSubmitted = 19,
    ApiCall = 20,
    PageOpened = 21,
    EmailSent = 22,
    StartTrial = 23,
    WidgetTextChanged = 24,
    WidgetImageChanged = 25,
    WidgetBgImageChanged = 26,
    WizardThemeOptionChanged = 27,
    WizardThemeColorChanged = 28,
    WizardThemeFontChanged = 29,
    WizardNewCategoryRequest = 30,
    WizardGroupDetailsOpened = 31,
    WizardIndustryTabSelected = 32,
    SiteSuspend = 33,
    SearchDomain = 34,
    FileManagerOpened = 35,
    ModalOpened = 36,
    DeactivateUser = 37,
    RegisterDomain = 38,
    DeactivateSite = 39,
    AiInteraction = 40,
    CampaignInteraction = 41,
    PaymentConnect = 42,
    IntegrationConnect = 43,
    IntegrationDisconnect = 44,
    CreateMailbox = 45,
    ImageUploadLimit = 46,
    PurchaseDomain = 47,
    ChangeUserEmail = 48,
    DocumentUploadLimit = 49,
    AudioUploadLimit = 50,
    VideoUploadLimit = 51,
    WizardLanguageChanged = 52,
    DeleteMailbox = 53,
    ProductCreated = 54,
    ProductEdited = 55,
    PaymentActivated = 56,
    PaymentDeactivated = 57,
    ImageUploaded = 58,
    AudioUploaded = 59,
    VideoUploaded = 60,
    DocumentUploaded = 61,
    AppActivated = 62,
    AppDeactivated = 63,
    WidgetAdded = 64,
    WidgetRemoved = 65,
    WidgetCopyrightTextChanged = 66,
    AiLogoApplied = 67,
    SubscriptionPayment = 68,
    PayProPayment = 69,
    DomainStatusChanged = 70,
    RenewDomain = 71,
    SubscriptionMoveToYearPlan = 72,
    CancelRequestStart = 73,
    CancelRequestFinished = 74,
    SwitchToYearly = 75,
    DomainMovedOut = 76,
    DomainMovedIn = 77,
    AiApplied = 78,
    SidebarOpened = 79,
    WidgetVariationChanged = 80,
    PageDeleted = 81,
    DashboardEditSite = 88,
    TestimonialFormCreated = 300,
    BookingLocationAdded = 400,
    BookingStaffAdded = 401,
    BookingServiceAdded = 402,
    BookingWidgetAdded = 403,
    BlogPostStatusChanged = 500,
    PopupAdded = 600,
    PopupDeleted = 601,
    AppliedLogo = 700,
    DesignAction = 701,
    OpenDashboardFromEditor = 702,
    MenuItemAdded = 800,
    MenuItemDeleted = 801,
}

export class BaseEventLog
{
    public siteId?: number;
    public userAuthId?: number;
    public email: string;
    public eventType: EventLogType;
    public data: { [index: string]: Object; };

    public constructor(init?: Partial<BaseEventLog>) { (Object as any).assign(this, init); }
}

// @DataContract
export enum ModalTypes
{
    Upgrade = 1,
    AiGeneration = 2,
    NewWidget = 3,
}

// @DataContract
export enum FileManagerEvents
{
    TabSwitch = 1,
    ImageEditing = 2,
    SearchFreeImages = 3,
    SearchPaidImages = 4,
}

export enum SidebarType
{
    Design = 1,
}

export enum DesignActionType
{
    ColorPaletteChanged = 1,
    FontChanged = 2,
    PaletteGenerated = 3,
    FaviconChanged = 4,
}

export interface ISpecificationValueOwnerRequest
{
    siteId?: number;
    userId?: number;
}

export enum SpecificationValueNumberOperation
{
    Change = 1,
    Increase = 2,
    Decrease = 3,
}

export enum SpecificationValueListOperation
{
    Change = 1,
    Append = 2,
    Remove = 3,
}

export class SaveSpecificationValue implements ISpecificationValueOwnerRequest
{
    public siteId?: number;
    public userId?: number;
    public specificationCode: string;
    public stringValue: string;
    public numberOperation: SpecificationValueNumberOperation;
    public longValue?: number;
    public decimalValue?: number;
    public booleanValue?: boolean;
    public dateTimeValue?: string;
    public listOperation: SpecificationValueListOperation;
    public listValueCodes: string[];

    public constructor(init?: Partial<SaveSpecificationValue>) { (Object as any).assign(this, init); }
}

export interface ICodeEntity
{
    code: string;
}

export enum SpecificationType
{
    String = 1,
    Long = 2,
    Decimal = 3,
    Boolean = 4,
    DateTime = 5,
    Item = 6,
    List = 7,
}

export enum SpecificationOwnerType
{
    Site = 1,
    User = 2,
}

export enum SpecificationConditionGroupLogicalOperation
{
    And = 1,
    Or = 2,
}

export enum StringSpecificationConditionType
{
    Equal = 1,
    NotEqual = 2,
    Contains = 3,
}

export class StringSpecificationCondition
{
    public type: StringSpecificationConditionType;
    public value: string;

    public constructor(init?: Partial<StringSpecificationCondition>) { (Object as any).assign(this, init); }
}

export enum NumberSpecificationConditionType
{
    Equal = 1,
    NotEqual = 2,
    LessThan = 3,
    LessThanOrEqual = 4,
    GreaterThan = 5,
    GreaterThanOrEqual = 6,
    Between = 7,
}

export class LongSpecificationCondition
{
    public type: NumberSpecificationConditionType;
    public value: number;
    public secondValue?: number;

    public constructor(init?: Partial<LongSpecificationCondition>) { (Object as any).assign(this, init); }
}

export class DecimalSpecificationCondition
{
    public type: NumberSpecificationConditionType;
    public value: number;
    public secondValue?: number;

    public constructor(init?: Partial<DecimalSpecificationCondition>) { (Object as any).assign(this, init); }
}

export enum BooleanSpecificationConditionType
{
    Equal = 1,
    NotEqual = 2,
}

export class BooleanSpecificationCondition
{
    public type: BooleanSpecificationConditionType;
    public value: boolean;

    public constructor(init?: Partial<BooleanSpecificationCondition>) { (Object as any).assign(this, init); }
}

export enum DateTimeSpecificationConditionType
{
    DateTimeEqual = 1,
    DateTimeNotEqual = 2,
    DateTimeLess = 3,
    DateTimeLessOrEqual = 4,
    DateTimeGreater = 5,
    DateTimeGreaterOrEqual = 6,
    DateTimeBetween = 7,
    DateEqual = 100,
    DateNotEqual = 101,
    DateLess = 102,
    DateLessOrEqual = 103,
    DateGreater = 104,
    DateGreaterOrEqual = 105,
    DateBetween = 106,
    NextNDays = 107,
    PreviousNDays = 108,
    TimeEqual = 200,
    TimeNotEqual = 201,
    TimeLess = 202,
    TimeLessOrEqual = 203,
    TimeGreater = 204,
    TimeGreaterOrEqual = 205,
    TimeBetween = 206,
    NextNMinutes = 207,
    PreviousNMinutes = 208,
}

export class DateTimeSpecificationCondition
{
    public type: DateTimeSpecificationConditionType;
    public value: string;
    public secondValue?: string;
    public numberValue?: number;

    public constructor(init?: Partial<DateTimeSpecificationCondition>) { (Object as any).assign(this, init); }
}

export enum ItemSpecificationConditionType
{
    Equal = 1,
    NotEqual = 2,
    In = 3,
    NotIn = 4,
}

export class ItemSpecificationCondition
{
    public type: ItemSpecificationConditionType;
    public itemCodes: string[];

    public constructor(init?: Partial<ItemSpecificationCondition>) { (Object as any).assign(this, init); }
}

export enum ListSpecificationConditionType
{
    In = 0,
    NotIn = 1,
    All = 2,
}

export class ListSpecificationCondition
{
    public type: ListSpecificationConditionType;
    public listCodes: string[];

    public constructor(init?: Partial<ListSpecificationCondition>) { (Object as any).assign(this, init); }
}

export class SpecificationCondition
{
    public specificationCode: string;
    public stringCondition: StringSpecificationCondition;
    public longCondition: LongSpecificationCondition;
    public decimalCondition: DecimalSpecificationCondition;
    public booleanCondition: BooleanSpecificationCondition;
    public dateTimeCondition: DateTimeSpecificationCondition;
    public itemCondition: ItemSpecificationCondition;
    public listCondition: ListSpecificationCondition;

    public constructor(init?: Partial<SpecificationCondition>) { (Object as any).assign(this, init); }
}

export class SpecificationConditionGroup
{
    public logicalOperation: SpecificationConditionGroupLogicalOperation;
    public conditions: SpecificationCondition[];
    public groups: SpecificationConditionGroup[];

    public constructor(init?: Partial<SpecificationConditionGroup>) { (Object as any).assign(this, init); }
}

export class SpecificationRule implements ICodeEntity
{
    public code: string;
    public condition: SpecificationConditionGroup;

    public constructor(init?: Partial<SpecificationRule>) { (Object as any).assign(this, init); }
}

export interface IProcessAnalyseRequest
{
    take: number;
    threadCount: number;
}

export class BaseSiteSearchElasticRequest implements ISearchElasticRequest
{
    public query: string;
    public take: number;
    public skip: number;
    public language: LocalizableLanguage;
    public fields: string[];
    public siteId?: number;
    public preHighlightTag: string;
    public postHighlightTag: string;
    public highlightFragmentSize: number;

    public constructor(init?: Partial<BaseSiteSearchElasticRequest>) { (Object as any).assign(this, init); }
}

export interface ISearchElasticRequest
{
    query: string;
    take: number;
    skip: number;
    language: LocalizableLanguage;
    fields: string[];
    siteId?: number;
    preHighlightTag: string;
    postHighlightTag: string;
    highlightFragmentSize: number;
}

export enum SearchContentType
{
    Page = 0,
    Post = 1,
    Product = 2,
}

export enum ActionSource
{
    Email = 0,
    Website = 1,
    App = 2,
    PhoneCall = 3,
    Chat = 4,
    PhysicalStore = 5,
    SystemGenerated = 6,
    Other = 7,
}

export enum AuthRole
{
    Anonymous = 1,
    SiteOwner = 2,
    SiteUser = 3,
    VisualContentCreator = 4,
    VisualContentManager = 5,
    Admin = 6,
    AdminReadOnly = 7,
    SiteClient = 8,
    SiteAnonymousClient = 9,
}

export enum TextFormat
{
    Text = 1,
    Html = 2,
    Xml = 3,
}

export enum SiteDeletedReasonType
{
    Other = 1,
    DeactivateUser = 2,
    SanctionedCountry = 3,
}

export class LanguageValue
{
    public value: string;

    public constructor(init?: Partial<LanguageValue>) { (Object as any).assign(this, init); }
}

export class Dictionary<T> { [Key: string]: T; }

export class LanguageValues extends Dictionary<LanguageValue>
{

    public constructor(init?: Partial<LanguageValues>) { super(); (Object as any).assign(this, init); }
}

export class LanguageConfig
{
    public columns: { [index: string]: LanguageValue; };
    public values: { [index: string]: LanguageValues; };

    public constructor(init?: Partial<LanguageConfig>) { (Object as any).assign(this, init); }
}

export class LocalizeConfig extends Dictionary<LanguageConfig>
{

    public constructor(init?: Partial<LocalizeConfig>) { super(); (Object as any).assign(this, init); }
}

export class LocalizeConfigResponse implements IPrimaryEntity, ILocalizableEntity
{
    public id: number;
    public stringColumns: { [index: string]: string; };
    public jsonColumns: { [index: string]: LocalizeValue[]; };
    public lcz: LocalizeConfig;

    public constructor(init?: Partial<LocalizeConfigResponse>) { (Object as any).assign(this, init); }
}

export class GetProductLczConfigResponse extends LocalizeConfigResponse implements IDisplayEntity, ISiteReferenceEntity
{
    public name: string;
    public siteId: number;

    public constructor(init?: Partial<GetProductLczConfigResponse>) { super(init); (Object as any).assign(this, init); }
}

export class BuildRecordLocalizeConfigItem implements IPrimaryEntity
{
    public id: number;
    public config: LocalizeConfig;

    public constructor(init?: Partial<BuildRecordLocalizeConfigItem>) { (Object as any).assign(this, init); }
}

export interface ILocalizeRecordResponse
{
    values: { [index: string]: string; };
}

export class TagWithCount
{
    public key: number;
    public label: string;
    public count: number;
    public siteId?: number;

    public constructor(init?: Partial<TagWithCount>) { (Object as any).assign(this, init); }
}

export class OrderInvoiceSellerInfo
{
    public legalName: string;
    public firstName: string;
    public lastName: string;
    public primaryEmail: string;
    public phoneNumber: string;
    public address: string;
    public city: string;
    public country: string;
    public state: string;
    public postalCode: string;

    public constructor(init?: Partial<OrderInvoiceSellerInfo>) { (Object as any).assign(this, init); }
}

export class GetCartProductItemResponse
{
    public basePrice: number;
    public salePrice?: number;
    public unitPrice: number;
    public couponDiscount: number;
    public saleDiscount: number;
    public totalDiscount: number;
    public finalPrice: number;

    public constructor(init?: Partial<GetCartProductItemResponse>) { (Object as any).assign(this, init); }
}

export class GetCartProductResponse implements IApplyDiscountProduct
{
    public skuId: number;
    public productId: number;
    public unitBasePrice: number;
    public unitSalePrice?: number;
    public unitPrice: number;
    public unitSaleDiscount: number;
    public unitFinalPrice: number;
    public cartItemBasePrice: number;
    public cartItemTotalDiscount: number;
    public cartItemCouponDiscount: number;
    public cartItemUnitPrice: number;
    public cartItemFinalPrice: number;
    public quantity: number;
    public sku: string;
    public skuName: string;
    public skuConfig: ProductGroupOptionRecord[];
    public productName: string;
    public productSlug: string;
    public productOptionGroups: ProductOptionsGroup[];
    public productImage: BaseWidgetImage;
    public skuType: ProductInventorySkuType;
    public skuImage: BaseWidgetImage;
    public skuInventoryStockType: InventoryStockType;
    public skuAvailableQuantity?: number;
    public skuIsAutomaticallyTrackInventory?: boolean;
    public units: GetCartProductItemResponse[];

    public constructor(init?: Partial<GetCartProductResponse>) { (Object as any).assign(this, init); }
}

export class GetCartBundleResponse
{
    public bundleId: number;
    public bundleName: string;
    public unitBasePrice: number;
    public unitFinalPrice: number;
    public cartItemBasePrice: number;
    public cartItemTotalDiscount: number;
    public cartItemFinalPrice: number;
    public quantity: number;
    public items: GetCartProductResponse[];

    public constructor(init?: Partial<GetCartBundleResponse>) { (Object as any).assign(this, init); }
}

export class BundleIncludedSkuResponse
{
    public sku: GetProductInventorySkuResponse;
    public quantity: number;

    public constructor(init?: Partial<BundleIncludedSkuResponse>) { (Object as any).assign(this, init); }
}

export class GetBundleProductResponse
{
    public id: number;
    public bundleId: number;
    public name: string;

    public constructor(init?: Partial<GetBundleProductResponse>) { (Object as any).assign(this, init); }
}

export class GetWizardProgressResponse implements IPrimaryEntity, IAuditEntity, IOwnerEntity
{
    public id: number;
    public createdAt: string;
    public updatedAt: string;
    public siteId?: number;
    public userAuthId?: number;
    public lastStepType: SignupWizardStepType;
    public landingData: WizardLandingData;
    public businessCategoryData: WizardBusinessCategoryData;
    public servicesData: WizardServicesData;
    public sentencesData: WizardSentencesData;
    public locationData: WizardLocationData;
    public businessNameData: WizardBusinessNameData;
    public businessUniquenessData: WizardBusinessUniquenessData;
    public contactInfoData: WizardContactInfoData;
    public themeSelectionData: WizardThemeSelectionData;

    public constructor(init?: Partial<GetWizardProgressResponse>) { (Object as any).assign(this, init); }
}

export enum PayProPaymentStatus
{
    InProcessing = 1,
    Success = 2,
    Waiting = 3,
}

export enum DisplaySubscriptionOrderStatus
{
    Success = 1,
    Failed = 2,
    OnWaiting = 3,
    Finished = 4,
}

export class GetSubscriptionCancelResponse implements IPrimaryEntity, ISiteReferenceEntity
{
    public id: number;
    public siteId: number;
    public reason: ReasonStep;
    public detail: CancelReasonDetailStep;
    public rating: CancelRatingStep;
    public finish: CancelFinishStep;

    public constructor(init?: Partial<GetSubscriptionCancelResponse>) { (Object as any).assign(this, init); }
}

export class SiteAiServiceConfig
{
    public title: string;
    public description: string;

    public constructor(init?: Partial<SiteAiServiceConfig>) { (Object as any).assign(this, init); }
}

export class SiteAiConfig
{
    public aiGenericTitle: string;
    public aiGenericDescription: string;
    public aiAboutUsDescription: string;
    public services: SiteAiServiceConfig[];

    public constructor(init?: Partial<SiteAiConfig>) { (Object as any).assign(this, init); }
}

export class SiteDomainInfo implements IPrimaryEntity, IDisplayEntity, ISiteReferenceEntity
{
    public id: number;
    public name: string;
    public type: DomainType;
    public status: DomainStatus;
    public expireDate?: string;
    public siteId: number;

    public constructor(init?: Partial<SiteDomainInfo>) { (Object as any).assign(this, init); }
}

export interface ICommunicationEntity
{
}

export class GetPayProPaymentResponse implements IPrimaryEntity, ICreatedAtEntity
{
    public id: number;
    public createdAt: string;
    public orderId?: number;
    public subscriptionId?: number;
    public productId: string;
    public productType?: PayProPaymentProductType;
    public ipnType?: PpgIpnTypeId;
    public tariffType?: PaymentProductTariffType;
    public siteId?: number;
    public userId?: number;
    public orderStatus?: PpgOrderStatus;
    public subscriptionStatus?: PpgSubscriptionStatus;
    public offerType?: SubscriptionPlanDiscountOfferType;
    public amount?: number;
    public currency: string;
    public userFullPrice?: number;
    public userAmount?: number;
    public userDiscount?: number;
    public userRefundAmount?: number;
    public userCurrency: string;
    public coupon: string;
    public customerCountry: string;
    public customerIp: string;
    public isTestMode?: boolean;
    public hasFreeDomain?: boolean;
    public hasFreeMailBox?: boolean;
    public freeDomainId?: number;
    public freeMailBox?: number;
    public data: { [index: string]: Object; };

    public constructor(init?: Partial<GetPayProPaymentResponse>) { (Object as any).assign(this, init); }
}

export class GetPageLczConfigResponse extends LocalizeConfigResponse implements IDisplayEntity, ISiteReferenceEntity
{
    public name: string;
    public siteId: number;

    public constructor(init?: Partial<GetPageLczConfigResponse>) { super(init); (Object as any).assign(this, init); }
}

export class BuildPageLocalizeConfigItem extends BuildRecordLocalizeConfigItem
{
    public siteId: number;

    public constructor(init?: Partial<BuildPageLocalizeConfigItem>) { super(init); (Object as any).assign(this, init); }
}

export class GetSitePolarisMailResponse extends BaseResponse
{
    public id: number;
    public createdAt: string;
    public updatedAt: string;
    public siteId: number;
    public userAuthId?: number;
    public expireAt: string;
    public domain: string;
    public userName: string;
    public password: string;
    public fullName: string;

    public constructor(init?: Partial<GetSitePolarisMailResponse>) { super(init); (Object as any).assign(this, init); }
}

export class SiteLogoResponse implements IPrimaryEntity, ISiteReferenceEntity
{
    public id: number;
    public siteId: number;
    public originUrl: string;
    public watermarkUrl: string;
    public url: string;
    public fileName: string;
    public siteOriginUrl: string;
    public siteTransparentOriginUrl: string;
    public siteWatermarkUrl: string;
    public siteUrl: string;

    public constructor(init?: Partial<SiteLogoResponse>) { (Object as any).assign(this, init); }
}

export class GenerateLogoResponseItem
{
    public originUrl: string;
    public watermarkUrl: string;
    public originFullUrl: string;
    public watermarkFullUrl: string;

    public constructor(init?: Partial<GenerateLogoResponseItem>) { (Object as any).assign(this, init); }
}

export class GetFaviconResponse implements IPrimaryEntity, IDeletedEntity, ISiteReferenceEntity
{
    public id: number;
    public name: string;
    public url: string;
    public siteId: number;
    public isDeleted: boolean;
    public isDefault?: boolean;
    public isSelectedByDefault?: boolean;

    public constructor(init?: Partial<GetFaviconResponse>) { (Object as any).assign(this, init); }
}

export class GetSiteDomainsResponse implements IPrimaryEntity, ICreatedAtEntity, ISiteReferenceEntity
{
    public id: number;
    public createdAt: string;
    public updatedAt: string;
    public siteId: number;
    public domainName: string;
    public isMainDomain?: boolean;
    public type: DomainType;
    public status: DomainStatus;
    public expireDate?: string;
    public renewalPrice?: number;
    public renewalFullPrice?: number;
    public autoRenewEnabled?: boolean;
    public isTransferred?: boolean;
    public verificationError: string;

    public constructor(init?: Partial<GetSiteDomainsResponse>) { (Object as any).assign(this, init); }
}

export class DomainPriceInfo
{
    public nameComPrice: number;
    public feePrice?: number;
    public withoutDiscountPrice: number;
    public discountPrice?: number;
    public finalPrice: number;
    public yearDiscount: number;
    public isHasFreeDomain: boolean;
    public freeDomainPaymentId?: number;
    public freeDomainIsFirstPayment: boolean;

    public constructor(init?: Partial<DomainPriceInfo>) { (Object as any).assign(this, init); }
}

export class SuggestDomain
{
    public domainName: string;
    public sld: string;
    public tld: string;
    public purchasable: boolean;
    public premium: boolean;
    public isFreeYear: boolean;
    public purchasePrice: number;
    public renewalPrice: number;

    public constructor(init?: Partial<SuggestDomain>) { (Object as any).assign(this, init); }
}

export class GetContactFormResponse implements IPrimaryEntity, IAuditEntity, ISiteReferenceEntity, IDeletedEntity
{
    public id: number;
    public formData: ContactFormDataItem[];
    public isDeleted: boolean;
    public createdAt: string;
    public updatedAt: string;
    public siteId: number;

    public constructor(init?: Partial<GetContactFormResponse>) { (Object as any).assign(this, init); }
}

export class GetSiteCommunicationResponse implements IPrimaryEntity, IAuditEntity, ISiteReferenceEntity, ICommunicationData
{
    public id: number;
    public createdAt: string;
    public updatedAt: string;
    public type: CommunicationType;
    public phone: CommunicationPhone;
    public email: CommunicationEmail;
    public address: CommunicationAddress;
    public social: CommunicationSocial;
    public siteId: number;
    public value: string;

    public constructor(init?: Partial<GetSiteCommunicationResponse>) { (Object as any).assign(this, init); }
}

export class GetEmailTemplatesResponse extends BaseResponse
{
    public id: number;
    public name: string;
    public subject: string;
    public language: LocalizableLanguage;
    public emailType: EmailTemplateTypeEnum;
    public type: EmailTemplatePartialType;
    public isActive?: boolean;
    public siteId?: number;
    public isDeleted: boolean;
    public createdAt: string;
    public updatedAt: string;
    public resourceCode: string;
    public body: string;
    public htmlBody: string;
    public headerEmailTemplateId?: number;
    public footerEmailTemplateId?: number;
    public staticData: Object;
    public testDynamicDataDictionary: { [index: string]: Object; };

    public constructor(init?: Partial<GetEmailTemplatesResponse>) { super(init); (Object as any).assign(this, init); }
}

export class GetEmailStopListResponse implements IPrimaryEntity
{
    public id: number;
    public type: StopListType;
    public email: string;
    public domain: string;

    public constructor(init?: Partial<GetEmailStopListResponse>) { (Object as any).assign(this, init); }
}

export class RecipientResponse implements IPrimaryEntity, IAuditEntity, IDeletedEntity, ISiteReferenceEntity
{
    public id: number;
    public createdAt: string;
    public updatedAt: string;
    public isDeleted: boolean;
    public siteId: number;
    public templateType: EmailTemplateTypeEnum;
    public email: string;

    public constructor(init?: Partial<RecipientResponse>) { (Object as any).assign(this, init); }
}

export class GetEmailLogResponse implements IPrimaryEntity, IAuditEntity
{
    public id: number;
    public createdAt: string;
    public updatedAt: string;
    public subject: string;
    public from: string;
    public to: string[];
    public cc: string[];
    public bcc: string[];
    public body: string;
    public htmlBody: string;
    public type: EmailTemplateTypeEnum;
    public model: string;
    public siteId?: number;
    public recordId?: number;

    public constructor(init?: Partial<GetEmailLogResponse>) { (Object as any).assign(this, init); }
}

export class GetEmailDemoDataResponse
{
    public id: number;
    public name: string;
    public emailTypes: EmailTemplateTypeEnum[];
    public data: Object;

    public constructor(init?: Partial<GetEmailDemoDataResponse>) { (Object as any).assign(this, init); }
}

export class AiPostSectionItem
{
    public subTitle: string;
    public body: string;

    public constructor(init?: Partial<AiPostSectionItem>) { (Object as any).assign(this, init); }
}

export class GetPostLczConfigResponse extends LocalizeConfigResponse implements IDisplayEntity, ISiteReferenceEntity
{
    public name: string;
    public siteId: number;

    public constructor(init?: Partial<GetPostLczConfigResponse>) { super(init); (Object as any).assign(this, init); }
}

export class GetBookingWorkersAtLocationsResponse extends BaseResponse
{
    public workerId: number;
    public workerName: string;
    public locationId: number;
    public locationName: string;
    public scheduleType: BookingWorkerLocationScheduleType;
    public workingTime: WorkingTime;
    public workingTimeDaily: CustomDailySchedule[];

    public constructor(init?: Partial<GetBookingWorkersAtLocationsResponse>) { super(init); (Object as any).assign(this, init); }
}

export class BookingLocationListItem
{
    public locationId: number;
    public locationName: string;

    public constructor(init?: Partial<BookingLocationListItem>) { (Object as any).assign(this, init); }
}

export class BookingWorkerListItem
{
    public workerId: number;
    public workerName: string;

    public constructor(init?: Partial<BookingWorkerListItem>) { (Object as any).assign(this, init); }
}

export class GetBookingServicesByWorkersResponse extends BaseResponse
{
    public serviceId: number;
    public serviceName: string;
    public workerId: number;
    public workerName: string;

    public constructor(init?: Partial<GetBookingServicesByWorkersResponse>) { super(init); (Object as any).assign(this, init); }
}

export class GetBookingServicesAtLocationsResponse extends BaseResponse
{
    public serviceId: number;
    public serviceName: string;
    public locationId: number;
    public locationName: string;

    public constructor(init?: Partial<GetBookingServicesAtLocationsResponse>) { super(init); (Object as any).assign(this, init); }
}

export class BookingServiceListItem
{
    public serviceId: number;
    public serviceName: string;

    public constructor(init?: Partial<BookingServiceListItem>) { (Object as any).assign(this, init); }
}

export class FileResponse
{
    public path: string;
    public name: string;
    public url: string;

    public constructor(init?: Partial<FileResponse>) { (Object as any).assign(this, init); }
}

export class SiteFileResponse extends FileResponse implements IPrimaryEntity, ISiteReferenceEntity, ICreatedAtEntity
{
    public id: number;
    public createdAt: string;
    public type: SiteFileType;
    public status: SiteFileStatus;
    public siteId: number;
    public sizeBites: number;

    public constructor(init?: Partial<SiteFileResponse>) { super(init); (Object as any).assign(this, init); }
}

export class VideoPreviewPicture
{
    public width: string;
    public height: string;
    public link: string;
    public linkWithPlayButton: string;

    public constructor(init?: Partial<VideoPreviewPicture>) { (Object as any).assign(this, init); }
}

export class SiteVideoResponse extends SiteFileResponse
{
    public videoIsAvailable?: boolean;
    public videoDuration?: number;
    public videoPreviewPictures: VideoPreviewPicture[];

    public constructor(init?: Partial<SiteVideoResponse>) { super(init); (Object as any).assign(this, init); }
}

export class ScannerResult
{
    public score: number;
    public mediaId: string;

    public constructor(init?: Partial<ScannerResult>) { (Object as any).assign(this, init); }
}

export class GetScanSiteVideoResponse extends SiteVideoResponse
{
    public scannerResult: ScannerResult;

    public constructor(init?: Partial<GetScanSiteVideoResponse>) { super(init); (Object as any).assign(this, init); }
}

export class CompleteUploadSiteVideo
{
    public id: number;
    public status: SiteFileStatus;

    public constructor(init?: Partial<CompleteUploadSiteVideo>) { (Object as any).assign(this, init); }
}

export class GetScanSiteImageResponse extends SiteFileResponse
{
    public scannerResult: ScannerResult;

    public constructor(init?: Partial<GetScanSiteImageResponse>) { super(init); (Object as any).assign(this, init); }
}

export class StockImage
{
    public id: string;
    public ixId: string;
    public description: string;
    public altDescription: string;
    public fullSizeUrl: string;
    public smallSizeUrl: string;
    public downloadUrl: string;
    public downloadLocationUrl: string;
    public viewUrl: string;
    public userId: string;
    public userName: string;
    public userHtmlUrl: string;

    public constructor(init?: Partial<StockImage>) { (Object as any).assign(this, init); }
}

export class GetScanSiteDocumentResponse extends SiteFileResponse
{
    public scannerResult: ScannerResult;

    public constructor(init?: Partial<GetScanSiteDocumentResponse>) { super(init); (Object as any).assign(this, init); }
}

export class GetScanSiteAudioResponse extends SiteFileResponse
{
    public scannerResult: ScannerResult;

    public constructor(init?: Partial<GetScanSiteAudioResponse>) { super(init); (Object as any).assign(this, init); }
}

export class GetCurrenciesResponse
{
    public code: string;
    public name: string;
    public symbol: string;
    public countryCode: string;
    public isSupportedByStripe: boolean;
    public isSupportedByPayPal: boolean;

    public constructor(init?: Partial<GetCurrenciesResponse>) { (Object as any).assign(this, init); }
}

export class GetStateResponse implements IPrimaryEntity, ICodeEntity, IDisplayEntity
{
    public id: number;
    public code: string;
    public name: string;
    public countryId: number;
    public isSupportedByNameCom?: boolean;

    public constructor(init?: Partial<GetStateResponse>) { (Object as any).assign(this, init); }
}

export class GetCountriesResponse implements IPrimaryEntity, ICodeEntity, IDisplayEntity
{
    public id: number;
    public code: string;
    public englishName: string;
    public hasState: boolean;
    public isoCurrencySymbol: string;
    public currencyEnglishName: string;
    public states: GetStateResponse[];
    public threeLetterISORegionName: string;
    public name: string;
    public isMetric: boolean;
    public geoId: number;
    public currencySymbol: string;
    public twoLetterISORegionName: string;
    public isSupportedByStripe?: boolean;
    public isSupportedByNameCom?: boolean;

    public constructor(init?: Partial<GetCountriesResponse>) { (Object as any).assign(this, init); }
}

export class GetFeedbackResponse implements IPrimaryEntity, ISiteReferenceEntity, IAuditEntity
{
    public id: number;
    public subscribeFormId: number;
    public rating: number;
    public ratingType: RatingType;
    public fields: FormField[];
    public siteId: number;
    public createdAt: string;
    public updatedAt: string;

    public constructor(init?: Partial<GetFeedbackResponse>) { (Object as any).assign(this, init); }
}

export class GetCampaignContactResponse
{
    public id: number;
    public campaignId: number;
    public status: CampaignContactStatus;
    public email: string;
    public firstName: string;
    public lastName: string;
    public siteId: number;

    public constructor(init?: Partial<GetCampaignContactResponse>) { (Object as any).assign(this, init); }
}

export enum MessageRole
{
    User = 0,
    Assistant = 1,
}

export class ChatMessage
{
    public id: string;
    public createdAt: string;
    public role: MessageRole;
    public text: string;

    public constructor(init?: Partial<ChatMessage>) { (Object as any).assign(this, init); }
}

export class GenerateAiContentPackServicesResponse extends BaseResponse
{
    public services: ContentPackSettingService[];
    public descriptions: ContentPackSettingDescription[];

    public constructor(init?: Partial<GenerateAiContentPackServicesResponse>) { super(init); (Object as any).assign(this, init); }
}

export enum RunStatus
{
    Queued = 0,
    InProgress = 1,
    RequiresAction = 2,
    Cancelling = 3,
    Cancelled = 4,
    Failed = 5,
    Completed = 6,
    Expired = 7,
}

export class ChatAction
{
    public id: string;
    public name: string;
    public arguments: string;
    public body: { [index: string]: Object; };

    public constructor(init?: Partial<ChatAction>) { (Object as any).assign(this, init); }
}

export class ChatWizardCategory
{
    public id: number;
    public name: string;
    public contentPackId: number;

    public constructor(init?: Partial<ChatWizardCategory>) { (Object as any).assign(this, init); }
}

export class ChatWizardService
{
    public id: string;
    public name: string;

    public constructor(init?: Partial<ChatWizardService>) { (Object as any).assign(this, init); }
}

export class ChatWizardConfig
{
    public category: ChatWizardCategory;
    public services: ChatWizardService[];
    public siteTitle: string;
    public location: string;
    public standoutPoints: string[];
    public apps: AppType[];
    public contactPhone: string;
    public contactEmail: string;

    public constructor(init?: Partial<ChatWizardConfig>) { (Object as any).assign(this, init); }
}

export class GetStructurePacksResponse extends BaseResponse
{
    public id: number;
    public name: string;
    public order: number;
    public pageTypeBlueprints: { [index: string]: PageBlueprint[]; };
    public pageTypeIds: PageType[];
    public appType: StructurePackAppType;
    public isDeleted?: boolean;
    public createdAt: string;
    public updatedAt: string;
    public widgetContainerType?: WidgetContainerType;

    public constructor(init?: Partial<GetStructurePacksResponse>) { super(init); (Object as any).assign(this, init); }
}

export class GetDesignDefaultContentPagesResponse extends BaseResponse
{
    public id: number;
    public name: string;
    public pageType: PageType;
    public widgets: BaseWidget[];
    public isDeleted?: boolean;
    public createdAt: string;
    public updatedAt: string;

    public constructor(init?: Partial<GetDesignDefaultContentPagesResponse>) { super(init); (Object as any).assign(this, init); }
}

export class GetDesignPacksResponse extends BaseResponse implements IPrimaryEntity, IDisplayEntity
{
    public id: number;
    public name: string;
    public logo: BaseWidgetImage;
    public thumbnail: string;
    public designPackImageId?: number;
    public contentPackId?: number;
    public structurePackId?: number;
    public widgets: BaseWidget[];
    public colorPaletteId?: number;
    public colorPaletteIds: number[];
    public fontGroupId?: number;
    public fontGroupIds: number[];
    public colorMappingId?: number;
    public fontMappingId?: number;
    public boxMappingId?: number;

    public constructor(init?: Partial<GetDesignPacksResponse>) { super(init); (Object as any).assign(this, init); }
}

export class GetFontMappingsResponse extends BaseResponse implements IPrimaryEntity
{
    public id: number;
    public settings: { [index: string]: UiFontSettings; };

    public constructor(init?: Partial<GetFontMappingsResponse>) { super(init); (Object as any).assign(this, init); }
}

export class GetFontGroupsResponse extends BaseResponse implements IPrimaryEntity, IDisplayEntity
{
    public id: number;
    public name: string;
    public fonts: ThemeFont[];
    public fontMappingId: number;
    public types: number[];
    public isMegaDefault?: boolean;
    public siteId?: number;

    public constructor(init?: Partial<GetFontGroupsResponse>) { super(init); (Object as any).assign(this, init); }
}

export class GetDesignPackImagesResponse extends BaseResponse implements IPrimaryEntity, IDisplayEntity
{
    public id: number;
    public name: string;
    public visibleElementsSettings: { [index: string]: WidgetVisibleElementSetting; };
    public aboutUs: { [index: string]: ImagePackVariationSetting[]; };
    public banner: { [index: string]: ImagePackVariationSetting[]; };
    public blog: { [index: string]: ImagePackVariationSetting[]; };
    public blogImage: { [index: string]: ImagePackVariationSetting[]; };
    public blogImageWithText: { [index: string]: ImagePackVariationSetting[]; };
    public blogGallery: { [index: string]: ImagePackVariationSetting[]; };
    public blogSubscribe: { [index: string]: ImagePackVariationSetting[]; };
    public blogAboutAuthor: { [index: string]: ImagePackVariationSetting[]; };
    public blogTopPosts: { [index: string]: ImagePackVariationSetting[]; };
    public blogRecipe: { [index: string]: ImagePackVariationSetting[]; };
    public blogSummary: { [index: string]: ImagePackVariationSetting[]; };
    public blogFeaturedItems: { [index: string]: ImagePackVariationSetting[]; };
    public productBundle: { [index: string]: ImagePackVariationSetting[]; };
    public productAdditionalInfoProductDescription: { [index: string]: ImagePackVariationSetting[]; };
    public productAdditionalInfoFeatures: { [index: string]: ImagePackVariationSetting[]; };
    public productAdditionalInfoSizeAndFit: { [index: string]: ImagePackVariationSetting[]; };
    public productAdditionalInfoShippingAndDelivery: { [index: string]: ImagePackVariationSetting[]; };
    public productAdditionalInfoReviews: { [index: string]: ImagePackVariationSetting[]; };
    public booking: { [index: string]: ImagePackVariationSetting[]; };
    public comments: { [index: string]: ImagePackVariationSetting[]; };
    public contactUs: { [index: string]: ImagePackVariationSetting[]; };
    public eventsApp: { [index: string]: ImagePackVariationSetting[]; };
    public faq: { [index: string]: ImagePackVariationSetting[]; };
    public featuredItems: { [index: string]: ImagePackVariationSetting[]; };
    public gallery: { [index: string]: ImagePackVariationSetting[]; };
    public logos: { [index: string]: ImagePackVariationSetting[]; };
    public marketing: { [index: string]: ImagePackVariationSetting[]; };
    public ourTeam: { [index: string]: ImagePackVariationSetting[]; };
    public priceList: { [index: string]: ImagePackVariationSetting[]; };
    public services: { [index: string]: ImagePackVariationSetting[]; };
    public slider: { [index: string]: ImagePackVariationSetting[]; };
    public smartBanner: { [index: string]: ImagePackVariationSetting[]; };
    public store: { [index: string]: ImagePackVariationSetting[]; };
    public subscribe: { [index: string]: ImagePackVariationSetting[]; };
    public testimonials: { [index: string]: ImagePackVariationSetting[]; };
    public testiomonialForm: { [index: string]: ImagePackVariationSetting[]; };
    public text: { [index: string]: ImagePackVariationSetting[]; };
    public video: { [index: string]: ImagePackVariationSetting[]; };
    public career: { [index: string]: ImagePackVariationSetting[]; };
    public music: { [index: string]: ImagePackVariationSetting[]; };
    public resources: { [index: string]: ImagePackVariationSetting[]; };
    public menu: { [index: string]: ImagePackVariationSetting[]; };
    public table: { [index: string]: ImagePackVariationSetting[]; };

    public constructor(init?: Partial<GetDesignPackImagesResponse>) { super(init); (Object as any).assign(this, init); }
}

export class GetColorPalettesResponse extends BaseResponse implements IPrimaryEntity, IDisplayEntity
{
    public id: number;
    public name: string;
    public colors: { [index: string]: string; };
    public colorMappingId: number;
    public siteId?: number;
    public includeMegaDefault?: boolean;

    public constructor(init?: Partial<GetColorPalettesResponse>) { super(init); (Object as any).assign(this, init); }
}

export class GetColorMappingsResponse extends BaseResponse implements IPrimaryEntity, IDisplayEntity
{
    public id: number;
    public name: string;
    public settings: { [index: string]: UiColorSettings; };

    public constructor(init?: Partial<GetColorMappingsResponse>) { super(init); (Object as any).assign(this, init); }
}

export class GetBoxMappingsResponse extends BaseResponse implements IPrimaryEntity, IDisplayEntity
{
    public id: number;
    public name: string;
    public settings: { [index: string]: UiBoxSettings; };
    public linkButtonLabelType: LinkButtonLabelType;
    public linkButtonLabelIcon: BaseWidgetImage;
    public sliderIndicatorsType: SliderIndicatorsType;
    public sliderLeftNavIcon: BaseWidgetImage;
    public sliderRightNavIcon: BaseWidgetImage;
    public sliderBottomLeftNavIcon: BaseWidgetImage;
    public sliderBottomRightNavIcon: BaseWidgetImage;
    public mapStyleSetting: UiBoxMapStyleSetting;
    public widgetSliderSettings: BaseWidgetSliderSettings;

    public constructor(init?: Partial<GetBoxMappingsResponse>) { super(init); (Object as any).assign(this, init); }
}

export class GetContentPackLczConfigResponse extends LocalizeConfigResponse implements IDisplayEntity
{
    public name: string;

    public constructor(init?: Partial<GetContentPackLczConfigResponse>) { super(init); (Object as any).assign(this, init); }
}

export class BuildDisplayRecordLocalizeConfigItem extends BuildRecordLocalizeConfigItem implements IDisplayEntity
{
    public name: string;

    public constructor(init?: Partial<BuildDisplayRecordLocalizeConfigItem>) { super(init); (Object as any).assign(this, init); }
}

export class BuildContentPackLocalizeConfigItem extends BuildDisplayRecordLocalizeConfigItem
{

    public constructor(init?: Partial<BuildContentPackLocalizeConfigItem>) { super(init); (Object as any).assign(this, init); }
}

export class GetContentPackRephraseLczConfigResponse extends LocalizeConfigResponse
{
    public widgetName: string;
    public elementName: string;
    public contentPackId: number;

    public constructor(init?: Partial<GetContentPackRephraseLczConfigResponse>) { super(init); (Object as any).assign(this, init); }
}

export class BuildContentPackRephraseLocalizeConfigItem extends BuildRecordLocalizeConfigItem
{
    public widgetName: string;

    public constructor(init?: Partial<BuildContentPackRephraseLocalizeConfigItem>) { super(init); (Object as any).assign(this, init); }
}

export class GetContentPackRephraseResponse
{
    public id: number;
    public widgetName: string;
    public elementName: string;
    public elementId: string;
    public contentPackId: number;
    public rephrases: LocalizeValue[];

    public constructor(init?: Partial<GetContentPackRephraseResponse>) { (Object as any).assign(this, init); }
}

export class GetBusinessIndustryLczConfigResponse extends LocalizeConfigResponse implements IDisplayEntity
{
    public name: string;

    public constructor(init?: Partial<GetBusinessIndustryLczConfigResponse>) { super(init); (Object as any).assign(this, init); }
}

export class GetBusinessIndustryResponse extends BaseResponse implements IPrimaryEntity, IDisplayEntity
{
    public id: number;
    public name: string;
    public groupId?: number;

    public constructor(init?: Partial<GetBusinessIndustryResponse>) { super(init); (Object as any).assign(this, init); }
}

export class DisplayLocalizeConfigResponse extends LocalizeConfigResponse implements IDisplayEntity
{
    public name: string;

    public constructor(init?: Partial<DisplayLocalizeConfigResponse>) { super(init); (Object as any).assign(this, init); }
}

export class GetBusinessGroupLczConfigResponse extends DisplayLocalizeConfigResponse implements ICodeEntity
{
    public code: string;

    public constructor(init?: Partial<GetBusinessGroupLczConfigResponse>) { super(init); (Object as any).assign(this, init); }
}

export class GetBusinessGroupResponse extends BaseResponse implements IPrimaryEntity, IDisplayEntity
{
    public id: number;
    public code: string;
    public name: string;
    public description: string;
    public defaultIndustryId?: number;

    public constructor(init?: Partial<GetBusinessGroupResponse>) { super(init); (Object as any).assign(this, init); }
}

export class GetBusinessCategoryLczConfigResponse extends DisplayLocalizeConfigResponse
{
    public isPublic?: boolean;
    public userId?: number;

    public constructor(init?: Partial<GetBusinessCategoryLczConfigResponse>) { super(init); (Object as any).assign(this, init); }
}

export class BusinessCategoryItem implements IPrimaryEntity, IDisplayEntity
{
    public id: number;
    public name: string;

    public constructor(init?: Partial<BusinessCategoryItem>) { (Object as any).assign(this, init); }
}

export class BusinessIndustryItem implements IPrimaryEntity, IDisplayEntity
{
    public id: number;
    public name: string;
    public categories: BusinessCategoryItem[];
    public defaultCategories: BusinessCategoryItem[];

    public constructor(init?: Partial<BusinessIndustryItem>) { (Object as any).assign(this, init); }
}

export class BusinessGroupItem implements IPrimaryEntity, IDisplayEntity
{
    public id: number;
    public showName: string;
    public name: string;
    public groupId: number;
    public industries: BusinessIndustryItem[];

    public constructor(init?: Partial<BusinessGroupItem>) { (Object as any).assign(this, init); }
}

// @DataContract
export class QueryResponse<T>
{
    // @DataMember(Order=1)
    public offset: number;

    // @DataMember(Order=2)
    public total: number;

    // @DataMember(Order=3)
    public results: T[];

    // @DataMember(Order=4)
    public meta: { [index: string]: string; };

    // @DataMember(Order=5)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<QueryResponse<T>>) { (Object as any).assign(this, init); }
}

export class GetSettingResponse
{
    public id: number;
    public code: string;
    public type: SettingValueType;
    public value: SettingValue;
    public siteId?: number;

    public constructor(init?: Partial<GetSettingResponse>) { (Object as any).assign(this, init); }
}

export class SettingGroup implements ICodeEntity
{
    public code: string;
    public type: SettingValueType;
    public count: number;

    public constructor(init?: Partial<SettingGroup>) { (Object as any).assign(this, init); }
}

export class GetProcessedRecordResponse implements IPrimaryEntity, ICreatedAtEntity, IData
{
    public id: number;
    public createdAt: string;
    public status: ProcessedRecordStatus;
    public recordId: number;
    public processCode: string;
    public error: ProcessedRecordError;
    public data: { [index: string]: Object; };

    public constructor(init?: Partial<GetProcessedRecordResponse>) { (Object as any).assign(this, init); }
}

export class ProcessedRecordGroup implements ICodeEntity
{
    public code: string;
    public count: number;

    public constructor(init?: Partial<ProcessedRecordGroup>) { (Object as any).assign(this, init); }
}

export class GetLastProcessStatusResponse
{
    public createdAt: string;
    public status: ProcessedRecordStatus;
    public recordId: number;

    public constructor(init?: Partial<GetLastProcessStatusResponse>) { (Object as any).assign(this, init); }
}

export class GetOpenAiTemplateResponse
{
    public id: number;
    public prompt: string;
    public maxTokens: number;
    public widgetName: string;
    public elementName: string;

    public constructor(init?: Partial<GetOpenAiTemplateResponse>) { (Object as any).assign(this, init); }
}

export class GetGlobalLocalizeLczConfigResponse extends LocalizeConfigResponse
{
    public code: string;
    public recordId?: number;

    public constructor(init?: Partial<GetGlobalLocalizeLczConfigResponse>) { super(init); (Object as any).assign(this, init); }
}

export class BuildGlobalLocalizeConfigItem extends BuildRecordLocalizeConfigItem
{
    public code: string;

    public constructor(init?: Partial<BuildGlobalLocalizeConfigItem>) { super(init); (Object as any).assign(this, init); }
}

export class GlobalLocalizeItem
{
    public code: string;
    public values: { [index: string]: LocalizeValue; };
    public columns: { [index: string]: string; };

    public constructor(init?: Partial<GlobalLocalizeItem>) { (Object as any).assign(this, init); }
}

export class GenerateGptResponse
{
    public isSuccess: boolean;
    public results: string[];
    public gptRequest: string;
    public gptResponse: string;
    public errorCode: string;

    public constructor(init?: Partial<GenerateGptResponse>) { (Object as any).assign(this, init); }
}

export class GetUserOpenAiHistoryResponse
{
    public id: number;
    public createdAt: string;
    public type: UserOpenAiHistoryType;
    public widgetName: string;
    public elementName: string;
    public toneOfVoice: ToneOfVoice;
    public value: string;
    public siteId?: number;
    public userAuthId?: number;
    public recordId?: number;
    public dependentOn: { [index: string]: string; };
    public response: GenerateGptResponse;
    public sentences: string[];

    public constructor(init?: Partial<GetUserOpenAiHistoryResponse>) { (Object as any).assign(this, init); }
}

export class GetEventLogResponse
{
    public id: number;
    public siteId?: number;
    public userAuthId?: number;
    public sessionId: string;
    public cfRay: string;
    public eventType: EventLogType;
    public utmSource: string;
    public utmMedium: string;
    public utmCampaign: string;
    public utmTerm: string;
    public utmContent: string;
    public hrefUrl: string;
    public refererUrl: string;
    public data: { [index: string]: Object; };
    public createdAt: string;
    public clientIp: string;
    public leadUserId: string;

    public constructor(init?: Partial<GetEventLogResponse>) { (Object as any).assign(this, init); }
}

export class GetSpecificationValueItem
{
    public specificationId: number;
    public specificationCode: string;
    public type: SpecificationType;
    public stringValue: string;
    public longValue?: number;
    public decimalValue?: number;
    public booleanValue?: boolean;
    public dateTimeValue?: string;
    public listValue: number[];
    public listValueCodes: string[];
    public itemValue?: number;
    public itemValueCode: string;

    public constructor(init?: Partial<GetSpecificationValueItem>) { (Object as any).assign(this, init); }
}

export class GetSpecificationValueDetail extends GetSpecificationValueItem
{
    public specificationName: string;
    public itemValueName: string;
    public listValueNames: { [index: string]: string; };

    public constructor(init?: Partial<GetSpecificationValueDetail>) { super(init); (Object as any).assign(this, init); }
}

export class GetSpecificationListItemResponse
{
    public id: number;
    public createdAt: string;
    public code: string;
    public name: string;
    public specificationId: number;
    public originalRecordId?: number;

    public constructor(init?: Partial<GetSpecificationListItemResponse>) { (Object as any).assign(this, init); }
}

export class GetSpecificationResponse
{
    public id: number;
    public createdAt: string;
    public code: string;
    public name: string;
    public type: SpecificationType;
    public ownerType: SpecificationOwnerType;

    public constructor(init?: Partial<GetSpecificationResponse>) { (Object as any).assign(this, init); }
}

export class SpecificationRuleGroupInfo implements IPrimaryEntity, ICodeEntity
{
    public id: number;
    public code: string;
    public condition: SpecificationConditionGroup;
    public rules: SpecificationRule[];

    public constructor(init?: Partial<SpecificationRuleGroupInfo>) { (Object as any).assign(this, init); }
}

export class GetSpecificationRuleGroupResponse extends SpecificationRuleGroupInfo
{
    public isActive?: boolean;

    public constructor(init?: Partial<GetSpecificationRuleGroupResponse>) { super(init); (Object as any).assign(this, init); }
}

export class StringList extends Array<string>
{

    public constructor(init?: Partial<StringList>) { super(); (Object as any).assign(this, init); }
}

export class ElasticLanguagesValues extends Dictionary<StringList>
{

    public constructor(init?: Partial<ElasticLanguagesValues>) { super(); (Object as any).assign(this, init); }
}

export class ElasticColumnValues extends Dictionary<ElasticLanguagesValues>
{

    public constructor(init?: Partial<ElasticColumnValues>) { super(); (Object as any).assign(this, init); }
}

export class SiteElasticModel
{
    public id: number;
    public columns: ElasticColumnValues;
    public siteId: number;

    public constructor(init?: Partial<SiteElasticModel>) { (Object as any).assign(this, init); }
}

export class SiteElasticModelResult
{
    public document: SiteElasticModel;
    public id: number;
    public siteId: number;
    public searchResults: { [index: string]: string[]; };

    public constructor(init?: Partial<SiteElasticModelResult>) { (Object as any).assign(this, init); }
}

export class SearchSiteItemContentResponse<T>
{
    public results: T[];
    public total: number;

    public constructor(init?: Partial<SearchSiteItemContentResponse<T>>) { (Object as any).assign(this, init); }
}

export class BaseSearchContentResponse implements IPrimaryEntity, ISiteReferenceEntity
{
    public id: number;
    public siteId: number;

    public constructor(init?: Partial<BaseSearchContentResponse>) { (Object as any).assign(this, init); }
}

export class SearchProductContentResponse extends BaseSearchContentResponse
{
    public name: string;
    public description: string;
    public widgetContent: string;
    public mainImage: BaseWidgetImage;
    public price?: number;
    public salePrice?: number;
    public isUsePrice?: boolean;
    public isUseSalePrice?: boolean;
    public slug: string;

    public constructor(init?: Partial<SearchProductContentResponse>) { super(init); (Object as any).assign(this, init); }
}

export class SearchPostContentResponse extends BaseSearchContentResponse
{
    public name: string;
    public description: string;
    public widgetContent: string;
    public slug: string;
    public coverImage: BaseWidgetImage;

    public constructor(init?: Partial<SearchPostContentResponse>) { super(init); (Object as any).assign(this, init); }
}

export class SearchPageContentResponse extends BaseSearchContentResponse
{
    public name: string;
    public widgetContents: string[];
    public slug: string;
    public type?: PageType;

    public constructor(init?: Partial<SearchPageContentResponse>) { super(init); (Object as any).assign(this, init); }
}

export class CheckSpecificationRuleGroupResult
{
    public groupCode: string;
    public ruleCode: string;

    public constructor(init?: Partial<CheckSpecificationRuleGroupResult>) { (Object as any).assign(this, init); }
}

export class GetAdminUserMonthlyResponse
{
    public month: string;
    public count: number;
    public paidCount: number;

    public constructor(init?: Partial<GetAdminUserMonthlyResponse>) { (Object as any).assign(this, init); }
}

export class GetAdminUserDailyResponse
{
    public month: string;
    public count: number;
    public paidCount: number;

    public constructor(init?: Partial<GetAdminUserDailyResponse>) { (Object as any).assign(this, init); }
}

export class GetAdminUsersResponse implements IPrimaryEntity, IAnalyticsTracking
{
    public id: number;
    public email: string;
    public utmSource: string;
    public utmMedium: string;
    public utmCampaign: string;
    public utmTerm: string;
    public utmContent: string;
    public isDeleted: boolean;
    public mainRole: AuthRole;
    public signupIpAddress: string;
    public signupIpCountry: string;
    public signupIpCity: string;
    public createdDate: string;
    public firstName: string;
    public lastName: string;
    public phoneNumber: string;
    public address: string;
    public country: string;
    public city: string;
    public businessNumber: string;
    public siteCount: number;
    public isWizardFinished: boolean;
    public category: string;
    public fbc: string;
    public fbp: string;
    public deletedDate?: string;
    public deletedReason: string;
    public leadUserId: string;
    public isCreatedSiteFromMobile: boolean;

    public constructor(init?: Partial<GetAdminUsersResponse>) { (Object as any).assign(this, init); }
}

export class GetAdminFilesResponse
{
    public id: string;
    public name: string;
    public sizeBytes: number;
    public sizeMegabytes: number;

    public constructor(init?: Partial<GetAdminFilesResponse>) { (Object as any).assign(this, init); }
}

export class GetAdminBucketsResponse
{
    public id: string;
    public name: string;

    public constructor(init?: Partial<GetAdminBucketsResponse>) { (Object as any).assign(this, init); }
}

export class GetSiteNoteResponse implements IPrimaryEntity, ISiteReferenceEntity
{
    public id: number;
    public siteId: number;
    public note: string;

    public constructor(init?: Partial<GetSiteNoteResponse>) { (Object as any).assign(this, init); }
}

export class GetAdminSiteMonthlyResponse
{
    public month: string;
    public paidCount: number;
    public distinctUserAuthIdPaidCount: number;
    public count: number;
    public distinctUserAuthIdCount: number;

    public constructor(init?: Partial<GetAdminSiteMonthlyResponse>) { (Object as any).assign(this, init); }
}

export class GetAdminSiteDailyResponse
{
    public day: string;
    public paidCount: number;
    public distinctUserAuthIdPaidCount: number;
    public count: number;
    public distinctUserAuthIdCount: number;

    public constructor(init?: Partial<GetAdminSiteDailyResponse>) { (Object as any).assign(this, init); }
}

export class GetAdminSitesResponse implements IPrimaryEntity, IAnalyticsTracking
{
    public id: number;
    public siteId: number;
    public domain: string;
    public createdAt: string;
    public isDeleted: boolean;
    public deletedDate?: string;
    public deletedReason: string;
    public utmSource: string;
    public utmMedium: string;
    public utmCampaign: string;
    public utmTerm: string;
    public utmContent: string;
    public contentPackName: string;
    public contentPackId: number;
    public customUserAuthEmail: string;
    public customUserAuthId: number;
    public customUserAuthFirstName: string;
    public customUserAuthLastName: string;
    public subscriptionPlan: HocoosSubscriptionPlan;
    public customUserAuthCreatedDate: string;
    public designPackName: string;
    public designPackId: number;
    public siteBusinessCategoryName: string;
    public siteBusinessCategoryId: number;
    public activeCampaignId?: number;

    public constructor(init?: Partial<GetAdminSitesResponse>) { (Object as any).assign(this, init); }
}

export class GetAdminPagesResponse
{
    public id: number;
    public siteId: number;
    public siteDomain: string;
    public slug: string;
    public pageType: PageType;
    public pageTemplateType: PageTemplateType;
    public widgets: BaseWidget[];
    public createdAt: string;
    public updatedAt: string;
    public isDeleted?: boolean;
    public userAuthId?: number;
    public userAuthName: string;

    public constructor(init?: Partial<GetAdminPagesResponse>) { (Object as any).assign(this, init); }
}

export class MqQueue
{
    public name: string;
    public state: string;
    public messages: number;
    public isHistory: boolean;

    public constructor(init?: Partial<MqQueue>) { (Object as any).assign(this, init); }
}

export class MqQueueMessageProperties
{
    public type: string;
    public timestamp: number;
    public messageId: string;
    public priority: number;
    public deliveryMode: number;
    public headers: { [index: string]: string; };
    public contentType: string;

    public constructor(init?: Partial<MqQueueMessageProperties>) { (Object as any).assign(this, init); }
}

export class MqQueueMessage
{
    public id: string;
    public payloadBytes: number;
    public redelivered: boolean;
    public exchange: string;
    public routingKey: string;
    public messageCount: number;
    public properties: MqQueueMessageProperties;
    public payload: string;
    public payloadEncoding: string;

    public constructor(init?: Partial<MqQueueMessage>) { (Object as any).assign(this, init); }
}

export class GetAdminEventLogsResponse
{
    public id: number;
    public sessionId: string;
    public cfRay: string;
    public utmSource: string;
    public utmMedium: string;
    public utmCampaign: string;
    public utmTerm: string;
    public utmContent: string;
    public hrefUrl: string;
    public refererUrl: string;
    public userAuthId?: number;
    public customUserAuthEmail: string;
    public customUserAuthFirstName: string;
    public customUserAuthLastName: string;
    public subscriptionPlan: HocoosSubscriptionPlan;
    public customUserAuthCreatedDate: string;
    public siteId?: number;
    public siteDomain: string;
    public siteCreatedAt?: string;
    public eventType: EventLogType;
    public data: Object;
    public createdAt: string;
    public clientIp: string;
    public leadUserId: string;

    public constructor(init?: Partial<GetAdminEventLogsResponse>) { (Object as any).assign(this, init); }
}

export class GetUtmAnalyticsResponse implements IAnalyticsTracking
{
    public utmSource: string;
    public utmMedium: string;
    public utmCampaign: string;
    public utmTerm: string;
    public utmContent: string;
    public country: string;
    public isSignupMobile: boolean;
    public countWizardStepFirst: number;
    public countWizardStepBusinessCategory: number;
    public countWizardStepMagicIntro1: number;
    public countWizardStepServices: number;
    public countWizardStepSentences: number;
    public countWizardStepLocation: number;
    public countWizardStepSchedule: number;
    public countWizardStepMagicIntro2: number;
    public countWizardStepBusinessName: number;
    public countWizardStepBusinessUniqueness: number;
    public countWizardStepContactInfo: number;
    public countWizardStepMagicIntro3: number;
    public countWizardStepPutAllTogether: number;
    public countWizardStepThemeSelection: number;
    public countAllLogin: number;
    public countStartTrial: number;
    public startTrialLogIds: number[];
    public startTrialUserIds: number[];
    public startTrialClientIps: string[];
    public countClickUpgradeAccountButton: number;
    public clickUpgradeAccountButtonLogIds: number[];
    public clickUpgradeAccountButtonUserIds: number[];
    public clickUpgradeAccountButtonClientIps: string[];
    public countSubscriptionUpgrade: number;
    public subscriptionUpgradeLogIds: number[];
    public subscriptionUpgradeUserIds: number[];
    public subscriptionUpgradeClientIps: string[];
    public countWizardOpened: number;
    public wizardOpenedLogIds: number[];
    public wizardOpenedUserIds: number[];
    public wizardOpenedClientIps: string[];
    public countSignup: number;
    public signupLogIds: number[];
    public signupUserIds: number[];
    public signupClientIps: string[];
    public countWizardFinished: number;
    public wizardFinishedLogIds: number[];
    public wizardFinishedUserIds: number[];
    public wizardFinishedClientIps: string[];
    public countContinueOnDesktopEmailLink: number;
    public continueOnDesktopEmailLinkLogIds: number[];
    public continueOnDesktopEmailLinkUserIds: number[];
    public continueOnDesktopEmailLinkClientIps: string[];
    public countSiteCreated: number;
    public siteCreatedLogIds: number[];
    public siteCreatedUserIds: number[];
    public siteCreatedClientIps: string[];
    public countWidgetTextChanged: number;
    public widgetTextChangedLogIds: number[];
    public widgetTextChangedUserIds: number[];
    public widgetTextChangedClientIps: string[];
    public countWidgetImageChanged: number;
    public widgetImageChangedLogIds: number[];
    public widgetImageChangedUserIds: number[];
    public widgetImageChangedClientIps: string[];
    public countWidgetBgImageChanged: number;
    public widgetBgImageChangedLogIds: number[];
    public widgetBgImageChangedUserIds: number[];
    public widgetBgImageChangedClientIps: string[];
    public countWidgetAnyChanged: number;
    public widgetAnyChangedLogIds: number[];
    public widgetAnyChangedUserIds: number[];
    public widgetAnyChangedClientIps: string[];

    public constructor(init?: Partial<GetUtmAnalyticsResponse>) { (Object as any).assign(this, init); }
}

export class DesignTemplateGroup implements IPrimaryEntity, IDisplayEntity
{
    public id: number;
    public name: string;
    public industries: DesignTemplateIndustry[];

    public constructor(init?: Partial<DesignTemplateGroup>) { (Object as any).assign(this, init); }
}

export class GetDesignTemplateResponse implements IPrimaryEntity
{
    public id: number;
    public name: string;
    public description: string;
    public siteId: number;
    public categoryId: number;
    public domain: string;

    public constructor(init?: Partial<GetDesignTemplateResponse>) { (Object as any).assign(this, init); }
}

export class GetDesignTemplateSiteResponse implements IPrimaryEntity, IDisplayEntity
{
    public id: number;
    public name: string;
    public domain: string;
    public categoryId: number;

    public constructor(init?: Partial<GetDesignTemplateSiteResponse>) { (Object as any).assign(this, init); }
}

export class FrameInfo
{
    public id: string;
    public position: number;

    public constructor(init?: Partial<FrameInfo>) { (Object as any).assign(this, init); }
}

export class Categories
{
    // @DataMember(Name="very_revealing")
    public veryRevealing: number;

    public revealing: number;
    public none: number;
    // @DataMember(Name="slightly_revealing")
    public slightlyRevealing?: number;

    public constructor(init?: Partial<Categories>) { (Object as any).assign(this, init); }
}

export class SuggestiveClasses
{
    public bikini: number;
    public cleavage: number;
    // @DataMember(Name="cleavage_categories")
    public cleavageCategories: Categories;

    // @DataMember(Name="male_chest")
    public maleChest: number;

    // @DataMember(Name="male_chest_categories")
    public maleChestCategories: Categories;

    // @DataMember(Name="male_underwear")
    public maleUnderwear: number;

    public lingerie: number;
    public miniskirt: number;
    public other: number;

    public constructor(init?: Partial<SuggestiveClasses>) { (Object as any).assign(this, init); }
}

export class NudityContext
{
    // @DataMember(Name="sea_lake_pool")
    public seaLakePool: number;

    // @DataMember(Name="outdoor_other")
    public outdoorOther: number;

    // @DataMember(Name="indoor_other")
    public indoorOther: number;

    public constructor(init?: Partial<NudityContext>) { (Object as any).assign(this, init); }
}

export class FrameNudity
{
    // @DataMember(Name="sexual_activity")
    public sexualActivity: number;

    // @DataMember(Name="sexual_display")
    public sexualDisplay: number;

    public erotica: number;
    // @DataMember(Name="sextoy")
    public sexToy: number;

    public suggestive: number;
    // @DataMember(Name="suggestive_classes")
    public suggestiveClasses: SuggestiveClasses;

    public none: number;
    public context: NudityContext;

    public constructor(init?: Partial<FrameNudity>) { (Object as any).assign(this, init); }
}

export class Frame
{
    public info: FrameInfo;
    public nudity: FrameNudity;

    public constructor(init?: Partial<Frame>) { (Object as any).assign(this, init); }
}

export class PageBlueprintWidgetButton
{
    public pageType: PageType;

    public constructor(init?: Partial<PageBlueprintWidgetButton>) { (Object as any).assign(this, init); }
}

export class PageBlueprintWidgetButtonGroup
{
    public primaryButton: PageBlueprintWidgetButton;
    public secondaryButton: PageBlueprintWidgetButton;

    public constructor(init?: Partial<PageBlueprintWidgetButtonGroup>) { (Object as any).assign(this, init); }
}

export class PageBlueprintWidget
{
    public name: string;
    public buttonGroups: PageBlueprintWidgetButtonGroup;

    public constructor(init?: Partial<PageBlueprintWidget>) { (Object as any).assign(this, init); }
}

export class PageBlueprint
{
    public widgets: PageBlueprintWidget[];
    public isEnabledInMenu: boolean;
    public previewUrl: string;

    public constructor(init?: Partial<PageBlueprint>) { (Object as any).assign(this, init); }
}

export class ImagePackVariationSetting
{
    public key?: number;
    public imageUrl: string;
    public variations: { [index: string]: string; };

    public constructor(init?: Partial<ImagePackVariationSetting>) { (Object as any).assign(this, init); }
}

export interface IContentPackWidgetSetting
{
}

export interface ILocalizableEntity
{
    lcz: LocalizeConfig;
}

export interface IApplyDiscountProduct
{
    skuId: number;
    productId: number;
    units: GetCartProductItemResponse[];
}

export interface ICommunicationData
{
    id: number;
    type: CommunicationType;
    phone: CommunicationPhone;
    email: CommunicationEmail;
    address: CommunicationAddress;
    social: CommunicationSocial;
}

export class DesignTemplateIndustry implements IPrimaryEntity, IDisplayEntity
{
    public id: number;
    public name: string;
    public categories: DesignTemplateCategory[];

    public constructor(init?: Partial<DesignTemplateIndustry>) { (Object as any).assign(this, init); }
}

export interface IUnitOrderItem
{
    id: string;
    isRefunded: boolean;
    productId: number;
    skuId?: number;
    couponDiscount: number;
    saleDiscount: number;
    totalDiscount: number;
    finalPrice: number;
    refundTotal: number;
    quantity: number;
}

export interface ICommunicationItem
{
    communicationId?: number;
    value: string;
}

export class DesignTemplateCategory implements IPrimaryEntity, IDisplayEntity
{
    public id: number;
    public name: string;

    public constructor(init?: Partial<DesignTemplateCategory>) { (Object as any).assign(this, init); }
}

export class GetTaxGroupsResponse extends BaseResponse implements IPrimaryEntity, ISiteReferenceEntity
{
    public id: number;
    public name: string;
    public rate?: number;
    public countryCodes: string[];
    public stateCodes: string[];
    public isSeparateState: boolean;
    public createdAt: string;
    public updatedAt: string;
    public isDeleted: boolean;
    public siteId: number;

    public constructor(init?: Partial<GetTaxGroupsResponse>) { super(init); (Object as any).assign(this, init); }
}

export class SaveProductInventorySkuListResponse extends BaseResponse
{
    public defaultProductInventorySku: GetProductInventorySkuResponse;
    public skuList: GetProductInventorySkuResponse[];

    public constructor(init?: Partial<SaveProductInventorySkuListResponse>) { super(init); (Object as any).assign(this, init); }
}

export class GetShippingGroupsResponse extends BaseResponse implements IPrimaryEntity, ISiteReferenceEntity
{
    public id: number;
    public siteId: number;
    public countryCodes: string[];
    public stateCodes: string[];
    public costType: ShippingGroupCostType;
    public isSeparateState: boolean;
    public cost?: number;

    public constructor(init?: Partial<GetShippingGroupsResponse>) { super(init); (Object as any).assign(this, init); }
}

export class GetPromotionsResponse extends BaseResponse implements IPrimaryEntity, ISiteReferenceEntity, IAuditEntity, IDeletedEntity
{
    public id: number;
    public name: string;
    public title: string;
    public publishDate?: string;
    public createdAt: string;
    public updatedAt: string;
    public isDeleted: boolean;
    public siteId: number;
    public assignedToProductIds: number[];
    public assignedToProducts: GetProductsResponse[];
    public promotedProductIds: number[];
    public promotedProducts: GetProductsResponse[];

    public constructor(init?: Partial<GetPromotionsResponse>) { super(init); (Object as any).assign(this, init); }
}

export class BuildRecordLocalizeConfigResponse<T>
{
    public configs: T[];

    public constructor(init?: Partial<BuildRecordLocalizeConfigResponse<T>>) { (Object as any).assign(this, init); }
}

export class LocalizeRecordResponse implements ILocalizeRecordResponse
{
    public columns: { [index: string]: string; };
    public values: { [index: string]: string; };

    public constructor(init?: Partial<LocalizeRecordResponse>) { (Object as any).assign(this, init); }
}

export class OptionsGroupOperationResponse extends BaseResponse
{
    public optionGroups: ProductOptionsGroup[];

    public constructor(init?: Partial<OptionsGroupOperationResponse>) { super(init); (Object as any).assign(this, init); }
}

export class GetPagePublishStatusCountsResponse extends BaseResponse
{
    public pagePublishStatusCounts: { [index: number]: number; };

    public constructor(init?: Partial<GetPagePublishStatusCountsResponse>) { super(init); (Object as any).assign(this, init); }
}

export class GetOrdersReportResponse extends BaseResponse
{
    public results: Object[];
    public total?: number;
    public offset?: number;

    public constructor(init?: Partial<GetOrdersReportResponse>) { super(init); (Object as any).assign(this, init); }
}

export class GetOrdersResponse extends BaseResponse implements IPrimaryEntity, IAuditEntity, ISiteReferenceEntity
{
    public id: number;
    public isTestMode: boolean;
    public paymentMethod?: PaymentMethodType;
    public paymentStatus?: PaymentStatusType;
    public orderReceivingMethodType?: StoreCheckoutOrderReceivingMethodType;
    public shipmentStatus?: ShipmentStatusType;
    public priceBeforeDiscount: number;
    public discount?: number;
    public subtotal: number;
    public refund?: number;
    public taxes?: number;
    public shippingCost?: number;
    public refundCost?: number;
    public total: number;
    public totalAtTimeOfSale: number;
    public billingTotalPrice: number;
    public billingTotalTaxesAmount?: number;
    public billingTotalPriceWithTaxes?: number;
    public billingCurrencyCode: string;
    public orderItems: OrderItem[];
    public mainProductName: string;
    public billingFirstName: string;
    public billingLastName: string;
    public billingFullName: string;
    public billingEmail: string;
    public billingAddress: string;
    public billingCity: string;
    public billingZip: string;
    public billingCountryCode: string;
    public billingStateCode: string;
    public billingStateId?: number;
    public billingFullAddress: string;
    public phone: string;
    public isCorporatePurchase?: boolean;
    public companyName: string;
    public vat: string;
    public isEqualBillingToShipping?: boolean;
    public shippingIsRefunded: boolean;
    public shippingAddress: string;
    public shippingCity: string;
    public shippingZip: string;
    public shippingCountryCode: string;
    public shippingStateCode: string;
    public shippingStateId?: number;
    public shippingFullAddress: string;
    public isAgree?: boolean;
    public isSubscribed?: boolean;
    public message: string;
    public notes: OrderNote[];
    public customFields: CustomField[];
    public isReturned?: boolean;
    public returnReasonType?: OrderReturnReasonType;
    public userIp: string;
    public userAgent: string;
    public payPalOrderId: string;
    public payPalEmail: string;
    public payPalApproveUrl: string;
    public createdAt: string;
    public updatedAt: string;
    public siteId: number;
    public customerId?: number;
    public stripeCustomerId: string;
    public stripePaymentIntentId: string;
    public stripeClientSecret: string;
    public cardToken: string;
    public cardNumberLast4: string;

    public constructor(init?: Partial<GetOrdersResponse>) { super(init); (Object as any).assign(this, init); }
}

export class GetOrderResponse extends GetOrdersResponse
{

    public constructor(init?: Partial<GetOrderResponse>) { super(init); (Object as any).assign(this, init); }
}

export class BaseSaveResponse extends BaseResponse implements IPrimaryEntity
{
    public id: number;

    public constructor(init?: Partial<BaseSaveResponse>) { super(init); (Object as any).assign(this, init); }
}

export class GetOrderInvoiceResponse extends BaseResponse
{
    public order: GetOrderResponse;
    public sellerInfo: OrderInvoiceSellerInfo;

    public constructor(init?: Partial<GetOrderInvoiceResponse>) { super(init); (Object as any).assign(this, init); }
}

export class GetDiscountsResponse extends BaseResponse implements IPrimaryEntity, IAuditEntity, ISiteReferenceEntity, IDeletedEntity, IOwnerEntity
{
    public id: number;
    public name: string;
    public couponCode: string;
    public discountAmount: number;
    public discountType: DiscountType;
    public status: DiscountStatus;
    public activeFrom: string;
    public activeTo?: string;
    public isEnabledQuantityOfCoupons: boolean;
    public isEnabledActivationStartDate: boolean;
    public isEnabledActivationFinishDate: boolean;
    public usageLimit?: number;
    public oneUsagePerCustomer: boolean;
    public assignType: DiscountAssignType;
    public applyType: DiscountProductApplyType;
    public isDeleted: boolean;
    public createdAt: string;
    public updatedAt: string;
    public siteId: number;
    public userAuthId?: number;
    public productIds: number[];
    public collectionIds: number[];
    public skuIds: number[];

    public constructor(init?: Partial<GetDiscountsResponse>) { super(init); (Object as any).assign(this, init); }
}

export class GetCustomersResponse extends BaseResponse implements IPrimaryEntity, IAuditEntity, ISiteReferenceEntity
{
    public id: number;
    public billingFirstName: string;
    public billingLastName: string;
    public billingEmail: string;
    public billingAddress: string;
    public billingCity: string;
    public billingZip: string;
    public billingCountryCode: string;
    public billingStateCode: string;
    public billingStateId?: number;
    public billingFullAddress: string;
    public isEqualBillingToShipping?: boolean;
    public shippingAddress: string;
    public shippingCity: string;
    public shippingZip: string;
    public shippingCountryCode: string;
    public shippingStateCode: string;
    public shippingStateId?: number;
    public shippingFullAddress: string;
    public phone: string;
    public isCorporatePurchase?: boolean;
    public companyName: string;
    public vat: string;
    public loginEmail: string;
    public notes: CustomerNote[];
    public billingTotalAmountSpent?: number;
    public createdAt: string;
    public updatedAt: string;
    public userIp: string;
    public userAgent: string;
    public siteId: number;
    public stripeId: string;
    public cardToken: string;

    public constructor(init?: Partial<GetCustomersResponse>) { super(init); (Object as any).assign(this, init); }
}

export class GetOrderRefundCartResponse extends BaseResponse
{
    public orderItems: OrderItem[];
    public refundOrderItemQuantityDict: { [index: string]: number; };
    public newRefundTotal: number;
    public finalPrice: number;
    public isFull: boolean;

    public constructor(init?: Partial<GetOrderRefundCartResponse>) { super(init); (Object as any).assign(this, init); }
}

export class GetCartResponse extends BaseResponse
{
    public productList: GetCartProductResponse[];
    public bundleList: GetCartBundleResponse[];
    public shippingCost: GetShippingGroupsResponse;
    public taxes: GetTaxGroupsResponse;
    public orderPriceBeforeDiscount: number;
    public orderDiscount: number;
    public orderSubtotal: number;
    public orderShippingCost: number;
    public orderTotalWithoutTax: number;
    public orderTaxes: number;
    public orderTotal: number;

    public constructor(init?: Partial<GetCartResponse>) { super(init); (Object as any).assign(this, init); }
}

export class GetBundlesResponse extends BaseResponse implements IPrimaryEntity, IAuditEntity, ISiteReferenceEntity, IDeletedEntity
{
    public id: number;
    public name: string;
    public priceType: BundlePriceType;
    public finalPrice?: number;
    public percentageDiscount?: number;
    public price: number;
    public productsPrice: number;
    public createdAt: string;
    public updatedAt: string;
    public isDeleted: boolean;
    public siteId: number;
    public items: BundleItem[];
    public applyProductIds: number[];
    public applyProducts: GetProductsResponse[];
    public includedSkus: BundleIncludedSkuResponse[];

    public constructor(init?: Partial<GetBundlesResponse>) { super(init); (Object as any).assign(this, init); }
}

export class CmsSharedOperationsResponse
{
    public getWizardProgressResponse: GetWizardProgressResponse;

    public constructor(init?: Partial<CmsSharedOperationsResponse>) { (Object as any).assign(this, init); }
}

export class GetUpdateSiteStatusResponse extends BaseResponse
{
    public status: PayProPaymentStatus;

    public constructor(init?: Partial<GetUpdateSiteStatusResponse>) { super(init); (Object as any).assign(this, init); }
}

export class GetUpgradeAccountPaymentUrlResponse extends BaseResponse
{
    public popupUrl: string;

    public constructor(init?: Partial<GetUpgradeAccountPaymentUrlResponse>) { super(init); (Object as any).assign(this, init); }
}

export class GetSiteSubscriptionInfoResponse extends BaseResponse
{
    public subscriptionOrderStatus: DisplaySubscriptionOrderStatus;
    public subscriptionStatus: PpgSubscriptionStatus;
    public createdAt?: string;
    public lastPayment?: string;
    public suspendDate?: string;
    public subscriptionPeriod: HocoosSubscriptionPlanPeriod;
    public invoiceAmount: number;
    public currency: string;
    public hasSubscription: boolean;
    public isAlreadyYearlyPlan: boolean;

    public constructor(init?: Partial<GetSiteSubscriptionInfoResponse>) { super(init); (Object as any).assign(this, init); }
}

export class GetDisplaySiteSubscriptionOrderStatusResponse extends BaseResponse
{
    public status: DisplaySubscriptionOrderStatus;

    public constructor(init?: Partial<GetDisplaySiteSubscriptionOrderStatusResponse>) { super(init); (Object as any).assign(this, init); }
}

export class GetSiteServiceDescriptionsResponse extends BaseResponse
{
    public services: SiteAiServiceConfig[];

    public constructor(init?: Partial<GetSiteServiceDescriptionsResponse>) { super(init); (Object as any).assign(this, init); }
}

export class GetSiteCategoryResponse extends BaseResponse
{
    public id: number;
    public name: string;
    public originalName: string;
    public customName: string;
    public groupId?: number;

    public constructor(init?: Partial<GetSiteCategoryResponse>) { super(init); (Object as any).assign(this, init); }
}

export class GetBaseSiteResponse extends BaseResponse implements IPrimaryEntity, ICommunicationEntity
{
    public id: number;
    public title: string;
    public userAuthId?: number;
    public layoutSettings: LayoutSettings;
    public subscriptionPlan: HocoosSubscriptionPlan;
    public homePageSettings: HomePageSetting;
    public layoutType?: LayoutType;
    public menuType?: MenuType;
    public alignMenuType?: HeaderAlignType;
    public menuItems: MenuItem[];
    public headerSettings: SiteHeaderSettings;
    public headerLogo: Logo;
    public footerSettings: SiteFooterSettings;
    public footerMenuItems: MenuItem[];
    public footerLogo: Logo;
    public footerCopyright: string;
    public footerCopyrightLcz: ColumnLocalizeValue;
    public styles: ThemeStyles;
    public fonts: ThemeFont[];
    public isEnabledPageAnimation?: boolean;
    public linkButtonLabelType?: LinkButtonLabelType;
    public mapStyleSetting: UiBoxMapStyleSetting;
    public favicon: string;
    public imageFilterSetting: ImageFilterSetting;
    public domain: string;
    public enabledApps: { [index: string]: EnabledElementSetting; };
    public storeAppSettings: StoreAppSetting;
    public bookingAppSettings: BookingAppSetting;
    public blogAppSettings: BlogAppSetting;
    public commentsAppSettings: CommentsAppSetting;
    public eventsAppSettings: EventsAppSetting;
    public subscribeFormId?: number;
    public vimeoFolderUri: string;
    public marketingIntegrationSettings: { [index: string]: MarketingIntegrationSetting; };
    public isStripeEnabled?: boolean;
    public isPayPalEnabled?: boolean;
    public stripeAccountId: string;
    public payPalClientId: string;
    public defaultCultureCode: string;
    public paymentCountryCode: string;
    public paymentCurrencyCode: string;
    public widgetDisplayCurrencyCode: string;
    public isCatalogModeOnly?: boolean;
    public isDeleted: boolean;
    public deletedReasonType?: SiteDeletedReasonType;
    public defaultLanguage: LocalizableLanguage;
    public defaultLanguageCode: string;
    public widgetSliderSettings: BaseWidgetSliderSettings;

    public constructor(init?: Partial<GetBaseSiteResponse>) { super(init); (Object as any).assign(this, init); }
}

export class GetSitesResponse extends GetBaseSiteResponse implements IOwnerEntity, IDisplayEntity
{
    public createdAt: string;
    public suspendDate?: string;
    public name: string;
    public initializationStatus?: SiteInitializationStatus;
    public premiumLastPeriod?: HocoosSubscriptionPlanPeriod;
    public subscriptionSuspendType?: SubscriptionPlanSuspendType;
    public thumbnail: string;
    public designPackId?: number;
    public contentPackId?: number;
    public designPackImageId?: number;
    public stucturePackId?: number;
    public businessCategoryId?: number;
    public customBusinessCategoryName: string;
    public colorPaletteId?: number;
    public fontGroupId?: number;
    public colorMappingId?: number;
    public fontMappingId?: number;
    public boxMappingId?: number;
    public structurePackBlueprintIndex?: number;
    public wizardAnswersDict: { [index: string]: string[]; };
    public wizardSelectedServices: WizardSelectedService[];
    public aiConfig: SiteAiConfig;
    public signupWizardGuid: string;
    public isEnabledOfferDiscount?: boolean;
    public widgetContainerType?: WidgetContainerType;
    public domainInfo: SiteDomainInfo;

    public constructor(init?: Partial<GetSitesResponse>) { super(init); (Object as any).assign(this, init); }
}

export class SaveCallbackResult extends BaseResponse
{
    public status: string;

    public constructor(init?: Partial<SaveCallbackResult>) { super(init); (Object as any).assign(this, init); }
}

export class SiteReferenceChargeResponse extends BaseResponse
{
    public isSuccess: boolean;
    public paymentPopupUrl: string;

    public constructor(init?: Partial<SiteReferenceChargeResponse>) { super(init); (Object as any).assign(this, init); }
}

export class GetSitePaymentsInfoResponse extends BaseResponse
{
    public paymentCountryCode: string;
    public isPayPalEnabled?: boolean;
    public isStripeEnabled?: boolean;
    public payPalClientId: string;
    public stripeAccountId: string;
    public isStripeStandard?: boolean;

    public constructor(init?: Partial<GetSitePaymentsInfoResponse>) { super(init); (Object as any).assign(this, init); }
}

export class ConnectPayPalAccountResponse
{
    public url: string;

    public constructor(init?: Partial<ConnectPayPalAccountResponse>) { (Object as any).assign(this, init); }
}

export class GetPayPalClientResponse
{
    public siteId: number;
    public isPayPalEnabled?: boolean;
    public payPalClientId: string;
    public payPalClientSecret: string;
    public payPalMerchantId: string;

    public constructor(init?: Partial<GetPayPalClientResponse>) { (Object as any).assign(this, init); }
}

export class CreateStripeAccountResponse extends BaseResponse
{
    public created: string;
    public expiresAt: string;
    public url: string;

    public constructor(init?: Partial<CreateStripeAccountResponse>) { super(init); (Object as any).assign(this, init); }
}

export class GetPageInfoResponse extends BaseResponse implements IPrimaryEntity
{
    public id: number;

    public constructor(init?: Partial<GetPageInfoResponse>) { super(init); (Object as any).assign(this, init); }
}

export class GetPagesResponse extends BaseResponse implements IPrimaryEntity, IDeletedEntity, ISiteReferenceEntity, IAuditEntity, IOwnerEntity
{
    public id: number;
    public order?: number;
    public name: string;
    public nameLcz: ColumnLocalizeValue;
    public slug: string;
    public isCustomSlug?: boolean;
    public widgets: BaseWidget[];
    public pageType?: PageType;
    public pageTemplateType: PageTemplateType;
    public keywords: Tag[];
    public metaTitle: string;
    public metaDescription: string;
    public createdAt: string;
    public updatedAt: string;
    public isDeleted: boolean;
    public siteId: number;
    public userAuthId?: number;
    public seoSettings: PageSeo;

    public constructor(init?: Partial<GetPagesResponse>) { super(init); (Object as any).assign(this, init); }
}

export class GetPaymentPopupUrlResponse extends BaseResponse
{
    public url: string;

    public constructor(init?: Partial<GetPaymentPopupUrlResponse>) { super(init); (Object as any).assign(this, init); }
}

export class GetIsSiteHasFreeMailBoxResponse extends BaseResponse
{
    public isFree: boolean;

    public constructor(init?: Partial<GetIsSiteHasFreeMailBoxResponse>) { super(init); (Object as any).assign(this, init); }
}

export class GetSiteLogosResponse
{
    public results: SiteLogoResponse[];

    public constructor(init?: Partial<GetSiteLogosResponse>) { (Object as any).assign(this, init); }
}

export class GenerateSiteLogoResponse
{
    public logos: SiteLogoResponse[];

    public constructor(init?: Partial<GenerateSiteLogoResponse>) { (Object as any).assign(this, init); }
}

export class GenerateLogoResponse extends BaseResponse
{
    public items: GenerateLogoResponseItem[];

    public constructor(init?: Partial<GenerateLogoResponse>) { super(init); (Object as any).assign(this, init); }
}

export class GetInstagramPostResponse
{
    public thumbnailUrl: string;

    public constructor(init?: Partial<GetInstagramPostResponse>) { (Object as any).assign(this, init); }
}

export class GetIsSiteHaveFreeDomainResponse extends BaseResponse
{
    public isHaveFreeDomain: boolean;
    public paymentId?: number;
    public isFirstPayment?: boolean;

    public constructor(init?: Partial<GetIsSiteHaveFreeDomainResponse>) { super(init); (Object as any).assign(this, init); }
}

export class PurchaseDomainResponse extends BaseResponse
{
    public domain: string;
    public paymentPopupUrl: string;
    public domainId?: number;
    public isPaid: boolean;

    public constructor(init?: Partial<PurchaseDomainResponse>) { super(init); (Object as any).assign(this, init); }
}

export class GetPurchaseDomainStatusResponse
{
    public status: PayProPaymentStatus;

    public constructor(init?: Partial<GetPurchaseDomainStatusResponse>) { (Object as any).assign(this, init); }
}

export class RegisterExistingDomainResponse extends BaseResponse
{
    public domain: string;

    public constructor(init?: Partial<RegisterExistingDomainResponse>) { super(init); (Object as any).assign(this, init); }
}

export class AddEmailRecordsResponse extends BaseResponse
{
    public added: boolean;

    public constructor(init?: Partial<AddEmailRecordsResponse>) { super(init); (Object as any).assign(this, init); }
}

export class GetRenewSiteDomainDetailsResponse extends BaseResponse
{
    public priceInfo: DomainPriceInfo;
    public domainName: string;
    public domainType: DomainType;
    public premiumPeriod?: HocoosSubscriptionPlanPeriod;
    public expireDate?: string;

    public constructor(init?: Partial<GetRenewSiteDomainDetailsResponse>) { super(init); (Object as any).assign(this, init); }
}

export class GetSiteNextExpiredAtDomainResponse extends BaseResponse
{
    public domainId?: number;

    public constructor(init?: Partial<GetSiteNextExpiredAtDomainResponse>) { super(init); (Object as any).assign(this, init); }
}

export class GetRenewSiteDomainPaymentLinkResponse extends BaseResponse
{
    public url: string;

    public constructor(init?: Partial<GetRenewSiteDomainPaymentLinkResponse>) { super(init); (Object as any).assign(this, init); }
}

export class RenewSiteDomainResponse extends BaseResponse
{
    public isSuccess: boolean;
    public url: string;

    public constructor(init?: Partial<RenewSiteDomainResponse>) { super(init); (Object as any).assign(this, init); }
}

export class SuggestDomainsResponse extends BaseResponse
{
    public results: SuggestDomain[];

    public constructor(init?: Partial<SuggestDomainsResponse>) { super(init); (Object as any).assign(this, init); }
}

export class GetBusinessCategoryResponse extends BaseResponse implements IPrimaryEntity, IDisplayEntity
{
    public id: number;
    public name: string;
    public userId?: number;
    public designPackIds: number[];
    public contentPackIds: number[];
    public structurePackIds: number[];
    public isDeleted?: boolean;
    public isDefault?: boolean;
    public hasServices?: boolean;
    public industryId?: number;
    public groupId?: number;
    public isCompleted?: boolean;
    public isPublic?: boolean;

    public constructor(init?: Partial<GetBusinessCategoryResponse>) { super(init); (Object as any).assign(this, init); }
}

export class GetSiteCustomBusinessCategoriesResponse extends QueryResponse<GetBusinessCategoryResponse>
{
    public customBusinessCategoryName: string;

    public constructor(init?: Partial<GetSiteCustomBusinessCategoriesResponse>) { super(init); (Object as any).assign(this, init); }
}

export class GetSiteBillingInfoResponse extends BaseResponse
{
    public type: BillingInfoType;
    public siteId: number;
    public legalName: string;
    public businessNumber: string;
    public firstName: string;
    public lastName: string;
    public primaryEmail: string;
    public countryId?: number;
    public stateId?: number;
    public stateCode: string;
    public city: string;
    public address: string;
    public phoneNumber: string;
    public postalCode: string;
    public countryCode: string;

    public constructor(init?: Partial<GetSiteBillingInfoResponse>) { super(init); (Object as any).assign(this, init); }
}

export class PingResponse extends BaseResponse
{
    public message: string;

    public constructor(init?: Partial<PingResponse>) { super(init); (Object as any).assign(this, init); }
}

export class PingSleepResponse
{
    public startDate: string;
    public endDate: string;

    public constructor(init?: Partial<PingSleepResponse>) { (Object as any).assign(this, init); }
}

export class GetEmailTemplateResponse extends GetEmailTemplatesResponse
{
    public headerTemplate: GetEmailTemplatesResponse;
    public footerTemplate: GetEmailTemplatesResponse;

    public constructor(init?: Partial<GetEmailTemplateResponse>) { super(init); (Object as any).assign(this, init); }
}

export class BuildEmailResponse extends BaseResponse
{
    public subject: string;
    public body: string;
    public htmlBody: string;

    public constructor(init?: Partial<BuildEmailResponse>) { super(init); (Object as any).assign(this, init); }
}

export class GetEmailTemplateJsonModelResponse extends BaseResponse
{
    public body: string;
    public subject: string;

    public constructor(init?: Partial<GetEmailTemplateJsonModelResponse>) { super(init); (Object as any).assign(this, init); }
}

export class ValidateEmailStopListResponse extends BaseResponse
{
    public isValid: boolean;

    public constructor(init?: Partial<ValidateEmailStopListResponse>) { super(init); (Object as any).assign(this, init); }
}

export class GetAiPostTitlesResponse extends BaseResponse
{
    public titles: string[];

    public constructor(init?: Partial<GetAiPostTitlesResponse>) { super(init); (Object as any).assign(this, init); }
}

export class GetAiPostSubTitlesResponse extends BaseResponse
{
    public subTitles: string[];

    public constructor(init?: Partial<GetAiPostSubTitlesResponse>) { super(init); (Object as any).assign(this, init); }
}

export class GetAiPostBodyResponse extends BaseResponse
{
    public introduction: string;
    public sections: AiPostSectionItem[];
    public conclusion: string;
    public quote: string;
    public summary: string;

    public constructor(init?: Partial<GetAiPostBodyResponse>) { super(init); (Object as any).assign(this, init); }
}

export class GetOpenAiPostAvailableRequestCountResponse extends BaseResponse
{
    public usedCount: number;
    public availableCount: number;

    public constructor(init?: Partial<GetOpenAiPostAvailableRequestCountResponse>) { super(init); (Object as any).assign(this, init); }
}

export class GetPostsArchivesResponse extends BaseResponse
{
    public dates: string[];

    public constructor(init?: Partial<GetPostsArchivesResponse>) { super(init); (Object as any).assign(this, init); }
}

export class GetBlogCategoryTemplatesResponse extends BaseResponse implements IPrimaryEntity, IAuditEntity, ISiteReferenceEntity, IDeletedEntity, IOwnerEntity
{
    public id: number;
    public name: string;
    public nameLcz: ColumnLocalizeValue;
    public slug: string;
    public structureType: BlogCategoryTemplateStructureType;
    public structureTypeVariationType: BlogCategoryTemplateStructureVariationType;
    public sidebarType: BlogPageTemplateSidebarType;
    public sortType: SortType;
    public sidebarWidgets: BlogPageTemplateSidebarWidget[];
    public widgets: BaseWidget[];
    public sliderPostIds: number[];
    public postElementShowSettings: { [index: string]: EnabledSetting; };
    public sliderElementShowSettings: { [index: string]: EnabledSetting; };
    public categoryId?: number;
    public createdAt: string;
    public updatedAt: string;
    public isDeleted: boolean;
    public pageType: PageType;
    public pageTemplateType: PageTemplateType;
    public isFinishedWizard: boolean;
    public siteId: number;
    public userAuthId?: number;
    public styleSettings: PageStyleSettings;

    public constructor(init?: Partial<GetBlogCategoryTemplatesResponse>) { super(init); (Object as any).assign(this, init); }
}

export class GetBookingWorkersResponse extends BaseResponse implements IPrimaryEntity, ISiteReferenceEntity, IDeletedEntity
{
    public id: number;
    public firstName: string;
    public lastName: string;
    public position: string;
    public emails: string[];
    public phones: string[];
    public socialNetworks: string[];
    public vacation: BookingWorkerVacation;
    public atLocations: GetBookingWorkersAtLocationsResponse[];
    public isDeleted: boolean;
    public siteId: number;
    public updatedAt: string;

    public constructor(init?: Partial<GetBookingWorkersResponse>) { super(init); (Object as any).assign(this, init); }
}

export class GetBookingServicesResponse extends BaseResponse implements IPrimaryEntity, ISiteReferenceEntity, IDeletedEntity
{
    public id: number;
    public title: string;
    public maxUpfrontPeriodValue: number;
    public maxUpfrontPeriodType: MaxUpfrontPeriodTypeEnum;
    public price?: number;
    public breakBeforeHours?: number;
    public breakBeforeMinutes?: number;
    public durationHours?: number;
    public durationMinutes?: number;
    public breakAfterHours?: number;
    public breakAfterMinutes?: number;
    public siteId: number;
    public atLocations: BookingLocationListItem[];
    public atLocationIds: number[];
    public byWorkers: BookingWorkerListItem[];
    public byWorkerIds: number[];
    public isDeleted: boolean;
    public updatedAt: string;

    public constructor(init?: Partial<GetBookingServicesResponse>) { super(init); (Object as any).assign(this, init); }
}

export class GetIsExistsFinishedSiteBookingServiceResponse
{
    public isExists: boolean;

    public constructor(init?: Partial<GetIsExistsFinishedSiteBookingServiceResponse>) { (Object as any).assign(this, init); }
}

export class GetBookingRequestsResponse extends BaseResponse implements IPrimaryEntity, ISiteReferenceEntity
{
    public id: number;
    public siteId: number;
    public email: string;
    public firstName: string;
    public lastName: string;
    public customFields: BookingCustomField[];
    public address: string;
    public phone: string;
    public countryId: number;
    public zipCode: string;
    public price: number;
    public scheduledAt: string;
    public status: RequestStatus;
    public note: string;
    public locationId: number;
    public locationName: string;
    public serviceId: number;
    public serviceName: string;
    public serviceDurationHours?: number;
    public serviceDurationMinutes?: number;
    public workerId: number;
    public workerName: string;
    public clientId?: number;
    public clientName: string;
    public isAvaiable: boolean;
    public availabilityNotes: string[];
    public source: RequestSource;

    public constructor(init?: Partial<GetBookingRequestsResponse>) { super(init); (Object as any).assign(this, init); }
}

export class GetBookingLocationResponse extends BaseResponse implements IPrimaryEntity, IDeletedEntity, ISiteReferenceEntity
{
    public id: number;
    public title: string;
    public workingTime: WorkingTime;
    public siteId: number;
    public isDeleted: boolean;

    public constructor(init?: Partial<GetBookingLocationResponse>) { super(init); (Object as any).assign(this, init); }
}

export class GetBookingClientsResponse extends BaseResponse implements IPrimaryEntity, ISiteReferenceEntity
{
    public bookingRequests: GetBookingRequestsResponse[];
    public id: number;
    public siteId: number;
    public email: string;
    public firstName: string;
    public lastName: string;
    public phone: string;
    public address: string;
    public countryCode: string;
    public visitedLocations: BookingLocationListItem[];
    public usedServices: BookingServiceListItem[];
    public visitsCount?: number;
    public totalSpent?: number;
    public createdAt: string;
    public updatedAt: string;
    public isDeleted?: boolean;

    public constructor(init?: Partial<GetBookingClientsResponse>) { super(init); (Object as any).assign(this, init); }
}

export class GetSiteFileUploadAvailableResponse extends BaseResponse
{
    public usedCount: number;
    public availableCount: number;
    public subscriptionPlan: HocoosSubscriptionPlan;
    public isValid: boolean;

    public constructor(init?: Partial<GetSiteFileUploadAvailableResponse>) { super(init); (Object as any).assign(this, init); }
}

export class GetSiteVideoUploadLinkResponse extends BaseSaveResponse
{
    public uploadLink: string;

    public constructor(init?: Partial<GetSiteVideoUploadLinkResponse>) { super(init); (Object as any).assign(this, init); }
}

export class CompleteUploadSiteVideosResponse extends BaseResponse
{
    public results: CompleteUploadSiteVideo[];

    public constructor(init?: Partial<CompleteUploadSiteVideosResponse>) { super(init); (Object as any).assign(this, init); }
}

export class GetFilesResult extends BaseResponse
{
    public files: FileResponse[];

    public constructor(init?: Partial<GetFilesResult>) { super(init); (Object as any).assign(this, init); }
}

export class SecureUploadSiteImageResponse extends BaseResponse
{
    public fullFilePath: string;
    public filePath: string;
    public size: number;

    public constructor(init?: Partial<SecureUploadSiteImageResponse>) { super(init); (Object as any).assign(this, init); }
}

export class OpenAiExtendSiteImageResponse extends BaseResponse
{
    public imageUrl: string;

    public constructor(init?: Partial<OpenAiExtendSiteImageResponse>) { super(init); (Object as any).assign(this, init); }
}

export class UploadSiteImageResponse extends BaseSaveResponse
{
    public fullFilePath: string;
    public filePath: string;

    public constructor(init?: Partial<UploadSiteImageResponse>) { super(init); (Object as any).assign(this, init); }
}

export class GetUploadSignedUrlResponse extends BaseResponse
{
    public url: string;
    public folderPath: string;

    public constructor(init?: Partial<GetUploadSignedUrlResponse>) { super(init); (Object as any).assign(this, init); }
}

export class GetSiteStockImagesResponse extends BaseResponse
{
    public results: StockImage[];
    public total: number;

    public constructor(init?: Partial<GetSiteStockImagesResponse>) { super(init); (Object as any).assign(this, init); }
}

export class GetUserGeoResponse extends BaseResponse
{
    public ip: string;
    public isEu: boolean;
    public city: string;
    public region: string;
    public regionCode: string;
    public regionType: string;
    public countryName: string;
    public countryCode: string;
    public continentName: string;
    public continentCode: string;
    public latitude: number;
    public longitude: number;
    public postal: string;
    public callingCode: string;
    public flag: string;
    public emojiFlag: string;
    public emojiUnicode: string;

    public constructor(init?: Partial<GetUserGeoResponse>) { super(init); (Object as any).assign(this, init); }
}

export class GetSubscribeFormsResponse extends BaseResponse implements IPrimaryEntity, ISiteReferenceEntity, IAuditEntity, IDeletedEntity, IOwnerEntity
{
    public id: number;
    public widgetType: PopupWidgetType;
    public type: SubscribeFormType;
    public position: SubscribeFormPosition;
    public transition: TransitionAnimationType;
    public variationType: SubscribeFormVariationType;
    public name: string;
    public subscribe: MarketingPopupSubscribe;
    public promotion: MarketingPopupPromotion;
    public contactUs: MarketingPopupContactUs;
    public feedback: MarketingPopupFeedback;
    public ageVerification: MarketiongPopupAgeAgeVerification;
    public socialBar: MarketingPopupSocialBar;
    public rules: SubscribeFormRule;
    public discountId?: number;
    public mailingListId?: number;
    public siteId: number;
    public userAuthId?: number;
    public isDeleted: boolean;
    public backgroundColor: string;
    public contentAligmentType: number;
    public updatedAt: string;
    public createdAt: string;

    public constructor(init?: Partial<GetSubscribeFormsResponse>) { super(init); (Object as any).assign(this, init); }
}

export class PageSeoResponse extends BaseResponse implements IPrimaryEntity, ISiteReferenceEntity
{
    public id: number;
    public siteId: number;
    public keywords: string[];
    public metaTitle: string;
    public metaDescription: string;
    public url: string;
    public pageType: PageSeoType;
    public pageId: number;

    public constructor(init?: Partial<PageSeoResponse>) { super(init); (Object as any).assign(this, init); }
}

export class GetMailingListsResponse extends BaseResponse implements IPrimaryEntity, ISiteReferenceEntity, IAuditEntity, IDeletedEntity, IOwnerEntity
{
    public id: number;
    public name: string;
    public siteId: number;
    public userAuthId?: number;
    public isDeleted: boolean;
    public createdAt: string;
    public updatedAt: string;
    public isDefault?: boolean;
    public contactCount?: number;

    public constructor(init?: Partial<GetMailingListsResponse>) { super(init); (Object as any).assign(this, init); }
}

export class GetMailingListContactsResponse extends BaseResponse implements IPrimaryEntity, ISiteReferenceEntity, IDeletedEntity, IAuditEntity
{
    public id: number;
    public email: string;
    public firstName: string;
    public lastName: string;
    public mailingListId: number;
    public source: string;
    public siteId: number;
    public isDeleted: boolean;
    public createdAt: string;
    public updatedAt: string;

    public constructor(init?: Partial<GetMailingListContactsResponse>) { super(init); (Object as any).assign(this, init); }
}

export class GetCampaignsResponse extends BaseResponse implements IPrimaryEntity, IOwnerEntity, IDeletedEntity, IAuditEntity
{
    public id: number;
    public status: CampaignStatus;
    public name: string;
    public emailSubject: string;
    public fromName: string;
    public replyTo: string;
    public emailTemplate: string;
    public emailTemplateContentSettings: EmailTemplateContentSettings;
    public emailWidgetCategoryType: EmailWidgetCategoryType;
    public mailListIds: number[];
    public siteId: number;
    public userAuthId?: number;
    public isDeleted: boolean;
    public publishTime: string;
    public createdAt: string;
    public updatedAt: string;

    public constructor(init?: Partial<GetCampaignsResponse>) { super(init); (Object as any).assign(this, init); }
}

export class AnalyticsReport extends BaseResponse
{
    public totalVisits: number;
    public uniqueVisis: number;
    public totalVisitorsSeries: number[];
    public uniqueVisitorsSeries: number[];
    public visitorsTimeSeries: string[];
    public deviceCategories: { [index: string]: number; };
    public trafficSources: { [index: string]: number; };
    public trafficByCountry: { [index: string]: number; };
    public browsers: { [index: string]: number; };
    public topPages: { [index: string]: number; };

    public constructor(init?: Partial<AnalyticsReport>) { super(init); (Object as any).assign(this, init); }
}

export class GetTestimonialRecordsResponse extends BaseResponse implements IPrimaryEntity, ISiteReferenceEntity, IDeletedEntity, IAuditEntity
{
    public id: number;
    public testimonialFormId?: number;
    public status?: TestimonialRecordStatusType;
    public userEmail: string;
    public userName: string;
    public userPosition: string;
    public userCity: string;
    public userAge: string;
    public userOther: string;
    public userReview: string;
    public userRating?: number;
    public maxRating?: number;
    public feedbackType?: TestimonialFormFeedbackType;
    public customizedFeedbacks: TestimonialFormCustomizedFeedback[];
    public openFeedback: string;
    public referralSource: string;
    public positivenessType?: TestimonialRecordPositivenessType;
    public siteId: number;
    public isDeleted: boolean;
    public createdAt: string;
    public updatedAt: string;

    public constructor(init?: Partial<GetTestimonialRecordsResponse>) { super(init); (Object as any).assign(this, init); }
}

export class GetFreeFormsResponse extends BaseResponse implements IPrimaryEntity
{
    public id: number;
    public data: string;

    public constructor(init?: Partial<GetFreeFormsResponse>) { super(init); (Object as any).assign(this, init); }
}

export class GetTestimonialFormsResponse extends BaseResponse implements IPrimaryEntity, ISiteReferenceEntity, IDeletedEntity
{
    public id: number;
    public title: string;
    public description: string;
    public allowSocialMedia?: boolean;
    public allowImageUpload?: boolean;
    public userFields: TestimonialFormUserField[];
    public feedbackType?: TestimonialFormFeedbackType;
    public customizedFeedbacks: TestimonialFormCustomizedFeedback[];
    public ratingType?: RatingUiType;
    public ratingMaxValue?: number;
    public positiveLabel: string;
    public avarageLabel: string;
    public negativeLabel: string;
    public confirmationTitle: string;
    public confirmationDescription: string;
    public confirmationImage: BaseWidgetImage;
    public feedbackRequestType?: TestimonialFormFeedbackRequestType;
    public emailTriggerAfterPeriodValue?: number;
    public emailTriggerAfterPeriodType?: TestimonialFormEmailTriggerAfterPeriodType;
    public serviceIds: number[];
    public publishStatus?: TestimonialFormPublishStatusType;
    public isDeleted: boolean;
    public recordsAvgRating?: number;
    public recordsCount?: number;
    public siteId: number;

    public constructor(init?: Partial<GetTestimonialFormsResponse>) { super(init); (Object as any).assign(this, init); }
}

export class WizardChatConnectResponse extends BaseResponse
{
    public threadId: string;
    public runId: string;
    public messages: ChatMessage[];

    public constructor(init?: Partial<WizardChatConnectResponse>) { super(init); (Object as any).assign(this, init); }
}

export class CreateContentPackStepResponse
{
    public categoryId: number;
    public categoryName: string;
    public contentPackId: number;
    public contentPackName: string;

    public constructor(init?: Partial<CreateContentPackStepResponse>) { (Object as any).assign(this, init); }
}

export class GetWizardContentPackServicesResponse extends GenerateAiContentPackServicesResponse
{

    public constructor(init?: Partial<GetWizardContentPackServicesResponse>) { super(init); (Object as any).assign(this, init); }
}

export class WizardChatGetMessagesResponse extends BaseResponse
{
    public messages: ChatMessage[];
    public status: RunStatus;
    public actions: ChatAction[];
    public config: ChatWizardConfig;

    public constructor(init?: Partial<WizardChatGetMessagesResponse>) { super(init); (Object as any).assign(this, init); }
}

export class GetWizardAiBusinessCategoryResponse
{
    public success: boolean;
    public categories: string[];
    public isHaveAnyServices?: boolean;
    public services: string[];

    public constructor(init?: Partial<GetWizardAiBusinessCategoryResponse>) { (Object as any).assign(this, init); }
}

export class GetWizardAiServicesResponse
{
    public success: boolean;

    public constructor(init?: Partial<GetWizardAiServicesResponse>) { (Object as any).assign(this, init); }
}

export class GenerateUserRephraseResponse extends BaseResponse
{
    public rephrases: string[];

    public constructor(init?: Partial<GenerateUserRephraseResponse>) { super(init); (Object as any).assign(this, init); }
}

export class GenerateUserNewTextResponse extends BaseResponse
{
    public rephrases: string[];

    public constructor(init?: Partial<GenerateUserNewTextResponse>) { super(init); (Object as any).assign(this, init); }
}

export class CreateAiContentPackResponse extends BaseResponse
{
    public id: number;
    public name: string;

    public constructor(init?: Partial<CreateAiContentPackResponse>) { super(init); (Object as any).assign(this, init); }
}

export class GetContentPacksResponse extends BaseResponse implements IPrimaryEntity
{
    public id: number;
    public name: string;
    public nameLcz: ColumnLocalizeValue;
    public aboutUs: ContentPackWidgetCategoryAboutUsSetting;
    public banner: ContentPackWidgetCategoryBannerSetting;
    public blog: ContentPackWidgetCategoryBlogSetting;
    public blogTitle: ContentPackWidgetCategoryBlogTitleSetting;
    public blogText: ContentPackWidgetCategoryBlogTextSetting;
    public blogButton: ContentPackWidgetCategoryBlogButtonSetting;
    public blogGallery: ContentPackWidgetCategoryBlogGallerySetting;
    public blogVideo: ContentPackWidgetCategoryBlogVideoSetting;
    public blogImage: ContentPackWidgetCategoryBlogImageSetting;
    public blogImageWithText: ContentPackWidgetCategoryBlogImageWithTextSetting;
    public blogQuote: ContentPackWidgetCategoryBlogQuoteSetting;
    public blogSubscribe: ContentPackWidgetCategorySubscribeSetting;
    public blogActions: ContentPackWidgetCategoryBlogActionsSetting;
    public blogAboutAuthor: ContentPackWidgetCategoryBlogAboutAuthorSetting;
    public blogTopPosts: ContentPackWidgetCategoryBlogTopPostsSetting;
    public blogRecipe: ContentPackWidgetCategoryBlogRecipeSetting;
    public blogSummary: ContentPackWidgetCategoryBlogSummarySetting;
    public blogFeaturedItems: ContentPackWidgetCategoryBlogFeaturedItemsSetting;
    public productBundle: ContentPackWidgetCategoryProductBundleSetting;
    public productAdditionalInfoProductDescription: ContentPackWidgetCategoryProductAdditionalInfoProductDescription;
    public productAdditionalInfoFeatures: ContentPackWidgetCategoryProductAdditionalInfoFeatures;
    public productAdditionalInfoSizeAndFit: ContentPackWidgetCategoryProductAdditionalInfoSizeAndFit;
    public productAdditionalInfoShippingAndDelivery: ContentPackWidgetCategoryProductAdditionalInfoShippingAndDelivery;
    public productAdditionalInfoReviews: ContentPackWidgetCategoryProductAdditionalInfoReviews;
    public booking: ContentPackWidgetCategoryBookingSetting;
    public comments: ContentPackWidgetCategoryCommentsSetting;
    public contactUs: ContentPackWidgetCategoryContactUsSetting;
    public events: ContentPackWidgetCategoryEventsSetting;
    public faq: ContentPackWidgetCategoryFaqSetting;
    public featuredItems: ContentPackWidgetCategoryFeaturedItemsSetting;
    public gallery: ContentPackWidgetCategoryGallerySetting;
    public logos: ContentPackWidgetCategoryLogosSetting;
    public ourTeam: ContentPackWidgetCategoryOurTeamSetting;
    public priceList: ContentPackWidgetCategoryPriceListSetting;
    public services: ContentPackWidgetCategoryServiceSetting;
    public slider: ContentPackWidgetCategorySliderSetting;
    public smartBanner: ContentPackWidgetCategorySmartBannerSetting;
    public subscribe: ContentPackWidgetCategorySubscribeSetting;
    public testimonials: ContentPackWidgetCategoryTestimonialstSetting;
    public testiomonialForm: ContentPackWidgetCategoryTestiomonialFormSetting;
    public text: ContentPackWidgetCategoryTextSetting;
    public video: ContentPackWidgetCategoryVideoSetting;
    public career: ContentPackWidgetCategoryCareerSetting;
    public music: ContentPackWidgetCategoryMusicSetting;
    public table: ContentPackWidgetCategoryTableSetting;
    public resources: ContentPackWidgetCategoryResourcesSetting;
    public menu: ContentPackWidgetCategoryMenuSetting;
    public isDeleted?: boolean;
    public createdAt: string;
    public updatedAt: string;

    public constructor(init?: Partial<GetContentPacksResponse>) { super(init); (Object as any).assign(this, init); }
}

export class GetWidgetsLczResponse extends BaseResponse
{
    public config: LocalizeConfig;

    public constructor(init?: Partial<GetWidgetsLczResponse>) { super(init); (Object as any).assign(this, init); }
}

export class SearchBusinessCategoriesResponse extends BaseResponse
{
    public results: BusinessCategoryItem[];
    public notFoundCategories: string[];

    public constructor(init?: Partial<SearchBusinessCategoriesResponse>) { super(init); (Object as any).assign(this, init); }
}

export class SearchAllBusinessCategoriesResponse extends QueryResponse<BusinessGroupItem>
{
    public notFoundCategories: string[];
    public isValidSearch: boolean;

    public constructor(init?: Partial<SearchAllBusinessCategoriesResponse>) { super(init); (Object as any).assign(this, init); }
}

export class GetSearchSimilarityBusinessCategoriesResponse extends BaseResponse
{
    public categories: BusinessCategoryItem[];
    public notFoundCategories: string[];

    public constructor(init?: Partial<GetSearchSimilarityBusinessCategoriesResponse>) { super(init); (Object as any).assign(this, init); }
}

export class GetLinkInfoResponse extends BaseResponse
{
    public link: string;

    public constructor(init?: Partial<GetLinkInfoResponse>) { super(init); (Object as any).assign(this, init); }
}

export class GetSettingGroupsResponse extends BaseResponse
{
    public results: SettingGroup[];

    public constructor(init?: Partial<GetSettingGroupsResponse>) { super(init); (Object as any).assign(this, init); }
}

export class GetProcessedRecordGroupResponse extends BaseResponse
{
    public results: ProcessedRecordGroup[];

    public constructor(init?: Partial<GetProcessedRecordGroupResponse>) { super(init); (Object as any).assign(this, init); }
}

export class GetLastProcessStatusesResponse
{
    public results: GetLastProcessStatusResponse[];

    public constructor(init?: Partial<GetLastProcessStatusesResponse>) { (Object as any).assign(this, init); }
}

export class GetGlobalLocalizationsResponse extends Dictionary<GlobalLocalizeItem> implements IBaseResponse
{

    public constructor(init?: Partial<GetGlobalLocalizationsResponse>) { super(); (Object as any).assign(this, init); }
}

export class GetOpenAiUserAvailableRequestCountResponse extends BaseResponse
{
    public usedCount: number;
    public availableCount: number;
    public isLimitExceeded: boolean;

    public constructor(init?: Partial<GetOpenAiUserAvailableRequestCountResponse>) { super(init); (Object as any).assign(this, init); }
}

export class SaveEventLogResponse extends BaseEventLog implements IBaseResponse
{
    public id: number;
    public createdAt: string;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<SaveEventLogResponse>) { super(init); (Object as any).assign(this, init); }
}

export class GetIsFirstEditorOpenResponse extends BaseResponse
{
    public isFirst: boolean;

    public constructor(init?: Partial<GetIsFirstEditorOpenResponse>) { super(init); (Object as any).assign(this, init); }
}

export class GetTrackingEventTypeInfoResponse extends BaseResponse
{
    public count: number;
    public wasSaved: boolean;

    public constructor(init?: Partial<GetTrackingEventTypeInfoResponse>) { super(init); (Object as any).assign(this, init); }
}

export class GetSpecificationValueResponse extends BaseResponse
{
    public result: GetSpecificationValueItem;

    public constructor(init?: Partial<GetSpecificationValueResponse>) { super(init); (Object as any).assign(this, init); }
}

export class GetSpecificationValuesResponse extends BaseResponse
{
    public results: GetSpecificationValueItem[];

    public constructor(init?: Partial<GetSpecificationValuesResponse>) { super(init); (Object as any).assign(this, init); }
}

export class GetSpecificationValuesDetailResponse extends BaseResponse
{
    public results: GetSpecificationValueDetail[];

    public constructor(init?: Partial<GetSpecificationValuesDetailResponse>) { super(init); (Object as any).assign(this, init); }
}

export class TestSpecificationRuleResponse
{
    public matches: { [index: number]: boolean; };
    public elapsedMilliseconds: number;
    public elapsedString: string;
    public sql: string;

    public constructor(init?: Partial<TestSpecificationRuleResponse>) { (Object as any).assign(this, init); }
}

export class SearchElasticResponse extends BaseResponse
{
    public results: SiteElasticModelResult[];
    public total: number;

    public constructor(init?: Partial<SearchElasticResponse>) { super(init); (Object as any).assign(this, init); }
}

export class SearchSiteContentResponse extends BaseResponse
{
    public products: SearchSiteItemContentResponse<SearchProductContentResponse>;
    public posts: SearchSiteItemContentResponse<SearchPostContentResponse>;
    public pages: SearchSiteItemContentResponse<SearchPageContentResponse>;

    public constructor(init?: Partial<SearchSiteContentResponse>) { super(init); (Object as any).assign(this, init); }
}

export class GetSiteActiveSpecRulesResponse extends BaseResponse
{
    public items: CheckSpecificationRuleGroupResult[];

    public constructor(init?: Partial<GetSiteActiveSpecRulesResponse>) { super(init); (Object as any).assign(this, init); }
}

export class AdminTranslateTextToAllLanguagesResponse extends BaseResponse
{
    public translations: { [index: string]: string; };

    public constructor(init?: Partial<AdminTranslateTextToAllLanguagesResponse>) { super(init); (Object as any).assign(this, init); }
}

export class AdminTranslateTextResponse extends BaseResponse
{
    public origin: string;
    public translation: string;

    public constructor(init?: Partial<AdminTranslateTextResponse>) { super(init); (Object as any).assign(this, init); }
}

export class AdminMergeTranslationResponse extends BaseResponse
{
    public logs: string[];

    public constructor(init?: Partial<AdminMergeTranslationResponse>) { super(init); (Object as any).assign(this, init); }
}

export class GetAdminQueuesResponse
{
    public queues: MqQueue[];

    public constructor(init?: Partial<GetAdminQueuesResponse>) { (Object as any).assign(this, init); }
}

export class GetAdminQueueMessagesResponse
{
    public messages: MqQueueMessage[];

    public constructor(init?: Partial<GetAdminQueueMessagesResponse>) { (Object as any).assign(this, init); }
}

export class GetUtmAnalyticsQueryResponse extends QueryResponse<GetUtmAnalyticsResponse>
{
    public sql: string;

    public constructor(init?: Partial<GetUtmAnalyticsQueryResponse>) { super(init); (Object as any).assign(this, init); }
}

export class GetDesignTemplateCategoriesResponse extends BaseResponse
{
    public results: DesignTemplateGroup[];

    public constructor(init?: Partial<GetDesignTemplateCategoriesResponse>) { super(init); (Object as any).assign(this, init); }
}

// @DataContract
export class AuthenticateResponse implements IHasSessionId, IHasBearerToken
{
    // @DataMember(Order=1)
    public userId: string;

    // @DataMember(Order=2)
    public sessionId: string;

    // @DataMember(Order=3)
    public userName: string;

    // @DataMember(Order=4)
    public displayName: string;

    // @DataMember(Order=5)
    public referrerUrl: string;

    // @DataMember(Order=6)
    public bearerToken: string;

    // @DataMember(Order=7)
    public refreshToken: string;

    // @DataMember(Order=8)
    public profileUrl: string;

    // @DataMember(Order=9)
    public roles: string[];

    // @DataMember(Order=10)
    public permissions: string[];

    // @DataMember(Order=11)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=12)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<AuthenticateResponse>) { (Object as any).assign(this, init); }
}

export class GetTaxGroups extends QueryBase implements IReturn<QueryResponse<GetTaxGroupsResponse>>, IStoreGroupRequest
{
    public id?: number;
    public rate?: number;
    public siteId?: number;
    public countryCode: string;
    public stateCode: string;
    public isSeparateState?: boolean;

    public constructor(init?: Partial<GetTaxGroups>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'GetTaxGroups'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QueryResponse<GetTaxGroupsResponse>(); }
}

export class SaveTaxGroup implements IReturn<GetTaxGroupsResponse>, IPrimaryEntity, ISiteReferenceEntity, IDeletedEntity
{
    public id: number;
    public name: string;
    public rate?: number;
    public countryCodes: string[];
    public stateCodes: string[];
    public isSeparateState?: boolean;
    public createdAt: string;
    public updatedAt: string;
    public isDeleted: boolean;
    public siteId: number;

    public constructor(init?: Partial<SaveTaxGroup>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SaveTaxGroup'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetTaxGroupsResponse(); }
}

// @Route("/product-inventory-skus", "GET")
export class GetProductInventorySkus extends QueryBase implements IReturn<QueryResponse<GetProductInventorySkuResponse>>, IPrimaryEntityRequest, IPrimaryListEntityRequest, ISiteReferenceRequest, ISearchEntityRequest
{
    public id?: number;
    public ids: number[];
    public productId?: number;
    public productIds: number[];
    public siteId: number;
    public collectionId?: number;
    public type?: ProductInventorySkuType;
    public search: string;
    public minPrice?: number;
    public maxPrice?: number;
    // @DataMember(Name="f")
    public productGroupsDic: { [index: string]: string[]; };

    public tagLabels: string[];
    public isIncludeProductOptionGroups: boolean;

    public constructor(init?: Partial<GetProductInventorySkus>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'GetProductInventorySkus'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QueryResponse<GetProductInventorySkuResponse>(); }
}

export class SaveProductInventorySku implements IReturn<GetProductInventorySkuResponse>, IPrimaryEntity, IDeletedEntity, ISiteReferenceRequest, IActionRequest
{
    public id: number;
    public action: OperationActionType;
    public name: string;
    public key: string;
    public image: BaseWidgetImage;
    public price?: number;
    public salePrice?: number;
    public sku: string;
    public skuConfig: ProductGroupOptionRecord[];
    public type: ProductInventorySkuType;
    public availableQuantity?: number;
    public inventoryStockType?: InventoryStockType;
    public isDeleted: boolean;
    public productId: number;
    public siteId: number;

    public constructor(init?: Partial<SaveProductInventorySku>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SaveProductInventorySku'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetProductInventorySkuResponse(); }
}

export class SaveProductInventorySkuList implements IReturn<SaveProductInventorySkuListResponse>, ISiteReferenceRequest
{
    public skuList: SaveProductInventorySku[];
    public siteId: number;

    public constructor(init?: Partial<SaveProductInventorySkuList>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SaveProductInventorySkuList'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SaveProductInventorySkuListResponse(); }
}

export class GenerateProductInventorySkuList implements IReturn<SaveProductInventorySkuListResponse>, ISiteReferenceRequest
{
    public productId: number;
    // @DataMember(Name="f")
    public productGroupsDic: { [index: string]: string[]; };

    public siteId: number;

    public constructor(init?: Partial<GenerateProductInventorySkuList>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GenerateProductInventorySkuList'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SaveProductInventorySkuListResponse(); }
}

export class GetShippingGroups extends QueryBase implements IReturn<QueryResponse<GetShippingGroupsResponse>>, IStoreGroupRequest
{
    public id?: number;
    public siteId?: number;
    public countryCode: string;
    public stateCode: string;

    public constructor(init?: Partial<GetShippingGroups>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'GetShippingGroups'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QueryResponse<GetShippingGroupsResponse>(); }
}

export class SaveShippingGroup implements IReturn<GetShippingGroupsResponse>, IPrimaryEntity, ISiteReferenceEntity, IDeletedEntity
{
    public id: number;
    public siteId: number;
    public countryCodes: string[];
    public stateCodes: string[];
    public isDeleted: boolean;
    public isSeparateState?: boolean;
    public costType: ShippingGroupCostType;
    public cost?: number;

    public constructor(init?: Partial<SaveShippingGroup>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SaveShippingGroup'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetShippingGroupsResponse(); }
}

export class GetPromotions extends QueryBase implements IReturn<QueryResponse<GetPromotionsResponse>>, IPrimaryEntityRequest, IPrimaryListEntityRequest, ISiteReferenceRequest, IOwnerEntityRequest, ISearchEntityRequest
{
    public id?: number;
    public ids: number[];
    public name: string;
    public search: string;
    public siteId: number;
    public userAuthId?: number;
    public forProductIds: number[];
    public forProductId?: number;
    public includePromotedProducts?: boolean;
    public includeAssignedToProducts?: boolean;

    public constructor(init?: Partial<GetPromotions>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'GetPromotions'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QueryResponse<GetPromotionsResponse>(); }
}

export class SavePromotion implements IReturn<GetPromotionsResponse>, IPrimaryEntity, ISiteReferenceEntity, IAuditEntity, IDeletedEntity
{
    public siteId: number;
    public id: number;
    public action?: OperationActionType;
    public name: string;
    public title: string;
    public promotedProductIds: number[];
    public assignedToProductIds: number[];
    public publishDate?: string;
    public createdAt: string;
    public updatedAt: string;
    public isDeleted: boolean;

    public constructor(init?: Partial<SavePromotion>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SavePromotion'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetPromotionsResponse(); }
}

export class CreateDefaultProduct implements IReturn<GetProductsResponse>, ICreateDefaultProductRequest, IEntityLocalizeRequest, ISiteReferenceRequest
{
    public siteId: number;
    public generalProductWidget: ProductWidget;
    public actionsProductWidget: ProductWidget;
    public languageCode: string;

    public constructor(init?: Partial<CreateDefaultProduct>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CreateDefaultProduct'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetProductsResponse(); }
}

export class CreateDefaultProducts implements IEntityLocalizeRequest, ISiteReferenceRequest
{
    public siteId: number;
    public languageCode: string;

    public constructor(init?: Partial<CreateDefaultProducts>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CreateDefaultProducts'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

export class SaveProductLcz implements IReturnVoid, IEntitySetLczRequest
{
    public id: number;
    public languageCode: string;
    public columnName: string;
    public key: string;
    public value: string;
    public isUserChange: boolean;

    public constructor(init?: Partial<SaveProductLcz>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SaveProductLcz'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

export class GetProductLczConfig extends BaseGetEntityLczConfig implements IReturn<QueryResponse<GetProductLczConfigResponse>>, IDisplayEntityRequest
{
    public isOnlyDefault: boolean;
    public name: string;

    public constructor(init?: Partial<GetProductLczConfig>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'GetProductLczConfig'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QueryResponse<GetProductLczConfigResponse>(); }
}

export class TranslateProduct extends BaseTranslateRecordRequest implements IReturn<BaseResponse>
{

    public constructor(init?: Partial<TranslateProduct>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'TranslateProduct'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

export class BuildProductLocalizeConfig extends BaseBuildRecordLocalizeConfigRequest implements IReturn<BuildRecordLocalizeConfigResponse<BuildRecordLocalizeConfigItem>>
{

    public constructor(init?: Partial<BuildProductLocalizeConfig>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'BuildProductLocalizeConfig'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BuildRecordLocalizeConfigResponse<BuildRecordLocalizeConfigItem>(); }
}

export class LocalizeProductRecord implements IReturn<LocalizeRecordResponse>, ILocalizeRecordRequest
{
    public languageCode: string;
    public recordId: number;
    public columnKeys: string[];
    public valueKeys: string[];

    public constructor(init?: Partial<LocalizeProductRecord>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'LocalizeProductRecord'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new LocalizeRecordResponse(); }
}

export class ExportProductLczReport implements IReturnVoid, IExportLczReport
{
    public id?: number;
    public ids: number[];

    public constructor(init?: Partial<ExportProductLczReport>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ExportProductLczReport'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

export class AddOptionsGroup implements IReturn<OptionsGroupOperationResponse>
{
    public group: ProductOptionsGroup;
    public defaultOptionKey: string;
    public productId: number;

    public constructor(init?: Partial<AddOptionsGroup>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AddOptionsGroup'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new OptionsGroupOperationResponse(); }
}

export class UpdateOptionsGroup
{
    public productId: number;
    public groupKey: string;
    public label: LocalizeValue;
    public isImageOptions: boolean;

    public constructor(init?: Partial<UpdateOptionsGroup>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdateOptionsGroup'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

export class SaveOptionsInGroup implements IReturn<OptionsGroupOperationResponse>
{
    public options: ProductOption[];
    public productId: number;
    public groupKey: string;

    public constructor(init?: Partial<SaveOptionsInGroup>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SaveOptionsInGroup'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new OptionsGroupOperationResponse(); }
}

export class RemoveOptionGroup implements IReturn<OptionsGroupOperationResponse>
{
    public productId: number;
    public groupKey: string;

    public constructor(init?: Partial<RemoveOptionGroup>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'RemoveOptionGroup'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new OptionsGroupOperationResponse(); }
}

export class RemoveOptionFromGroup implements IReturn<OptionsGroupOperationResponse>
{
    public productId: number;
    public groupKey: string;
    public optionKey: string;

    public constructor(init?: Partial<RemoveOptionFromGroup>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'RemoveOptionFromGroup'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new OptionsGroupOperationResponse(); }
}

// @Route("/products", "GET")
export class GetProducts extends QueryBase implements IReturn<QueryResponse<GetProductsResponse>>, IProductFilterOptions, IOnlyActiveEntityRequest, IPrimaryEntityRequest, IOwnerEntityRequest, ISiteLocalizeRequest, IPrimaryGreaterEntityRequest, IPrimaryListEntityRequest, ISearchEntityRequest
{
    public id?: number;
    public ids: number[];
    public idGreaterThan?: number;
    public excludedIds: number[];
    public collectionId?: number;
    public promotionId?: number;
    public isDefault?: boolean;
    public includeAssignedToCurrentPromotionId?: number;
    public excludeAssignedToOtherPromotion?: boolean;
    public name: string;
    public nameHtml: string;
    public slug: string;
    public search: string;
    public advancedSearch: string;
    public filterBy: string;
    public publishStatus?: PublishStatusType;
    public includeDeleted: boolean;
    public siteId: number;
    public userAuthId?: number;
    public viewedCount?: number;
    public publishDateLessThan?: string;
    public minPrice?: number;
    public maxPrice?: number;
    public rating?: number;
    public tagLabels: string[];
    // @DataMember(Name="f")
    public productGroupsDic: { [index: string]: string[]; };

    public includeSku?: boolean;
    public skuCount: number;
    public includeOnlyPublished?: boolean;
    public isEnabledViwedCounter?: boolean;
    public excludeAssignedToPromotion?: boolean;
    // @Ignore()
    public sortType: GetProductsSortType;

    public languageCode: string;
    public siteLanguageCode: string;
    public isDefaultSortType: boolean;
    public isPriceSortType: boolean;
    public orderBy: string;

    public constructor(init?: Partial<GetProducts>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'GetProducts'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QueryResponse<GetProductsResponse>(); }
}

export class BulkUpdateProductsAssignedToPromotion implements IReturnVoid, ISiteReferenceRequest
{
    public promotionId: number;
    public promotionProductIds: number[];
    public siteId: number;

    public constructor(init?: Partial<BulkUpdateProductsAssignedToPromotion>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BulkUpdateProductsAssignedToPromotion'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

export class SaveProduct implements IReturn<GetProductsResponse>, IPrimaryEntity, IDeletedEntity, IAuditEntity, ISiteLocalizeRequest
{
    public siteId: number;
    public id: number;
    public isDefault?: boolean;
    public action?: OperationActionType;
    public updateOrderActionType?: UpdateItemOrderActionType;
    public includeSku?: boolean;
    public name: string;
    public nameHtml: string;
    public order?: number;
    public keywords: Tag[];
    public metaTitle: string;
    public metaDescription: string;
    public slug: string;
    public isCustomSlug?: boolean;
    public description: string;
    public mainImage: BaseWidgetImage;
    public isEnabledRollOverPicture: boolean;
    public rollOverPictureImage: BaseWidgetImage;
    public isEnabledOptionGroups?: boolean;
    public type?: ProductType;
    public optionGroups: ProductOptionsGroup[];
    public images: BaseWidgetImage[];
    public videoLinks: string[];
    public label: string;
    public isEnabledDimensions?: boolean;
    public height?: number;
    public width?: number;
    public weight?: number;
    public volume: string;
    public isAutomaticallyTrackInventory?: boolean;
    public inventoryStockType?: InventoryStockType;
    public availableQuantity?: number;
    public price?: number;
    public salePrice?: number;
    public isUsePrice?: boolean;
    public isUseSalePrice?: boolean;
    public isEnabledSku?: boolean;
    public sku: string;
    public skus: GetProductInventorySkuResponse[];
    public tags: Tag[];
    public viewedCount: number;
    public publishStatus?: PublishStatusType;
    public publishDate?: string;
    public createdAt: string;
    public updatedAt: string;
    public isDeleted: boolean;
    public pageType?: PageType;
    public pageTemplateType?: PageTemplateType;
    public promotionId?: number;
    public widgets: BaseWidget[];
    public promotionWidgetSettings: { [index: string]: EnabledSetting; };
    public shareActions: { [index: string]: EnabledSetting; };
    public rating?: number;
    public defaultInventorySkuId?: number;
    public languageCode: string;
    public siteLanguageCode: string;
    public seoSettings: PageSeo;

    public constructor(init?: Partial<SaveProduct>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SaveProduct'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetProductsResponse(); }
}

export class GetProductTags implements IReturn<Tag[]>, IProductFilterOptions, ISiteReferenceRequest
{
    public tagKeyLike?: number;
    public tagLabelLike: string;
    public siteId: number;
    public tagLabels: string[];
    public tagKeys: number[];
    // @DataMember(Name="f")
    public productGroupsDic: { [index: string]: string[]; };

    public collectionId?: number;

    public constructor(init?: Partial<GetProductTags>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetProductTags'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new Array<Tag>(); }
}

export class GetProductTagsWithCount implements IReturn<TagWithCount[]>, IProductFilterOptions, ISiteReferenceRequest
{
    public tagLabelLike: string;
    public siteId: number;
    public tagLabels: string[];
    // @DataMember(Name="f")
    public productGroupsDic: { [index: string]: string[]; };

    public collectionId?: number;

    public constructor(init?: Partial<GetProductTagsWithCount>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetProductTagsWithCount'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new Array<TagWithCount>(); }
}

export class GetProductGroupOptions implements IReturn<GetProductGroupOptionsResponse>, IProductFilterOptions, ISiteReferenceRequest
{
    public siteId: number;
    public groupLabelLike: string;
    public optionLabelLike: string;
    public tagLabels: string[];
    public minPrice?: number;
    public maxPrice?: number;
    public rating?: number;
    public includeOnlyPublished?: boolean;
    // @DataMember(Name="f")
    public productGroupsDic: { [index: string]: string[]; };

    public collectionId?: number;

    public constructor(init?: Partial<GetProductGroupOptions>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetProductGroupOptions'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetProductGroupOptionsResponse(); }
}

export class GetProductsPublishStatusCounts implements IReturn<GetPagePublishStatusCountsResponse>, ISiteReferenceRequest
{
    public siteId: number;

    public constructor(init?: Partial<GetProductsPublishStatusCounts>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetProductsPublishStatusCounts'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetPagePublishStatusCountsResponse(); }
}

export class ProductMoveDefaultSortOrder implements ISiteReferenceRequest
{
    public collectionId: number;
    public productId: number;
    public type: UpdateItemOrderActionType;
    public siteId: number;

    public constructor(init?: Partial<ProductMoveDefaultSortOrder>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProductMoveDefaultSortOrder'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

export class PayPalStoreWebHook extends BasePayPalWebHook implements IReturn<BaseResponse>
{

    public constructor(init?: Partial<PayPalStoreWebHook>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'PayPalStoreWebHook'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

export class PayPalWebHook extends BasePayPalWebHook implements IReturn<BaseResponse>
{

    public constructor(init?: Partial<PayPalWebHook>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'PayPalWebHook'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

export class StripeWebHook implements IReturnVoid
{
    public body: string;

    public constructor(init?: Partial<StripeWebHook>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'StripeWebHook'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

export class OrderRefund implements IReturnVoid, IPrimaryEntity, ISiteReferenceRequest
{
    public id: number;
    public type: OrderRefundType;
    public orderItems: { [index: string]: string[]; };
    public isNotificationCustomer: boolean;
    public isUpdateInventory: boolean;
    public siteId: number;

    public constructor(init?: Partial<OrderRefund>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'OrderRefund'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

// @Route("/orders-report", "GET")
export class GetOrdersReport implements IReturn<GetOrdersReportResponse>
{
    public take?: number;
    public skip?: number;
    public reportType: ReportType;
    public groupBy: ReportColumn[];
    public select: ReportColumn[];
    public in: { [index: string]: number[]; };
    public strIn: { [index: string]: string[]; };
    public decimalRange: { [index: string]: ReporDecimalRangeFilter; };
    public dateRange: { [index: string]: ReporDateRangeFilter; };
    public like: { [index: string]: string; };
    public orderBy: number[];
    public siteId: number;

    public constructor(init?: Partial<GetOrdersReport>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetOrdersReport'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetOrdersReportResponse(); }
}

// @Route("/orders", "GET")
export class GetOrders extends QueryBase implements IReturn<QueryResponse<GetOrdersResponse>>, ISiteReferenceEntity, IPrimaryEntityRequest, IPrimaryListEntityRequest, ISearchEntityRequest
{
    public siteId: number;
    public id?: number;
    public ids: number[];
    public customerId?: number;
    public search: string;
    public startDate?: string;
    public endDate?: string;
    public paymentStatus?: PaymentStatusType;
    public shipmentStatus?: ShipmentStatusType;
    public paymentMethod?: PaymentMethodType;
    public cardNumberLast4: string;
    public stripePaymentIntentId: string;
    public payPalOrderId: string;
    public productId?: number;
    public payPalEmail: string;
    public billingFirstName: string;
    public billingLastName: string;
    public billingEmail: string;
    public billingAddress: string;
    public billingCity: string;
    public billingZip: string;
    public billingCountryCode: string;
    public phone: string;
    public companyName: string;
    public total?: number;

    public constructor(init?: Partial<GetOrders>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'GetOrders'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QueryResponse<GetOrdersResponse>(); }
}

export class GetOrder implements IReturn<GetOrderResponse>, ISiteReferenceRequest
{
    public id: number;
    public siteId: number;

    public constructor(init?: Partial<GetOrder>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetOrder'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetOrderResponse(); }
}

export class CancelPayment implements IReturn<BaseSaveResponse>, ISiteReferenceRequest
{
    public stripePaymentIntentId: string;
    public payPalOrderId: string;
    public siteId: number;

    public constructor(init?: Partial<CancelPayment>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CancelPayment'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseSaveResponse(); }
}

// @Route("/orders")
export class SaveOrder implements IReturn<GetOrdersResponse>, IPrimaryEntity, IAuditEntity, ISiteClientEntity
{
    public id: number;
    public createdAt: string;
    public updatedAt: string;
    public siteId: number;
    public clientId: number;
    public action?: SaveOrderActionType;
    public paymentMethod?: PaymentMethodType;
    public orderReceivingMethodType?: StoreCheckoutOrderReceivingMethodType;
    public paymentStatus?: PaymentStatusType;
    public shipmentStatus?: ShipmentStatusType;
    public billingTotalPrice: number;
    public billingTotalTaxesAmount: number;
    public billingTotalPriceWithTaxes: number;
    public orderItems: OrderItem[];
    public cartItemInputs: CartItemInput[];
    public billingFirstName: string;
    public isCouponCode?: boolean;
    public couponCode: string;
    public billingLastName: string;
    public billingFullName: string;
    public billingEmail: string;
    public billingAddress: string;
    public billingCity: string;
    public billingZip: string;
    public billingCountryCode: string;
    public billingStateCode: string;
    public billingStateId?: number;
    public phone: string;
    public isCorporatePurchase?: boolean;
    public companyName: string;
    public vat: string;
    public isEqualBillingToShipping?: boolean;
    public shippingAddress: string;
    public shippingCity: string;
    public shippingZip: string;
    public shippingCountryCode: string;
    public shippingStateCode: string;
    public shippingStateId?: number;
    public isAgree?: boolean;
    public isSubscribed?: boolean;
    public message: string;
    public notes: OrderNote[];
    public customFields: CustomField[];
    public isTestMode: boolean;
    public vatId: string;
    public isReturned?: boolean;
    public returnReasonType?: OrderReturnReasonType;
    public ip: string;
    public countryCodeByIp: string;
    public userAgent: string;
    public payPalEmail: string;
    public payPalOrderId: string;
    public payPalApproveUrl: string;
    public stripeCustomerId: string;
    public stripePaymentIntentId: string;
    public stripeClientSecret: string;
    public customerId?: number;
    public cardToken: string;
    public trafficSourceUrl: string;
    public trafficSourceDomain: string;

    public constructor(init?: Partial<SaveOrder>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SaveOrder'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetOrdersResponse(); }
}

// @Route("/verify-stripe-payment", "POST")
export class VerifyStripeOrderPayment implements IReturn<GetOrderResponse>
{
    public clientSecret: string;
    public paymentIntentJson: string;

    public constructor(init?: Partial<VerifyStripeOrderPayment>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'VerifyStripeOrderPayment'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetOrderResponse(); }
}

// @Route("/verify-paypal-payment", "POST")
export class VerifyPayPalOrderPayment implements IReturn<GetOrderResponse>
{
    public token: string;
    public payerId: string;

    public constructor(init?: Partial<VerifyPayPalOrderPayment>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'VerifyPayPalOrderPayment'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetOrderResponse(); }
}

export class VerifyManualPayment implements IReturn<GetOrderResponse>
{
    public orderId: number;

    public constructor(init?: Partial<VerifyManualPayment>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'VerifyManualPayment'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetOrderResponse(); }
}

export class GetOrderInvoice implements IReturn<GetOrderInvoiceResponse>, ISiteReferenceRequest
{
    public id: number;
    public siteId: number;

    public constructor(init?: Partial<GetOrderInvoice>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetOrderInvoice'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetOrderInvoiceResponse(); }
}

export class UpdateOrderShipmentStatus implements IReturnVoid, IPrimaryEntity, ISiteReferenceEntity
{
    public id: number;
    public shipmentStatus: ShipmentStatusType;
    public siteId: number;

    public constructor(init?: Partial<UpdateOrderShipmentStatus>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdateOrderShipmentStatus'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

export class UpdateOrderPaymentStatus implements IReturnVoid, IPrimaryEntity, ISiteReferenceEntity
{
    public id: number;
    public paymentStatus: PaymentStatusType;
    public siteId: number;

    public constructor(init?: Partial<UpdateOrderPaymentStatus>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdateOrderPaymentStatus'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

// @ValidateRequest(Validator="SecureSiteService")
export class SendCustomerPurchaseConfirmationEmail implements IReturnVoid, ISiteReferenceRequest, IEntityLocalizeRequest
{
    public orderId: number;
    public siteId: number;
    public languageCode: string;

    public constructor(init?: Partial<SendCustomerPurchaseConfirmationEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendCustomerPurchaseConfirmationEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

// @ValidateRequest(Validator="SecureSiteService")
export class SendSellerPurchaseConfirmation implements IReturnVoid, ISiteReferenceRequest, IEntityLocalizeRequest
{
    public orderId: number;
    public siteId: number;
    public languageCode: string;

    public constructor(init?: Partial<SendSellerPurchaseConfirmation>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendSellerPurchaseConfirmation'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

// @ValidateRequest(Validator="SecureSiteService")
export class SendSellerManualPaymentPurchaseConfirmationEmail implements IReturnVoid, ISiteReferenceRequest, IEntityLocalizeRequest
{
    public orderId: number;
    public siteId: number;
    public languageCode: string;

    public constructor(init?: Partial<SendSellerManualPaymentPurchaseConfirmationEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendSellerManualPaymentPurchaseConfirmationEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

// @ValidateRequest(Validator="SecureSiteService")
export class SendCustomerManualPaymentPurchaseConfirmationEmail implements IReturnVoid, ISiteReferenceRequest, IEntityLocalizeRequest
{
    public orderId: number;
    public siteId: number;
    public languageCode: string;

    public constructor(init?: Partial<SendCustomerManualPaymentPurchaseConfirmationEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendCustomerManualPaymentPurchaseConfirmationEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

// @ValidateRequest(Validator="SecureSiteService")
export class SendCustomerOrderShipmentDeliveredEmail implements IReturnVoid, ISiteReferenceRequest, IEntityLocalizeRequest
{
    public orderId: number;
    public siteId: number;
    public languageCode: string;

    public constructor(init?: Partial<SendCustomerOrderShipmentDeliveredEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendCustomerOrderShipmentDeliveredEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

// @ValidateRequest(Validator="SecureSiteService")
export class SendCustomerOrderShipmentInTransitEmail implements IReturnVoid, ISiteReferenceRequest, IEntityLocalizeRequest
{
    public orderId: number;
    public siteId: number;
    public languageCode: string;

    public constructor(init?: Partial<SendCustomerOrderShipmentInTransitEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendCustomerOrderShipmentInTransitEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

// @ValidateRequest(Validator="SecureSiteService")
export class SendCustomerOrderShipmentReturnedEmail implements IReturnVoid, ISiteReferenceRequest, IEntityLocalizeRequest
{
    public orderId: number;
    public siteId: number;
    public languageCode: string;

    public constructor(init?: Partial<SendCustomerOrderShipmentReturnedEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendCustomerOrderShipmentReturnedEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

// @ValidateRequest(Validator="SecureSiteService")
export class SendSellerOrderShipmentInTransitEmail implements IReturnVoid, ISiteReferenceRequest, IEntityLocalizeRequest
{
    public orderId: number;
    public siteId: number;
    public languageCode: string;

    public constructor(init?: Partial<SendSellerOrderShipmentInTransitEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendSellerOrderShipmentInTransitEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

// @ValidateRequest(Validator="SecureSiteService")
export class SendSellerOrderShipmentDeliveredEmail implements IReturnVoid, ISiteReferenceRequest, IEntityLocalizeRequest
{
    public orderId: number;
    public siteId: number;
    public languageCode: string;

    public constructor(init?: Partial<SendSellerOrderShipmentDeliveredEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendSellerOrderShipmentDeliveredEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

// @ValidateRequest(Validator="SecureSiteService")
export class SendSellerOrderShipmentReturnedEmail implements IReturnVoid, ISiteReferenceRequest, IEntityLocalizeRequest
{
    public orderId: number;
    public siteId: number;
    public languageCode: string;

    public constructor(init?: Partial<SendSellerOrderShipmentReturnedEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendSellerOrderShipmentReturnedEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

// @ValidateRequest(Validator="SecureSiteService")
export class SendCustomerRefundNotificationEmail implements IReturnVoid, ISiteReferenceRequest, IEntityLocalizeRequest
{
    public orderId: number;
    public siteId: number;
    public type: OrderRefundType;
    public typeStr: string;
    public languageCode: string;

    public constructor(init?: Partial<SendCustomerRefundNotificationEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendCustomerRefundNotificationEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

// @ValidateRequest(Validator="SecureSiteService")
export class SendCustomerPayPalCancelEmail implements IReturnVoid, ISiteReferenceRequest, IEntityLocalizeRequest
{
    public orderId: number;
    public siteId: number;
    public languageCode: string;

    public constructor(init?: Partial<SendCustomerPayPalCancelEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendCustomerPayPalCancelEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

// @ValidateRequest(Validator="SecureSiteService")
export class SendOwnerPayPalCancelEmail implements IReturnVoid, ISiteReferenceRequest, IEntityLocalizeRequest
{
    public orderId: number;
    public siteId: number;
    public languageCode: string;

    public constructor(init?: Partial<SendOwnerPayPalCancelEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendOwnerPayPalCancelEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

// @ValidateRequest(Validator="SecureSiteService")
export class SendSellerRefundNotificationEmail implements IReturnVoid, ISiteReferenceRequest, IEntityLocalizeRequest
{
    public orderId: number;
    public siteId: number;
    public type: OrderRefundType;
    public typeStr: string;
    public amount?: number;
    public languageCode: string;

    public constructor(init?: Partial<SendSellerRefundNotificationEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendSellerRefundNotificationEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

// @ValidateRequest(Validator="SecureSiteService")
export class SentEmptyInventoryStockEmail implements IReturnVoid, ISiteReferenceRequest, IEntityLocalizeRequest
{
    public skuId: number;
    public siteId: number;
    public languageCode: string;

    public constructor(init?: Partial<SentEmptyInventoryStockEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SentEmptyInventoryStockEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

// @ValidateRequest(Validator="SecureSiteService")
export class SentLowInventoryStockEmail implements IReturnVoid, ISiteReferenceRequest, IEntityLocalizeRequest
{
    public skuId: number;
    public siteId: number;
    public languageCode: string;

    public constructor(init?: Partial<SentLowInventoryStockEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SentLowInventoryStockEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

export class GetDiscounts extends QueryBase implements IReturn<QueryResponse<GetDiscountsResponse>>, IPrimaryEntityRequest, IPrimaryListEntityRequest, ISiteReferenceRequest, ISearchEntityRequest
{
    public id?: number;
    public ids: number[];
    public siteId: number;
    public search: string;
    public couponCode: string;
    public activeFrom?: string;
    public activeTo?: string;
    public status?: DiscountStatus;

    public constructor(init?: Partial<GetDiscounts>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'GetDiscounts'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QueryResponse<GetDiscountsResponse>(); }
}

export class SaveDiscount implements IReturn<GetDiscountsResponse>, IPrimaryEntity, IAuditEntity, ISiteReferenceEntity, IDeletedEntity, IActionRequest
{
    public id: number;
    public name: string;
    public couponCode: string;
    public discountAmount: number;
    public discountType: DiscountType;
    public status: DiscountStatus;
    public isEnabledQuantityOfCoupons: boolean;
    public isEnabledActivationStartDate: boolean;
    public isEnabledActivationFinishDate: boolean;
    public activeFrom: string;
    public activeTo?: string;
    public usageLimit?: number;
    public oneUsagePerCustomer: boolean;
    public assignType: DiscountAssignType;
    public applyType: DiscountProductApplyType;
    public isDeleted: boolean;
    public createdAt: string;
    public updatedAt: string;
    public siteId: number;
    public productIds: number[];
    public collectionIds: number[];
    public skuIds: number[];
    public action: OperationActionType;

    public constructor(init?: Partial<SaveDiscount>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SaveDiscount'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetDiscountsResponse(); }
}

export class GetCouponsPublishStatusCounts implements IReturn<GetPagePublishStatusCountsResponse>, ISiteReferenceRequest
{
    public siteId: number;

    public constructor(init?: Partial<GetCouponsPublishStatusCounts>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetCouponsPublishStatusCounts'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetPagePublishStatusCountsResponse(); }
}

// @Route("/customers", "GET")
export class GetCustomers extends QueryBase implements IReturn<QueryResponse<GetCustomersResponse>>, IPrimaryEntityRequest, ISiteReferenceRequest, ISearchEntityRequest
{
    public id?: number;
    public customerBillingEmail: string;
    public siteId: number;
    public search: string;
    public includeTotalAmountSpent?: boolean;
    public startDate?: string;
    public endDate?: string;

    public constructor(init?: Partial<GetCustomers>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'GetCustomers'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QueryResponse<GetCustomersResponse>(); }
}

// @Route("/customers", "PATCH")
export class SaveCustomer implements IReturn<GetCustomersResponse>, CustomerDbSavableFields, IPrimaryEntity, IAuditEntity, ISiteReferenceEntity
{
    public id: number;
    public action?: UpdateCustomerActionType;
    public billingFirstName: string;
    public billingLastName: string;
    public billingEmail: string;
    public billingAddress: string;
    public billingCity: string;
    public billingZip: string;
    public billingCountryCode: string;
    public billingStateCode: string;
    public billingStateId?: number;
    public isEqualBillingToShipping?: boolean;
    public shippingAddress: string;
    public shippingCity: string;
    public shippingZip: string;
    public shippingCountryCode: string;
    public shippingStateCode: string;
    public shippingStateId?: number;
    public phone: string;
    public isCorporatePurchase?: boolean;
    public companyName: string;
    public vatId: string;
    public loginEmail: string;
    public notes: CustomerNote[];
    public createdAt: string;
    public updatedAt: string;
    public siteId: number;
    public stripeId: string;
    public cardToken: string;

    public constructor(init?: Partial<SaveCustomer>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SaveCustomer'; }
    public getMethod() { return 'PATCH'; }
    public createResponse() { return new GetCustomersResponse(); }
}

// @Route("/collections", "GET")
export class GetCollections extends QueryBase implements IReturn<QueryResponse<GetCollectionsResponse>>, IPrimaryEntityRequest, IPrimaryListEntityRequest, IDeletedEntityRequest, IOwnerEntityRequest, ISiteLocalizeRequest, ISearchEntityRequest
{
    public id?: number;
    public ids: number[];
    public name: string;
    public slug: string;
    public search: string;
    public isDeleted?: boolean;
    public siteId: number;
    public userAuthId?: number;
    public publishStatus?: PublishStatusType;
    public publishDateLessThan?: string;
    public includeAllProducts?: boolean;
    public includeOnlyPublished?: boolean;
    public includeSidebarContent?: boolean;
    public tagLabels: string[];
    public minPrice?: number;
    public maxPrice?: number;
    public rating?: number;
    public useProductCount: boolean;
    // @DataMember(Name="f")
    public filterProductGroupsDic: { [index: string]: string[]; };

    public languageCode: string;
    public siteLanguageCode: string;

    public constructor(init?: Partial<GetCollections>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'GetCollections'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QueryResponse<GetCollectionsResponse>(); }
}

export class SaveCollection implements IReturn<GetCollectionsResponse>, IPrimaryEntity, ISiteLocalizeRequest, IActionRequest
{
    public id: number;
    // @Validate(Validator="MaximumLength(255)")
    public name: string;

    public action: OperationActionType;
    public keywords: Tag[];
    public metaTitle: string;
    public metaDescription: string;
    public slug: string;
    public isCustomSlug?: boolean;
    public sortType: SortType;
    public image: BaseWidgetImage;
    public usePresetsOfCollections?: boolean;
    public publishStatus?: PublishStatusType;
    public publishDate?: string;
    public type: CollectionType;
    public includeAllProducts: boolean;
    public productIds: number[];
    public byOptionsOrTagsProductIds: number[];
    public siteId: number;
    public widgetSettings: CollectionWidgetSettings;
    public widgets: BaseWidget[];
    public productTagLabels: string[];
    public productGroupsDic: { [index: string]: string[]; };
    public includeSidebarContent?: boolean;
    public languageCode: string;
    public siteLanguageCode: string;
    public seoSettings: PageSeo;

    public constructor(init?: Partial<SaveCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SaveCollection'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetCollectionsResponse(); }
}

export class GetOrderRefundCart implements IReturn<GetOrderRefundCartResponse>, ISiteReferenceRequest
{
    public orderId: number;
    public siteId: number;
    public refundOrderItemIds: { [index: string]: string[]; };
    public isRefundShipping: boolean;

    public constructor(init?: Partial<GetOrderRefundCart>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetOrderRefundCart'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetOrderRefundCartResponse(); }
}

export class GetCart implements IReturn<GetCartResponse>, ISiteReferenceEntity
{
    public skus: GetCartSkuItem[];
    public bundles: GetCartBundleItem[];
    public billingCountryCode: string;
    public billingStateCode: string;
    public shippingCountryCode: string;
    public shippingStateCode: string;
    public couponCode: string;
    public siteId: number;

    public constructor(init?: Partial<GetCart>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetCart'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetCartResponse(); }
}

export class GetBundles extends QueryBase implements IReturn<QueryResponse<GetBundlesResponse>>, IPrimaryEntityRequest, IPrimaryListEntityRequest, ISiteReferenceRequest, ISearchEntityRequest
{
    public id?: number;
    public ids: number[];
    public productId?: number;
    public search: string;
    public siteId: number;
    public isIncludeApplyProducts: boolean;
    public applyProductFields: string;
    public isIncludeSkus: boolean;
    public includeSkusFields: string;

    public constructor(init?: Partial<GetBundles>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'GetBundles'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QueryResponse<GetBundlesResponse>(); }
}

export class SaveBundle implements IReturn<GetBundlesResponse>, IPrimaryEntity, ISiteReferenceEntity, IActionRequest
{
    public id: number;
    // @Validate(Validator="MaximumLength(255)")
    public name: string;

    public action: OperationActionType;
    public siteId: number;
    public priceType: BundlePriceType;
    public finalPrice?: number;
    public percentageDiscount?: number;
    public items: BundleItem[];
    public applyProductIds: number[];
    public applyProductWidget: BaseWidget;

    public constructor(init?: Partial<SaveBundle>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SaveBundle'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetBundlesResponse(); }
}

export class GetBundleProducts implements IReturn<QueryResponse<GetBundleProductResponse>>, ISiteReferenceRequest
{
    public bundleId?: number;
    public bundleIds: number[];
    public siteId: number;

    public constructor(init?: Partial<GetBundleProducts>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetBundleProducts'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new QueryResponse<GetBundleProductResponse>(); }
}

export class CmsSharedOperations implements IReturn<CmsSharedOperationsResponse>
{

    public constructor(init?: Partial<CmsSharedOperations>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CmsSharedOperations'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CmsSharedOperationsResponse(); }
}

export class GetWizardProgresses extends QueryBase implements IReturn<QueryResponse<GetWizardProgressResponse>>, IPrimaryEntityRequest, IPrimaryListEntityRequest, IPrimaryGreaterEntityRequest
{
    public id?: number;
    public ids: number[];
    public idGreaterThan?: number;
    public createdAtLessThan?: string;
    public createdAtGreaterThan?: string;
    public updatedAtLessThan?: string;
    public updatedAtGreaterThan?: string;
    public siteIdIsNull?: boolean;
    public siteId?: number;
    public userAuthId?: number;
    public lastStepType?: SignupWizardStepType;
    public landingEmail: string;
    public categoryId?: number;
    public categoryName: string;
    public serviceName: string;
    public sentenceName: string;
    public locationType?: WizardLocationType;
    public businessName: string;
    public businessUniquenessName: string;
    public contactInfoType?: ContactType;
    public themeOptionId?: number;
    public themeColorId?: number;
    public themeFontId?: number;

    public constructor(init?: Partial<GetWizardProgresses>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'GetWizardProgresses'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QueryResponse<GetWizardProgressResponse>(); }
}

export class SaveWizardProgress implements IReturn<BaseSaveResponse>, IAnalyticsTracking, IPrimaryEntity, IOwnerEntity
{
    public id: number;
    public utmSource: string;
    public utmMedium: string;
    public utmCampaign: string;
    public utmTerm: string;
    public utmContent: string;
    public lastStepType: SignupWizardStepType;
    public landingData: WizardLandingData;
    public businessCategoryData: WizardBusinessCategoryData;
    public servicesData: WizardServicesData;
    public sentencesData: WizardSentencesData;
    public locationData: WizardLocationData;
    public businessNameData: WizardBusinessNameData;
    public businessUniquenessData: WizardBusinessUniquenessData;
    public contactInfoData: WizardContactInfoData;
    public themeSelectionData: WizardThemeSelectionData;
    public userAuthId?: number;

    public constructor(init?: Partial<SaveWizardProgress>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SaveWizardProgress'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseSaveResponse(); }
}

export class GetUpdateSiteStatus implements IReturn<GetUpdateSiteStatusResponse>, ISiteReferenceRequest
{
    public siteId: number;

    public constructor(init?: Partial<GetUpdateSiteStatus>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetUpdateSiteStatus'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetUpdateSiteStatusResponse(); }
}

export class GetUpgradeAccountPaymentUrl implements IReturn<GetUpgradeAccountPaymentUrlResponse>, ISiteReferenceRequest
{
    public siteId: number;
    public offerType: SubscriptionPlanDiscountOfferType;
    public subscriptionPeriodType: HocoosSubscriptionPlanPeriod;
    public upgradeType: UpgradeModalTypes;

    public constructor(init?: Partial<GetUpgradeAccountPaymentUrl>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetUpgradeAccountPaymentUrl'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetUpgradeAccountPaymentUrlResponse(); }
}

export class GetSiteSubscriptionInfo implements IReturn<GetSiteSubscriptionInfoResponse>, ISiteReferenceRequest
{
    public siteId: number;

    public constructor(init?: Partial<GetSiteSubscriptionInfo>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSiteSubscriptionInfo'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetSiteSubscriptionInfoResponse(); }
}

export class GetDisplaySiteSubscriptionOrderStatus implements IReturn<GetDisplaySiteSubscriptionOrderStatusResponse>, ISiteReferenceRequest
{
    public siteId: number;

    public constructor(init?: Partial<GetDisplaySiteSubscriptionOrderStatus>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetDisplaySiteSubscriptionOrderStatus'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetDisplaySiteSubscriptionOrderStatusResponse(); }
}

export class SwitchSiteSubscriptionPlan implements IReturnVoid, ISiteReferenceEntity
{
    public siteId: number;
    public newPlan: HocoosSubscriptionPlanPeriod;

    public constructor(init?: Partial<SwitchSiteSubscriptionPlan>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SwitchSiteSubscriptionPlan'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

export class GetSiteSubscriptionCancelProgresses extends QueryBase implements IReturn<QueryResponse<GetSubscriptionCancelResponse>>, IPrimaryEntityRequest, IPrimaryListEntityRequest, ISiteReferenceRequest
{
    public id?: number;
    public ids: number[];
    public siteId: number;

    public constructor(init?: Partial<GetSiteSubscriptionCancelProgresses>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSiteSubscriptionCancelProgresses'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QueryResponse<GetSubscriptionCancelResponse>(); }
}

export class StartSiteSubscriptionCancelProgress implements IReturnVoid, ISiteReferenceRequest
{
    public siteId: number;

    public constructor(init?: Partial<StartSiteSubscriptionCancelProgress>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'StartSiteSubscriptionCancelProgress'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

export class SubmitSiteSubscriptionCancelProgress implements IReturn<BaseSaveResponse>, ISiteReferenceEntity
{
    public siteId: number;
    public reason: ReasonStep;
    public detail: CancelReasonDetailStep;
    public rating: CancelRatingStep;
    public finish: CancelFinishStep;

    public constructor(init?: Partial<SubmitSiteSubscriptionCancelProgress>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SubmitSiteSubscriptionCancelProgress'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseSaveResponse(); }
}

export class GetSiteServiceDescriptions implements IReturn<GetSiteServiceDescriptionsResponse>, ISiteReferenceRequest
{
    public siteId: number;

    public constructor(init?: Partial<GetSiteServiceDescriptions>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSiteServiceDescriptions'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetSiteServiceDescriptionsResponse(); }
}

export class GetSiteCategory implements IReturn<GetSiteCategoryResponse>, ISiteLocalizeRequest
{
    public siteId: number;
    public languageCode: string;
    public siteLanguageCode: string;

    public constructor(init?: Partial<GetSiteCategory>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSiteCategory'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetSiteCategoryResponse(); }
}

// @Route("/sites", "GET")
export class GetSites extends QueryBase implements IReturn<QueryResponse<GetSitesResponse>>, IPrimaryEntityRequest, IOwnerEntityRequest, IEntityLocalizeRequest, IPrimaryListEntityRequest, IPrimaryGreaterEntityRequest, IOnlyActiveEntityRequest, ISearchEntityRequest
{
    public id?: number;
    public idGreaterThan?: number;
    public domain: string;
    public userAuthId?: number;
    public languageCode: string;
    public search: string;
    public ids: number[];
    public idsNotIn: number[];
    public isDeleted?: boolean;
    public title: string;
    public utmSource: string;
    public utmMedium: string;
    public utmCampaign: string;
    public utmTerm: string;
    public utmContent: string;
    public createdAtLessThan?: string;
    public createdAtGreaterThan?: string;
    public suspendDateLessThan?: string;
    public suspendDateGreaterThan?: string;
    public subscriptionPlan?: HocoosSubscriptionPlan;
    public subscriptionPlans: HocoosSubscriptionPlan[];
    public premiumLastPeriod?: HocoosSubscriptionPlanPeriod;
    public subscriptionSuspendType?: SubscriptionPlanSuspendType;
    public suspendType?: SiteSuspendType;
    public initializationStatus?: SiteInitializationStatus;
    public thumbnail: string;
    public designPackId?: number;
    public contentPackId?: number;
    public designPackImageId?: number;
    public stucturePackId?: number;
    public structurePackBlueprintIndex?: number;
    public businessCategoryIdIsNotNull?: boolean;
    public businessCategoryId?: number;
    public customBusinessCategoryId?: number;
    public colorPaletteId?: number;
    public fontGroupId?: number;
    public colorMappingId?: number;
    public fontMappingId?: number;
    public boxMappingId?: number;
    public defaultLanguage?: LocalizableLanguage;
    public isPayPalEnabled?: boolean;
    public payPalClientId: string;
    public isStripeEnabled?: boolean;
    public stripeAccountId: string;
    public vimeoFolderUriIsNotNull?: boolean;
    public includeDeleted: boolean;

    public constructor(init?: Partial<GetSites>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSites'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QueryResponse<GetSitesResponse>(); }
}

export class GetAccountDefaultSite implements IReturn<GetSitesResponse>, IPrimaryEntityRequest, IEntityLocalizeRequest, ISiteReferenceRequest
{
    public id?: number;
    public languageCode: string;
    public siteId: number;
    public fields: string;

    public constructor(init?: Partial<GetAccountDefaultSite>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetAccountDefaultSite'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetSitesResponse(); }
}

// @Route("/sites/{Id}", "GET")
export class GetSite implements IReturn<GetBaseSiteResponse>, IPrimaryEntityRequest, IEntityLocalizeRequest, ISiteReferenceRequest
{
    public id?: number;
    public domain: string;
    public languageCode: string;
    public fields: string;
    public siteId: number;

    public constructor(init?: Partial<GetSite>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSite'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetBaseSiteResponse(); }
}

export class SaveSite implements IReturn<BaseSaveResponse>, IPrimaryEntity, IAnalyticsTracking, IEntityLocalizeRequest
{
    public id: number;
    public title: string;
    public utmSource: string;
    public utmMedium: string;
    public utmCampaign: string;
    public utmTerm: string;
    public utmContent: string;
    public isDevMode: boolean;
    public action?: UpdateSiteActionType;
    public homePageSettings: HomePageSetting;
    public layoutType?: LayoutType;
    public menuType?: MenuType;
    public alignMenuType?: HeaderAlignType;
    public menuItems: MenuItem[];
    public headerSettings: SiteHeaderSettings;
    public headerLogo: Logo;
    public footerSettings: SiteFooterSettings;
    public footerMenuItems: MenuItem[];
    public footerLogo: Logo;
    public footerCopyright: string;
    public designPackId?: number;
    public contentPackId?: number;
    public designPackImageId?: number;
    public stucturePackId?: number;
    public businessCategoryId?: number;
    public colorPaletteId?: number;
    public fontGroupId?: number;
    public colorMappingId?: number;
    public fontMappingId?: number;
    public boxMappingId?: number;
    public structurePackBlueprintIndex?: number;
    public styles: ThemeStyles;
    public fonts: ThemeFont[];
    public layoutSettings: LayoutSettings;
    public isEnabledPageAnimation?: boolean;
    public widgetContainerType?: WidgetContainerType;
    public linkButtonLabelType?: LinkButtonLabelType;
    public mapStyleSetting: UiBoxMapStyleSetting;
    public favicons: string[];
    public favicon: string;
    public imageFilterSetting: ImageFilterSetting;
    public domain: string;
    public enabledApps: { [index: string]: EnabledElementSetting; };
    public storeAppSettings: StoreAppSetting;
    public bookingAppSettings: BookingAppSetting;
    public blogAppSettings: BlogAppSetting;
    public commentsAppSettings: CommentsAppSetting;
    public eventsAppSettings: EventsAppSetting;
    public subscribeFormId?: number;
    public wizardAnswersDict: { [index: string]: string[]; };
    public signupWizardGuid: string;
    public vimeoFolderUri: string;
    public marketingIntegrationSettings: { [index: string]: MarketingIntegrationSetting; };
    public isPayPalEnabled?: boolean;
    public paymentCountryCode: string;
    public paymentCurrencyCode: string;
    public widgetDisplayCurrencyCode: string;
    public isCatalogModeOnly?: boolean;
    public defaultLanguage?: LocalizableLanguage;
    public languageCode: string;
    public wizardSelectedServices: WizardSelectedService[];
    public widgetSliderSettings: BaseWidgetSliderSettings;
    public progressId?: number;

    public constructor(init?: Partial<SaveSite>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SaveSite'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseSaveResponse(); }
}

export class SiteRecordReferenceTracker implements IReturnVoid
{
    public id: number;
    public siteId: number;

    public constructor(init?: Partial<SiteRecordReferenceTracker>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SiteRecordReferenceTracker'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

export class SiteReferenceTracker implements IReturnVoid
{
    public siteId: number;
    public traceId: string;

    public constructor(init?: Partial<SiteReferenceTracker>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SiteReferenceTracker'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

export class GetPayProPayments extends QueryBase implements IReturn<QueryResponse<GetPayProPaymentResponse>>, ISiteReferenceEntity, IPrimaryEntityRequest, IPrimaryListEntityRequest
{
    public siteId: number;
    public id?: number;
    public orderId?: number;
    public ids: number[];
    public subscriptionIdIsNotNull?: boolean;
    public subscriptionIdNotEqualTo?: number;
    public productType?: PayProPaymentProductType;
    public ipnType?: PpgIpnTypeId;
    public ipnTypeIn: PpgIpnTypeId[];
    public tariffType?: PaymentProductTariffType;
    public orderStatus?: PpgOrderStatus;
    public subscriptionStatus?: PpgSubscriptionStatus;
    public offerType?: SubscriptionPlanDiscountOfferType;

    public constructor(init?: Partial<GetPayProPayments>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'GetPayProPayments'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QueryResponse<GetPayProPaymentResponse>(); }
}

export class SaveCallback implements IReturn<SaveCallbackResult>
{
    public from: string;

    public constructor(init?: Partial<SaveCallback>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SaveCallback'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SaveCallbackResult(); }
}

export class UpgradeStoragePaymentRequest implements IReturn<SiteReferenceChargeResponse>, ISiteReferenceRequest
{
    public siteId: number;
    public siteFileType: SiteFileType;

    public constructor(init?: Partial<UpgradeStoragePaymentRequest>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpgradeStoragePaymentRequest'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SiteReferenceChargeResponse(); }
}

export class GetSitePaymentsInfo implements IReturn<GetSitePaymentsInfoResponse>, ISiteReferenceRequest
{
    public siteId: number;

    public constructor(init?: Partial<GetSitePaymentsInfo>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSitePaymentsInfo'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetSitePaymentsInfoResponse(); }
}

export class ConnectPayPalAccount implements IReturn<ConnectPayPalAccountResponse>, ISiteReferenceRequest, IActionSourceRequest
{
    public siteId: number;
    public source: string;

    public constructor(init?: Partial<ConnectPayPalAccount>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConnectPayPalAccount'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ConnectPayPalAccountResponse(); }
}

export class DisconnectPayPalAccount implements IReturnVoid, ISiteReferenceEntity
{
    public siteId: number;

    public constructor(init?: Partial<DisconnectPayPalAccount>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DisconnectPayPalAccount'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

export class SavePayPalSettings implements IReturnVoid, ISiteReferenceRequest
{
    public siteId: number;
    public isEnabled: boolean;

    public constructor(init?: Partial<SavePayPalSettings>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SavePayPalSettings'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

export class GetPayPalClient implements IReturn<GetPayPalClientResponse>
{
    public clientId: string;
    public siteId?: number;

    public constructor(init?: Partial<GetPayPalClient>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetPayPalClient'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetPayPalClientResponse(); }
}

export class PayPalPartnerWebHook extends BasePayPalWebHook
{

    public constructor(init?: Partial<PayPalPartnerWebHook>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'PayPalPartnerWebHook'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

export class SaveStripeSettings implements IReturnVoid, ISiteReferenceRequest
{
    public siteId: number;
    public isEnabled: boolean;

    public constructor(init?: Partial<SaveStripeSettings>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SaveStripeSettings'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

// @Route("/create-stripe-account")
export class ConnectStripeAccount implements IReturn<CreateStripeAccountResponse>, ISiteReferenceRequest, IActionSourceRequest
{
    public siteId: number;
    public source: string;

    public constructor(init?: Partial<ConnectStripeAccount>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConnectStripeAccount'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CreateStripeAccountResponse(); }
}

export class DisconnectStripeAccount implements IReturnVoid, ISiteReferenceRequest
{
    public siteId: number;

    public constructor(init?: Partial<DisconnectStripeAccount>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DisconnectStripeAccount'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

export class SavePageLcz implements IReturnVoid, IEntitySetLczRequest
{
    public id: number;
    public languageCode: string;
    public columnName: string;
    public key: string;
    public value: string;
    public isUserChange: boolean;

    public constructor(init?: Partial<SavePageLcz>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SavePageLcz'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

export class GetPageLczConfig extends BaseGetEntityLczConfig implements IReturn<QueryResponse<GetPageLczConfigResponse>>
{

    public constructor(init?: Partial<GetPageLczConfig>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'GetPageLczConfig'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QueryResponse<GetPageLczConfigResponse>(); }
}

export class TranslatePage extends BaseTranslateRecordRequest implements IReturn<BaseResponse>
{

    public constructor(init?: Partial<TranslatePage>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'TranslatePage'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

export class BuildPageLocalizeConfig extends BaseBuildRecordLocalizeConfigRequest implements IReturn<BuildRecordLocalizeConfigResponse<BuildPageLocalizeConfigItem>>
{
    public isSkipDefaultValues: boolean;

    public constructor(init?: Partial<BuildPageLocalizeConfig>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'BuildPageLocalizeConfig'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BuildRecordLocalizeConfigResponse<BuildPageLocalizeConfigItem>(); }
}

export class LocalizePageRecord implements IReturn<LocalizeRecordResponse>, ILocalizeRecordRequest
{
    public languageCode: string;
    public recordId: number;
    public columnKeys: string[];
    public valueKeys: string[];

    public constructor(init?: Partial<LocalizePageRecord>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'LocalizePageRecord'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new LocalizeRecordResponse(); }
}

export class ExportPageLczReport implements IReturnVoid, IExportLczReport
{
    public id?: number;
    public ids: number[];

    public constructor(init?: Partial<ExportPageLczReport>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ExportPageLczReport'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

// @Route("/pages", "GET")
export class GetPages extends QueryBase implements IReturn<QueryResponse<GetPagesResponse>>, IPrimaryEntityRequest, IPrimaryListEntityRequest, ISiteLocalizeRequest, ISearchEntityRequest
{
    public id?: number;
    public ids: number[];
    public order?: number;
    public siteId: number;
    public pageType?: PageType;
    public nameContains: string;
    public slug: string;
    public search: string;
    public languageCode: string;
    // @Ignore()
    public siteLanguageCode: string;

    public constructor(init?: Partial<GetPages>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'GetPages'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QueryResponse<GetPagesResponse>(); }
}

export class GetPageInfo implements IReturn<GetPageInfoResponse>, IPrimaryEntityRequest, ISiteReferenceRequest
{
    public slug: string;
    public siteId: number;
    public id?: number;

    public constructor(init?: Partial<GetPageInfo>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetPageInfo'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetPageInfoResponse(); }
}

// @Route("/pages/{Id}", "GET")
export class GetPage implements IReturn<GetPagesResponse>, IPrimaryEntityRequest, ISiteLocalizeRequest
{
    public id?: number;
    public name: string;
    public slug: string;
    public siteId: number;
    public pageType?: PageType;
    public order?: number;
    public languageCode: string;
    public siteLanguageCode: string;
    public fields: string;

    public constructor(init?: Partial<GetPage>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetPage'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetPagesResponse(); }
}

export class SaveDefaultPages implements IReturn<QueryResponse<BaseSaveResponse>>, ISiteLocalizeRequest
{
    public savePages: SaveDefaultPageConfig[];
    public siteId: number;
    public languageCode: string;
    public siteLanguageCode: string;

    public constructor(init?: Partial<SaveDefaultPages>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SaveDefaultPages'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new QueryResponse<BaseSaveResponse>(); }
}

export class SavePage extends BaseSavePage implements IReturn<GetPagesResponse>, IPrimaryEntity, ISiteLocalizeRequest, IActionRequest, IActionSourceRequest
{
    public id: number;
    public action: OperationActionType;
    public order?: number;
    public name: string;
    public slug: string;
    public isCustomSlug?: boolean;
    public keywords: Tag[];
    public metaTitle: string;
    public metaDescription: string;
    public siteId: number;
    public seoSettings: PageSeo;
    public languageCode: string;
    // @Ignore()
    public siteLanguageCode: string;

    public source: string;

    public constructor(init?: Partial<SavePage>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'SavePage'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetPagesResponse(); }
}

export class PurchaseSitePolarisMail implements IReturn<GetPaymentPopupUrlResponse>, ISiteReferenceEntity
{
    public siteId: number;
    public domainId: number;
    public userName: string;
    public password: string;
    public fullName: string;

    public constructor(init?: Partial<PurchaseSitePolarisMail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PurchaseSitePolarisMail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetPaymentPopupUrlResponse(); }
}

export class GetSitePolarisMails extends QueryBase implements IReturn<QueryResponse<GetSitePolarisMailResponse>>, IPrimaryEntityRequest, IPrimaryListEntityRequest, ISiteReferenceRequest
{
    public id?: number;
    public ids: number[];
    public siteId: number;
    public userName: string;
    public subscriptionId?: number;

    public constructor(init?: Partial<GetSitePolarisMails>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSitePolarisMails'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QueryResponse<GetSitePolarisMailResponse>(); }
}

export class EditSitePolarisMail implements IReturn<BaseSaveResponse>, IPrimaryEntity, ISiteReferenceRequest
{
    public id: number;
    public siteId: number;
    public newPassword: string;
    public newFullName: string;

    public constructor(init?: Partial<EditSitePolarisMail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EditSitePolarisMail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseSaveResponse(); }
}

export class GetIsSiteHasFreeMailBox implements IReturn<GetIsSiteHasFreeMailBoxResponse>, ISiteReferenceRequest
{
    public siteId: number;

    public constructor(init?: Partial<GetIsSiteHasFreeMailBox>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetIsSiteHasFreeMailBox'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetIsSiteHasFreeMailBoxResponse(); }
}

export class DeactivateSitePolarisMail implements IReturnVoid, IPolarisMailRecordFilterRequest
{
    public polarisEmailId?: number;
    public subscriptionId?: number;
    public siteId: number;
    public isFinishSubscription: boolean;

    public constructor(init?: Partial<DeactivateSitePolarisMail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DeactivateSitePolarisMail'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

export class GetSiteLogos implements IReturn<GetSiteLogosResponse>, IPrimaryEntityRequest, IPrimaryListEntityRequest, ISiteReferenceRequest
{
    public id?: number;
    public ids: number[];
    public siteId: number;
    public urlEndsWith: string;
    public take?: number;

    public constructor(init?: Partial<GetSiteLogos>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSiteLogos'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetSiteLogosResponse(); }
}

export class GenerateSiteLogo implements IReturn<GenerateSiteLogoResponse>, ISiteReferenceRequest
{
    public siteId: number;
    public style: LogoStyle;

    public constructor(init?: Partial<GenerateSiteLogo>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GenerateSiteLogo'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GenerateSiteLogoResponse(); }
}

export class GenerateLogo implements IReturn<GenerateLogoResponse>
{
    public path: string;
    public businessName: string;
    public prompt: string;
    public style: LogoStyle;
    public siteId?: number;
    public quantity: number;

    public constructor(init?: Partial<GenerateLogo>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GenerateLogo'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GenerateLogoResponse(); }
}

export class SetSiteLogo implements IReturn<BaseResponse>, ISiteReferenceRequest
{
    public siteLogoId?: number;
    public siteId: number;
    public isAuto: boolean;

    public constructor(init?: Partial<SetSiteLogo>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SetSiteLogo'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

export class GetInstagramPost implements IReturn<GetInstagramPostResponse>, ISiteReferenceRequest
{
    public url: string;
    public siteId: number;

    public constructor(init?: Partial<GetInstagramPost>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetInstagramPost'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetInstagramPostResponse(); }
}

export class GetFavicons extends QueryBase implements IReturn<QueryResponse<GetFaviconResponse>>, IPrimaryEntityRequest, IPrimaryListEntityRequest, ISiteReferenceRequest
{
    public id?: number;
    public ids: number[];
    public siteId: number;

    public constructor(init?: Partial<GetFavicons>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'GetFavicons'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QueryResponse<GetFaviconResponse>(); }
}

export class SaveFavicon implements IReturn<BaseSaveResponse>, IPrimaryEntity, IDeletedEntity
{
    public id: number;
    public action?: OperationActionType;
    public name: string;
    public url: string;
    public siteId: number;
    public isDeleted: boolean;

    public constructor(init?: Partial<SaveFavicon>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SaveFavicon'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseSaveResponse(); }
}

export class CmsExportTypeOperation implements IReturnVoid
{
    public siteErrorStatusCode: SiteErrorStatusCode;

    public constructor(init?: Partial<CmsExportTypeOperation>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CmsExportTypeOperation'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

// @ValidateRequest(Validator="SecureSiteService")
export class SendGeneralContactFormRequestEmail implements IReturnVoid, ISiteReferenceRequest, IEntityLocalizeRequest
{
    public contactFormId: number;
    public siteId: number;
    public languageCode: string;

    public constructor(init?: Partial<SendGeneralContactFormRequestEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendGeneralContactFormRequestEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

// @ValidateRequest(Validator="SecureSiteService")
export class SendDomainVerificationEmail implements IReturnVoid, ISiteReferenceRequest, IEntityLocalizeRequest
{
    public domainId: number;
    public siteId: number;
    public languageCode: string;

    public constructor(init?: Partial<SendDomainVerificationEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendDomainVerificationEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

// @ValidateRequest(Validator="SecureSiteService")
export class SendSubscriptionCancelEmail implements IReturnVoid, ISiteReferenceRequest, IEntityLocalizeRequest
{
    public id: number;
    public siteId: number;
    public languageCode: string;

    public constructor(init?: Partial<SendSubscriptionCancelEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendSubscriptionCancelEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

// @ValidateRequest(Validator="SecureSiteService")
export class SendPurchaseDomainConfirmationEmail implements IReturnVoid, ISiteReferenceRequest, IEntityLocalizeRequest
{
    public domainId: number;
    public siteId: number;
    public languageCode: string;

    public constructor(init?: Partial<SendPurchaseDomainConfirmationEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendPurchaseDomainConfirmationEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

// @ValidateRequest(Validator="SecureSiteService")
export class SendMobileWizardSiteCreatedEmail implements IReturnVoid, ISiteReferenceRequest, IEntityLocalizeRequest
{
    public userId: number;
    public siteId: number;
    public languageCode: string;

    public constructor(init?: Partial<SendMobileWizardSiteCreatedEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendMobileWizardSiteCreatedEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

// @ValidateRequest(Validator="SecureSiteService")
export class SendPremiumUpgradeSubscriptionEmail implements IReturnVoid, ISiteReferenceRequest, IEntityLocalizeRequest
{
    public siteId: number;
    public languageCode: string;

    public constructor(init?: Partial<SendPremiumUpgradeSubscriptionEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendPremiumUpgradeSubscriptionEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

// @ValidateRequest(Validator="SecureSiteService")
export class SendPremiumCancelSubscriptionEmail implements IReturnVoid, ISiteReferenceRequest, IEntityLocalizeRequest
{
    public siteId: number;
    public languageCode: string;

    public constructor(init?: Partial<SendPremiumCancelSubscriptionEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendPremiumCancelSubscriptionEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

// @ValidateRequest(Validator="SecureSiteService")
export class SendNeedPremiumPaymentEmail implements IReturnVoid, ISiteReferenceRequest
{
    public payPalPaymentId: number;
    public siteId: number;

    public constructor(init?: Partial<SendNeedPremiumPaymentEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendNeedPremiumPaymentEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

// @ValidateRequest(Validator="SecureSiteService")
export class SendDomainRenewalEmail implements IReturnVoid, ISiteReferenceRequest, IEntityLocalizeRequest
{
    public domainId: number;
    public siteId: number;
    public offerType: DomainRenewalOfferType;
    public languageCode: string;

    public constructor(init?: Partial<SendDomainRenewalEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendDomainRenewalEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

// @ValidateRequest(Validator="SecureSiteService")
export class SendDomainRenewalConfirmationEmail implements IReturnVoid, ISiteReferenceRequest, IEntityLocalizeRequest
{
    public domainId: number;
    public siteId: number;
    public type: DomainRenewalType;
    public languageCode: string;

    public constructor(init?: Partial<SendDomainRenewalConfirmationEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendDomainRenewalConfirmationEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

// @ValidateRequest(Validator="SecureSiteService")
export class SendUnfinishedSiteCreationEmail implements IReturnVoid, ISiteReferenceRequest, IEntityLocalizeRequest
{
    public userAuthId: number;
    public siteId: number;
    public languageCode: string;

    public constructor(init?: Partial<SendUnfinishedSiteCreationEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendUnfinishedSiteCreationEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

// @ValidateRequest(Validator="SecureSiteService")
export class SendFailedAutoRenewDomainEmail implements IReturnVoid, ISiteReferenceRequest, IEntityLocalizeRequest
{
    public domainId: number;
    public siteId: number;
    public isHasFreeDomain: boolean;
    public renewalPrice: number;
    public languageCode: string;

    public constructor(init?: Partial<SendFailedAutoRenewDomainEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendFailedAutoRenewDomainEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

// @ValidateRequest(Validator="SecureSiteService")
export class SendAutoRenewDomainEmail implements IReturnVoid, ISiteReferenceRequest, IEntityLocalizeRequest
{
    public domainId: number;
    public siteId: number;
    public languageCode: string;
    public paymentBeforeExpirationDays: number;

    public constructor(init?: Partial<SendAutoRenewDomainEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendAutoRenewDomainEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

// @ValidateRequest(Validator="SecureSiteService")
export class SendSiteSubscriptionCancelWarningEmail implements IReturnVoid, ISiteReferenceRequest, IEntityLocalizeRequest
{
    public siteId: number;
    public languageCode: string;

    public constructor(init?: Partial<SendSiteSubscriptionCancelWarningEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendSiteSubscriptionCancelWarningEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

export class MoveDomainTransferStatus implements IReturnVoid, IPrimaryListEntityRequest
{
    public ids: number[];

    public constructor(init?: Partial<MoveDomainTransferStatus>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MoveDomainTransferStatus'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

export class SetSitePrimaryDomain implements IReturnVoid, ISiteReferenceEntity
{
    public domainId: number;
    public siteId: number;

    public constructor(init?: Partial<SetSitePrimaryDomain>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SetSitePrimaryDomain'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

export class SaveSiteDomainTransferStatus implements IReturnVoid, ISiteReferenceRequest, IPrimaryEntity
{
    public id: number;
    public siteId: number;
    public isTransferred: boolean;

    public constructor(init?: Partial<SaveSiteDomainTransferStatus>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SaveSiteDomainTransferStatus'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

export class GetSiteDomains extends QueryBase implements IReturn<QueryResponse<GetSiteDomainsResponse>>, IPrimaryEntityRequest, ISiteReferenceRequest
{
    public id?: number;
    public siteId: number;
    public domainName: string;
    public types: DomainType[];
    public statusIn: DomainStatus[];
    public isMainDomain?: boolean;
    public expireDateLessThan?: string;
    public expireDateGreaterThan?: string;
    public isTransferred?: boolean;

    public constructor(init?: Partial<GetSiteDomains>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSiteDomains'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QueryResponse<GetSiteDomainsResponse>(); }
}

export class GetIsSiteHaveFreeDomain implements IReturn<GetIsSiteHaveFreeDomainResponse>, ISiteReferenceRequest
{
    public siteId: number;

    public constructor(init?: Partial<GetIsSiteHaveFreeDomain>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetIsSiteHaveFreeDomain'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetIsSiteHaveFreeDomainResponse(); }
}

export class TryToConnectSiteDomain implements IReturn<BaseSaveResponse>, ISiteReferenceRequest
{
    public domainId: number;
    public siteId: number;

    public constructor(init?: Partial<TryToConnectSiteDomain>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TryToConnectSiteDomain'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseSaveResponse(); }
}

export class DisconnectSiteDomain implements IReturn<BaseSaveResponse>, ISiteReferenceRequest
{
    public domainId: number;
    public siteId: number;

    public constructor(init?: Partial<DisconnectSiteDomain>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DisconnectSiteDomain'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseSaveResponse(); }
}

export class MoveDomainBetweenSites implements IReturnVoid, ISiteReferenceRequest
{
    public domainId: number;
    public siteId: number;
    public recipientSiteId: number;

    public constructor(init?: Partial<MoveDomainBetweenSites>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MoveDomainBetweenSites'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

// @Route("/purchase-domain", "POST")
export class PurchaseDomain implements IReturn<PurchaseDomainResponse>, IActionSourceRequest
{
    public siteId: number;
    public domain: string;
    public source: string;

    public constructor(init?: Partial<PurchaseDomain>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PurchaseDomain'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PurchaseDomainResponse(); }
}

export class GetPurchaseDomainStatus implements IReturn<GetPurchaseDomainStatusResponse>, ISiteReferenceRequest
{
    public domain: string;
    public siteId: number;

    public constructor(init?: Partial<GetPurchaseDomainStatus>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetPurchaseDomainStatus'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetPurchaseDomainStatusResponse(); }
}

export class RegisterExistingDomain implements IReturn<RegisterExistingDomainResponse>, ISiteReferenceEntity
{
    public siteId: number;
    public domain: string;

    public constructor(init?: Partial<RegisterExistingDomain>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'RegisterExistingDomain'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new RegisterExistingDomainResponse(); }
}

export class AddEmailRecords implements IReturn<AddEmailRecordsResponse>
{
    public domain: string;
    public siteId: number;
    public authId: number;

    public constructor(init?: Partial<AddEmailRecords>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AddEmailRecords'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AddEmailRecordsResponse(); }
}

// @Route("/validate-domain-contacts", "GET")
export class ValidateDomainContacts implements IReturnVoid, ISiteReferenceRequest
{
    public siteId: number;

    public constructor(init?: Partial<ValidateDomainContacts>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ValidateDomainContacts'; }
    public getMethod() { return 'GET'; }
    public createResponse() {}
}

export class RenewSiteDomainsMailProcess implements IReturnVoid
{
    public takePerType: number;

    public constructor(init?: Partial<RenewSiteDomainsMailProcess>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'RenewSiteDomainsMailProcess'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

export class SaveSiteDomainAutoRenewStatus implements IReturn<BaseSaveResponse>, IPrimaryEntity, ISiteReferenceEntity
{
    public id: number;
    public siteId: number;
    public autoRenewEnabled: boolean;

    public constructor(init?: Partial<SaveSiteDomainAutoRenewStatus>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SaveSiteDomainAutoRenewStatus'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseSaveResponse(); }
}

export class GetRenewSiteDomainDetails implements IReturn<GetRenewSiteDomainDetailsResponse>, ISiteReferenceRequest
{
    public domainId: number;
    public siteId: number;

    public constructor(init?: Partial<GetRenewSiteDomainDetails>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetRenewSiteDomainDetails'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetRenewSiteDomainDetailsResponse(); }
}

export class GetSiteNextExpiredAtDomain implements IReturn<GetSiteNextExpiredAtDomainResponse>, ISiteReferenceRequest
{
    public siteId: number;

    public constructor(init?: Partial<GetSiteNextExpiredAtDomain>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSiteNextExpiredAtDomain'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetSiteNextExpiredAtDomainResponse(); }
}

export class GetRenewSiteDomainPaymentLink implements IReturn<GetRenewSiteDomainPaymentLinkResponse>, ISiteReferenceRequest
{
    public domainId: number;
    public siteId: number;

    public constructor(init?: Partial<GetRenewSiteDomainPaymentLink>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetRenewSiteDomainPaymentLink'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetRenewSiteDomainPaymentLinkResponse(); }
}

export class RenewSiteDomain implements IReturn<RenewSiteDomainResponse>, ISiteReferenceRequest
{
    public domainId: number;
    public siteId: number;
    public isMoveToYearPlan: boolean;

    public constructor(init?: Partial<RenewSiteDomain>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'RenewSiteDomain'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new RenewSiteDomainResponse(); }
}

export class SearchDefaultSuggestDomains implements IReturn<SuggestDomainsResponse>, ISiteReferenceRequest
{
    public siteId: number;

    public constructor(init?: Partial<SearchDefaultSuggestDomains>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SearchDefaultSuggestDomains'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SuggestDomainsResponse(); }
}

// @Route("/suggest-domains", "GET")
export class GetSuggestDomains implements IReturn<SuggestDomainsResponse>, ISiteReferenceRequest
{
    public siteId: number;
    public domain: string;

    public constructor(init?: Partial<GetSuggestDomains>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSuggestDomains'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SuggestDomainsResponse(); }
}

export class VerifySiteDomain implements IReturnVoid, ISiteReferenceRequest
{
    public domainId: number;
    public siteId: number;
    public disconnectFaultDomainsPeriod?: string;

    public constructor(init?: Partial<VerifySiteDomain>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'VerifySiteDomain'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

// @Route("/contact-form", "POST")
export class CreateContactForm implements IReturn<Object>, IAuditEntity, ISiteReferenceEntity, IDeletedEntity
{
    public receiverEmail: string;
    public formData: ContactFormDataItem[];
    public isDeleted: boolean;
    public createdAt: string;
    public updatedAt: string;
    public siteId: number;

    public constructor(init?: Partial<CreateContactForm>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CreateContactForm'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new Object(); }
}

// @Route("/contact-form", "GET")
export class GetContactForms extends QueryBase implements IReturn<QueryResponse<GetContactFormResponse>>, IPrimaryEntityRequest, ISiteReferenceRequest, ISearchEntityRequest
{
    public id?: number;
    public formData: Object;
    public siteId: number;
    public search: string;

    public constructor(init?: Partial<GetContactForms>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'GetContactForms'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QueryResponse<GetContactFormResponse>(); }
}

export class GetSiteCommunications extends QueryBase implements IReturn<QueryResponse<GetSiteCommunicationResponse>>, IPrimaryEntityRequest, IPrimaryListEntityRequest, ISiteReferenceRequest
{
    public id?: number;
    public ids: number[];
    public siteId: number;
    public types: CommunicationType[];
    public email: string;
    public emailContains: string;
    public phone: string;
    public phoneContains: string;
    public socialTypes: SocialNetworkType[];
    public socialUrl: string;
    public socialUrlContains: string;
    public fullAddress: string;
    public fullAddressContains: string;

    public constructor(init?: Partial<GetSiteCommunications>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSiteCommunications'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QueryResponse<GetSiteCommunicationResponse>(); }
}

export class SaveSiteCommunication implements IReturn<BaseSaveResponse>, IActionRequest, ISiteReferenceEntity
{
    public id: number;
    public siteId: number;
    public action: OperationActionType;
    public type: CommunicationType;
    public phone: CommunicationPhone;
    public email: CommunicationEmail;
    public address: CommunicationAddress;
    public social: CommunicationSocial;

    public constructor(init?: Partial<SaveSiteCommunication>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SaveSiteCommunication'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseSaveResponse(); }
}

export class SetOldCategory implements IReturnVoid
{
    public siteId: number;
    public answers: { [index: string]: string[]; };
    public language: LocalizableLanguage;

    public constructor(init?: Partial<SetOldCategory>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SetOldCategory'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

export class GetSiteCustomBusinessCategories extends QueryBase implements IReturn<GetSiteCustomBusinessCategoriesResponse>, IEntityLocalizeRequest, ISearchEntityRequest
{
    public search: string;
    public siteId: number;
    public languageCode: string;

    public constructor(init?: Partial<GetSiteCustomBusinessCategories>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSiteCustomBusinessCategories'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetSiteCustomBusinessCategoriesResponse(); }
}

export class SaveSiteCustomBusinessCategory implements IReturnVoid, ISiteReferenceRequest
{
    public siteId: number;
    public isCustom: boolean;
    public categoryId?: number;
    public customName: string;

    public constructor(init?: Partial<SaveSiteCustomBusinessCategory>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SaveSiteCustomBusinessCategory'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

export class SaveSiteBillingInfo extends BaseSaveResponse implements ISiteReferenceEntity
{
    public type: BillingInfoType;
    public siteId: number;
    public businessName: string;
    public taxId: string;
    public firstName: string;
    public lastName: string;
    public primaryEmail: string;
    public legalName: string;
    public countryId?: number;
    public stateId?: number;
    public stateCode: string;
    public city: string;
    public address: string;
    public phoneNumber: string;
    public businessNumber: string;
    public postalCode: string;

    public constructor(init?: Partial<SaveSiteBillingInfo>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'SaveSiteBillingInfo'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

export class GetSiteBillingInfo implements IReturn<GetSiteBillingInfoResponse>, ISiteReferenceRequest
{
    public siteId: number;
    public type: BillingInfoType;

    public constructor(init?: Partial<GetSiteBillingInfo>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSiteBillingInfo'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetSiteBillingInfoResponse(); }
}

export class SaveAndGetRequest implements IReturn<Object>
{
    public save: OperationRequest;
    public get: OperationRequest;

    public constructor(init?: Partial<SaveAndGetRequest>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SaveAndGetRequest'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new Object(); }
}

export class Ping implements IReturn<PingResponse>
{

    public constructor(init?: Partial<Ping>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'Ping'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PingResponse(); }
}

export class PingError implements IReturnVoid
{
    public isInner: boolean;

    public constructor(init?: Partial<PingError>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PingError'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

export class BaseModelsRequest implements IReturnVoid
{
    public siteEvent: SiteEvent<string>;
    public globalEvent: GlobalEvent<string>;

    public constructor(init?: Partial<BaseModelsRequest>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BaseModelsRequest'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

export class PingSleep implements IReturn<PingSleepResponse>
{
    public milliseconds: number;

    public constructor(init?: Partial<PingSleep>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PingSleep'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PingSleepResponse(); }
}

export class GetEmailTemplates extends QueryBase implements IReturn<QueryResponse<GetEmailTemplatesResponse>>, IPrimaryEntityRequest, IPrimaryListEntityRequest, IDeletedEntityRequest
{
    public id?: number;
    public ids: number[];
    public name: string;
    public subject: string;
    public language?: LocalizableLanguage;
    public emailType?: EmailTemplateTypeEnum;
    public type?: EmailTemplatePartialType;
    public isActive?: boolean;
    public isDeleted?: boolean;
    public siteId?: number;
    public siteIdIsNull?: boolean;

    public constructor(init?: Partial<GetEmailTemplates>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'GetEmailTemplates'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QueryResponse<GetEmailTemplatesResponse>(); }
}

export class GetEmailTemplate implements IReturn<GetEmailTemplateResponse>
{
    public id: number;
    public siteId?: number;

    public constructor(init?: Partial<GetEmailTemplate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetEmailTemplate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetEmailTemplateResponse(); }
}

export class SaveEmailTemplate implements IReturn<BaseSaveResponse>
{
    public id: number;
    public name: string;
    public subject: string;
    public language: LocalizableLanguage;
    public emailType?: EmailTemplateTypeEnum;
    public type: EmailTemplatePartialType;
    public isActive?: boolean;
    public siteId?: number;
    public isDeleted: boolean;
    public resourceCode: string;
    public body: string;
    public htmlBody: string;
    public headerEmailTemplateId?: number;
    public footerEmailTemplateId?: number;
    public staticData: Object;
    public testDynamicDataDictionary: { [index: string]: Object; };

    public constructor(init?: Partial<SaveEmailTemplate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SaveEmailTemplate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseSaveResponse(); }
}

export class BuildEmail implements IReturn<BuildEmailResponse>
{
    public subject: string;
    public html: string;
    public body: string;
    public jsonModel: string;
    public siteId?: number;

    public constructor(init?: Partial<BuildEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BuildEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BuildEmailResponse(); }
}

export class BuildEmailTemplate implements IReturn<BuildEmailResponse>, IEntityLocalizeRequest
{
    public templateId: number;
    public jsonModel: string;
    public siteId?: number;
    public languageCode: string;

    public constructor(init?: Partial<BuildEmailTemplate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BuildEmailTemplate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BuildEmailResponse(); }
}

export class DeleteEmailTemplate implements IReturnVoid, IPrimaryEntity
{
    public id: number;

    public constructor(init?: Partial<DeleteEmailTemplate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DeleteEmailTemplate'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

export class GetEmailTemplateJsonModel implements IReturn<GetEmailTemplateJsonModelResponse>
{
    public templateId: number;
    public siteId?: number;
    public jsonModel: string;

    public constructor(init?: Partial<GetEmailTemplateJsonModel>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetEmailTemplateJsonModel'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetEmailTemplateJsonModelResponse(); }
}

export class GetEmailStopList extends QueryBase implements IReturn<QueryResponse<GetEmailStopListResponse>>, IPrimaryEntityRequest, IPrimaryListEntityRequest, IDeletedEntityRequest
{
    public id?: number;
    public ids: number[];
    public type?: StopListType;
    public email: string;
    public domain: string;
    public isDeleted?: boolean;

    public constructor(init?: Partial<GetEmailStopList>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'GetEmailStopList'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QueryResponse<GetEmailStopListResponse>(); }
}

export class SaveEmailStopList implements IReturn<BaseSaveResponse>, IPrimaryEntity, IDeletedEntity
{
    public id: number;
    public type: StopListType;
    public email: string;
    public domain: string;
    public isDeleted: boolean;

    public constructor(init?: Partial<SaveEmailStopList>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SaveEmailStopList'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseSaveResponse(); }
}

export class ValidateEmailStopList implements IReturn<ValidateEmailStopListResponse>
{
    public email: string;

    public constructor(init?: Partial<ValidateEmailStopList>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ValidateEmailStopList'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ValidateEmailStopListResponse(); }
}

export class GetEmailRecipients extends QueryBase implements IReturn<QueryResponse<RecipientResponse>>, IPrimaryEntityRequest, IPrimaryListEntityRequest, IDeletedEntityRequest, ISiteReferenceRequest
{
    public id?: number;
    public ids: number[];
    public isDeleted?: boolean;
    public siteId: number;
    public templateType?: EmailTemplateTypeEnum;
    public templateTypes: EmailTemplateTypeEnum[];
    public email: string;

    public constructor(init?: Partial<GetEmailRecipients>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'GetEmailRecipients'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QueryResponse<RecipientResponse>(); }
}

export class SaveEmailRecipient implements IReturn<BaseSaveResponse>, IPrimaryEntity, ISiteReferenceEntity
{
    public id: number;
    public siteId: number;
    public templateType: EmailTemplateTypeEnum;
    public email: string;

    public constructor(init?: Partial<SaveEmailRecipient>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SaveEmailRecipient'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseSaveResponse(); }
}

export class GetEmailLogs extends QueryBase implements IReturn<QueryResponse<GetEmailLogResponse>>
{
    public id?: number;
    public siteId?: number;
    public recordId?: number;
    public toEmail: string;
    public ccEmail: string;
    public bccEmail: string;
    public type?: EmailTemplateTypeEnum;
    public types: EmailTemplateTypeEnum[];

    public constructor(init?: Partial<GetEmailLogs>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'GetEmailLogs'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QueryResponse<GetEmailLogResponse>(); }
}

export class GetEmailDemoData extends QueryBase implements IReturn<QueryResponse<GetEmailDemoDataResponse>>, IPrimaryEntityRequest, IPrimaryListEntityRequest
{
    public id?: number;
    public ids: number[];
    public emailType?: EmailTemplateTypeEnum;

    public constructor(init?: Partial<GetEmailDemoData>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'GetEmailDemoData'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QueryResponse<GetEmailDemoDataResponse>(); }
}

export class SaveEmailDemoData implements IReturn<BaseSaveResponse>, IPrimaryEntity
{
    public id: number;
    public name: string;
    public emailTypes: EmailTemplateTypeEnum[];
    public data: Object;

    public constructor(init?: Partial<SaveEmailDemoData>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SaveEmailDemoData'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseSaveResponse(); }
}

export class DeleteEmailDemoData implements IReturnVoid, IPrimaryEntity
{
    public id: number;

    public constructor(init?: Partial<DeleteEmailDemoData>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DeleteEmailDemoData'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

export class GetBlogTags extends QueryBase implements IReturn<QueryResponse<GetBlogTagsResponse>>, IPrimaryEntityRequest, IPrimaryListEntityRequest, IDeletedEntityRequest, ISiteReferenceRequest, IOwnerEntityRequest, IOnlyPublishedRequest
{
    public id?: number;
    public ids: number[];
    public postId?: number;
    public name: string;
    public slug: string;
    public siteId: number;
    public category: string;
    public tag: string;
    public search: string;
    public isDeleted?: boolean;
    public userAuthId?: number;
    public isOnlyPublished: boolean;

    public constructor(init?: Partial<GetBlogTags>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'GetBlogTags'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QueryResponse<GetBlogTagsResponse>(); }
}

export class SaveBlogTag implements IReturn<GetBlogTagsResponse>, IPrimaryEntity, ISiteReferenceEntity, IDeletedEntity
{
    public id: number;
    public name: string;
    public keywords: Tag[];
    public metaTitle: string;
    public metaDescription: string;
    public slug: string;
    public postIds: number[];
    public isDeleted: boolean;
    public siteId: number;
    public action: UpdateBlogTagActionType;

    public constructor(init?: Partial<SaveBlogTag>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SaveBlogTag'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetBlogTagsResponse(); }
}

export class GetAiPostTitles implements IReturn<GetAiPostTitlesResponse>, ISiteReferenceRequest, IEntityLocalizeRequest
{
    public subject: string;
    public siteId: number;
    public languageCode: string;

    public constructor(init?: Partial<GetAiPostTitles>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetAiPostTitles'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetAiPostTitlesResponse(); }
}

export class GetAiPostSubTitles implements IReturn<GetAiPostSubTitlesResponse>, ISiteReferenceRequest, IEntityLocalizeRequest
{
    public title: string;
    public siteId: number;
    public languageCode: string;

    public constructor(init?: Partial<GetAiPostSubTitles>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetAiPostSubTitles'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetAiPostSubTitlesResponse(); }
}

export class GetAiPostBody implements IReturn<GetAiPostBodyResponse>, ISiteReferenceRequest, IEntityLocalizeRequest
{
    public title: string;
    public subTitles: string[];
    public siteId: number;
    public languageCode: string;

    public constructor(init?: Partial<GetAiPostBody>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetAiPostBody'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetAiPostBodyResponse(); }
}

export class GetOpenAiPostAvailableRequestCount implements IReturn<GetOpenAiPostAvailableRequestCountResponse>, ISiteReferenceRequest
{
    public siteId: number;

    public constructor(init?: Partial<GetOpenAiPostAvailableRequestCount>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetOpenAiPostAvailableRequestCount'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetOpenAiPostAvailableRequestCountResponse(); }
}

export class GetPostLczConfig extends BaseGetEntityLczConfig implements IReturn<QueryResponse<GetPostLczConfigResponse>>, IDisplayEntityRequest
{
    public isOnlyDefault: boolean;
    public name: string;

    public constructor(init?: Partial<GetPostLczConfig>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'GetPostLczConfig'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QueryResponse<GetPostLczConfigResponse>(); }
}

export class BuildPostLocalizeConfig extends BaseBuildRecordLocalizeConfigRequest implements IReturn<BuildRecordLocalizeConfigResponse<BuildRecordLocalizeConfigItem>>
{

    public constructor(init?: Partial<BuildPostLocalizeConfig>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'BuildPostLocalizeConfig'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BuildRecordLocalizeConfigResponse<BuildRecordLocalizeConfigItem>(); }
}

export class GetPosts extends QueryBase implements IReturn<QueryResponse<GetPostsResponse>>, IPrimaryEntityRequest, IPrimaryListEntityRequest, IDeletedEntityRequest, IOwnerEntityRequest, ISiteLocalizeRequest
{
    public id?: number;
    public ids: number[];
    public order?: number;
    public viewedCount?: number;
    public name: string;
    public nameHtml: string;
    public slug: string;
    public siteId: number;
    public authorId?: number;
    public search: string;
    public isDeleted?: boolean;
    public userAuthId?: number;
    public publishStatus?: PublishStatusType;
    public includeOnlyPublished?: boolean;
    public includeMainCategoryDetails?: boolean;
    public includeTags?: boolean;
    public includeAuthor?: boolean;
    public isEnabledViwedCounter?: boolean;
    public includeSidebarContent?: boolean;
    public isGeneratedByAi?: boolean;
    public fromDate?: string;
    public toDate?: string;
    public archiveDate?: string;
    public languageCode: string;
    public siteLanguageCode: string;

    public constructor(init?: Partial<GetPosts>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'GetPosts'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QueryResponse<GetPostsResponse>(); }
}

export class SavePost implements IReturn<GetPostsResponse>, IPrimaryEntity, IDeletedEntity, ISiteLocalizeRequest
{
    public id: number;
    public name: string;
    public nameHtml: string;
    public shortDescription: string;
    public order: number;
    public keywords: Tag[];
    public metaTitle: string;
    public metaDescription: string;
    public slug: string;
    public isCustomSlug?: boolean;
    public expectedReadMinutes?: number;
    public authorId?: number;
    public publishStatus: PublishStatusType;
    public coverImage: BaseWidgetImage;
    public widgets: BaseWidget[];
    public mainCategoryId?: number;
    public publishDate: string;
    public viewedCount: number;
    public sharedCount: number;
    public likedCount: number;
    public button: BaseWidgetButton;
    public sidebarType: BlogPageTemplateSidebarType;
    public sidebarWidgets: BlogPageTemplateSidebarWidget[];
    public isDeleted: boolean;
    public pageType: PageType;
    public pageTemplateType: PageTemplateType;
    public siteId: number;
    public includeSidebarContent?: boolean;
    public includeTags?: boolean;
    public includeAuthor?: boolean;
    public isGeneratedByAi?: boolean;
    public action: OperationActionType;
    public updateOrderActionType?: UpdateItemOrderActionType;
    public globalLocalizeCode: string;
    public languageCode: string;
    public siteLanguageCode: string;
    public seoSettings: PageSeo;

    public constructor(init?: Partial<SavePost>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SavePost'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetPostsResponse(); }
}

export class LikePost implements IReturn<GetPostsResponse>
{
    public id: number;
    public siteId: number;
    public isLiked?: boolean;

    public constructor(init?: Partial<LikePost>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'LikePost'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetPostsResponse(); }
}

export class GetPostsArchives implements IReturn<GetPostsArchivesResponse>
{
    public siteId: number;

    public constructor(init?: Partial<GetPostsArchives>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetPostsArchives'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetPostsArchivesResponse(); }
}

export class GetPostsPublishStatusCounts implements IReturn<GetPagePublishStatusCountsResponse>
{
    public siteId: number;

    public constructor(init?: Partial<GetPostsPublishStatusCounts>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetPostsPublishStatusCounts'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetPagePublishStatusCountsResponse(); }
}

export class PostMoveDefaultSortOrder implements IReturnVoid
{
    public postId: number;
    public siteId: number;
    public publishStatus?: PublishStatusType;
    public type: UpdateItemOrderActionType;

    public constructor(init?: Partial<PostMoveDefaultSortOrder>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PostMoveDefaultSortOrder'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

export class GetBlogCategoryTemplates extends QueryBase implements IReturn<QueryResponse<GetBlogCategoryTemplatesResponse>>, IPrimaryEntityRequest, IPrimaryListEntityRequest, IDeletedEntityRequest, IOwnerEntityRequest, ISiteLocalizeRequest
{
    public id?: number;
    public ids: number[];
    public name: string;
    public siteId: number;
    public search: string;
    public isDeleted?: boolean;
    public includeSidebarContent?: boolean;
    public userAuthId?: number;
    public languageCode: string;
    public siteLanguageCode: string;
    public styleSettings: PageStyleSettings;

    public constructor(init?: Partial<GetBlogCategoryTemplates>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'GetBlogCategoryTemplates'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QueryResponse<GetBlogCategoryTemplatesResponse>(); }
}

export class SaveBlogCategoryTemplate implements IReturn<GetBlogCategoryTemplatesResponse>, IPrimaryEntity, IDeletedEntity, IActionRequest, ISiteLocalizeRequest
{
    public id: number;
    public name: string;
    public slug: string;
    public structureType: BlogCategoryTemplateStructureType;
    public structureTypeVariationType: BlogCategoryTemplateStructureVariationType;
    public sidebarType: BlogPageTemplateSidebarType;
    public sortType: SortType;
    public sidebarWidgets: BlogPageTemplateSidebarWidget[];
    public widgets: BaseWidget[];
    public sliderPostIds: number[];
    public postElementShowSettings: { [index: string]: EnabledSetting; };
    public sliderElementShowSettings: { [index: string]: EnabledSetting; };
    public categoryId?: number;
    public isDeleted: boolean;
    public pageType: PageType;
    public pageTemplateType: PageTemplateType;
    public isFinishedWizard: boolean;
    public siteId: number;
    public includeSidebarContent?: boolean;
    public action: OperationActionType;
    public languageCode: string;
    public siteLanguageCode: string;
    public styleSettings: PageStyleSettings;

    public constructor(init?: Partial<SaveBlogCategoryTemplate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SaveBlogCategoryTemplate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetBlogCategoryTemplatesResponse(); }
}

export class GetBlogCategories extends QueryBase implements IReturn<QueryResponse<GetBlogCategoriesResponse>>, IPrimaryEntityRequest, IPrimaryListEntityRequest, ISiteNullableReferenceRequest, IDeletedEntityRequest, IOwnerEntityRequest, IOnlyPublishedRequest
{
    public id?: number;
    public ids: number[];
    public name: string;
    public slug: string;
    public siteId?: number;
    public search: string;
    public isDeleted?: boolean;
    public userAuthId?: number;
    public isOnlyPublished: boolean;

    public constructor(init?: Partial<GetBlogCategories>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'GetBlogCategories'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QueryResponse<GetBlogCategoriesResponse>(); }
}

export class SaveBlogCategory implements IReturn<GetBlogCategoriesResponse>, IPrimaryEntity, IDeletedEntity, ISiteReferenceEntity
{
    public id: number;
    public name: string;
    public keywords: Tag[];
    public metaTitle: string;
    public metaDescription: string;
    public slug: string;
    public postIds: number[];
    public isDeleted: boolean;
    public siteId: number;
    public action: UpdateBlogCategoryActionType;

    public constructor(init?: Partial<SaveBlogCategory>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SaveBlogCategory'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetBlogCategoriesResponse(); }
}

// @Route("/authors", "GET")
export class GetAuthors extends QueryBase implements IReturn<QueryResponse<GetAuthorsResponse>>, IPrimaryEntityRequest, IPrimaryListEntityRequest, ISiteReferenceRequest, IOwnerEntityRequest, IOnlyPublishedRequest
{
    public id?: number;
    public ids: number[];
    public name: string;
    public publishedAt?: string;
    public createdAt?: string;
    public search: string;
    public siteId: number;
    public includePostRecordsData?: boolean;
    public userAuthId?: number;
    public isOnlyPublished: boolean;

    public constructor(init?: Partial<GetAuthors>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'GetAuthors'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QueryResponse<GetAuthorsResponse>(); }
}

export class SaveAuthor implements IReturn<GetAuthorsResponse>, IPrimaryEntity, ISiteReferenceEntity, IDeletedEntityRequest
{
    public id: number;
    public firstName: string;
    public lastName: string;
    public position: string;
    public profileAvatar: BaseWidgetImage;
    public description: string;
    public socialLinks: { [index: string]: string; };
    public uploadedSocialIcons: BaseWidgetImage[];
    public isDeleted?: boolean;
    public siteId: number;
    public action: UpdateAuthorActionType;

    public constructor(init?: Partial<SaveAuthor>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SaveAuthor'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetAuthorsResponse(); }
}

// @Route("/booking-workers", "GET")
export class GetBookingWorkers extends QueryBase implements IReturn<QueryResponse<GetBookingWorkersResponse>>, IPrimaryEntityRequest, IPrimaryListEntityRequest, ISiteReferenceEntity, IDeletedEntityRequest
{
    public id?: number;
    public ids: number[];
    public locationIds: number[];
    public serviceIds: number[];
    public search: string;
    public siteId: number;
    public isDeleted?: boolean;

    public constructor(init?: Partial<GetBookingWorkers>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'GetBookingWorkers'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QueryResponse<GetBookingWorkersResponse>(); }
}

export class SaveBookingWorker implements IReturn<GetBookingWorkersResponse>, IPrimaryEntity, ISiteReferenceEntity, IDeletedEntity, IAuditEntity
{
    public id: number;
    public action?: UpdateBookingWorkerActionType;
    public siteId: number;
    public firstName: string;
    public lastName: string;
    public position: string;
    public emails: string[];
    public phones: string[];
    public socialNetworks: string[];
    public vacation: BookingWorkerVacation;
    public isDeleted: boolean;
    public createdAt: string;
    public updatedAt: string;
    public atLocations: BookingWorkerAtLocationInput[];

    public constructor(init?: Partial<SaveBookingWorker>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SaveBookingWorker'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetBookingWorkersResponse(); }
}

export class GetBookingWorkersAtLocations extends QueryBase implements IReturn<QueryResponse<GetBookingWorkersAtLocationsResponse>>, ISiteReferenceRequest
{
    public id?: number;
    public workerId?: number;
    public workerIds: number[];
    public locationIds: number[];
    public ignoreDeletedWorkers: boolean;
    public ignoreDeletedLocations: boolean;
    public siteId: number;

    public constructor(init?: Partial<GetBookingWorkersAtLocations>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'GetBookingWorkersAtLocations'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QueryResponse<GetBookingWorkersAtLocationsResponse>(); }
}

export class ConnectBookingWorkerWithLocations implements IReturnVoid
{
    public workerId: number;
    public locations: BookingWorkerAtLocationInput[];
    public locationIds: number[];

    public constructor(init?: Partial<ConnectBookingWorkerWithLocations>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConnectBookingWorkerWithLocations'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

export class RemoveBookingWorkerAtLocationConnections implements IReturnVoid
{
    public forWorkerId?: number;
    public forLocationId?: number;

    public constructor(init?: Partial<RemoveBookingWorkerAtLocationConnections>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'RemoveBookingWorkerAtLocationConnections'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

// @Route("/booking-services", "GET")
export class GetBookingServices extends QueryBase implements IReturn<QueryResponse<GetBookingServicesResponse>>, IPrimaryEntityRequest, IPrimaryListEntityRequest, ISiteReferenceRequest, IDeletedEntityRequest
{
    public id?: number;
    public ids: number[];
    public locationIds: number[];
    public workerIds: number[];
    public siteId: number;
    public titleContains: string;
    public serviceName: string;
    public isDeleted?: boolean;

    public constructor(init?: Partial<GetBookingServices>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'GetBookingServices'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QueryResponse<GetBookingServicesResponse>(); }
}

export class SaveBookingService implements IReturn<GetBookingServicesResponse>, BookingServiceUpdatableFields, BookingServiceExtendedWebFields, IPrimaryEntity, ISiteReferenceEntity, IDeletedEntity, IAuditEntity
{
    public id: number;
    public action?: UpdateBookingServiceActionType;
    public title: string;
    public maxUpfrontPeriodValue: number;
    public maxUpfrontPeriodType: MaxUpfrontPeriodTypeEnum;
    public price?: number;
    public breakBeforeHours?: number;
    public breakBeforeMinutes?: number;
    public durationHours?: number;
    public durationMinutes?: number;
    public breakAfterHours?: number;
    public breakAfterMinutes?: number;
    public atLocationIds: number[];
    public byWorkerIds: number[];
    public createdAt: string;
    public updatedAt: string;
    public isDeleted: boolean;
    public siteId: number;

    public constructor(init?: Partial<SaveBookingService>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SaveBookingService'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetBookingServicesResponse(); }
}

export class GetIsExistsFinishedSiteBookingService implements IReturn<GetIsExistsFinishedSiteBookingServiceResponse>, ISiteReferenceRequest
{
    public siteId: number;

    public constructor(init?: Partial<GetIsExistsFinishedSiteBookingService>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetIsExistsFinishedSiteBookingService'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetIsExistsFinishedSiteBookingServiceResponse(); }
}

export class GetBookingServicesByWorkers extends QueryBase implements IReturn<QueryResponse<GetBookingServicesByWorkersResponse>>, IPrimaryEntityRequest, ISiteReferenceRequest
{
    public id?: number;
    public workerId?: number;
    public workerIds: number[];
    public serviceId?: number;
    public serviceIds: number[];
    public ignoreDeletedWorkers: boolean;
    public ignoreDeletedServices: boolean;
    public siteId: number;

    public constructor(init?: Partial<GetBookingServicesByWorkers>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'GetBookingServicesByWorkers'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QueryResponse<GetBookingServicesByWorkersResponse>(); }
}

export class ConnectBookingServiceWithWorkers implements IReturnVoid
{
    public serviceId: number;
    public byWorkerIds: number[];

    public constructor(init?: Partial<ConnectBookingServiceWithWorkers>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConnectBookingServiceWithWorkers'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

export class RemoveBookingServiceByWorkerConnections implements IReturnVoid
{
    public forServiceId?: number;
    public forWorkerId?: number;

    public constructor(init?: Partial<RemoveBookingServiceByWorkerConnections>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'RemoveBookingServiceByWorkerConnections'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

export class GetBookingServicesAtLocations extends QueryBase implements IReturn<QueryResponse<GetBookingServicesAtLocationsResponse>>, ISiteReferenceRequest
{
    public serviceId?: number;
    public serviceIds: number[];
    public locationIds: number[];
    public ignoreDeletedServices: boolean;
    public ignoreDeletedLocations: boolean;
    public siteId: number;

    public constructor(init?: Partial<GetBookingServicesAtLocations>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'GetBookingServicesAtLocations'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QueryResponse<GetBookingServicesAtLocationsResponse>(); }
}

export class ConnectBookingServiceWithLocations implements IReturnVoid, ISiteReferenceRequest
{
    public serviceId: number;
    public atLocationIds: number[];
    public siteId: number;

    public constructor(init?: Partial<ConnectBookingServiceWithLocations>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConnectBookingServiceWithLocations'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

export class RemoveBookingServiceAtLocationConnections implements IReturnVoid
{
    public forServiceId?: number;
    public forLocationId?: number;

    public constructor(init?: Partial<RemoveBookingServiceAtLocationConnections>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'RemoveBookingServiceAtLocationConnections'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

export class GetBookingRequests extends QueryBase implements IReturn<QueryResponse<GetBookingRequestsResponse>>, IPrimaryEntityRequest, IPrimaryListEntityRequest, ISiteReferenceRequest
{
    public id?: number;
    public ids: number[];
    public locationIds: number[];
    public serviceIds: number[];
    public workerIds: number[];
    public workerId?: number;
    public clientIds: number[];
    public clientId?: number;
    public siteId: number;
    public statuses: RequestStatus[];
    public scheduledAtGreaterThan?: string;
    public scheduledAtLessThan?: string;

    public constructor(init?: Partial<GetBookingRequests>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'GetBookingRequests'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QueryResponse<GetBookingRequestsResponse>(); }
}

export class SaveBookingRequest implements IReturn<GetBookingRequestsResponse>, IPrimaryEntity, ISiteReferenceEntity, IAuditEntity
{
    public id: number;
    public action?: UpdateBookingRequestActionType;
    public email: string;
    public firstName: string;
    public lastName: string;
    public address: string;
    public phone: string;
    public countryId?: number;
    public zipCode: string;
    public price?: number;
    public scheduledAt: string;
    public status: RequestStatus;
    public note: string;
    public customFields: BookingCustomField[];
    public source?: RequestSource;
    public locationId?: number;
    public serviceId?: number;
    public workerId?: number;
    public siteId: number;
    public createdAt: string;
    public updatedAt: string;

    public constructor(init?: Partial<SaveBookingRequest>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SaveBookingRequest'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetBookingRequestsResponse(); }
}

// @Route("/booking-locations", "GET")
export class GetBookingLocations extends QueryBase implements IReturn<QueryResponse<GetBookingLocationResponse>>, IPrimaryEntityRequest, IPrimaryListEntityRequest, ISiteReferenceRequest, IDeletedEntityRequest
{
    public id?: number;
    public ids: number[];
    public serviceIds: number[];
    public workerIds: number[];
    public workingTime: WorkingTime;
    public search: string;
    public isDeleted?: boolean;
    public siteId: number;

    public constructor(init?: Partial<GetBookingLocations>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'GetBookingLocations'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QueryResponse<GetBookingLocationResponse>(); }
}

export class SaveBookingLocation implements IReturn<GetBookingLocationResponse>, IPrimaryEntity, IAuditEntity, IDeletedEntity, ISiteReferenceEntity
{
    public id: number;
    public action?: OperationActionType;
    public title: string;
    public workingTime: WorkingTime;
    public createdAt: string;
    public updatedAt: string;
    public isDeleted: boolean;
    public siteId: number;

    public constructor(init?: Partial<SaveBookingLocation>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SaveBookingLocation'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetBookingLocationResponse(); }
}

// @ValidateRequest(Validator="SecureSiteService")
export class SendCustomerRequestCanceledEmail implements IReturnVoid, ISiteReferenceRequest, IEntityLocalizeRequest
{
    public bookingRequestId: number;
    public siteId: number;
    public languageCode: string;

    public constructor(init?: Partial<SendCustomerRequestCanceledEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendCustomerRequestCanceledEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

// @ValidateRequest(Validator="SecureSiteService")
export class SendCustomerRequestAcceptedEmail implements IReturnVoid, ISiteReferenceRequest, IEntityLocalizeRequest
{
    public bookingRequestId: number;
    public siteId: number;
    public languageCode: string;

    public constructor(init?: Partial<SendCustomerRequestAcceptedEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendCustomerRequestAcceptedEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

// @ValidateRequest(Validator="SecureSiteService")
export class SendCustomerRequestDetailsChangedEmail implements IReturnVoid, ISiteReferenceRequest, IEntityLocalizeRequest
{
    public bookingRequestId: number;
    public siteId: number;
    public languageCode: string;

    public constructor(init?: Partial<SendCustomerRequestDetailsChangedEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendCustomerRequestDetailsChangedEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

// @ValidateRequest(Validator="SecureSiteService")
export class SendOwnerRequestCanceledEmail implements IReturnVoid, ISiteReferenceRequest, IEntityLocalizeRequest
{
    public bookingRequestId: number;
    public siteId: number;
    public languageCode: string;

    public constructor(init?: Partial<SendOwnerRequestCanceledEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendOwnerRequestCanceledEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

// @ValidateRequest(Validator="SecureSiteService")
export class SendOwnerRequestDetailsChangedEmail implements IReturnVoid, ISiteReferenceRequest, IEntityLocalizeRequest
{
    public bookingRequestId: number;
    public siteId: number;
    public languageCode: string;

    public constructor(init?: Partial<SendOwnerRequestDetailsChangedEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendOwnerRequestDetailsChangedEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

// @ValidateRequest(Validator="SecureSiteService")
export class SendOwnerRequestReceivedEmail implements IReturnVoid, ISiteReferenceRequest, IEntityLocalizeRequest
{
    public bookingRequestId: number;
    public siteId: number;
    public languageCode: string;

    public constructor(init?: Partial<SendOwnerRequestReceivedEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendOwnerRequestReceivedEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

// @Route("/booking-customer", "GET")
export class GetBookingClients extends QueryBase implements IReturn<QueryResponse<GetBookingClientsResponse>>, IPrimaryEntityRequest, ISiteReferenceRequest
{
    public bookingRequestServiceIds: number[];
    public bookingRequestServiceScheduledAtGreaterThan?: string;
    public bookingRequestServiceScheduledAtLessThan?: string;
    public id?: number;
    public siteId: number;
    public siteIds: number[];
    public email: string;
    public firstName: string;
    public lastName: string;
    public phone: string;
    public address: string;
    public countryCode: string;
    public search: string;
    public createdAtGreaterThan?: string;
    public createdAtAtLessThan?: string;

    public constructor(init?: Partial<GetBookingClients>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'GetBookingClients'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QueryResponse<GetBookingClientsResponse>(); }
}

export class SaveBookingClient implements IReturn<GetBookingClientsResponse>, IPrimaryEntity, ISiteReferenceRequest, IDeletedEntity
{
    public id: number;
    public action?: OperationActionType;
    public siteId: number;
    public email: string;
    public firstName: string;
    public lastName: string;
    public address: string;
    public phone: string;
    public countryCode: string;
    public isDeleted: boolean;

    public constructor(init?: Partial<SaveBookingClient>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SaveBookingClient'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetBookingClientsResponse(); }
}

export class GetSiteFileUploadAvailable implements IReturn<GetSiteFileUploadAvailableResponse>, ISiteReferenceRequest
{
    public siteId: number;
    public siteFileType: SiteFileType;

    public constructor(init?: Partial<GetSiteFileUploadAvailable>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSiteFileUploadAvailable'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetSiteFileUploadAvailableResponse(); }
}

export class GetScanSiteVideos extends QueryBase implements IReturn<QueryResponse<GetScanSiteVideoResponse>>, ISiteFileScannerRequest
{
    public id?: number;
    public ids: number[];
    public createdAtBefore?: string;
    public createdAtAfter?: string;
    public name: string;
    public nameContains: string;
    public siteId?: number;
    public status?: SiteFileStatus;
    public videoIsAvailable?: boolean;
    public scoreLessThan?: number;
    public scoreGreaterThan?: number;
    public mediaId: string;

    public constructor(init?: Partial<GetScanSiteVideos>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'GetScanSiteVideos'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QueryResponse<GetScanSiteVideoResponse>(); }
}

export class ScanSiteVideoProcess implements IReturnVoid, ISiteReferenceRequest
{
    public videoFileId: number;
    public siteId: number;
    public timeout: string;

    public constructor(init?: Partial<ScanSiteVideoProcess>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ScanSiteVideoProcess'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

export class FinishScanSiteVideo implements IReturnVoid
{
    // @DataMember(Name="progressId")
    public processId: number;

    // @DataMember(Name="videoId")
    public videoId: number;

    public request: string;
    public media: Media;
    public data: ModerationData;

    public constructor(init?: Partial<FinishScanSiteVideo>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'FinishScanSiteVideo'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

export class GetSiteVideoFiles extends QueryBase implements IReturn<QueryResponse<SiteVideoResponse>>, ISiteFilesRequest
{
    public id?: number;
    public ids: number[];
    public siteId: number;
    public statusIn: SiteFileStatus[];
    public isCheckUploadingStatus: boolean;
    public nameContains: string;
    public name: string;

    public constructor(init?: Partial<GetSiteVideoFiles>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSiteVideoFiles'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QueryResponse<SiteVideoResponse>(); }
}

// @Route("/files/video/uploadlink", "POST")
export class GetSiteVideoUploadLink implements IReturn<GetSiteVideoUploadLinkResponse>, ISiteReferenceRequest
{
    public siteId: number;
    public fileName: string;
    public size: number;

    public constructor(init?: Partial<GetSiteVideoUploadLink>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSiteVideoUploadLink'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetSiteVideoUploadLinkResponse(); }
}

export class CompleteUploadSiteVideos implements IReturn<CompleteUploadSiteVideosResponse>, ISiteReferenceRequest
{
    public ids: number[];
    public siteId: number;

    public constructor(init?: Partial<CompleteUploadSiteVideos>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CompleteUploadSiteVideos'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CompleteUploadSiteVideosResponse(); }
}

export class DeleteSiteVideo implements IReturnVoid, IPrimaryEntity, ISiteReferenceRequest
{
    public siteId: number;
    public id: number;

    public constructor(init?: Partial<DeleteSiteVideo>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DeleteSiteVideo'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

export class GetDesignImages implements IReturn<GetFilesResult>
{
    public rootPath: string;

    public constructor(init?: Partial<GetDesignImages>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetDesignImages'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetFilesResult(); }
}

export class UploadDesignImage implements IReturn<SecureUploadSiteImageResponse>
{
    public folderPath: string;

    public constructor(init?: Partial<UploadDesignImage>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UploadDesignImage'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SecureUploadSiteImageResponse(); }
}

export class DeleteDesignImage implements IReturnVoid
{
    public path: string;

    public constructor(init?: Partial<DeleteDesignImage>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DeleteDesignImage'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() {}
}

export class OpenAiExtendSiteImage implements IReturn<OpenAiExtendSiteImageResponse>, ISiteReferenceRequest
{
    public siteId: number;
    public imageUrl: string;
    public maskImageUrl: string;
    public prompt: string;
    public size: string;

    public constructor(init?: Partial<OpenAiExtendSiteImage>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'OpenAiExtendSiteImage'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new OpenAiExtendSiteImageResponse(); }
}

export class GetScanSiteImages extends QueryBase implements IReturn<QueryResponse<GetScanSiteImageResponse>>, ISiteFileScannerRequest
{
    public id?: number;
    public ids: number[];
    public createdAtBefore?: string;
    public createdAtAfter?: string;
    public siteId?: number;
    public name: string;
    public nameContains: string;
    public status?: SiteFileStatus;
    public scoreLessThan?: number;
    public scoreGreaterThan?: number;
    public mediaId: string;

    public constructor(init?: Partial<GetScanSiteImages>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'GetScanSiteImages'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QueryResponse<GetScanSiteImageResponse>(); }
}

export class GetSiteImageFiles extends QueryBase implements IReturn<QueryResponse<SiteFileResponse>>, ISiteFilesRequest
{
    public id?: number;
    public ids: number[];
    public siteId: number;
    public nameContains: string;
    public name: string;

    public constructor(init?: Partial<GetSiteImageFiles>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSiteImageFiles'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QueryResponse<SiteFileResponse>(); }
}

export class UploadSiteImage implements IReturn<UploadSiteImageResponse>, ISiteReferenceRequest
{
    public fileUrl: string;
    public fileName: string;
    public siteId: number;

    public constructor(init?: Partial<UploadSiteImage>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UploadSiteImage'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new UploadSiteImageResponse(); }
}

export class SecureUploadSiteImage implements IReturn<SecureUploadSiteImageResponse>
{
    public siteId?: number;
    public folderPath: string;
    public fileName: string;

    public constructor(init?: Partial<SecureUploadSiteImage>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SecureUploadSiteImage'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SecureUploadSiteImageResponse(); }
}

export class DeleteSiteImage implements IReturnVoid, IPrimaryEntity, ISiteReferenceRequest
{
    public id: number;
    public siteId: number;

    public constructor(init?: Partial<DeleteSiteImage>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DeleteSiteImage'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

export class GetUploadSignedUrl implements IReturn<GetUploadSignedUrlResponse>, ISiteReferenceRequest
{
    public siteId: number;

    public constructor(init?: Partial<GetUploadSignedUrl>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetUploadSignedUrl'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetUploadSignedUrlResponse(); }
}

export class UploadSiteStockImage implements IReturn<BaseSaveResponse>, ISiteReferenceRequest
{
    public id: string;
    public ixId: string;
    public siteId: number;
    public fileName: string;

    public constructor(init?: Partial<UploadSiteStockImage>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UploadSiteStockImage'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseSaveResponse(); }
}

export class GetSiteStockImages implements IReturn<GetSiteStockImagesResponse>, ISiteReferenceRequest
{
    public searchQuery: string;
    public orientation?: ImageOrientation;
    public page: number;
    public perPage: number;
    public siteId: number;

    public constructor(init?: Partial<GetSiteStockImages>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSiteStockImages'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetSiteStockImagesResponse(); }
}

export class GetScanSiteDocuments extends QueryBase implements IReturn<QueryResponse<GetScanSiteDocumentResponse>>, ISiteFileScannerRequest
{
    public id?: number;
    public ids: number[];
    public createdAtBefore?: string;
    public createdAtAfter?: string;
    public siteId?: number;
    public name: string;
    public nameContains: string;
    public status?: SiteFileStatus;
    public scoreLessThan?: number;
    public scoreGreaterThan?: number;
    public mediaId: string;

    public constructor(init?: Partial<GetScanSiteDocuments>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'GetScanSiteDocuments'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QueryResponse<GetScanSiteDocumentResponse>(); }
}

export class GetSiteDocumentFiles extends QueryBase implements IReturn<QueryResponse<SiteFileResponse>>, ISiteFilesRequest
{
    public id?: number;
    public ids: number[];
    public siteId: number;
    public nameContains: string;
    public name: string;

    public constructor(init?: Partial<GetSiteDocumentFiles>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSiteDocumentFiles'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QueryResponse<SiteFileResponse>(); }
}

export class UploadSiteDocument implements IReturn<BaseSaveResponse>, ISiteReferenceRequest
{
    public siteId: number;

    public constructor(init?: Partial<UploadSiteDocument>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UploadSiteDocument'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseSaveResponse(); }
}

export class DeleteSiteDocument implements IPrimaryEntity, ISiteReferenceRequest
{
    public siteId: number;
    public id: number;

    public constructor(init?: Partial<DeleteSiteDocument>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DeleteSiteDocument'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

export class GetSiteAudioFiles extends QueryBase implements IReturn<QueryResponse<SiteFileResponse>>, ISiteFilesRequest
{
    public id?: number;
    public ids: number[];
    public siteId: number;
    public nameContains: string;
    public name: string;

    public constructor(init?: Partial<GetSiteAudioFiles>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSiteAudioFiles'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QueryResponse<SiteFileResponse>(); }
}

export class UploadSiteAudio implements IReturn<BaseSaveResponse>, ISiteReferenceRequest
{
    public siteId: number;

    public constructor(init?: Partial<UploadSiteAudio>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UploadSiteAudio'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseSaveResponse(); }
}

export class DeleteSiteAudio implements IReturnVoid, IPrimaryEntity, ISiteReferenceRequest
{
    public id: number;
    public siteId: number;

    public constructor(init?: Partial<DeleteSiteAudio>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DeleteSiteAudio'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

export class GetScanSiteAudios extends QueryBase implements IReturn<QueryResponse<GetScanSiteAudioResponse>>, ISiteFileScannerRequest
{
    public id?: number;
    public ids: number[];
    public createdAtBefore?: string;
    public createdAtAfter?: string;
    public siteId?: number;
    public name: string;
    public nameContains: string;
    public status?: SiteFileStatus;
    public scoreLessThan?: number;
    public scoreGreaterThan?: number;
    public mediaId: string;

    public constructor(init?: Partial<GetScanSiteAudios>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'GetScanSiteAudios'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QueryResponse<GetScanSiteAudioResponse>(); }
}

// @Route("/currencies")
export class GetCurrencies extends QueryBase implements IReturn<QueryResponse<GetCurrenciesResponse>>
{
    public code: string;
    public countryCode: string;
    public isSupportedByAnyPaymentMethod: boolean;
    public isSupportedByStripe?: boolean;
    public isSupportedByPayPal?: boolean;

    public constructor(init?: Partial<GetCurrencies>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'GetCurrencies'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QueryResponse<GetCurrenciesResponse>(); }
}

export class GetStates extends QueryBase implements IReturn<QueryResponse<GetStateResponse>>, IPrimaryEntityRequest, IPrimaryListEntityRequest, ICodeEntityRequest, ISearchEntityRequest
{
    public id?: number;
    public ids: number[];
    public code: string;
    public search: string;
    public countryId?: number;
    public countryIds: number[];
    public isSupportedByNameCom?: boolean;

    public constructor(init?: Partial<GetStates>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'GetStates'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QueryResponse<GetStateResponse>(); }
}

// @Route("/countries")
export class GetCountries extends QueryBase implements IReturn<QueryResponse<GetCountriesResponse>>, IPrimaryListEntityRequest, ISearchEntityRequest
{
    public ids: number[];
    public code: string;
    public search: string;
    public isSupportedByStripe?: boolean;
    public isSupportedByNameCom?: boolean;

    public constructor(init?: Partial<GetCountries>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'GetCountries'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QueryResponse<GetCountriesResponse>(); }
}

export class GetUserGeo implements IReturn<GetUserGeoResponse>
{
    public ip: string;

    public constructor(init?: Partial<GetUserGeo>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetUserGeo'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetUserGeoResponse(); }
}

export class SaveFeedback implements IReturn<BaseSaveResponse>, IPrimaryEntity, ISiteReferenceEntity
{
    public id: number;
    public siteId: number;
    public subscribeFormId: number;
    public rating: number;
    public ratingType: RatingType;
    public fields: FormField[];
    public isSendEmail: boolean;

    public constructor(init?: Partial<SaveFeedback>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SaveFeedback'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseSaveResponse(); }
}

export class GetFeedbacks extends QueryBase implements IReturn<QueryResponse<GetFeedbackResponse>>, IPrimaryEntityRequest, IPrimaryListEntityRequest, ISiteReferenceRequest
{
    public id?: number;
    public ids: number[];
    public siteId: number;

    public constructor(init?: Partial<GetFeedbacks>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'GetFeedbacks'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QueryResponse<GetFeedbackResponse>(); }
}

export class GetSubscribeForms extends QueryBase implements IReturn<QueryResponse<GetSubscribeFormsResponse>>, IPrimaryEntityRequest, IPrimaryListEntityRequest, ISiteReferenceRequest
{
    public id?: number;
    public ids: number[];
    public siteId: number;
    public widgetType?: PopupWidgetType;
    public mailingListId?: number;
    public mailingListIds: number[];
    public search: string;

    public constructor(init?: Partial<GetSubscribeForms>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSubscribeForms'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QueryResponse<GetSubscribeFormsResponse>(); }
}

export class SaveSubscribeForm implements IReturn<GetSubscribeFormsResponse>, IPrimaryEntity, ISiteReferenceEntity, IDeletedEntity
{
    public id: number;
    public widgetType: PopupWidgetType;
    public type: SubscribeFormType;
    public position: SubscribeFormPosition;
    public transition: TransitionAnimationType;
    public variationType: SubscribeFormVariationType;
    public name: string;
    public subscribe: MarketingPopupSubscribe;
    public promotion: MarketingPopupPromotion;
    public contactUs: MarketingPopupContactUs;
    public feedback: MarketingPopupFeedback;
    public ageVerification: MarketiongPopupAgeAgeVerification;
    public socialBar: MarketingPopupSocialBar;
    public rules: SubscribeFormRule;
    public discountId?: number;
    public mailingListId?: number;
    public siteId: number;
    public isDeleted: boolean;
    public isFinished?: boolean;
    public backgroundColor: string;
    public contentAligmentType: number;
    public action?: OperationActionType;

    public constructor(init?: Partial<SaveSubscribeForm>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SaveSubscribeForm'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetSubscribeFormsResponse(); }
}

export class ActivateSubscribeForm implements ISiteReferenceRequest
{
    public id: number;
    public siteId: number;

    public constructor(init?: Partial<ActivateSubscribeForm>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ActivateSubscribeForm'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

export class AddSubscription implements IReturnVoid, ISiteReferenceRequest
{
    public subscribeFormId: number;
    public emailAddress: string;
    public siteId: number;

    public constructor(init?: Partial<AddSubscription>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AddSubscription'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

export class GetCurrentPageSeo implements IReturn<PageSeoResponse>, ISiteReferenceRequest
{
    public siteId: number;
    public pageType: PageSeoType;
    public pageId?: number;
    public slug: string;

    public constructor(init?: Partial<GetCurrentPageSeo>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetCurrentPageSeo'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PageSeoResponse(); }
}

export class SavePageSeo implements IReturnVoid, IPrimaryEntity, ISiteReferenceEntity
{
    public id: number;
    public siteId: number;
    public keywords: string[];
    public metaTitle: string;
    public metaDescription: string;
    public url: string;
    public pageType: PageSeoType;
    public pageId: number;

    public constructor(init?: Partial<SavePageSeo>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SavePageSeo'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

export class GetMailingLists extends QueryBase implements IReturn<QueryResponse<GetMailingListsResponse>>, IPrimaryEntityRequest, IPrimaryListEntityRequest, ISiteReferenceRequest
{
    public id?: number;
    public ids: number[];
    public siteId: number;
    public includeConactRecordsData?: boolean;
    public isDefault?: boolean;
    public search: string;

    public constructor(init?: Partial<GetMailingLists>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'GetMailingLists'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QueryResponse<GetMailingListsResponse>(); }
}

export class SaveMailingList implements IReturn<GetMailingListsResponse>, ISiteReferenceEntity, IDeletedEntity, IPrimaryEntity
{
    public id: number;
    public action?: OperationActionType;
    public name: string;
    public siteId: number;
    public isDeleted: boolean;
    public isDefault?: boolean;

    public constructor(init?: Partial<SaveMailingList>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SaveMailingList'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetMailingListsResponse(); }
}

export class UploadMailListFile implements IReturnVoid, ISiteReferenceRequest
{
    public id: number;
    public siteId: number;

    public constructor(init?: Partial<UploadMailListFile>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UploadMailListFile'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

export class DownloadMailListFile implements IReturnVoid, ISiteReferenceRequest
{
    public id: number;
    public siteId: number;

    public constructor(init?: Partial<DownloadMailListFile>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DownloadMailListFile'; }
    public getMethod() { return 'GET'; }
    public createResponse() {}
}

export class DownloadDemoMailListFile implements IReturnVoid, ISiteReferenceRequest
{
    public siteId: number;

    public constructor(init?: Partial<DownloadDemoMailListFile>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DownloadDemoMailListFile'; }
    public getMethod() { return 'GET'; }
    public createResponse() {}
}

// @Route("/mailing-list-contacts", "GET")
export class GetMailingListContacts extends QueryBase implements IReturn<QueryResponse<GetMailingListContactsResponse>>, IPrimaryEntityRequest, IPrimaryListEntityRequest, ISiteReferenceRequest
{
    public id?: number;
    public ids: number[];
    public mailingListId?: number;
    public mailingListIds: number[];
    public emails: string[];
    public siteId: number;
    public search: string;

    public constructor(init?: Partial<GetMailingListContacts>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'GetMailingListContacts'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QueryResponse<GetMailingListContactsResponse>(); }
}

export class SaveMailingListContact implements IReturn<GetMailingListContactsResponse>, IPrimaryEntity, ISiteReferenceEntity, IDeletedEntity, IAuditEntity, IEmailAddressInfo
{
    public id: number;
    public action?: OperationActionType;
    public email: string;
    public firstName: string;
    public lastName: string;
    public mailingListId: number;
    public source: string;
    public siteId: number;
    public isDeleted: boolean;
    public createdAt: string;
    public updatedAt: string;
    public isIgnoreExeption?: boolean;

    public constructor(init?: Partial<SaveMailingListContact>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SaveMailingListContact'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetMailingListContactsResponse(); }
}

export class UnsibscribeMailingListContact implements IReturn<Object>, ISiteReferenceEntity, IDeletedEntity
{
    public id?: number;
    public campaignId?: number;
    public siteId: number;
    public email: string;
    public isDeleted: boolean;

    public constructor(init?: Partial<UnsibscribeMailingListContact>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UnsibscribeMailingListContact'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new Object(); }
}

export class ImportExistingMailingListContacts implements IReturn<GetMailingListContactsResponse>, ISiteReferenceRequest
{
    public mailingListId: number;
    public importedMailingListId: number;
    public siteId: number;

    public constructor(init?: Partial<ImportExistingMailingListContacts>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ImportExistingMailingListContacts'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetMailingListContactsResponse(); }
}

// @ValidateRequest(Validator="SecureSiteService")
export class SendMarketingDiscussEmail implements IReturnVoid, IEntityLocalizeRequest
{
    public name: string;
    public email: string;
    public message: string;
    public languageCode: string;

    public constructor(init?: Partial<SendMarketingDiscussEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendMarketingDiscussEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

// @ValidateRequest(Validator="SecureSiteService")
export class SendOwnerFeedbackEmail implements IReturnVoid, ISiteReferenceRequest, IEntityLocalizeRequest
{
    public feedbackId: number;
    public siteId: number;
    public languageCode: string;

    public constructor(init?: Partial<SendOwnerFeedbackEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendOwnerFeedbackEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

export class SendDiscussEmail implements IReturnVoid
{
    public name: string;
    public email: string;
    public message: string;

    public constructor(init?: Partial<SendDiscussEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendDiscussEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

export class GetCampaignContacts extends QueryBase implements IReturn<QueryResponse<GetCampaignContactResponse>>, IPrimaryEntityRequest, IPrimaryListEntityRequest, ISiteReferenceRequest
{
    public id?: number;
    public ids: number[];
    public siteId: number;
    public campaignId: number;
    public search: string;
    public status?: CampaignContactStatus;

    public constructor(init?: Partial<GetCampaignContacts>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'GetCampaignContacts'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QueryResponse<GetCampaignContactResponse>(); }
}

export class SaveMarketingCampaignContact implements IReturn<BaseSaveResponse>, IPrimaryEntity, ISiteReferenceEntity, IActionRequest, IEmailAddressInfo
{
    public id: number;
    public action: OperationActionType;
    public campaignId: number;
    public status: CampaignContactStatus;
    public email: string;
    public firstName: string;
    public lastName: string;
    public siteId: number;
    public logId?: number;

    public constructor(init?: Partial<SaveMarketingCampaignContact>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SaveMarketingCampaignContact'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseSaveResponse(); }
}

export class FillCampaignContacts implements IReturnVoid, ISiteReferenceRequest
{
    public siteId: number;
    public campaignId: number;
    public mailListIds: number[];

    public constructor(init?: Partial<FillCampaignContacts>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'FillCampaignContacts'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

export class StartMarketingCampaign implements IReturnVoid, IPrimaryEntity, ISiteReferenceEntity
{
    public id: number;
    public siteId: number;

    public constructor(init?: Partial<StartMarketingCampaign>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'StartMarketingCampaign'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

export class StopMarketingCampaign implements IReturnVoid, IPrimaryEntity, ISiteReferenceEntity
{
    public id: number;
    public siteId: number;

    public constructor(init?: Partial<StopMarketingCampaign>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'StopMarketingCampaign'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

export class GetCampaigns extends QueryBase implements IReturn<QueryResponse<GetCampaignsResponse>>, IPrimaryEntityRequest, IPrimaryListEntityRequest, ISiteReferenceRequest
{
    public id?: number;
    public ids: number[];
    public siteId: number;
    public search: string;

    public constructor(init?: Partial<GetCampaigns>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'GetCampaigns'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QueryResponse<GetCampaignsResponse>(); }
}

export class SaveMarketingCampaign implements IReturn<GetCampaignsResponse>, IPrimaryEntity, IOwnerEntity, ISiteReferenceEntity
{
    public id: number;
    public name: string;
    public emailSubject: string;
    public fromName: string;
    public replyTo: string;
    public emailTemplate: string;
    public emailTemplateContentSettings: EmailTemplateContentSettings;
    public emailWidgetCategoryType: EmailWidgetCategoryType;
    public mailListIds: number[];
    public siteId: number;
    public userAuthId?: number;
    public publishTime: string;
    public action: OperationActionType;

    public constructor(init?: Partial<SaveMarketingCampaign>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SaveMarketingCampaign'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetCampaignsResponse(); }
}

export class SendTestMail implements IReturnVoid, IPrimaryEntity, IOwnerEntity, ISiteReferenceEntity
{
    public id: number;
    public status: CampaignStatus;
    public name: string;
    public emailSubject: string;
    public fromName: string;
    public replyTo: string;
    public emailTemplate: string;
    public emailTemplateContentSettings: EmailTemplateContentSettings;
    public emailWidgetCategoryType: EmailWidgetCategoryType;
    public mailListIds: number[];
    public siteId: number;
    public userAuthId?: number;
    public isDeleted: boolean;
    public publishTime: string;
    public createdAt: string;
    public updatedAt: string;
    public toAddress: string;

    public constructor(init?: Partial<SendTestMail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendTestMail'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

export class InsertAnalyticsRecord implements IReturnVoid, IPrimaryEntity
{
    public id: number;
    public siteId: number;
    public type: string;
    public timeStamp: string;
    public title: string;
    public url: string;
    public path: string;
    public hash: string;
    public search: string;
    public width: number;
    public height: number;
    public referrer: string;
    public userId: string;
    public anonymousId: string;
    public ip: string;
    public userAgent: string;
    public browser: string;
    public deviceCategory: string;
    public countryCode: string;

    public constructor(init?: Partial<InsertAnalyticsRecord>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'InsertAnalyticsRecord'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

export class GetAnalyticsReport implements IReturn<AnalyticsReport>, ISiteReferenceRequest
{
    public siteId: number;
    public startTime: string;
    public endtime: string;

    public constructor(init?: Partial<GetAnalyticsReport>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetAnalyticsReport'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AnalyticsReport(); }
}

export class GetTestimonialRecords extends QueryBase implements IReturn<QueryResponse<GetTestimonialRecordsResponse>>, IPrimaryEntityRequest, ISiteReferenceRequest
{
    public id?: number;
    public siteId: number;
    public status?: TestimonialRecordStatusType;
    public positivenessType?: TestimonialRecordPositivenessType;
    public startDate?: string;
    public endDate?: string;

    public constructor(init?: Partial<GetTestimonialRecords>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'GetTestimonialRecords'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QueryResponse<GetTestimonialRecordsResponse>(); }
}

export class SaveTestimonialRecord implements IReturn<GetTestimonialRecordsResponse>, TestimonialRecordDbSavableFields, IPrimaryEntity, ISiteReferenceEntity, IDeletedEntity, IAuditEntity
{
    public id: number;
    public action?: SaveTestimonialRecordActionType;
    public formId: number;
    public status?: TestimonialRecordStatusType;
    public userEmail: string;
    public userName: string;
    public userPosition: string;
    public userCity: string;
    public userAge: string;
    public userOther: string;
    public userReview: string;
    public userRating?: number;
    public maxRating?: number;
    public feedbackType?: TestimonialFormFeedbackType;
    public customizedFeedbacks: TestimonialFormCustomizedFeedback[];
    public openFeedback: string;
    public referralSource: string;
    public positivenessType?: TestimonialRecordPositivenessType;
    public siteId: number;
    public isDeleted: boolean;
    public createdAt: string;
    public updatedAt: string;

    public constructor(init?: Partial<SaveTestimonialRecord>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SaveTestimonialRecord'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetTestimonialRecordsResponse(); }
}

export class SaveFormHistory implements IReturnVoid, ISiteReferenceEntity
{
    public siteId: number;
    public formId?: number;
    public bookingRequestId?: number;

    public constructor(init?: Partial<SaveFormHistory>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SaveFormHistory'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

export class GetFreeForms extends QueryBase implements IReturn<QueryResponse<GetFreeFormsResponse>>, IPrimaryEntityRequest
{
    public id?: number;

    public constructor(init?: Partial<GetFreeForms>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'GetFreeForms'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QueryResponse<GetFreeFormsResponse>(); }
}

export class SaveFreeForm implements IReturn<GetFreeFormsResponse>, IPrimaryEntity
{
    public id: number;
    public data: string;

    public constructor(init?: Partial<SaveFreeForm>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SaveFreeForm'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetFreeFormsResponse(); }
}

export class GetTestimonialForms extends QueryBase implements IReturn<QueryResponse<GetTestimonialFormsResponse>>, IPrimaryEntityRequest, ISiteReferenceRequest
{
    public id?: number;
    public siteId: number;
    public feedbackRequestType?: TestimonialFormFeedbackRequestType;
    public publishStatus?: TestimonialFormPublishStatusType;
    public includeFormRecordsData?: boolean;
    public search: string;

    public constructor(init?: Partial<GetTestimonialForms>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'GetTestimonialForms'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QueryResponse<GetTestimonialFormsResponse>(); }
}

export class SaveTestimonialForm implements IReturn<GetTestimonialFormsResponse>, TestimonialFormDbSavableFields, IPrimaryEntity, ISiteReferenceEntity, IDeletedEntity
{
    public id: number;
    public action?: UpdateTestimonialFormActionType;
    public title: string;
    public description: string;
    public allowSocialMedia?: boolean;
    public allowImageUpload?: boolean;
    public userFields: TestimonialFormUserField[];
    public feedbackType?: TestimonialFormFeedbackType;
    public customizedFeedbacks: TestimonialFormCustomizedFeedback[];
    public ratingType?: RatingUiType;
    public ratingMaxValue?: number;
    public positiveLabel: string;
    public avarageLabel: string;
    public negativeLabel: string;
    public confirmationTitle: string;
    public confirmationDescription: string;
    public confirmationImage: BaseWidgetImage;
    public feedbackRequestType?: TestimonialFormFeedbackRequestType;
    public emailTriggerAfterPeriodValue?: number;
    public emailTriggerAfterPeriodType?: TestimonialFormEmailTriggerAfterPeriodType;
    public serviceIds: number[];
    public publishStatus?: TestimonialFormPublishStatusType;
    public isDeleted: boolean;
    public siteId: number;

    public constructor(init?: Partial<SaveTestimonialForm>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SaveTestimonialForm'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetTestimonialFormsResponse(); }
}

// @ValidateRequest(Validator="SecureSiteService")
export class SendOwnerRequestForFeedbackEmail implements IReturnVoid, ISiteReferenceRequest, IEntityLocalizeRequest
{
    public testimonialId: number;
    public siteId: number;
    public languageCode: string;

    public constructor(init?: Partial<SendOwnerRequestForFeedbackEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendOwnerRequestForFeedbackEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

// @ValidateRequest(Validator="SecureSiteService")
export class SendCustomerRequestForFeedbackEmail implements IReturnVoid, ISiteReferenceRequest, IEntityLocalizeRequest
{
    public testimonialFormId: number;
    public bookingRequestId: number;
    public siteId: number;
    public languageCode: string;

    public constructor(init?: Partial<SendCustomerRequestForFeedbackEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendCustomerRequestForFeedbackEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

export class WizardChatConnect implements IReturn<WizardChatConnectResponse>
{
    public message: string;

    public constructor(init?: Partial<WizardChatConnect>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'WizardChatConnect'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new WizardChatConnectResponse(); }
}

export class CreateContentPackStep implements IReturn<CreateContentPackStepResponse>, IEntityLocalizeRequest
{
    public businessCategoryName: string;
    public reserveBusinessCategoryId: number;
    public languageCode: string;

    public constructor(init?: Partial<CreateContentPackStep>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CreateContentPackStep'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CreateContentPackStepResponse(); }
}

export class GetWizardContentPackServices implements IReturn<GetWizardContentPackServicesResponse>, IEntityLocalizeRequest
{
    public categoryId: number;
    public languageCode: string;

    public constructor(init?: Partial<GetWizardContentPackServices>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetWizardContentPackServices'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetWizardContentPackServicesResponse(); }
}

export class WizardChatSendMessage implements IReturn<WizardChatGetMessagesResponse>
{
    public threadId: string;
    public message: string;

    public constructor(init?: Partial<WizardChatSendMessage>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'WizardChatSendMessage'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new WizardChatGetMessagesResponse(); }
}

export class WizardChatGetMessages implements IReturn<WizardChatGetMessagesResponse>
{
    public threadId: string;

    public constructor(init?: Partial<WizardChatGetMessages>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'WizardChatGetMessages'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new WizardChatGetMessagesResponse(); }
}

export class GetWizardAiBusinessCategory implements IReturn<GetWizardAiBusinessCategoryResponse>
{
    public categoryName: string;

    public constructor(init?: Partial<GetWizardAiBusinessCategory>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetWizardAiBusinessCategory'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetWizardAiBusinessCategoryResponse(); }
}

export class GetWizardAiServices implements IReturn<GetWizardAiServicesResponse>
{
    public serviceNames: string[];

    public constructor(init?: Partial<GetWizardAiServices>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetWizardAiServices'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetWizardAiServicesResponse(); }
}

export class GetStructurePacks extends QueryBase implements IReturn<QueryResponse<GetStructurePacksResponse>>, IPrimaryEntityRequest, IPrimaryListEntityRequest, ISearchEntityRequest
{
    public id?: number;
    public ids: number[];
    public pageType?: PageType;
    public order?: number;
    public appType?: StructurePackAppType;
    public search: string;

    public constructor(init?: Partial<GetStructurePacks>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'GetStructurePacks'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QueryResponse<GetStructurePacksResponse>(); }
}

export class SaveStructurePack implements IReturn<BaseSaveResponse>, IPrimaryEntity, IDisplayEntity, IActionRequest
{
    public id: number;
    public name: string;
    public action: OperationActionType;
    public order: number;
    public pageTypeBlueprints: { [index: string]: PageBlueprint[]; };
    public pageTypeIds: PageType[];
    public appType: StructurePackAppType;
    public widgetContainerType?: WidgetContainerType;

    public constructor(init?: Partial<SaveStructurePack>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SaveStructurePack'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseSaveResponse(); }
}

export class GetDesignDefaultContentPages extends QueryBase implements IReturn<QueryResponse<GetDesignDefaultContentPagesResponse>>, IPrimaryEntityRequest, IPrimaryListEntityRequest, ISearchEntityRequest
{
    public id?: number;
    public ids: number[];
    public pageType?: PageType;
    public search: string;

    public constructor(init?: Partial<GetDesignDefaultContentPages>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'GetDesignDefaultContentPages'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QueryResponse<GetDesignDefaultContentPagesResponse>(); }
}

export class SaveDesignDefaultContentPage implements IReturn<BaseSaveResponse>, IPrimaryEntity, IDisplayEntity, IActionRequest
{
    public id: number;
    public name: string;
    public action: OperationActionType;
    public pageType: PageType;
    public widgets: BaseWidget[];

    public constructor(init?: Partial<SaveDesignDefaultContentPage>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SaveDesignDefaultContentPage'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseSaveResponse(); }
}

export class GetDesignPacks extends QueryBase implements IReturn<QueryResponse<GetDesignPacksResponse>>, IPrimaryEntityRequest, IPrimaryListEntityRequest, ISearchEntityRequest
{
    public id?: number;
    public ids: number[];
    public search: string;

    public constructor(init?: Partial<GetDesignPacks>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'GetDesignPacks'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QueryResponse<GetDesignPacksResponse>(); }
}

export class SaveDesignPack implements IReturn<BaseSaveResponse>, IPrimaryEntity, IDisplayEntity, IActionRequest
{
    public id: number;
    public name: string;
    public action: OperationActionType;
    public logo: BaseWidgetImage;
    public thumbnail: string;
    public designPackImageId?: number;
    public contentPackId?: number;
    public structurePackId?: number;
    public widgets: BaseWidget[];
    public colorPaletteId?: number;
    public colorPaletteIds: number[];
    public fontGroupId?: number;
    public fontGroupIds: number[];
    public colorMappingId?: number;
    public fontMappingId?: number;
    public boxMappingId?: number;

    public constructor(init?: Partial<SaveDesignPack>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SaveDesignPack'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseSaveResponse(); }
}

export class GetFontMappings extends QueryBase implements IReturn<QueryResponse<GetFontMappingsResponse>>, IPrimaryEntityRequest, IPrimaryListEntityRequest
{
    public id?: number;
    public ids: number[];

    public constructor(init?: Partial<GetFontMappings>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'GetFontMappings'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QueryResponse<GetFontMappingsResponse>(); }
}

export class SaveFontMapping implements IReturn<BaseSaveResponse>, IPrimaryEntity, IActionRequest
{
    public id: number;
    public action: OperationActionType;
    public settings: { [index: string]: UiFontSettings; };

    public constructor(init?: Partial<SaveFontMapping>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SaveFontMapping'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseSaveResponse(); }
}

export class GetFontGroups extends QueryBase implements IReturn<QueryResponse<GetFontGroupsResponse>>, IPrimaryEntityRequest, IPrimaryListEntityRequest, ISearchEntityRequest
{
    public id?: number;
    public ids: number[];
    public search: string;
    public fontMappingId?: number;
    public type?: DesignPackFontGroupType;
    public includeMegaDefault?: boolean;
    public siteId?: number;

    public constructor(init?: Partial<GetFontGroups>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'GetFontGroups'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QueryResponse<GetFontGroupsResponse>(); }
}

export class SaveFontGroup implements IReturn<BaseSaveResponse>, IPrimaryEntity, IDisplayEntity, IActionRequest
{
    public id: number;
    public name: string;
    public action: OperationActionType;
    public fonts: ThemeFont[];
    public fontMappingId: number;
    public types: number[];
    public isMegaDefault?: boolean;
    public siteId?: number;

    public constructor(init?: Partial<SaveFontGroup>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SaveFontGroup'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseSaveResponse(); }
}

export class ApplyFontGroupToAllDesingPack implements IReturnVoid, IPrimaryEntity
{
    public id: number;
    public isApply: boolean;

    public constructor(init?: Partial<ApplyFontGroupToAllDesingPack>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ApplyFontGroupToAllDesingPack'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

export class GetDesignPackImages extends QueryBase implements IReturn<QueryResponse<GetDesignPackImagesResponse>>, IPrimaryEntityRequest, IPrimaryListEntityRequest, ISearchEntityRequest
{
    public id?: number;
    public ids: number[];
    public colorMappingId?: number;
    public search: string;

    public constructor(init?: Partial<GetDesignPackImages>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'GetDesignPackImages'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QueryResponse<GetDesignPackImagesResponse>(); }
}

export class SaveDesignPackImage implements IReturn<BaseSaveResponse>, IPrimaryEntity, IDisplayEntity, IActionRequest
{
    public id: number;
    public name: string;
    public action: OperationActionType;
    public visibleElementsSettings: { [index: string]: WidgetVisibleElementSetting; };
    public aboutUs: { [index: string]: ImagePackVariationSetting[]; };
    public banner: { [index: string]: ImagePackVariationSetting[]; };
    public blog: { [index: string]: ImagePackVariationSetting[]; };
    public blogImage: { [index: string]: ImagePackVariationSetting[]; };
    public blogImageWithText: { [index: string]: ImagePackVariationSetting[]; };
    public blogGallery: { [index: string]: ImagePackVariationSetting[]; };
    public blogSubscribe: { [index: string]: ImagePackVariationSetting[]; };
    public blogAboutAuthor: { [index: string]: ImagePackVariationSetting[]; };
    public blogTopPosts: { [index: string]: ImagePackVariationSetting[]; };
    public blogRecipe: { [index: string]: ImagePackVariationSetting[]; };
    public blogSummary: { [index: string]: ImagePackVariationSetting[]; };
    public blogFeaturedItems: { [index: string]: ImagePackVariationSetting[]; };
    public productBundle: { [index: string]: ImagePackVariationSetting[]; };
    public productAdditionalInfoProductDescription: { [index: string]: ImagePackVariationSetting[]; };
    public productAdditionalInfoFeatures: { [index: string]: ImagePackVariationSetting[]; };
    public productAdditionalInfoSizeAndFit: { [index: string]: ImagePackVariationSetting[]; };
    public productAdditionalInfoShippingAndDelivery: { [index: string]: ImagePackVariationSetting[]; };
    public productAdditionalInfoReviews: { [index: string]: ImagePackVariationSetting[]; };
    public booking: { [index: string]: ImagePackVariationSetting[]; };
    public comments: { [index: string]: ImagePackVariationSetting[]; };
    public contactUs: { [index: string]: ImagePackVariationSetting[]; };
    public eventsApp: { [index: string]: ImagePackVariationSetting[]; };
    public faq: { [index: string]: ImagePackVariationSetting[]; };
    public featuredItems: { [index: string]: ImagePackVariationSetting[]; };
    public gallery: { [index: string]: ImagePackVariationSetting[]; };
    public logos: { [index: string]: ImagePackVariationSetting[]; };
    public marketing: { [index: string]: ImagePackVariationSetting[]; };
    public ourTeam: { [index: string]: ImagePackVariationSetting[]; };
    public priceList: { [index: string]: ImagePackVariationSetting[]; };
    public services: { [index: string]: ImagePackVariationSetting[]; };
    public slider: { [index: string]: ImagePackVariationSetting[]; };
    public smartBanner: { [index: string]: ImagePackVariationSetting[]; };
    public store: { [index: string]: ImagePackVariationSetting[]; };
    public subscribe: { [index: string]: ImagePackVariationSetting[]; };
    public testimonials: { [index: string]: ImagePackVariationSetting[]; };
    public testiomonialForm: { [index: string]: ImagePackVariationSetting[]; };
    public text: { [index: string]: ImagePackVariationSetting[]; };
    public video: { [index: string]: ImagePackVariationSetting[]; };
    public career: { [index: string]: ImagePackVariationSetting[]; };
    public music: { [index: string]: ImagePackVariationSetting[]; };
    public resources: { [index: string]: ImagePackVariationSetting[]; };
    public menu: { [index: string]: ImagePackVariationSetting[]; };
    public table: { [index: string]: ImagePackVariationSetting[]; };

    public constructor(init?: Partial<SaveDesignPackImage>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SaveDesignPackImage'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseSaveResponse(); }
}

export class GetColorPalettes extends QueryBase implements IReturn<QueryResponse<GetColorPalettesResponse>>, IPrimaryEntityRequest, IPrimaryListEntityRequest, ISearchEntityRequest
{
    public id?: number;
    public ids: number[];
    public siteId?: number;
    public search: string;
    public includeMegaDefault?: boolean;
    public isExcludeGeneratedByUser?: boolean;

    public constructor(init?: Partial<GetColorPalettes>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'GetColorPalettes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QueryResponse<GetColorPalettesResponse>(); }
}

export class SaveColorPalette implements IReturn<BaseSaveResponse>, IPrimaryEntity, IDisplayEntity, IActionRequest
{
    public id: number;
    public name: string;
    public action: OperationActionType;
    public colors: { [index: string]: string; };
    public colorMappingId?: number;
    public siteId?: number;
    public isMegaDefault?: boolean;

    public constructor(init?: Partial<SaveColorPalette>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SaveColorPalette'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseSaveResponse(); }
}

export class ApplyColorPaletteToAllDesingPack implements IReturnVoid
{
    public id: number;
    public isApply: boolean;

    public constructor(init?: Partial<ApplyColorPaletteToAllDesingPack>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ApplyColorPaletteToAllDesingPack'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

export class GetColorMappings extends QueryBase implements IReturn<QueryResponse<GetColorMappingsResponse>>, IPrimaryEntityRequest, IPrimaryListEntityRequest
{
    public id?: number;
    public ids: number[];

    public constructor(init?: Partial<GetColorMappings>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'GetColorMappings'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QueryResponse<GetColorMappingsResponse>(); }
}

export class SaveColorMapping implements IReturn<BaseSaveResponse>, IPrimaryEntity, IDisplayEntity, IActionRequest
{
    public id: number;
    public name: string;
    public action: OperationActionType;
    public settings: { [index: string]: UiColorSettings; };

    public constructor(init?: Partial<SaveColorMapping>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SaveColorMapping'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseSaveResponse(); }
}

export class GetBoxMappings extends QueryBase implements IReturn<QueryResponse<GetBoxMappingsResponse>>, IPrimaryEntityRequest, IPrimaryListEntityRequest, ISearchEntityRequest
{
    public id?: number;
    public ids: number[];
    public search: string;

    public constructor(init?: Partial<GetBoxMappings>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'GetBoxMappings'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QueryResponse<GetBoxMappingsResponse>(); }
}

export class SaveBoxMapping implements IReturn<BaseSaveResponse>, IPrimaryEntity, IDisplayEntity, IActionRequest
{
    public id: number;
    public name: string;
    public action: OperationActionType;
    public settings: { [index: string]: UiBoxSettings; };
    public linkButtonLabelType: LinkButtonLabelType;
    public linkButtonLabelIcon: BaseWidgetImage;
    public sliderIndicatorsType: SliderIndicatorsType;
    public sliderLeftNavIcon: BaseWidgetImage;
    public sliderRightNavIcon: BaseWidgetImage;
    public sliderBottomLeftNavIcon: BaseWidgetImage;
    public sliderBottomRightNavIcon: BaseWidgetImage;
    public mapStyleSetting: UiBoxMapStyleSetting;
    public widgetSliderSettings: BaseWidgetSliderSettings;

    public constructor(init?: Partial<SaveBoxMapping>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SaveBoxMapping'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseSaveResponse(); }
}

export class GenerateUserRephrase implements IReturn<GenerateUserRephraseResponse>, ISiteReferenceRequest, IEntityLocalizeRequest
{
    public widgetName: string;
    public elementName: string;
    public toneOfVoice: ToneOfVoice;
    public value: string;
    public dependentOn: { [index: string]: string; };
    public siteId: number;
    public languageCode: string;

    public constructor(init?: Partial<GenerateUserRephrase>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GenerateUserRephrase'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GenerateUserRephraseResponse(); }
}

export class GenerateUserNewText implements IReturn<GenerateUserNewTextResponse>, ISiteReferenceRequest, IEntityLocalizeRequest
{
    public widgetName: string;
    public isGeneric: boolean;
    public elementName: string;
    public toneOfVoice: ToneOfVoice;
    public value: string;
    public dependentOn: { [index: string]: string; };
    public siteId: number;
    public maxCharacters?: number;
    public languageCode: string;

    public constructor(init?: Partial<GenerateUserNewText>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GenerateUserNewText'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GenerateUserNewTextResponse(); }
}

export class CreateAiContentPack implements IReturn<CreateAiContentPackResponse>
{
    public businessCategoryId: number;

    public constructor(init?: Partial<CreateAiContentPack>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CreateAiContentPack'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CreateAiContentPackResponse(); }
}

export class SaveContentPackLcz implements IReturnVoid, IEntitySetLczRequest
{
    public id: number;
    public languageCode: string;
    public columnName: string;
    public key: string;
    public value: string;

    public constructor(init?: Partial<SaveContentPackLcz>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SaveContentPackLcz'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

export class TranslateContentPack extends BaseTranslateRecordRequest implements IReturn<BaseResponse>
{

    public constructor(init?: Partial<TranslateContentPack>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'TranslateContentPack'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

export class GetContentPackLczConfig extends BaseGetEntityLczConfig implements IReturn<QueryResponse<GetContentPackLczConfigResponse>>, IDisplayEntityRequest
{
    public name: string;
    public isDynamicCategoriesOnly: boolean;

    public constructor(init?: Partial<GetContentPackLczConfig>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'GetContentPackLczConfig'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QueryResponse<GetContentPackLczConfigResponse>(); }
}

export class BuildContentPackLocalizeConfig extends BaseBuildRecordLocalizeConfigRequest implements IReturn<BuildRecordLocalizeConfigResponse<BuildContentPackLocalizeConfigItem>>
{
    public useRephrase: boolean;

    public constructor(init?: Partial<BuildContentPackLocalizeConfig>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'BuildContentPackLocalizeConfig'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BuildRecordLocalizeConfigResponse<BuildContentPackLocalizeConfigItem>(); }
}

export class ExportContentPackLczReport implements IReturnVoid, IExportLczReport
{
    public id?: number;
    public ids: number[];

    public constructor(init?: Partial<ExportContentPackLczReport>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ExportContentPackLczReport'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

export class ImportContentPackLczReport implements IReturnVoid, IImportLczReport
{

    public constructor(init?: Partial<ImportContentPackLczReport>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ImportContentPackLczReport'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

export class GetContentPackRephraseLczConfig extends BaseGetEntityLczConfig implements IReturn<QueryResponse<GetContentPackRephraseLczConfigResponse>>
{
    public contentPackId?: number;
    public widgetName: string;
    public elementName: string;

    public constructor(init?: Partial<GetContentPackRephraseLczConfig>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'GetContentPackRephraseLczConfig'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QueryResponse<GetContentPackRephraseLczConfigResponse>(); }
}

export class SaveContentPackRephraseLcz implements IReturnVoid, IEntitySetLczRequest
{
    public id: number;
    public languageCode: string;
    public columnName: string;
    public key: string;
    public value: string;

    public constructor(init?: Partial<SaveContentPackRephraseLcz>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SaveContentPackRephraseLcz'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

export class TranslateContentPackRephrase extends BaseTranslateRecordRequest implements IReturn<BaseResponse>
{

    public constructor(init?: Partial<TranslateContentPackRephrase>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'TranslateContentPackRephrase'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

export class BuildContentPackRephraseLocalizeConfig extends BaseBuildRecordLocalizeConfigRequest implements IReturn<BuildRecordLocalizeConfigResponse<BuildContentPackRephraseLocalizeConfigItem>>
{
    public contentPackId?: number;

    public constructor(init?: Partial<BuildContentPackRephraseLocalizeConfig>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'BuildContentPackRephraseLocalizeConfig'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BuildRecordLocalizeConfigResponse<BuildContentPackRephraseLocalizeConfigItem>(); }
}

export class ExportContentPackRephraseLczReport implements IReturnVoid, IExportLczReport
{
    public id?: number;
    public ids: number[];

    public constructor(init?: Partial<ExportContentPackRephraseLczReport>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ExportContentPackRephraseLczReport'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

export class ImportContentPackRephraseLczReport implements IReturnVoid, IImportLczReport
{

    public constructor(init?: Partial<ImportContentPackRephraseLczReport>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ImportContentPackRephraseLczReport'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

export class GetContentPackRephrases extends QueryBase implements IReturn<QueryResponse<GetContentPackRephraseResponse>>, IPrimaryEntityRequest, IPrimaryListEntityRequest
{
    public id?: number;
    public ids: number[];
    public widgetName: string;
    public elementName: string;
    public elementId: string;
    public contentPackId?: number;

    public constructor(init?: Partial<GetContentPackRephrases>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'GetContentPackRephrases'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QueryResponse<GetContentPackRephraseResponse>(); }
}

export class SaveContentPackRephrase implements IReturn<BaseSaveResponse>
{
    public id: number;
    public widgetName: string;
    public elementName: string;
    public elementId: string;
    public contentPackId?: number;
    public rephrases: LocalizeValue[];

    public constructor(init?: Partial<SaveContentPackRephrase>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SaveContentPackRephrase'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseSaveResponse(); }
}

export class GetContentPack implements IReturn<GetContentPacksResponse>, IEntityLocalizeRequest
{
    public id: number;
    public useRephrase: boolean;
    public fields: string;
    public languageCode: string;

    public constructor(init?: Partial<GetContentPack>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetContentPack'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetContentPacksResponse(); }
}

export class GetContentPacks extends QueryBase implements IReturn<QueryResponse<GetContentPacksResponse>>, IPrimaryEntityRequest, IPrimaryListEntityRequest, IEntityLocalizeRequest
{
    public id?: number;
    public ids: number[];
    public colorMappingId?: number;
    public search: string;
    public languageCode: string;
    public isPublic?: boolean;

    public constructor(init?: Partial<GetContentPacks>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'GetContentPacks'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QueryResponse<GetContentPacksResponse>(); }
}

export class GetGenericContentPack implements IReturn<GetContentPacksResponse>
{
    public fields: string;

    public constructor(init?: Partial<GetGenericContentPack>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetGenericContentPack'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetContentPacksResponse(); }
}

export class SaveContentPack implements IReturn<GetContentPacksResponse>, IEntityLocalizeRequest, IAuditEntity
{
    public id: number;
    public createdAt: string;
    public updatedAt: string;
    public name: string;
    public action?: UpdateContentPackActionType;
    public aboutUs: ContentPackWidgetCategoryAboutUsSetting;
    public banner: ContentPackWidgetCategoryBannerSetting;
    public blog: ContentPackWidgetCategoryBlogSetting;
    public blogTitle: ContentPackWidgetCategoryBlogTitleSetting;
    public blogText: ContentPackWidgetCategoryBlogTextSetting;
    public blogButton: ContentPackWidgetCategoryBlogButtonSetting;
    public blogGallery: ContentPackWidgetCategoryBlogGallerySetting;
    public blogVideo: ContentPackWidgetCategoryBlogVideoSetting;
    public blogImage: ContentPackWidgetCategoryBlogImageSetting;
    public blogImageWithText: ContentPackWidgetCategoryBlogImageWithTextSetting;
    public blogQuote: ContentPackWidgetCategoryBlogQuoteSetting;
    public blogSubscribe: ContentPackWidgetCategorySubscribeSetting;
    public blogActions: ContentPackWidgetCategoryBlogActionsSetting;
    public blogAboutAuthor: ContentPackWidgetCategoryBlogAboutAuthorSetting;
    public blogTopPosts: ContentPackWidgetCategoryBlogTopPostsSetting;
    public blogRecipe: ContentPackWidgetCategoryBlogRecipeSetting;
    public blogSummary: ContentPackWidgetCategoryBlogSummarySetting;
    public blogFeaturedItems: ContentPackWidgetCategoryBlogFeaturedItemsSetting;
    public productBundle: ContentPackWidgetCategoryProductBundleSetting;
    public productAdditionalInfoProductDescription: ContentPackWidgetCategoryProductAdditionalInfoProductDescription;
    public productAdditionalInfoFeatures: ContentPackWidgetCategoryProductAdditionalInfoFeatures;
    public productAdditionalInfoSizeAndFit: ContentPackWidgetCategoryProductAdditionalInfoSizeAndFit;
    public productAdditionalInfoShippingAndDelivery: ContentPackWidgetCategoryProductAdditionalInfoShippingAndDelivery;
    public productAdditionalInfoReviews: ContentPackWidgetCategoryProductAdditionalInfoReviews;
    public booking: ContentPackWidgetCategoryBookingSetting;
    public comments: ContentPackWidgetCategoryCommentsSetting;
    public contactUs: ContentPackWidgetCategoryContactUsSetting;
    public events: ContentPackWidgetCategoryEventsSetting;
    public faq: ContentPackWidgetCategoryFaqSetting;
    public featuredItems: ContentPackWidgetCategoryFeaturedItemsSetting;
    public gallery: ContentPackWidgetCategoryGallerySetting;
    public logos: ContentPackWidgetCategoryLogosSetting;
    public ourTeam: ContentPackWidgetCategoryOurTeamSetting;
    public priceList: ContentPackWidgetCategoryPriceListSetting;
    public services: ContentPackWidgetCategoryServiceSetting;
    public slider: ContentPackWidgetCategorySliderSetting;
    public smartBanner: ContentPackWidgetCategorySmartBannerSetting;
    public subscribe: ContentPackWidgetCategorySubscribeSetting;
    public testimonials: ContentPackWidgetCategoryTestimonialstSetting;
    public testiomonialForm: ContentPackWidgetCategoryTestiomonialFormSetting;
    public text: ContentPackWidgetCategoryTextSetting;
    public video: ContentPackWidgetCategoryVideoSetting;
    public career: ContentPackWidgetCategoryCareerSetting;
    public music: ContentPackWidgetCategoryMusicSetting;
    public table: ContentPackWidgetCategoryTableSetting;
    public resources: ContentPackWidgetCategoryResourcesSetting;
    public menu: ContentPackWidgetCategoryMenuSetting;
    public isDeleted?: boolean;
    public languageCode: string;

    public constructor(init?: Partial<SaveContentPack>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SaveContentPack'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetContentPacksResponse(); }
}

export class ConvertContentPackDbLczStructure implements IReturnVoid
{

    public constructor(init?: Partial<ConvertContentPackDbLczStructure>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConvertContentPackDbLczStructure'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

export class GetWidgetsLcz implements IReturn<GetWidgetsLczResponse>
{
    public widgets: BaseWidget[];
    public contentPackId: number;

    public constructor(init?: Partial<GetWidgetsLcz>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetWidgetsLcz'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetWidgetsLczResponse(); }
}

export class GetBusinessIndustryLczConfig extends BaseGetEntityLczConfig implements IReturn<QueryResponse<GetBusinessIndustryLczConfigResponse>>, IDisplayEntityRequest
{
    public name: string;

    public constructor(init?: Partial<GetBusinessIndustryLczConfig>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'GetBusinessIndustryLczConfig'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QueryResponse<GetBusinessIndustryLczConfigResponse>(); }
}

export class SaveBusinessIndustryLcz implements IReturnVoid, IEntitySetLczRequest
{
    public id: number;
    public languageCode: string;
    public columnName: string;
    public key: string;
    public value: string;
    public isUserChange: boolean;

    public constructor(init?: Partial<SaveBusinessIndustryLcz>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SaveBusinessIndustryLcz'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

export class TranslateBusinessIndustry extends BaseTranslateRecordRequest implements IReturn<BaseResponse>
{

    public constructor(init?: Partial<TranslateBusinessIndustry>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'TranslateBusinessIndustry'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

export class ExportBusinessIndustryLczReport implements IReturnVoid, IExportLczReport
{
    public id?: number;
    public ids: number[];

    public constructor(init?: Partial<ExportBusinessIndustryLczReport>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ExportBusinessIndustryLczReport'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

export class BuildBusinessIndustryLocalizeConfig extends BaseBuildRecordLocalizeConfigRequest implements IReturn<BuildRecordLocalizeConfigResponse<BuildDisplayRecordLocalizeConfigItem>>
{

    public constructor(init?: Partial<BuildBusinessIndustryLocalizeConfig>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'BuildBusinessIndustryLocalizeConfig'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BuildRecordLocalizeConfigResponse<BuildDisplayRecordLocalizeConfigItem>(); }
}

export class ImportBusinessIndustryLczReport implements IReturnVoid, IImportLczReport
{

    public constructor(init?: Partial<ImportBusinessIndustryLczReport>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ImportBusinessIndustryLczReport'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

export class GetBusinessIndustries extends QueryBase implements IReturn<QueryResponse<GetBusinessIndustryResponse>>, IPrimaryEntityRequest, ISearchEntityRequest, IPrimaryListEntityRequest, IEntityLocalizeRequest
{
    public id?: number;
    public ids: number[];
    public search: string;
    public groupId?: number;
    public languageCode: string;

    public constructor(init?: Partial<GetBusinessIndustries>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'GetBusinessIndustries'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QueryResponse<GetBusinessIndustryResponse>(); }
}

export class GetBusinessGroupLczConfig extends BaseGetEntityLczConfig implements IReturn<QueryResponse<GetBusinessGroupLczConfigResponse>>, ICodeEntityRequest, IDisplayEntityRequest
{
    public code: string;
    public name: string;

    public constructor(init?: Partial<GetBusinessGroupLczConfig>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'GetBusinessGroupLczConfig'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QueryResponse<GetBusinessGroupLczConfigResponse>(); }
}

export class SaveBusinessGroupLcz implements IReturnVoid, IEntitySetLczRequest
{
    public id: number;
    public languageCode: string;
    public columnName: string;
    public key: string;
    public value: string;
    public isUserChange: boolean;

    public constructor(init?: Partial<SaveBusinessGroupLcz>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SaveBusinessGroupLcz'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

export class BuildBusinessGroupLocalizeConfig extends BaseBuildRecordLocalizeConfigRequest implements IReturn<BuildRecordLocalizeConfigResponse<BuildDisplayRecordLocalizeConfigItem>>
{

    public constructor(init?: Partial<BuildBusinessGroupLocalizeConfig>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'BuildBusinessGroupLocalizeConfig'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BuildRecordLocalizeConfigResponse<BuildDisplayRecordLocalizeConfigItem>(); }
}

export class TranslateBusinessGroup extends BaseTranslateRecordRequest implements IReturn<BaseResponse>
{

    public constructor(init?: Partial<TranslateBusinessGroup>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'TranslateBusinessGroup'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

export class ExportBusinessGroupLczReport implements IReturnVoid, IExportLczReport
{
    public id?: number;
    public ids: number[];

    public constructor(init?: Partial<ExportBusinessGroupLczReport>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ExportBusinessGroupLczReport'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

export class ImportBusinessGroupLczReport implements IReturnVoid, IImportLczReport
{

    public constructor(init?: Partial<ImportBusinessGroupLczReport>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ImportBusinessGroupLczReport'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

export class GetBusinessGroups extends QueryBase implements IReturn<QueryResponse<GetBusinessGroupResponse>>, IPrimaryEntityRequest, IPrimaryListEntityRequest, IEntityLocalizeRequest, ISearchEntityRequest, ICodeEntityRequest
{
    public id?: number;
    public ids: number[];
    public code: string;
    public search: string;
    public languageCode: string;

    public constructor(init?: Partial<GetBusinessGroups>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'GetBusinessGroups'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QueryResponse<GetBusinessGroupResponse>(); }
}

export class GetBusinessCategoryLczConfig extends BaseGetEntityLczConfig implements IReturn<QueryResponse<GetBusinessCategoryLczConfigResponse>>, IDisplayEntityRequest
{
    public name: string;
    public isOnlySystem: boolean;
    public isPublic?: boolean;
    public userId?: number;
    public userIdIsNull?: boolean;

    public constructor(init?: Partial<GetBusinessCategoryLczConfig>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'GetBusinessCategoryLczConfig'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QueryResponse<GetBusinessCategoryLczConfigResponse>(); }
}

export class SaveBusinessCategoryLcz implements IReturnVoid, IEntitySetLczRequest
{
    public id: number;
    public languageCode: string;
    public columnName: string;
    public key: string;
    public value: string;
    public isUserChange: boolean;

    public constructor(init?: Partial<SaveBusinessCategoryLcz>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SaveBusinessCategoryLcz'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

export class BuildBusinessCategoryLocalizeConfig extends BaseBuildRecordLocalizeConfigRequest implements IReturn<BuildRecordLocalizeConfigResponse<BuildDisplayRecordLocalizeConfigItem>>
{

    public constructor(init?: Partial<BuildBusinessCategoryLocalizeConfig>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'BuildBusinessCategoryLocalizeConfig'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BuildRecordLocalizeConfigResponse<BuildDisplayRecordLocalizeConfigItem>(); }
}

export class TranslateBusinessCategory extends BaseTranslateRecordRequest implements IReturn<BaseResponse>
{

    public constructor(init?: Partial<TranslateBusinessCategory>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'TranslateBusinessCategory'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

export class ExportBusinessCategoryLczReport implements IReturnVoid, IExportLczReport
{
    public id?: number;
    public ids: number[];

    public constructor(init?: Partial<ExportBusinessCategoryLczReport>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ExportBusinessCategoryLczReport'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

export class ImportBusinessCategoryLczReport implements IReturnVoid, IImportLczReport
{

    public constructor(init?: Partial<ImportBusinessCategoryLczReport>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ImportBusinessCategoryLczReport'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

export class SearchBusinessCategories implements IReturn<SearchBusinessCategoriesResponse>, ISearchEntityRequest
{
    public search: string;

    public constructor(init?: Partial<SearchBusinessCategories>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SearchBusinessCategories'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SearchBusinessCategoriesResponse(); }
}

export class SearchAllBusinessCategories implements IReturn<SearchAllBusinessCategoriesResponse>, IPrimaryListEntityRequest, IEntityLocalizeRequest
{
    public groupId?: number;
    public categoriesInIndustryLimit?: number;
    public search: string;
    public ids: number[];
    public useAi: boolean;
    public languageCode: string;

    public constructor(init?: Partial<SearchAllBusinessCategories>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SearchAllBusinessCategories'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SearchAllBusinessCategoriesResponse(); }
}

export class GetBusinessCategories extends QueryBase implements IReturn<QueryResponse<GetBusinessCategoryResponse>>, IEntityLocalizeRequest, IOnlyActiveEntityRequest, IPrimaryListEntityRequest, ISearchEntityRequest
{
    public id?: number;
    public ids: number[];
    public name: string;
    public nameIsNotNull?: boolean;
    public hasAnyDesignPackIds: number[];
    public hasAnyContentPackIds: number[];
    public hasAnyStructurePackIds: number[];
    public industryId?: number;
    public groupId?: number;
    public search: string;
    public includeDefaults: boolean;
    public includeUsers: boolean;
    public includeDeleted: boolean;
    public categoryUserId?: number;
    public isCompleted?: boolean;
    public isPublic?: boolean;
    public languageCode: string;

    public constructor(init?: Partial<GetBusinessCategories>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'GetBusinessCategories'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QueryResponse<GetBusinessCategoryResponse>(); }
}

export class SaveBusinessCategory implements IReturn<GetBusinessCategoryResponse>, IPrimaryEntity, IAuditEntity, IEntityLocalizeRequest
{
    public id: number;
    public name: string;
    public designPackIds: number[];
    public contentPackIds: number[];
    public structurePackIds: number[];
    public userId?: number;
    public isDeleted?: boolean;
    public isDefault?: boolean;
    public hasServices?: boolean;
    public groupId?: number;
    public industryId?: number;
    public createdAt: string;
    public updatedAt: string;
    public action: OperationActionType;
    public isUpdateEmbedding: boolean;
    public languageCode: string;
    public isCompleted?: boolean;
    public isPublic?: boolean;

    public constructor(init?: Partial<SaveBusinessCategory>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SaveBusinessCategory'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetBusinessCategoryResponse(); }
}

export class SaveCategoryEmbeddings implements IPrimaryListEntityRequest
{
    public ids: number[];

    public constructor(init?: Partial<SaveCategoryEmbeddings>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SaveCategoryEmbeddings'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

export class GetAiSearchBusinessCategories implements IReturn<GetSearchSimilarityBusinessCategoriesResponse>, IEntityLocalizeRequest
{
    public search: string;
    public languageCode: string;

    public constructor(init?: Partial<GetAiSearchBusinessCategories>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetAiSearchBusinessCategories'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetSearchSimilarityBusinessCategoriesResponse(); }
}

export class GetSearchSimilarityBusinessCategories implements IReturn<GetSearchSimilarityBusinessCategoriesResponse>
{
    public search: string;

    public constructor(init?: Partial<GetSearchSimilarityBusinessCategories>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSearchSimilarityBusinessCategories'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetSearchSimilarityBusinessCategoriesResponse(); }
}

export class SendSiteNotification implements IReturnVoid, ISiteReferenceRequest
{
    public siteId: number;
    public notification: SiteNotification;

    public constructor(init?: Partial<SendSiteNotification>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendSiteNotification'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

export class SiteEventReceived implements IReturnVoid
{
    public id: number;

    public constructor(init?: Partial<SiteEventReceived>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SiteEventReceived'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

export class PublishSiteEventsInQueue implements IReturnVoid
{
    public eventName: string;
    public siteId: number;

    public constructor(init?: Partial<PublishSiteEventsInQueue>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PublishSiteEventsInQueue'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

export class PingEvent implements IReturnVoid
{
    public eventName: string;
    public siteId?: number;
    public body: Object;
    public sendType: EventSendType;

    public constructor(init?: Partial<PingEvent>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PingEvent'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

export class GetLinkInfo implements IReturn<GetLinkInfoResponse>
{
    public link: string;

    public constructor(init?: Partial<GetLinkInfo>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetLinkInfo'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetLinkInfoResponse(); }
}

export class GetSettings extends QueryBase implements IReturn<QueryResponse<GetSettingResponse>>, IPrimaryEntityRequest, IPrimaryListEntityRequest, ICodeEntityRequest, ICodeListEntityRequest
{
    public id?: number;
    public ids: number[];
    public code: string;
    public codes: string[];
    public siteId?: number;
    public siteIdIsNull?: boolean;

    public constructor(init?: Partial<GetSettings>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSettings'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QueryResponse<GetSettingResponse>(); }
}

export class SaveSetting implements IReturn<BaseSaveResponse>, IPrimaryEntity
{
    public id: number;
    public code: string;
    public type: SettingValueType;
    public value: SettingValue;
    public siteId?: number;
    public action: OperationActionType;

    public constructor(init?: Partial<SaveSetting>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SaveSetting'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseSaveResponse(); }
}

export class SaveSettingValue implements IReturn<BaseSaveResponse>
{
    public code: string;
    public type: SettingValueType;
    public value: SettingValue;
    public siteId?: number;
    public isCreateIfNotExists: boolean;

    public constructor(init?: Partial<SaveSettingValue>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SaveSettingValue'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseSaveResponse(); }
}

export class GetSettingGroups implements IReturn<GetSettingGroupsResponse>, ICodeEntityRequest, ICodeListEntityRequest
{
    public code: string;
    public codeContains: string;
    public codes: string[];
    public type?: SettingValueType;

    public constructor(init?: Partial<GetSettingGroups>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSettingGroups'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetSettingGroupsResponse(); }
}

export class GetProcessedRecords extends QueryBase implements IReturn<QueryResponse<GetProcessedRecordResponse>>, IPrimaryEntityRequest, IPrimaryListEntityRequest
{
    public id?: number;
    public createdAt?: string;
    public createdAtGreaterThanOrEqualTo: string;
    public ids: number[];
    public recordId?: number;
    public recordIds: number[];
    public statusIn: ProcessedRecordStatus[];
    public processCode: string;

    public constructor(init?: Partial<GetProcessedRecords>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'GetProcessedRecords'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QueryResponse<GetProcessedRecordResponse>(); }
}

export class GetProcessedRecordGroups implements IReturn<GetProcessedRecordGroupResponse>, ICodeEntityRequest
{
    public code: string;
    public codeContains: string;

    public constructor(init?: Partial<GetProcessedRecordGroups>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetProcessedRecordGroups'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetProcessedRecordGroupResponse(); }
}

export class GetLastProcessStatuses implements IReturn<GetLastProcessStatusesResponse>
{
    public processCode: string;
    public recordIds: number[];
    public statusIn: ProcessedRecordStatus[];
    public expireDate?: string;

    public constructor(init?: Partial<GetLastProcessStatuses>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetLastProcessStatuses'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetLastProcessStatusesResponse(); }
}

export class SaveProcessedRecord implements IReturn<BaseSaveResponse>, IPrimaryEntity, IActionRequest, IData
{
    public id: number;
    public action: OperationActionType;
    public recordId: number;
    public processCode: string;
    public status: ProcessedRecordStatus;
    public error: ProcessedRecordError;
    public data: { [index: string]: Object; };

    public constructor(init?: Partial<SaveProcessedRecord>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SaveProcessedRecord'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseSaveResponse(); }
}

export class GetOpenAiTemplates extends QueryBase implements IReturn<QueryResponse<GetOpenAiTemplateResponse>>, IPrimaryEntityRequest, IPrimaryListEntityRequest
{
    public id?: number;
    public ids: number[];
    public widgetName: string;
    public elementName: string;

    public constructor(init?: Partial<GetOpenAiTemplates>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'GetOpenAiTemplates'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QueryResponse<GetOpenAiTemplateResponse>(); }
}

export class SaveGlobalLocalize implements IReturn<BaseSaveResponse>, IPrimaryEntity, IActionRequest
{
    public id: number;
    public action: OperationActionType;
    public code: string;
    public values: { [index: string]: LocalizeValue; };
    public columns: { [index: string]: LocalizeValue; };

    public constructor(init?: Partial<SaveGlobalLocalize>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SaveGlobalLocalize'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseSaveResponse(); }
}

export class SaveGlobalLocalizeLcz implements IReturnVoid, IEntitySetLczRequest
{
    public id: number;
    public languageCode: string;
    public columnName: string;
    public key: string;
    public value: string;
    public isUserChange: boolean;

    public constructor(init?: Partial<SaveGlobalLocalizeLcz>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SaveGlobalLocalizeLcz'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

export class GetGlobalLocalizeLczConfig extends BaseGetEntityLczConfig implements IReturn<QueryResponse<GetGlobalLocalizeLczConfigResponse>>, ICodeEntityRequest, ICodeListEntityRequest
{
    public code: string;
    public codes: string[];

    public constructor(init?: Partial<GetGlobalLocalizeLczConfig>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'GetGlobalLocalizeLczConfig'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QueryResponse<GetGlobalLocalizeLczConfigResponse>(); }
}

export class TranslateGlobalLocalize extends BaseTranslateRecordRequest implements IReturn<BaseResponse>
{

    public constructor(init?: Partial<TranslateGlobalLocalize>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'TranslateGlobalLocalize'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

export class BuildGlobalLocalizeConfig extends BaseBuildRecordLocalizeConfigRequest implements IReturn<BuildRecordLocalizeConfigResponse<BuildGlobalLocalizeConfigItem>>, ICodeEntityRequest
{
    public code: string;
    public codes: string[];

    public constructor(init?: Partial<BuildGlobalLocalizeConfig>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'BuildGlobalLocalizeConfig'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BuildRecordLocalizeConfigResponse<BuildGlobalLocalizeConfigItem>(); }
}

export class GlobalLocalizeRecord implements IReturn<LocalizeRecordResponse>, IEntityLocalizeRequest
{
    public languageCode: string;
    public codes: string[];
    public columnKeys: string[];
    public valueKeys: string[];

    public constructor(init?: Partial<GlobalLocalizeRecord>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GlobalLocalizeRecord'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new LocalizeRecordResponse(); }
}

export class GetGlobalLocalizations implements IReturn<GetGlobalLocalizationsResponse>, IEntityLocalizeRequest
{
    public codes: string[];
    public languageCode: string;

    public constructor(init?: Partial<GetGlobalLocalizations>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetGlobalLocalizations'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetGlobalLocalizationsResponse(); }
}

export class ExportGlobalLocalizeReport implements IReturnVoid, IExportLczReport
{
    public id?: number;
    public ids: number[];

    public constructor(init?: Partial<ExportGlobalLocalizeReport>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ExportGlobalLocalizeReport'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

export class CreateNewGlobalColumnValue implements IReturn<BaseSaveResponse>
{
    public recordId: number;
    public origin: string;
    public key: string;

    public constructor(init?: Partial<CreateNewGlobalColumnValue>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CreateNewGlobalColumnValue'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseSaveResponse(); }
}

export class GetOpenAiUserAvailableRequestCount implements IReturn<GetOpenAiUserAvailableRequestCountResponse>, ISiteReferenceRequest
{
    public siteId: number;

    public constructor(init?: Partial<GetOpenAiUserAvailableRequestCount>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetOpenAiUserAvailableRequestCount'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetOpenAiUserAvailableRequestCountResponse(); }
}

export class GetGenerateLogoAvailableRequestCount implements IReturn<GetOpenAiUserAvailableRequestCountResponse>, ISiteReferenceRequest
{
    public siteId: number;

    public constructor(init?: Partial<GetGenerateLogoAvailableRequestCount>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetGenerateLogoAvailableRequestCount'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetOpenAiUserAvailableRequestCountResponse(); }
}

export class GetExtentImageAvailableRequestCount implements IReturn<GetOpenAiUserAvailableRequestCountResponse>, ISiteReferenceRequest
{
    public siteId: number;

    public constructor(init?: Partial<GetExtentImageAvailableRequestCount>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetExtentImageAvailableRequestCount'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetOpenAiUserAvailableRequestCountResponse(); }
}

export class GetUserOpenAiHistory extends QueryBase implements IReturn<QueryResponse<GetUserOpenAiHistoryResponse>>, IPrimaryEntityRequest, IPrimaryListEntityRequest
{
    public id?: number;
    public ids: number[];
    public type?: UserOpenAiHistoryType;
    public widgetName: string;
    public elementName: string;
    public value: string;
    public siteId?: number;
    public userAuthId?: number;

    public constructor(init?: Partial<GetUserOpenAiHistory>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'GetUserOpenAiHistory'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QueryResponse<GetUserOpenAiHistoryResponse>(); }
}

export class SaveUserOpenAiHistory implements IReturn<BaseSaveResponse>, IPrimaryEntityRequest
{
    public id?: number;
    public createdAt: string;
    public type: UserOpenAiHistoryType;
    public widgetName: string;
    public elementName: string;
    public toneOfVoice: ToneOfVoice;
    public value: string;
    public siteId?: number;
    public userAuthId?: number;
    public recordId?: number;
    public amount?: number;
    public dependentOn: { [index: string]: string; };
    public response: { [index: string]: Object; };
    public sentences: string[];
    public exception: Object;

    public constructor(init?: Partial<SaveUserOpenAiHistory>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SaveUserOpenAiHistory'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseSaveResponse(); }
}

export class GetProcessEventLogs extends QueryBase implements IReturn<QueryResponse<GetEventLogResponse>>, IPrimaryEntityRequest, IPrimaryListEntityRequest, IOwnerEntityRequest, ICreatedAtRangeRequest
{
    public id?: number;
    public ids: number[];
    public createdAtBefore?: string;
    public createdAtAfter?: string;
    public userAuthId?: number;
    public eventType?: EventLogType;
    public eventTypes: EventLogType[];
    public siteId?: number;
    public processCode: string;

    public constructor(init?: Partial<GetProcessEventLogs>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'GetProcessEventLogs'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QueryResponse<GetEventLogResponse>(); }
}

export class GetEventLogs extends QueryBase implements IReturn<QueryResponse<GetEventLogResponse>>, IPrimaryEntityRequest, IPrimaryListEntityRequest, IOwnerEntityRequest
{
    public id?: number;
    public ids: number[];
    public eventType?: EventLogType;
    public siteId?: number;
    public userAuthId?: number;

    public constructor(init?: Partial<GetEventLogs>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'GetEventLogs'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QueryResponse<GetEventLogResponse>(); }
}

export class SaveEventLog extends BaseEventLog implements IReturn<SaveEventLogResponse>, IAnalyticsTracking
{
    public utmSource: string;
    public utmMedium: string;
    public utmCampaign: string;
    public utmTerm: string;
    public utmContent: string;
    public clientIp: string;

    public constructor(init?: Partial<SaveEventLog>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'SaveEventLog'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SaveEventLogResponse(); }
}

export class GetIsFirstEditorOpen implements IReturn<GetIsFirstEditorOpenResponse>, IPrimaryEntityRequest, IPrimaryListEntityRequest
{
    public id?: number;
    public ids: number[];
    public siteId?: number;
    public userAuthId?: number;

    public constructor(init?: Partial<GetIsFirstEditorOpen>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetIsFirstEditorOpen'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetIsFirstEditorOpenResponse(); }
}

export class ExportEventTypes
{
    public upgradeModalTypes: UpgradeModalTypes;
    public modalTypes: ModalTypes;
    public fileManagerEvents: FileManagerEvents;
    public sidebarType: SidebarType;
    public designActionType: DesignActionType;

    public constructor(init?: Partial<ExportEventTypes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ExportEventTypes'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

export class GetTrackingEventTypeInfo implements IReturn<GetTrackingEventTypeInfoResponse>, ISiteReferenceRequest
{
    public logType: EventLogType;
    public additionalDataKeyValue: string;
    public siteId: number;

    public constructor(init?: Partial<GetTrackingEventTypeInfo>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetTrackingEventTypeInfo'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetTrackingEventTypeInfoResponse(); }
}

export class GetSpecificationValue implements IReturn<GetSpecificationValueResponse>, ISpecificationValueOwnerRequest, ICodeEntityRequest
{
    public siteId?: number;
    public userId?: number;
    public code: string;

    public constructor(init?: Partial<GetSpecificationValue>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSpecificationValue'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetSpecificationValueResponse(); }
}

export class GetSpecificationValues implements IReturn<GetSpecificationValuesResponse>, ISpecificationValueOwnerRequest, ICodeListEntityRequest
{
    public siteId?: number;
    public userId?: number;
    public codes: string[];

    public constructor(init?: Partial<GetSpecificationValues>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSpecificationValues'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetSpecificationValuesResponse(); }
}

export class GetSpecificationValuesDetail implements IReturn<GetSpecificationValuesDetailResponse>, ISpecificationValueOwnerRequest, ICodeListEntityRequest
{
    public siteId?: number;
    public userId?: number;
    public codes: string[];

    public constructor(init?: Partial<GetSpecificationValuesDetail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSpecificationValuesDetail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetSpecificationValuesDetailResponse(); }
}

export class SaveSiteSpecificationValue extends SaveSpecificationValue implements IReturn<BaseSaveResponse>
{

    public constructor(init?: Partial<SaveSiteSpecificationValue>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'SaveSiteSpecificationValue'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseSaveResponse(); }
}

export class SaveUserSpecificationValue extends SaveSpecificationValue implements IReturn<BaseSaveResponse>
{

    public constructor(init?: Partial<SaveUserSpecificationValue>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'SaveUserSpecificationValue'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseSaveResponse(); }
}

export class GetSpecificationListItems extends QueryBase implements IReturn<QueryResponse<GetSpecificationListItemResponse>>, IPrimaryEntityRequest, IPrimaryListEntityRequest, ICodeEntityRequest, ICodeListEntityRequest, ICreatedAtRangeRequest
{
    public id?: number;
    public ids: number[];
    public createdAtBefore?: string;
    public createdAtAfter?: string;
    public code: string;
    public codes: string[];
    public name: string;
    public specificationId: number;
    public originalRecordId?: number;

    public constructor(init?: Partial<GetSpecificationListItems>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSpecificationListItems'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QueryResponse<GetSpecificationListItemResponse>(); }
}

export class SaveSpecificationListItem implements IReturn<BaseSaveResponse>, IPrimaryEntity, IActionRequest, ICodeEntity, IDisplayEntity
{
    public id: number;
    public action: OperationActionType;
    public code: string;
    public name: string;
    public specificationId: number;
    public originalRecordId?: number;

    public constructor(init?: Partial<SaveSpecificationListItem>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SaveSpecificationListItem'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseSaveResponse(); }
}

export class GetSpecifications extends QueryBase implements IReturn<QueryResponse<GetSpecificationResponse>>, IPrimaryEntityRequest, IPrimaryListEntityRequest, ICodeEntityRequest, ICodeListEntityRequest, ICreatedAtRangeRequest
{
    public id?: number;
    public ids: number[];
    public createdAtBefore?: string;
    public createdAtAfter?: string;
    public code: string;
    public codes: string[];
    public name: string;
    public type?: SpecificationType;

    public constructor(init?: Partial<GetSpecifications>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSpecifications'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QueryResponse<GetSpecificationResponse>(); }
}

export class SaveSpecification implements IReturn<BaseSaveResponse>, IPrimaryEntity, IActionRequest, ICodeEntity, IDisplayEntity
{
    public id: number;
    public action: OperationActionType;
    public type?: SpecificationType;
    public ownerType: SpecificationOwnerType;
    public code: string;
    public name: string;

    public constructor(init?: Partial<SaveSpecification>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SaveSpecification'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseSaveResponse(); }
}

export class GetSpecificationRuleGroups extends QueryBase implements IReturn<QueryResponse<GetSpecificationRuleGroupResponse>>, IPrimaryEntityRequest, IPrimaryListEntityRequest, ICodeEntityRequest
{
    public id?: number;
    public ids: number[];
    public code: string;
    public isActive?: boolean;

    public constructor(init?: Partial<GetSpecificationRuleGroups>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSpecificationRuleGroups'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QueryResponse<GetSpecificationRuleGroupResponse>(); }
}

export class SaveSpecificationRuleGroup implements IReturn<BaseSaveResponse>, IPrimaryEntity, ICodeEntity, IActionRequest
{
    public id: number;
    public action: OperationActionType;
    public code: string;
    public isActive?: boolean;
    public condition: SpecificationConditionGroup;
    public rules: SpecificationRule[];
    public isDeleted: boolean;

    public constructor(init?: Partial<SaveSpecificationRuleGroup>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SaveSpecificationRuleGroup'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseSaveResponse(); }
}

export class TestSiteSpecificationRule implements IReturn<TestSpecificationRuleResponse>
{
    public siteIds: number[];
    public rootGroup: SpecificationConditionGroup;
    public isGenerateSql: boolean;

    public constructor(init?: Partial<TestSiteSpecificationRule>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TestSiteSpecificationRule'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new TestSpecificationRuleResponse(); }
}

export class ProcessAnalysePages implements IReturnVoid, IProcessAnalyseRequest
{
    public take: number;
    public threadCount: number;

    public constructor(init?: Partial<ProcessAnalysePages>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProcessAnalysePages'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

export class SearchElasticPages extends BaseSiteSearchElasticRequest implements IReturn<SearchElasticResponse>
{

    public constructor(init?: Partial<SearchElasticPages>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'SearchElasticPages'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SearchElasticResponse(); }
}

export class ProcessAnalysePosts implements IReturnVoid, IProcessAnalyseRequest
{
    public take: number;
    public threadCount: number;

    public constructor(init?: Partial<ProcessAnalysePosts>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProcessAnalysePosts'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

export class SearchElasticPosts extends BaseSiteSearchElasticRequest implements IReturn<SearchElasticResponse>
{

    public constructor(init?: Partial<SearchElasticPosts>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'SearchElasticPosts'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SearchElasticResponse(); }
}

export class ProcessAnalyseProducts implements IReturnVoid, IProcessAnalyseRequest
{
    public take: number;
    public threadCount: number;

    public constructor(init?: Partial<ProcessAnalyseProducts>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProcessAnalyseProducts'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

export class SearchElasticProducts extends BaseSiteSearchElasticRequest implements IReturn<SearchElasticResponse>
{

    public constructor(init?: Partial<SearchElasticProducts>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'SearchElasticProducts'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SearchElasticResponse(); }
}

export class SearchSiteContent implements IReturn<SearchSiteContentResponse>, ISiteLocalizeRequest, ISearchEntityRequest
{
    public search: string;
    public siteId: number;
    public languageCode: string;
    public siteLanguageCode: string;
    public take: number;
    public skip: number;
    public contentTypes: SearchContentType[];
    public preHighlightTag: string;
    public postHighlightTag: string;
    public highlightFragmentSize: number;

    public constructor(init?: Partial<SearchSiteContent>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SearchSiteContent'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SearchSiteContentResponse(); }
}

export class ProcessAnalyseSiteContent implements IReturnVoid
{
    public take: number;
    public threadCount: number;

    public constructor(init?: Partial<ProcessAnalyseSiteContent>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProcessAnalyseSiteContent'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

export class ConversionsApiEventHandler implements IReturnVoid, IPrimaryEntity
{
    public id: number;

    public constructor(init?: Partial<ConversionsApiEventHandler>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConversionsApiEventHandler'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

export class SaveUserConversionsApiEvent implements IReturnVoid
{
    public eventName: string;
    public eventSourceUrl: string;
    public optOut?: boolean;
    public eventId: string;
    public actionSource?: ActionSource;
    public userAuthId?: number;

    public constructor(init?: Partial<SaveUserConversionsApiEvent>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SaveUserConversionsApiEvent'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

export class ActiveCampaignEventHandler implements IReturnVoid, IPrimaryEntity
{
    public id: number;

    public constructor(init?: Partial<ActiveCampaignEventHandler>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ActiveCampaignEventHandler'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

export class GetSiteActiveSpecRules implements IReturn<GetSiteActiveSpecRulesResponse>, ISiteReferenceRequest
{
    public siteId: number;

    public constructor(init?: Partial<GetSiteActiveSpecRules>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSiteActiveSpecRules'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetSiteActiveSpecRulesResponse(); }
}

export class SegmentEventLogHandler implements IReturnVoid, IPrimaryEntity
{
    public id: number;

    public constructor(init?: Partial<SegmentEventLogHandler>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SegmentEventLogHandler'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

export class GetAdminUsersMonthly extends QueryBase implements IReturn<QueryResponse<GetAdminUserMonthlyResponse>>
{

    public constructor(init?: Partial<GetAdminUsersMonthly>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'GetAdminUsersMonthly'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QueryResponse<GetAdminUserMonthlyResponse>(); }
}

export class GetAdminUsersDaily extends QueryBase implements IReturn<QueryResponse<GetAdminUserDailyResponse>>
{

    public constructor(init?: Partial<GetAdminUsersDaily>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'GetAdminUsersDaily'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QueryResponse<GetAdminUserDailyResponse>(); }
}

// @Route("/admin/users", "GET")
export class GetAdminUsers extends QueryBase implements IReturn<QueryResponse<GetAdminUsersResponse>>
{
    public id?: number;
    public ids: number[];
    public createdDateBefore?: string;
    public createdDateAfter?: string;
    public isDeleted?: boolean;
    public mainRole?: AuthRole;
    public ppgReferencedOrderId?: number;
    public category: string;
    public signupIpAddress: string;
    public signupIpAddressContains: string;
    public signupIpCountry: string;
    public signupIpCity: string;
    public firstName: string;
    public firstNameStartsWith: string;
    public firstNameEndsWith: string;
    public firstNameContains: string;
    public email: string;
    public emailStartsWith: string;
    public emailEndsWith: string;
    public emailContains: string;
    public lastName: string;
    public lastNameStartsWith: string;
    public lastNameEndsWith: string;
    public lastNameContains: string;
    public phoneNumber: string;
    public address: string;
    public country: string;
    public city: string;
    public businessNumber: string;
    public leadUserId: string;

    public constructor(init?: Partial<GetAdminUsers>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'GetAdminUsers'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QueryResponse<GetAdminUsersResponse>(); }
}

export class SaveAdminUserAuth implements IReturn<BaseSaveResponse>, IPrimaryEntity
{
    public id: number;
    public category: string;

    public constructor(init?: Partial<SaveAdminUserAuth>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SaveAdminUserAuth'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseSaveResponse(); }
}

export class DeactivateUser implements IReturnVoid
{
    public userId: number;
    public reason: string;
    public isDeactivateSites: boolean;

    public constructor(init?: Partial<DeactivateUser>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DeactivateUser'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

export class AdminChangeUserEmail implements IReturnVoid
{
    public userId: number;
    public newEmail: string;

    public constructor(init?: Partial<AdminChangeUserEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdminChangeUserEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

export class AdminTranslateTextToAllLanguages implements IReturn<AdminTranslateTextToAllLanguagesResponse>
{
    public origin: string;
    public sourceLanguageCode: string;
    public useAi: boolean;
    public textFormat: TextFormat;

    public constructor(init?: Partial<AdminTranslateTextToAllLanguages>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdminTranslateTextToAllLanguages'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AdminTranslateTextToAllLanguagesResponse(); }
}

export class AdminTranslateText implements IReturn<AdminTranslateTextResponse>
{
    public origin: string;
    public sourceLanguageCode: string;
    public targetLanguageCode: string;
    public useAI: boolean;
    public textFormat: TextFormat;

    public constructor(init?: Partial<AdminTranslateText>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdminTranslateText'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AdminTranslateTextResponse(); }
}

export class AdminMergeTranslation implements IReturn<AdminMergeTranslationResponse>
{
    public mainFileName: string;
    public additionalFileName: string;
    public keyColumnName: string;

    public constructor(init?: Partial<AdminMergeTranslation>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdminMergeTranslation'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AdminMergeTranslationResponse(); }
}

export class GetAdminFiles implements IReturn<QueryResponse<GetAdminFilesResponse>>
{
    public prefix: string;
    public bucketName: string;
    public pageSize: number;
    public sizeBytes?: number;
    public regularExpression: string;

    public constructor(init?: Partial<GetAdminFiles>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetAdminFiles'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new QueryResponse<GetAdminFilesResponse>(); }
}

export class GetAdminBuckets implements IReturn<QueryResponse<GetAdminBucketsResponse>>
{
    public pageSize: number;

    public constructor(init?: Partial<GetAdminBuckets>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetAdminBuckets'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new QueryResponse<GetAdminBucketsResponse>(); }
}

export class GetSiteNotes extends QueryBase implements IReturn<QueryResponse<GetSiteNoteResponse>>, IPrimaryEntityRequest, IPrimaryListEntityRequest, ISiteReferenceRequest
{
    public id?: number;
    public ids: number[];
    public siteId: number;

    public constructor(init?: Partial<GetSiteNotes>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSiteNotes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QueryResponse<GetSiteNoteResponse>(); }
}

export class SaveSiteNote implements IReturn<BaseSaveResponse>, IPrimaryEntity, ISiteReferenceEntity, IActionRequest
{
    public id: number;
    public action: OperationActionType;
    public note: string;
    public siteId: number;

    public constructor(init?: Partial<SaveSiteNote>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SaveSiteNote'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseSaveResponse(); }
}

export class AdminScanSiteVideosProcess implements IReturnVoid, IPrimaryListEntityRequest
{
    public ids: number[];

    public constructor(init?: Partial<AdminScanSiteVideosProcess>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdminScanSiteVideosProcess'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

export class GetAdminSitesMonthly extends QueryBase implements IReturn<QueryResponse<GetAdminSiteMonthlyResponse>>
{

    public constructor(init?: Partial<GetAdminSitesMonthly>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'GetAdminSitesMonthly'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QueryResponse<GetAdminSiteMonthlyResponse>(); }
}

export class GetAdminSitesDaily extends QueryBase implements IReturn<QueryResponse<GetAdminSiteDailyResponse>>
{

    public constructor(init?: Partial<GetAdminSitesDaily>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'GetAdminSitesDaily'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QueryResponse<GetAdminSiteDailyResponse>(); }
}

// @Route("/admin/sites", "GET")
export class GetAdminSites extends QueryBase implements IReturn<QueryResponse<GetAdminSitesResponse>>
{
    public siteId?: number;
    public isDeleted?: boolean;
    public utmSource: string;
    public utmMedium: string;
    public utmCampaign: string;
    public contentPackId?: number;
    public contentPackName: string;
    public contentPackNameContains: string;
    public contentPackNameStartsWith: string;
    public designPackId?: number;
    public designPackName: string;
    public designPackNameContains: string;
    public designPackNameStartsWith: string;
    public designPackImageId?: number;
    public designPackImageName: string;
    public designPackImageNameContains: string;
    public designPackImageNameStartsWith: string;
    public structurePackId?: number;
    public structurePackName: string;
    public structurePackNameContains: string;
    public structurePackNameStartsWith: string;
    public siteBusinessCategoryId?: number;
    public siteBusinessCategoryName: string;
    public siteBusinessCategoryNameContains: string;
    public siteBusinessCategoryNameStartsWith: string;
    public domain: string;
    public domainStartsWith: string;
    public domainEndsWith: string;
    public domainContains: string;
    public createdAtBefore?: string;
    public createdAtAfter?: string;
    public customUserAuthId?: number;
    public customUserAuthEmail: string;
    public customUserAuthEmailStartsWith: string;
    public customUserAuthEmailEndsWith: string;
    public customUserAuthEmailContains: string;
    public subscriptionPlan?: HocoosSubscriptionPlan;
    public customUserAuthCreatedDateBefore?: string;
    public customUserAuthCreatedDateAfter?: string;
    public condition: SpecificationConditionGroup;

    public constructor(init?: Partial<GetAdminSites>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'GetAdminSites'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QueryResponse<GetAdminSitesResponse>(); }
}

export class AdminUpgradeSite implements IReturn<BaseSaveResponse>
{
    public id: number;
    public period: HocoosSubscriptionPlanPeriod;
    public endDate: string;

    public constructor(init?: Partial<AdminUpgradeSite>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdminUpgradeSite'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseSaveResponse(); }
}

export class AdminMoveSitePremium implements IReturn<BaseResponse>
{
    public fromSiteId: number;
    public toSiteId: number;

    public constructor(init?: Partial<AdminMoveSitePremium>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdminMoveSitePremium'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BaseResponse(); }
}

export class AdminDeactivateSite implements IReturnVoid, IPrimaryEntity
{
    public id: number;
    public reason: string;
    public reasonType: SiteDeletedReasonType;

    public constructor(init?: Partial<AdminDeactivateSite>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdminDeactivateSite'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

export class GetAdminPages extends QueryBase implements IReturn<QueryResponse<GetAdminPagesResponse>>
{
    public pageId?: number;
    public pageIsDeleted?: boolean;
    public siteId?: number;
    public siteDomain: string;
    public siteDomainStartsWith: string;
    public siteDomainEndsWith: string;
    public siteDomainContains: string;
    public userAuthId?: number;

    public constructor(init?: Partial<GetAdminPages>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'GetAdminPages'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QueryResponse<GetAdminPagesResponse>(); }
}

export class GetAdminQueues implements IReturn<GetAdminQueuesResponse>
{
    public nameContains: string;

    public constructor(init?: Partial<GetAdminQueues>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetAdminQueues'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetAdminQueuesResponse(); }
}

export class GetAdminQueueMessages implements IReturn<GetAdminQueueMessagesResponse>
{
    public queueName: string;
    public take: number;

    public constructor(init?: Partial<GetAdminQueueMessages>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetAdminQueueMessages'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetAdminQueueMessagesResponse(); }
}

export class MqRestartFailedQueueMessages
{
    public queueName: string;
    public messageIds: string[];
    public restartAll: boolean;

    public constructor(init?: Partial<MqRestartFailedQueueMessages>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MqRestartFailedQueueMessages'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

export class GetAdminEventLogs extends QueryBase implements IReturn<QueryResponse<GetAdminEventLogsResponse>>, IPrimaryEntityRequest, IPrimaryListEntityRequest
{
    public id?: number;
    public ids: number[];
    public eventType?: EventLogType;
    public eventTypeIn: EventLogType[];
    public eventLogId?: number;
    public siteId?: number;
    public siteDomain: string;
    public utmSource: string;
    public utmMedium: string;
    public utmCampaign: string;
    public utmTerm: string;
    public utmContent: string;
    public hrefUrl: string;
    public refererUrl: string;
    public siteDomainStartsWith: string;
    public siteDomainEndsWith: string;
    public siteDomainContains: string;
    public siteCreatedAtBefore?: string;
    public siteCreatedAtAfter?: string;
    public eventTypeCreatedAtBefore?: string;
    public eventTypeCreatedAtAfter?: string;
    public userAuthId?: number;
    public customUserAuthEmail: string;
    public customUserAuthEmailStartsWith: string;
    public customUserAuthEmailEndsWith: string;
    public customUserAuthEmailContains: string;
    public customUserAuthFirstName: string;
    public customUserAuthFirstNameStartsWith: string;
    public customUserAuthFirstNameEndsWith: string;
    public customUserAuthFirstNameContains: string;
    public customUserAuthLastName: string;
    public customUserAuthLastNameStartsWith: string;
    public customUserAuthLastNameEndsWith: string;
    public customUserAuthLastNameContains: string;
    public subscriptionPlan?: HocoosSubscriptionPlan;
    public customUserAuthCreatedDateBefore?: string;
    public customUserAuthCreatedDateAfter?: string;
    public clientIp: string;
    public leadUserId: string;

    public constructor(init?: Partial<GetAdminEventLogs>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'GetAdminEventLogs'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QueryResponse<GetAdminEventLogsResponse>(); }
}

export class GetUtmAnalytics implements IReturn<GetUtmAnalyticsQueryResponse>, IAnalyticsTracking
{
    public includeCountry: boolean;
    public country: string;
    public utmSource: string;
    public utmMedium: string;
    public utmCampaign: string;
    public utmTerm: string;
    public utmContent: string;
    public firstWizardOpenedStartDate?: string;
    public firstWizardOpenedEndDate?: string;

    public constructor(init?: Partial<GetUtmAnalytics>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetUtmAnalytics'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetUtmAnalyticsQueryResponse(); }
}

export class ResendAdminEmail implements IReturnVoid, IPrimaryEntity
{
    public id: number;
    public email: string;
    public isUseNewTemplateVersion: boolean;

    public constructor(init?: Partial<ResendAdminEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ResendAdminEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

export class GetDesignTemplateCategories implements IReturn<GetDesignTemplateCategoriesResponse>
{

    public constructor(init?: Partial<GetDesignTemplateCategories>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetDesignTemplateCategories'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetDesignTemplateCategoriesResponse(); }
}

export class GetDesignTemplates extends QueryBase implements IReturn<QueryResponse<GetDesignTemplateResponse>>, IPrimaryEntityRequest, IPrimaryListEntityRequest, ISearchEntityRequest
{
    public id?: number;
    public ids: number[];
    public categoryId?: number;
    public search: string;

    public constructor(init?: Partial<GetDesignTemplates>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'GetDesignTemplates'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QueryResponse<GetDesignTemplateResponse>(); }
}

export class SaveDesignTemplate implements IPrimaryEntity
{
    public id: number;
    public name: string;
    public description: string;
    public siteId: number;
    public categoryId: number;
    public domain: string;

    public constructor(init?: Partial<SaveDesignTemplate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SaveDesignTemplate'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

export class GetDesignTemplateSites extends QueryBase implements IReturn<QueryResponse<GetDesignTemplateSiteResponse>>, IPrimaryEntityRequest, IPrimaryListEntityRequest, ISearchEntityRequest
{
    public id?: number;
    public ids: number[];
    public search: string;

    public constructor(init?: Partial<GetDesignTemplateSites>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'GetDesignTemplateSites'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QueryResponse<GetDesignTemplateSiteResponse>(); }
}

/**
* Sign In
*/
// @Route("/auth", "OPTIONS,GET,POST,DELETE")
// @Route("/auth/{provider}", "OPTIONS,GET,POST,DELETE")
// @Api(Description="Sign In")
// @DataContract
export class Authenticate implements IReturn<AuthenticateResponse>, IPost
{
    /**
    * AuthProvider, e.g. credentials
    */
    // @DataMember(Order=1)
    public provider: string;

    // @DataMember(Order=2)
    public state: string;

    // @DataMember(Order=3)
    public oauth_token: string;

    // @DataMember(Order=4)
    public oauth_verifier: string;

    // @DataMember(Order=5)
    public userName: string;

    // @DataMember(Order=6)
    public password: string;

    // @DataMember(Order=7)
    public rememberMe?: boolean;

    // @DataMember(Order=9)
    public errorView: string;

    // @DataMember(Order=10)
    public nonce: string;

    // @DataMember(Order=11)
    public uri: string;

    // @DataMember(Order=12)
    public response: string;

    // @DataMember(Order=13)
    public qop: string;

    // @DataMember(Order=14)
    public nc: string;

    // @DataMember(Order=15)
    public cnonce: string;

    // @DataMember(Order=17)
    public accessToken: string;

    // @DataMember(Order=18)
    public accessTokenSecret: string;

    // @DataMember(Order=19)
    public scope: string;

    // @DataMember(Order=20)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<Authenticate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'Authenticate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AuthenticateResponse(); }
}

